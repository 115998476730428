import React, {useState,useEffect} from 'react';
import {Link} from 'react-router-dom'

import './Add-Event.styles.css'

// SVG Import 
import {ReactComponent as PigFace} from '../../Assets/pigFace.svg';
import {ReactComponent as Customer} from '../../Assets/customer.svg';
import {ReactComponent as Farm} from '../../Assets/farm.svg';
import {ReactComponent as Dialogue} from '../../Assets/dialogue.svg';

const ShowEvents = (props) => {
    let [togglediv,setToggleDiv] = useState({index:'',toggle:false}) ;
    let [categoryType,setCategoryType] = useState(props.eventClickedCAtegory.replace(/\s+/g,'').toLowerCase());
    let [accordianData,setAccordianData] = useState({title:'',data:[],seller:0})
    let {categoryWiseEvents,deleteEvents,role,isDateClicked,availablecategory,eventClickedCAtegory} = props;
    let onluGrazrEvents = [];
    let groupedEvents= [];
    let nonGrazrEvents = [];
    console.log("props value of ----",props);
    if(categoryWiseEvents && categoryWiseEvents.length > 0){
        groupedEvents = categoryWiseEvents
        .filter(({project_id})=>project_id?true:false)
        console.log(groupedEvents.length,'GGGGGGGGGG')
        
        if(groupedEvents && groupedEvents.length>1){
            groupedEvents
            .reduce((accumulator, pilot)=>{
                if(accumulator != pilot.project_id)
                onluGrazrEvents.push(pilot)
                return accumulator = pilot.project_id
            });
        }else{
            onluGrazrEvents = groupedEvents
        }
        nonGrazrEvents = categoryWiseEvents
        .filter(({project_id})=>!project_id?true:false);
        onluGrazrEvents=onluGrazrEvents.concat(nonGrazrEvents);
        
    }
    function handleChange(e){
        let {name,value}  = e.target;
        let {extendedProps,handleEventClick} = props;

        let eprop = { event:{
           extendedProps:{...extendedProps.event.extendedProps}
        }}
        eprop.event.extendedProps.category = value
        setCategoryType(value)
        handleEventClick(eprop)
     }
    function accordianExpand({project_id,index,toggle,seller}){
        accordianData.title = seller ? 'Seller Information' : 'Buyer Information';
        accordianData.data = seller ? [onluGrazrEvents[0]] : groupedEvents; 
        accordianData.seller = seller;
        setToggleDiv({index,toggle});
        setAccordianData(accordianData);
    }
    return (<>
        {!isDateClicked && role!='seller' ?<>
                        <div id="Showevents1">
                            <div class='selectOptiom'>
                                <select className='select-head' name='categoryType' onChange={handleChange}  value={categoryType}>
                                <option>Select Option</option>
                                    {availablecategory && availablecategory.length>0 
                                        ? availablecategory.map(({category},ind)=>{
                                        return <React.Fragment key={ind}><option value={category} selected={categoryType.toLowerCase()===category.toLowerCase() ? true:false}>{category}</option></React.Fragment>
                                        }):null}
                                    {role !== 'seller' ? <option  value='NonGrazrAnimal' selected={categoryType.toLowerCase()===eventClickedCAtegory.replace(/\s+/g,'').toLowerCase() ? true:false}>Non Grazr Animal</option>:null}
                                </select>
                            </div>
                        </div>
                            </>:null}
                            <div id="Showevents">
                                    <div className="row cursor-pointer Header" >
                                        <div class="col-2">
                                            <div>Id</div>
                                        </div>
                                        <div class="col-2">
                                            <div>Type</div>
                                        </div>
                                        <div class="col-2">
                                            <div>Title</div>
                                        </div>
                                        {/* <div class="col-2">
                                            <div>Seller Info</div>
                                        </div>
                                        <div class="col-2">
                                            <div>Buyer Info</div>
                                        </div> */}
                                        {role!='seller'?<div class="col-6 text-center">
                                            <div>Action</div>
                                        </div>:null}
                                    </div>
                                </div>
        {onluGrazrEvents&&onluGrazrEvents.length > 0 ? 
            onluGrazrEvents.map(({project_id,eventname,fid},index)=>{
         return   <div id='Showevents1' key={index}>
                    <div className='row cursor-pointer'  data-toggle="collapse" data-target={`#collapseExample${index}`} aria-expanded="false" aria-controls="collapseExample">
                        <div className='col-2'>
                            <div className="headid">{project_id ? project_id : <PigFace/> }</div>
                        </div>
                        <div className='col-2'>
                            <div>{project_id ? 'Grazr' : 'Non Grazr'}</div>
                        </div>
                        <div className='col-2'>
                            <div>{eventname}</div>
                        </div>
                        {/* <div className='col-2'>
                            <div>{eventname}</div>
                        </div>
                        <div className='col-2'>
                            <div>{eventname}</div>
                        </div> */}
                        {role!='seller'?<div className='col-6 d-flex justify-content-between'>
                            <div onClick={()=>accordianExpand({project_id:project_id,index:index,toggle:!togglediv.toggle,seller:0})} className={` ${project_id?'visible ':'invisible '}`}><Customer/></div>
                            <div onClick={()=>accordianExpand({project_id:project_id,index:index,toggle:!togglediv.toggle,seller:1})} className={` ${project_id?'visible ':'invisible '}`}> <Farm/></div>
                            <div className={`acrbutton ${project_id?'visible ':'invisible '}`}>Accept</div>
                            <div className={`acrbutton ${project_id?'visible ':'invisible '}`}>Reject</div>
                            <div><i className={`fa fa-times ${!project_id?'visible ':'invisible '}`} onClick={()=>deleteEvents({id:fid,tableName:'forum_questions'})}></i></div>        
                        </div>:null}
                    </div>
                    {togglediv.index === index && togglediv.toggle ? 
                        accordianData&&accordianData.data.length>0 ? 
                            <div class="inneraccordian">
                                <div class="col-4">{accordianData.title}</div>
                                {accordianData.data.map((ele,index)=>{
                                    return <React.Fragment key={index}>
                                        <div className="inneraccordianrow cursor-pointer" >
                                            <div class="col-2">
                                                <div>{accordianData.seller ? "-" : ele.ctid}</div>
                                            </div>
                                            <div class="col-2">
                                                <div>{accordianData.seller ? ele.selleremail : ele.buyeremail}</div>
                                            </div>
                                            <div class="col-2">
                                                <div>{accordianData.seller ? ele.sellerphone : ele.buyerphone}</div>
                                            </div>
                                            <div class="col-2">
                                                <div><Link to={{pathname:`${accordianData.seller?'/sellerdashboard/message':'/buyerdashboard/message'}`,state:{seller:accordianData.seller,userId:accordianData.seller?ele.seller_user_id:ele.buyer_user_id}}}><Dialogue/></Link></div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                                })}
                            </div> : null : null}
                </div>
        }) : null}
    </>)
}
export default ShowEvents;