export const hogParts = [
  {
    id: "organs",
    title: "Organs",
    fontSize: "0px",
    coordinates: { top: "15%", left: "10%" },
    rotation: 0,
    subcuts: [],
  },
  {
    id: "head",
    title: "HEAD",
    fontSize: "20px",
    coordinates: { top: "25%", left: "10%" },
    rotation: -10,
    subParts: [],
  },
  {
    id: "ear",
    title: "EAR",
    fontSize: "16px",
    coordinates: { top: "10%", left: "10%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "snout",
    title: "SNOUT",
    fontSize: "10px",
    coordinates: { top: "28%", left: "-1%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "jowl",
    title: "JOWL",
    fontSize: "20px",
    coordinates: { top: "38%", left: "10%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "neck",
    title: "NECK",
    fontSize: "20px",
    coordinates: { top: "25%", left: "22%" },
    rotation: 90,
    subParts: [],
  },
  {
    id: "blade_shoulder",
    title: "BLADE SHOULDER",
    fontSize: "10px",
    coordinates: { top: "10%", left: "31%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "picnic_ham",
    title: "PICNIC HAM",
    fontSize: "20px",
    coordinates: { top: "40%", left: "32%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "back_fat",
    title: "BACK FAT",
    fontSize: "14px",
    coordinates: { top: "-3%", left: "50%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "spare_ribs",
    title: "SPARE RIBS",
    fontSize: "14px",
    coordinates: { top: "35%", left: "50%" },
    rotation: -30,
    subParts: [],
  },
  {
    id: "loin",
    title: "LOIN",
    fontSize: "25px",
    coordinates: { top: "15%", left: "50%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "bacon",
    title: "BACON",
    fontSize: "22px",
    coordinates: { top: "48%", left: "55%" },
    rotation: -35,
    subParts: [],
  },
  {
    id: "ham",
    title: "HAM",
    fontSize: "18px",
    coordinates: { top: "25%", left: "75%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "hock",
    title: "HOCK",
    fontSize: "18px",
    coordinates: { top: "60%", left: "71%" },
    rotation: 70,
    subParts: [],
  },
];
