import "react-day-picker/lib/style.css";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@material-ui/core";
// import Autocomplete from '@material-ui/lab/Autocomplete';
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import React, { Component } from "react";
import {
  faCross,
  faPlus,
  faTrash,
  faX,
} from "@fortawesome/free-solid-svg-icons";

import { AddCircle } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import { DateTime } from "rrule/dist/esm/datetime";
import DayPickerInput from "react-day-picker/DayPickerInput";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogPopup from "../../../components/Popup/DialogPopup";
import DialogTitle from "@material-ui/core/DialogTitle";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import LoadingBtn from "../../../components/LoadingBtn/LoadingBtn.jsx";
import LoadingButton from "../../../components/LoadingButton/LoadingButton";
import MyAgendaMini from "../../ProcessorDashboard/CalenderSetup/MyAgendaMini.component";
import { NOTIFICATION } from "../../../actions";
import RRuleGenerator from "@protodo-io/react-rrule-generator-lite";
import axios from "axios";
import { connect } from "react-redux";
import { currentServerTime } from "../../../util/socket";
import moment from "moment";
import refreshToken from "./RefreshToken"; // Import the refreshToken function
import AddorUpdateCustomer from "./AddorUpdateCustomer";

class NewOrder extends Component {
  state = {
    isNoOption: false,
    qtyTotal: 0,
    totalanimals: 0,
    cut_sheet_animal: [],
    rrule: "",
    event_name: "",
    schedule_dropoff: "",
    address: "",
    producers_name: "",
    business_name: "",
    organic: "",
    prev_cut_sheet: "",
    new_cut_sheet: "",
    yak_qty: 0,
    elk_qty: 0,
    goat_qty: 0,
    lamb_qty: 0,
    hog_qty: 0,
    bison_qty: 0,
    beef_qty: 0,
    pork_qty: 0,
    poultry_qty: 0,
    deer_qty: 0,
    wild_game_qty: 0,
    type: "",
    phone: "",
    role: "",
    currentDate: "",
    organs: "",
    temp_organs: "",
    dropoff_date: "",
    temp_dropoff_date: "",
    kill_date: "",
    temp_kill_date: "",
    recurring: 0,
    temp_recurring: 0,
    availableSlot: 0,
    usedSlot: 0,
    data: [],
    list_customer: [0],
    scheduleModal: false,
    cutSheetTemp: [],
    dropdownCustomer: [],
    addCustomerDisable: true,
    beef_qty: 0,
    bison_qty: 0,
    goat_qty: 0,
    hog_qty: 0,
    elk_qty: 0,
    lamb_qty: 0,
    yak_qty: 0,
    pork_qty: 0,
    poultry_qty: 0,
    deer_qty: 0,
    wild_game_qty: 0,
    listOrganic: [],
    send_cut_sheet: false,
    addCustomerBtn: false,
    organicModal: false,
    rruleModal: false,
    listFile1: [],
    listFile2: [],
    selectedFile1: null,
    selectedFile2: null,
    customers_all: [],
    submitLoading: false,
    showTabs: false,
    openAddCustomerModal: false,
    dialogOpen: false,
    fields: [{ name: "", amount: "", animal: "" }],
    savedFields: [],
    splitCustomerWhole1Name: "",
    errorMessage: "",
    selectedTab: null, // Track selected tab
    cutSheet: "", // Initialize as an empty array
    clickedOrderNumber: 0,
    selectedCustomersList: [], // New state for the list of selected customers
    dropdownProducer: [],
    customer_type: "customer",
    customer_portal: "yes",
  };
  constructor(props) {
    super(props);
  }

  componentWillReceiveProps = async (props) => {
    // console.log(props);
  };

  componentDidMount() {
    currentServerTime((data) => {
      this.setState({
        currentServerTime: data,
      });
    });
    this.getCutSheetData();
    this.getCustomersData();
    this.getProducerData();
    // console.log('user',this.props.user)
  }
  openAddCustomerModal = () => {
    this.setState({ openAddCustomerModal: true });
  };
  closeAddCustomerModal = () => {
    this.setState({ openAddCustomerModal: false });
  };
  handleChangeName = (event, value) => {
    this.setState({ splitCustomerWhole1Name: value });
  };

  handleSelectChange = (index, selectedObject) => {
    const updatedFields = [...this.state.fields];
    updatedFields[index] = {
      ...updatedFields[index],
      cutsheet: selectedObject,
    };
    this.setState({ fields: updatedFields });
  };

  handleDeleteField = (index) => {
    const fields = [...this.state.fields];

    if (index === 0) {
      fields[index] = { name: "", cutsheet: "", amount: "" };
    } else {
      fields.splice(index, 1);
    }

    this.setState({ fields }, () => {
      // Call handleDeleteField1 after updating the state
      this.handleDeleteField1(index);
    });
  };

  handleDeleteField1 = (index) => {
    // Create a copy of savedFields
    const updatedSavedFields = [...this.state.savedFields];

    // Remove the item at the specified index
    updatedSavedFields.splice(index, 1);

    // Update the state with the modified array
    this.setState({ savedFields: updatedSavedFields });
  };

  handleFieldChange = (index, field, value) => {
    const fields = [...this.state.fields];

    if (field === "name") {
      if (value && value.first_name === "Add") {
        this.setState({ openAddCustomerModal: true });
        // Handle the case where `first_name` is "Add"
        // You might want to open a dialog or show some input for adding a new customer
        console.log("Adding new customer with value:", value);
      } else {
        // Check if value is null before performing the duplicate check
        if (value && value.id) {
          const isDuplicate = fields.some(
            (fieldItem, idx) =>
              fieldItem.name && fieldItem.name.id === value.id && idx !== index
          );

          if (isDuplicate) {
            this.setState({
              errorMessage:
                "Customer already selected. Please choose a different customer.",
            });
            return;
          }
        }

        // Update the field regardless of duplicate check
        fields[index][field] = value;
        this.setState({ fields });
        this.handleAnimalSelection(index, "animal", this.state.selectedTab);
      }
    }
  };
  handleAnimalSelection = (index, field, value) => {
    console.log("index:", index, "field:", field, "value:", value);
    const fields = [...this.state.fields];

    if (field === "animal") {
      fields[index][field] = value;
      this.setState({ fields, errorMessage: "" });
    }
  };

  handleFieldChangeAmount = (index, field, value) => {
    const fields = [...this.state.fields];
    fields[index][field] = value;
    this.setState({ fields });
  };

  handleAddMore = () => {
    if (!this.canAddMore()) {
      this.setState({
        errorMessage: "Cannot add more customers based on the selected amount.",
      });
      return;
    }

    const hasSelectedAmount = this.state.fields.some(
      (field) => field.amount !== ""
    );

    if (hasSelectedAmount) {
      this.setState((prevState) => ({
        fields: [
          ...prevState.fields,
          {
            cutsheet: this.state.cutSheet,
            name: "",
            amount: "",
            animal: "",
            orderNumber: this.state.clickedOrderNumber,
          }, // Add the animal value here},
        ],
        errorMessage: "",
      }));
    } else {
      this.setState({
        errorMessage: "Please select an amount before adding more fields.",
      });
    }
  };

  canAddMore = () => {
    const totalAmount = this.calculateTotalAmount(this.state.fields);
    return totalAmount < 100;
  };

  calculateTotalAmount = (fields) => {
    return fields.reduce((sum, field) => {
      switch (field.amount) {
        case "Whole":
          return sum + 100;
        case "Half":
          return sum + 50;
        case "Quarter":
          return sum + 25;
        default:
          return sum;
      }
    }, 0);
  };

  setSplitOrder = () => {
    const totalAmount = this.calculateTotalAmount(this.state.fields);

    if (totalAmount !== 100) {
      this.setState({ errorMessage: "Total amount must equal 100%." });
    } else {
      // Create a new array based on the current `savedFields`
      const updatedSavedFields = [...this.state.savedFields];

      // Iterate over the current fields and update savedFields accordingly
      this.state.fields.forEach((field) => {
        // Find the index of the existing entry with matching name.id and animal
        if (field.name) {
          const existingIndex = updatedSavedFields.findIndex(
            (savedField) =>
              savedField.name.id === field.name.id &&
              savedField.animal === field.animal
          );

          if (existingIndex !== -1) {
            // If a matching entry (both name.id and animal) is found, replace that entry
            updatedSavedFields[existingIndex] = field;
          } else {
            // If no match is found, add the field to savedFields
            updatedSavedFields.push(field);
          }
        }
      });

      // Update the state
      this.setState(
        {
          errorMessage: "",
          savedFields: updatedSavedFields,
          fields: [{ name: "", amount: "", animal: "" }],
          dialogOpen: false,
        },
        () => {
          // Callback function to ensure state is updated
          console.log(this.state.fields);
        }
      );

      /* if (this.props.onSubmit) {
      this.props.onSubmit(this.state.fields);
    } */
    }
  };

  isAmountDisabled = (amount) => {
    const { fields } = this.state;
    const totalAmount = this.calculateTotalAmount(fields);
    const halfCount = fields.filter((field) => field.amount === "Half").length;
    const quarterCount = fields.filter(
      (field) => field.amount === "Quarter"
    ).length;
    if (amount === "Whole") {
      return totalAmount > 0;
    }
    if (amount === "Half") {
      return totalAmount + 50 > 100 || (halfCount === 2 && quarterCount === 2);
    }

    if (amount === "Quarter") {
      return totalAmount + 25 > 100 || (halfCount === 2 && quarterCount === 2);
    }

    return false;
  };

  handleChangeNameInput = async () => {
    var element = await document.querySelector("#no-option");
    if (element != null) {
      this.setState({ isNoOption: true });
    } else {
      this.setState({ isNoOption: false });
    }
  };

  handleDialogClose = () => {
    this.setState({
      dialogOpen: false,
    });
  };

  // Method to get the list of animals with quantity > 0
  getAnimalTabs() {
    const animals = [
      "beef",
      "bison",
      "hog",
      "lamb",
      "goat",
      "elk",
      "poultry",
      "deer",
      "wild_game",
    ];

    // Filter animals based on quantity and map to include quantity
    return animals
      .map((animal) => ({
        name: animal,
        qty: this.state[`${animal}_qty`] || 0, // Default to 0 if quantity is not set
      }))
      .filter((animal) => animal.qty > 0); // Only include animals with qty > 0
  }

  getCutSheetData = () => {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.user.user_token;
    let url = process.env.REACT_APP_BASE_URL;

    let self = this;
    const dataNya = new FormData();
    dataNya.append("form_status", "1");
    dataNya.append(
      "user_id",
      this.props.user.role == "customer" || this.props.user.role == "producer"
        ? this.props.user.admin
        : this.props.user.id
    );
    axios
      .post(url + "cutsheet/get_custom_form", dataNya)
      .then(function (response) {
        if (response.data.status) {
          self.setState({
            cutSheetTemp: response.data.result.data,
          });
        } else {
          self.props.dispatch(
            NOTIFICATION({
              msg: response.data.message || "something went wrong",
              msgColor: "#FAD5BD",
            })
          );
          setTimeout(() => {
            self.props.dispatch(NOTIFICATION({ msg: "" }));
          }, 3000);
        }
      })
      .catch((error) => {
        self.props.dispatch(
          NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
        );
        setTimeout(() => {
          self.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 2000);
      });
  };

  getCustomerCutSheetData = async (customer_id, index) => {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.user.user_token;
    let url = process.env.REACT_APP_BASE_URL;

    let self = this;

    const data = {
      status: "1",
      user_id:
        this.props.user.role == "customer" || this.props.user.role == "producer"
          ? this.props.user.admin
          : this.props.user.id,
      customer_id: customer_id,
      form_status: "completed",
    };
    axios
      .post(url + "cutsheet/get_custom_form_data", data)
      .then(function (response) {
        if (response.data.status) {
          let list = {};
          response.data.result.data.forEach(function (element, i) {
            let data = {
              label:
                "Order " +
                element.order_id +
                " (" +
                element.type +
                ") " +
                element.name +
                " " +
                moment(element.date_added).format("MM/DD/YYYY") +
                " (" +
                element.form_status +
                ")",
              value: element.id,
            };
            if (!list[element.type]) {
              list[element.type] = [];
            }
            list[element.type].push(data);
          });
          console.log("getCustomerCutSheetData", list, index);

          if (index >= 0) {
            let { customers_all } = self.state;

            self.state.cut_sheet_animal.map((animal) => {
              customers_all[index]["customerCutSheetData"][animal] =
                list[animal];

              if (self.props.attach_cut_sheet) {
                customers_all[index].cut_sheet_data_id[animal] =
                  self.props.attach_cut_sheet;
              }
            });

            self.setState({
              customers_all: customers_all,
            });
          }
        } else {
          self.props.dispatch(
            NOTIFICATION({
              msg: response.data.message || "something went wrong",
              msgColor: "#FAD5BD",
            })
          );
          setTimeout(() => {
            self.props.dispatch(NOTIFICATION({ msg: "" }));
          }, 3000);
        }
      });
  };

  getCustomersData = () => {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.user.user_token;
    let url = process.env.REACT_APP_BASE_URL;

    let self = this;
    axios.get(url + "profile/getAllCustomers").then(function (response) {
      if (response.data.status == "yes") {
        console.log(response.data.data);
        self.setState({ dropdownCustomer: response.data.data });
      } else {
        self.props.dispatch(
          NOTIFICATION({
            msg: response.data.message || "something went wrong",
            msgColor: "#FAD5BD",
          })
        );
      }
    });
  };

  valueChange = (name) => (e) => {
    console.log("change value: ", { [name]: e.target.value });
    this.setState({ [name]: e.target.value });
    if (name == "temp_recurring" && e.target.value == 1) {
      this.setState({ rruleModal: true });
      // setTimeout(this.removeSecondElement, 500);
    }
  };

  removeSecondElement = () => {
    const rrulegenerator = document.querySelector(".rrulegenerator");

    if (rrulegenerator) {
      const elementsToModify = rrulegenerator.querySelectorAll(
        ".col-sm-1.offset-sm-2"
      );
      if (elementsToModify.length >= 2) {
        elementsToModify[1].innerHTML = "on day";
        elementsToModify[1].classList.remove("col-sm-1");
      }
      const elementsToRemove = rrulegenerator.querySelectorAll(".col-sm-1");

      if (elementsToRemove.length >= 2) {
        elementsToRemove[2].remove();
      }
    }
  };

  valueChangeName = (e) => {
    this.setState({ name: e.target.value });
  };

  valueChangeNumber = (name) => async (e) => {
    if (e.target.value >= 0) {
      this.setState({ [name]: parseInt(e.target.value) });
    }

    let beef_qty = parseInt(this.state.beef_qty) || 0;
    let bison_qty = parseInt(this.state.bison_qty) || 0;
    let hog_qty = parseInt(this.state.hog_qty) || 0;
    let lamb_qty = parseInt(this.state.lamb_qty) || 0;
    let goat_qty = parseInt(this.state.goat_qty) || 0;
    let elk_qty = parseInt(this.state.elk_qty) || 0;
    let yak_qty = parseInt(this.state.yak_qty) || 0;
    let pork_qty = parseInt(this.state.pork_qty) || 0;
    let poultry_qty = parseInt(this.state.poultry_qty) || 0;
    let deer_qty = parseInt(this.state.deer_qty) || 0;
    let wild_game_qty = parseInt(this.state.wild_game_qty) || 0;

    eval(`${name} = ${e.target.value ? parseInt(e.target.value) : 0}`);

    let count =
      beef_qty +
      bison_qty +
      hog_qty +
      lamb_qty +
      goat_qty +
      elk_qty +
      yak_qty +
      pork_qty +
      poultry_qty +
      deer_qty +
      wild_game_qty;

    // Enable/Disable add customer button
    if (count > 0) {
      this.setState({ addCustomerDisable: false });
    } else {
      let customers_all = this.state.customers_all.filter(
        (customer, index) => index === 0
      );
      this.setState({ customers_all, addCustomerDisable: true });
    }

    // Gather selected animals
    let cut_sheet_animal = [];
    if (beef_qty) cut_sheet_animal.push("beef");
    if (bison_qty) cut_sheet_animal.push("bison");
    if (hog_qty) cut_sheet_animal.push("hog");
    if (lamb_qty) cut_sheet_animal.push("lamb");
    if (goat_qty) cut_sheet_animal.push("goat");
    if (elk_qty) cut_sheet_animal.push("elk");
    if (yak_qty) cut_sheet_animal.push("yak");
    if (pork_qty) cut_sheet_animal.push("pork");
    if (poultry_qty) cut_sheet_animal.push("poultry");
    if (deer_qty) cut_sheet_animal.push("deer");
    if (wild_game_qty) cut_sheet_animal.push("wild_game");

    // Update the state with the selected animals and total quantity
    this.setState({
      qtyTotal: count,
      totalanimals: count,
      cut_sheet_animal,
      showTabs: true,
    });

    // Find the first animal tab that matches a cut_sheet_animal and update the selectedTab
    const animalTabs = this.getAnimalTabs();
    const selectedAnimal = animalTabs.find((tab) =>
      cut_sheet_animal.includes(tab.name)
    );

    if (selectedAnimal) {
      this.setState({
        /*  selectedTab: selectedAnimal.name, */
        qtyTotal: selectedAnimal.qty, // You may want to keep count here if needed
      });
    }

    console.log("Selected animals: ", cut_sheet_animal);
    console.log("Total count: ", count);
  };

  animalLabel = (code) => {
    if (typeof code === "undefined") return "";

    const arr = code.split("_");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(" ");
  };

  postNewOrder = () => {
    this.setState({
      submitLoading: true,
    });
    console.log(this.state.savedFields);
    var data = {
      event_name: this.state.event_name,
      organic: this.state.organic,
      beef_qty: this.state.beef_qty,
      bison_qty: this.state.bison_qty,
      hog_qty: this.state.hog_qty,
      lamb_qty: this.state.lamb_qty,
      goat_qty: this.state.goat_qty,
      elk_qty: this.state.elk_qty,
      yak_qty: this.state.yak_qty,
      pork_qty: this.state.pork_qty,
      poultry_qty: this.state.poultry_qty,
      deer_qty: this.state.deer_qty,
      wild_game_qty: this.state.wild_game_qty,
      // dropoff_date: localStorage.getItem("schedule_day_off"),
      // kill_date: localStorage.getItem("schedule_kill"),
      dropoff_date: this.state.dropoff_date,
      kill_date: this.state.kill_date,
      recurring: this.state.recurring,
      collect_deposit: 1,
      send_cut_sheet: this.state.sendcs,
      organs: this.state.organs,
      process_option: this.state.label,
      sex: this.state.sex,
      day: localStorage.getItem("day"),
      status: 0,
      extra_field_1: "",
      organ: "yes",
      collect_deposit: this.state.deposit,
      customers: this.state.customers_all,
      organic_verify: this.state.listOrganic,
      rrule: this.state.rrule,
      split: this.state.savedFields,
    };

    var emailOrPhone = this.state.emailOrPhoneDeposit
      ? this.state.emailOrPhoneDeposit.includes("@")
      : false;

    if (emailOrPhone) {
      data["deposite_email"] = this.state.emailOrPhoneDeposit;
    } else {
      data["deposite_phone"] = this.state.emailOrPhoneDeposit;
    }

    var listFile1 = "";
    var listFile2 = "";

    this.state.listFile1.map((e, index) => {
      if (index == 0) {
        listFile1 = listFile1 + e.id;
      } else {
        listFile1 = listFile1 + "," + e.id;
      }
    });

    this.state.listFile2.map((e, index) => {
      if (index == 0) {
        listFile2 = listFile2 + e.id;
      } else {
        listFile2 = listFile2 + "," + e.id;
      }
    });

    data["organic_certified_file"] = listFile1;
    data["thirty_plus_file"] = listFile2;

    console.log(listFile1);
    console.log(listFile2);
    console.log(data);

    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.user.user_token;
    let url = process.env.REACT_APP_BASE_URL;

    axios
      .post(url + "order/createManualOrder", data)
      .then((response) => {
        if (response && response.status == 200) {
          console.log(response.data.status);
          if (response.data.status == "no") {
            this.props.dispatch(
              NOTIFICATION({ msg: response.data.message, msgColor: "#FAD5BD" })
            );
            setTimeout(() => {
              this.props.dispatch(NOTIFICATION({ msg: "" }));
            }, 2000);
          } else {
            this.props.dispatch(
              NOTIFICATION({ msg: "Add New Order Successfully" })
            );
            setTimeout(() => {
              this.props.dispatch(NOTIFICATION({ msg: "" }));
              window.location.reload();
            }, 3000);
            return false;
          }
        }

        this.setState({
          submitLoading: false,
        });
      })
      .catch((error) => {
        this.props.dispatch(
          NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
        );
        setTimeout(() => {
          this.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 2000);

        this.setState({
          submitLoading: false,
        });
      });
  };

  addAditionalCustomer = async (orderNumber) => {
    const { selectedTab, savedFields, fields } = this.state;
    const updatedFields = [];
    let hasMatchingFields = false;

    // Collect fields that match the selectedTab and order number
    savedFields.forEach((field) => {
      if (field.animal === selectedTab && field.orderNumber === orderNumber) {
        hasMatchingFields = true; // Set flag to true if there's a match
        if (Array.isArray(field)) {
          updatedFields.push(...field);
        } else {
          updatedFields.push(field);
        }
      }
    });

    if (hasMatchingFields) {
      // If matching fields are found, update the state with those fields
      await this.setState({ fields: updatedFields });
    } else {
      // If no matching fields, initialize an empty field for the new order
      await this.setState({
        fields: [
          {
            name: "",
            amount: "",
            animal: selectedTab,
            orderNumber: orderNumber,
          },
        ],
      });
    }

    // Open the dialog to add or modify the customer
    await this.setState({
      customers_all: this.state.customers_all,
      dialogOpen: true,
      clickedOrderNumber: orderNumber,
    });
  };

  setDropOff = (date) => {
    console.log("setdropoffdate:", date);
    this.setState({
      temp_dropoff_date: date,
    });
  };

  setKillDate = (date) => {
    this.setState({
      temp_kill_date: date,
    });
  };

  customerFieldHandle = (e, index) => {
    let { customers_all } = this.state;
    console.log("customerFieldHandle ====", e.target.value);
    let { name, value } = e.target;
    // eval( `let ${name} = value;`)
    customers_all[index][name] = value;
    this.setState({
      customers_all: customers_all,
    });
  };

  cutSheetHandle = (e, index, animal) => {
    let { customers_all } = this.state;
    let { name, value } = e.target;
    // eval( `let ${name} = value;`)
    customers_all[index][name][animal] = value;
    this.setState({
      customers_all: customers_all,
    });
  };

  handleChangeOrganicData = (e, index) => {
    let { listOrganic } = this.state;
    console.log("customerFieldHandle ====", e.target.value);
    let { name, value } = e.target;
    listOrganic[index][name] = value;
    this.setState({
      listOrganic: listOrganic,
    });
  };

  handleChangeOrganic = (e) => {
    this.setState({ organic: e.target.value });
    if (e.target.value == "yes") {
      this.setState({ organicModal: true });
      var listOrganic = [];

      var beef_qty = this.state.beef_qty;
      var bison_qty = this.state.bison_qty;
      var hog_qty = this.state.hog_qty;
      var lamb_qty = this.state.lamb_qty;
      var goat_qty = this.state.goat_qty;
      var elk_qty = this.state.elk_qty;
      var yak_qty = this.state.yak_qty;
      var pork_qty = this.state.pork_qty;
      var poultry_qty = this.state.poultry_qty;
      var deer_qty = this.state.deer_qty;
      var wild_game_qty = this.state.wild_game_qty;
      // var date = localStorage.getItem("schedule_day_off")
      var date = this.state.dropoff_date;

      for (var i = 0; beef_qty > i; i++) {
        listOrganic.push({
          species: "beef",
          ear_tag_id: null,
          hang: 0,
          date: date,
          organic: "",
          organs: "",
          sex: "",
          label: "",
        });
      }

      for (var i = 0; bison_qty > i; i++) {
        listOrganic.push({
          species: "bison",
          ear_tag_id: null,
          hang: 0,
          date: date,
          organic: "",
          organs: "",
          sex: "",
          label: "",
        });
      }

      for (var i = 0; hog_qty > i; i++) {
        listOrganic.push({
          species: "hog",
          ear_tag_id: null,
          hang: 0,
          date: date,
          organic: "",
          organs: "",
          sex: "",
          label: "",
        });
      }

      for (var i = 0; lamb_qty > i; i++) {
        listOrganic.push({
          species: "lamb",
          ear_tag_id: null,
          hang: 0,
          date: date,
          organic: "",
          organs: "",
          sex: "",
          label: "",
        });
      }

      for (var i = 0; goat_qty > i; i++) {
        listOrganic.push({
          species: "goat",
          ear_tag_id: null,
          hang: 0,
          date: date,
          organic: "",
          organs: "",
          sex: "",
          label: "",
        });
      }

      for (var i = 0; elk_qty > i; i++) {
        listOrganic.push({
          species: "elk",
          ear_tag_id: null,
          hang: 0,
          date: date,
          organic: "",
          organs: "",
          sex: "",
          label: "",
        });
      }

      // for (var i = 0; yak_qty > i; i++) {
      //   listOrganic.push({
      //     "species": "yak",
      //     "ear_tag_id": null,
      //     "hang": 0,
      //     "date": date,
      //     "organic": "",
      //     "organs": "",
      //     "sex": "",
      //     "label": "",
      //   })
      // }

      for (var i = 0; pork_qty > i; i++) {
        listOrganic.push({
          species: "pork",
          ear_tag_id: null,
          hang: 0,
          date: date,
          organic: "",
          organs: "",
          sex: "",
          label: "",
        });
      }

      for (var i = 0; poultry_qty > i; i++) {
        listOrganic.push({
          species: "poultry",
          ear_tag_id: null,
          hang: 0,
          date: date,
          organic: "",
          organs: "",
          sex: "",
          label: "",
        });
      }

      for (var i = 0; deer_qty > i; i++) {
        listOrganic.push({
          species: "deer",
          ear_tag_id: null,
          hang: 0,
          date: date,
          organic: "",
          organs: "",
          sex: "",
          label: "",
        });
      }

      for (var i = 0; wild_game_qty > i; i++) {
        listOrganic.push({
          species: "wild_game",
          ear_tag_id: null,
          hang: 0,
          date: date,
          organic: "",
          organs: "",
          sex: "",
          label: "",
        });
      }

      this.setState({ listOrganic: listOrganic });
    }
    // let { customers_all } = this.state;
    // console.log("customerFieldHandle ====", e.target.value);
    // let { name, value } = e.target;
    // customers_all[index][name] = value;
    // this.setState({
    //   customers_all: customers_all,
    // });
  };

  handleChangeName1 = (event, value) => {
    if (value && value.first_name === "Add") {
      this.setState({ openAddCustomerModal: true });
      // Handle the case where `first_name` is "Add"
      // You might want to open a dialog or show some input for adding a new customer
      console.log("Adding new customer with value:", value);
    } else {
      this.setState({ splitCustomerWhole1Name: value });
    }
  };

  handleChangeName = (o, v, r, index) => {
    // console.log("customerFieldHandle ====", v.id);
    if (v == null) {
      this.setState({ openAddCustomerModal: true });
      // Handle the case where `first_name` is "Add"
      // You might want to open a dialog or show some input for adding a new customer
    }

    let { customers_all } = this.state;

    customers_all[index]["customer_id"] = v.id;
    customers_all[index]["phone"] = v.phone_number;
    customers_all[index]["address"] = v.street_address;
    customers_all[index]["customer_name"] = v;
    if (v !== null && v.id === false) {
      customers_all[index]["customer_name"] = null;
      this.setState({
        addCustomerModal: true,
      });
    }
    this.setState({
      customers_all,
    });

    this.getCustomerCutSheetData(v.id, index);
  };

  handleChangeNameInput = async () => {
    var element = await document.querySelector("#no-option");
    if (element != null) {
      this.setState({ isNoOption: true });
    } else {
      this.setState({ isNoOption: false });
    }
  };

  handleCheckboxSelect = (e, data) => {
    if (e.target.checked) {
      let arr = this.state.temp_organs
        .split(",")
        .filter((organ) => organ !== "");
      arr.push(data);
      var txt = arr.join(",");
      this.setState({ temp_organs: txt });
    } else {
      var txt = this.state.temp_organs
        .split(",")
        .filter((organ) => {
          if (organ.trim() !== data) return organ;
        })
        .join(",");
      this.setState({ temp_organs: txt });
    }
  };

  handleNoMatches = (value) => {
    console.log(`No matches found for: ${value}`);
  };

  handleDatePicker = (e, name) => {
    const originalDate = new Date(e);
    const formattedDate = `${originalDate.getFullYear()}-${(
      originalDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${originalDate.getDate().toString().padStart(2, "0")}`;
    this.setState({
      [name]: formattedDate,
    });

    console.log("name", name);
    console.log("formattedDate", formattedDate);
  };

  selectFile1 = (e) => {
    this.setState({
      selectedFile1: e.target.files[0],
    });
  };

  selectFile2 = (e) => {
    this.setState({
      selectedFile2: e.target.files[0],
    });
  };
  addCustomerToList = (type) => {
    const { splitCustomerWhole1Name, selectedCustomersList } = this.state;

    if (splitCustomerWhole1Name) {
      const listToUpdate =
        type === "Customers" ? selectedCustomersList : selectedCustomersList;
      const customerAlreadyInList = listToUpdate.some(
        (customer) => customer.id === splitCustomerWhole1Name.id
      );

      // If the customer is not already in the list, add them
      if (!customerAlreadyInList) {
        this.setState((prevState) => ({
          [type === "Customers"
            ? "selectedCustomersList"
            : "selectedCustomersList"]: [
            ...listToUpdate,
            {
              ...splitCustomerWhole1Name,
              preType: "add", // Set preType field here
            },
          ],
          splitCustomerWhole1Name: null, // Clear the selected name after adding
        }));
      }
    }
  };

  fetch_all_customer = () => {
    let url = process.env.REACT_APP_BASE_URL;
    let self = this;
    self.setState({
      customer_loading: true,
    });

    let data = new FormData();
    self.setState({
      scheduled_order: [],
      scheduled_order_download: [{ "No data found": "" }],
      scheduled_order_loading: true,
    });

    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.user.user_token;
    axios.get(`${url}profile/getAllCustomers`).then(function (response) {
      if (
        response &&
        response.data &&
        response.data &&
        response.data.status === "no"
      ) {
        self.props.dispatch(
          NOTIFICATION({ msg: response.data.message, msgColor: "#FAD5BD" })
        );
        setTimeout(() => {
          self.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 2000);
      } else {
        let listing_data = response.data.data;

        if (listing_data && listing_data.length > 0) {
          self.setState({ customer_data: listing_data });
        } else {
          self.setState({
            scheduled_order: [],
            scheduled_order_download: [{ "No data found": "" }],
            scheduled_order_loading: false,
          });
        }
      }

      self.setState({
        customer_loading: false,
      });
    });
  };
  postCustomer = async () => {
    try {
      // Attempt to refresh token
      const newAccessToken = await refreshToken();
      console.log(newAccessToken);

      // Token refresh successful, set the new access token in axios headers
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.props.user.user_token;
      axios.defaults.headers.common["qb-auth-token"] = newAccessToken; // Add your qb-auth-token here

      // Determine the value of the customers field
      let customerIds = null;
      if (this.state.customer_type !== "customer") {
        if (this.state.selectedCustomersList) {
          customerIds = this.state.selectedCustomersList.map(
            (customer) => customer.user_ref
          );
        }
      }

      // Your axios post request
      let url = process.env.REACT_APP_BASE_URL;

      const data = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        phone: this.state.phone_number,
        address: this.state.address,
        city: this.state.city,
        state: this.state.state,
        zip: this.state.zip,
        custom_portal: this.state.customer_portal == "yes" ? 1 : 0,
        type: this.state.customer_type,
        enablecp: this.state.customer_portal,
        producer:
          /* this.state.selectedProducer
            ? this.state.selectedProducer.id === ""
              ? null
              : this.state.selectedProducer.id
            : */ this.state.selectedProducer,
        customers: customerIds ?? [], // Pass the list of customer IDs or null
      };
      await new Promise((resolve) => {
        axios
          .post(url + "profile/addCustomer", data)
          .then((response) => {
            console.log(response.data.message);
            if (response && response.status === 200) {
              console.log(response.data.message);
              if (response.data.status === "no") {
                this.props.dispatch(
                  NOTIFICATION({
                    msg: response.data.message,
                    msgColor: "#FAD5BD",
                  })
                );
                setTimeout(() => {
                  this.props.dispatch(NOTIFICATION({ msg: "" }));
                }, 2000);
              } else {
                this.setState({
                  addModal: false,
                  first_name: "",
                  last_name: "",
                  email: "",
                  phone_number: "",
                  address: "",
                  city: "",
                  state: "",
                  zip: "",
                  selectedProducer: null,
                  selectedCustomersList: [],
                });

                this.fetch_all_customer();
                this.getCustomersData();
                this.props.dispatch(
                  NOTIFICATION({ msg: "Add Customer Successfully" })
                );
                setTimeout(() => {
                  this.props.dispatch(NOTIFICATION({ msg: "" }));
                }, 3000);
              }
            }
            resolve(true);
          })
          .catch((error) => {
            this.props.dispatch(
              NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
            );
            setTimeout(() => {
              this.props.dispatch(NOTIFICATION({ msg: "" }));
            }, 2000);
            resolve(false);
          });
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  removeCustomerFromList = (customerId, preType, type, subtype) => {
    this.setState(
      (prevState) => {
        const { selectedCustomersList, deletedCustomersList } = prevState;
        if (subtype === "Customer") {
          // Create a new Set to avoid duplicates in deletedCustomersList
          let updatedDeletedCustomersSet = new Set(deletedCustomersList);

          // Add customerId to the Set if preType is 'update'
          if (preType === "update") {
            updatedDeletedCustomersSet.add(customerId);
          }

          // Convert the Set back to an array
          let updatedDeletedCustomersList = Array.from(
            updatedDeletedCustomersSet
          );

          // Determine which list to filter based on 'type'
          if (type === "first") {
            return {
              deletedCustomersList: updatedDeletedCustomersList,
              selectedCustomersList: selectedCustomersList.filter(
                (customer) => customer.id !== customerId
              ),
            };
          } else if (type === "second") {
            return {
              deletedCustomersList: updatedDeletedCustomersList,
              selectedCustomersList: selectedCustomersList.filter(
                (customer) => customer.id !== customerId
              ),
            };
          }
        } else {
        }
      },
      () => {
        // Log after state update to confirm changes
        console.log("After State Update:");
        console.log("deletedCustomersList:", this.state.deletedCustomersList);

        if (type === "list1") {
          console.log(
            "selectedCustomersList:",
            this.state.selectedCustomersList
          );
        } else if (type === "list2") {
          console.log(
            "selectedCustomersList:",
            this.state.selectedCustomersList
          );
        }
      }
    );
  };

  uploadFile1 = () => {
    const { selectedFile1 } = this.state;

    if (!selectedFile1) {
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile1);

    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.user.user_token;
    let url = process.env.REACT_APP_BASE_URL;

    axios
      .post(url + "misc/addAttachment", formData)
      .then((response) => {
        if (response && response.status == 200) {
          console.log(response.data.uploaded);
          if (response.data.uploaded != 1) {
            this.props.dispatch(
              NOTIFICATION({ msg: "Upload File Failed", msgColor: "#FAD5BD" })
            );
            setTimeout(() => {
              this.props.dispatch(NOTIFICATION({ msg: "" }));
            }, 2000);
          } else {
            var files = this.state.listFile1;
            files.push({
              name: response.data.fileName,
              id: response.data.attachment_id,
            });
            this.setState({
              selectedFile1: null,
              listFile1: files,
            });

            this.props.dispatch(
              NOTIFICATION({ msg: "Upload Image Successfully" })
            );
            setTimeout(() => {
              this.props.dispatch(NOTIFICATION({ msg: "" }));
            }, 2000);

            return false;
          }
        } else {
        }
      })
      .catch((error) => {
        this.props.dispatch(
          NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
        );
        setTimeout(() => {
          this.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 2000);
      });
  };

  uploadFile2 = () => {
    const { selectedFile2 } = this.state;

    if (!selectedFile2) {
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile2);

    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.user.user_token;
    let url = process.env.REACT_APP_BASE_URL;

    axios
      .post(url + "misc/addAttachment", formData)
      .then((response) => {
        if (response && response.status == 200) {
          console.log(response.data.uploaded);
          if (response.data.uploaded != 1) {
            this.props.dispatch(
              NOTIFICATION({ msg: "Upload File Failed", msgColor: "#FAD5BD" })
            );
            setTimeout(() => {
              this.props.dispatch(NOTIFICATION({ msg: "" }));
            }, 2000);
          } else {
            var files = this.state.listFile2;
            files.push({
              name: response.data.fileName,
              id: response.data.attachment_id,
            });
            this.setState({
              selectedFile2: null,
              listFile2: files,
            });

            this.props.dispatch(
              NOTIFICATION({
                msg: "Upload " + response.data.fileName + " Successfully",
              })
            );
            return false;
          }
        } else {
        }
      })
      .catch((error) => {
        this.props.dispatch(
          NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
        );
        setTimeout(() => {
          this.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 2000);
      });
  };

  getAvailableSlot = () => {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.user.user_token;
    let url = process.env.REACT_APP_BASE_URL;
    console.log("dropoff date", this.state.dropoff_date);
    var data = {
      // date: localStorage.getItem('schedule_day_off'),
      date: this.state.temp_dropoff_date,
    };

    axios
      .post(url + "calendar/get_date_available_slot", data)
      .then((response) => {
        if (response && response.status == 200) {
          if (response.data.status == "no") {
            this.props.dispatch(
              NOTIFICATION({ msg: response.data.message, msgColor: "#FAD5BD" })
            );
            setTimeout(() => {
              this.props.dispatch(NOTIFICATION({ msg: "" }));
            }, 2000);
          } else {
            this.getOrder();
            this.setState({
              availableSlot: response.data.data.available,
              usedSlot: response.data.data.used,
              dropoff_date: this.state.temp_dropoff_date,
              kill_date: this.state.temp_dropoff_date,
              recurring: this.state.temp_recurring,
            });

            return false;
          }
        }
      })
      .catch((error) => {
        this.props.dispatch(
          NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
        );
        setTimeout(() => {
          this.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 2000);
      });
  };

  getOrder = () => {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.user.user_token;
    let url = process.env.REACT_APP_BASE_URL;
    var data = {
      // date: localStorage.getItem('schedule_day_off'),
      date: this.state.dropoff_date,
      order_type: "pending",
    };

    axios
      .post(url + "order/pro_order_list", data)
      .then((response) => {
        if (response && response.status == 200) {
          if (response.data.status == "no") {
            // this.props.dispatch(NOTIFICATION({ msg: "Add Customer Failed", msgColor: "#FAD5BD" }))
            //   setTimeout(() => {
            //     this.props.dispatch(NOTIFICATION({ msg: "" }))
            //   }, 2000);
          } else {
            this.setState({
              // availableSlot: response.data.data.available,
              // usedSlot: response.data.data.used,
              // scheduleModal:false
              data: response.data.data,
              scheduleModal: false,
            });

            return false;
          }
        }
      })
      .catch((error) => {
        this.props.dispatch(
          NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
        );
        setTimeout(() => {
          this.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 2000);
      });
  };

  speciesLabel = (species) => {
    if (species == "wild_game") {
      return "Wild Game";
    } else {
      return species.charAt(0).toUpperCase() + species.slice(1);
    }
  };
  handleTabClick = (animalName) => {
    const animalTabs = this.getAnimalTabs();
    const animal = animalTabs.find((tab) => tab.name === animalName);
    const qty = animal ? animal.qty : 0;

    this.setState({
      selectedTab: null,
      selectedTab: animalName,
      qtyTotal: qty,
    });
  };

  animalLabel = (animal) => {
    // Customize this method as needed
    return animal;
  };

  handleOpenAddCustomerModal = () => {
    this.setState({ openAddCustomerModal: true });
  };

  getProducerData = () => {
    // Ensure Authorization header is set
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.user.user_token}`;
    const url = `${process.env.REACT_APP_BASE_URL}profile/getProducers`;

    // Use arrow function to keep the context of 'this'
    axios
      .get(url)
      .then((response) => {
        console.log("response data", response.data.data); // Log the response data directly

        if (response.data.status === "yes") {
          // Handle the case where this.props.cart might be undefined
          const cart = this.props.cart || {}; // Default to an empty object

          const customer = {
            id: cart.form_customer_id || "", // Default to empty string if undefined
            first_name: cart.first_name || "",
            last_name: cart.last_name || "",
            email: cart.email || "",
            phone: cart.phone || "",
          };

          // Update state with the fetched producer data
          this.setState({
            dropdownProducer: response.data.data,
            splitCustomerWhole1: customer.id,
            splitCustomerWhole1Name: customer,
            splitCustomerHalf1: customer.id,
            splitCustomerHalf1Name: customer,
            splitCustomerQuarter1: customer.id,
            splitCustomerQuarter1Name: customer,
          });
        } else {
          // Handle the case where the response status is not 'yes'
          this.props.dispatch(
            NOTIFICATION({ msg: response.data.message, msgColor: "#FAD5BD" })
          );
          setTimeout(() => {
            this.props.dispatch(NOTIFICATION({ msg: "" }));
          }, 2000);
        }
      })
      .catch((error) => {
        // Handle any errors from the API request
        console.error("Error fetching data:", error.message); // Log the error message
        this.props.dispatch(
          NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
        );
        setTimeout(() => {
          this.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 2000);
      });
  };

  render() {
    // console.log('state', this.state)
    const animalTabs = this.getAnimalTabs();
    const { selectedTab, dialogOpen, qtyTotal, totalanimals } = this.state;
    // Define the styles
    const animalTabsStyle = {
      display: "flex",
      overflowX: "auto",
      marginBottom: "20px",
    };

    const animalTabStyle = {
      backgroundColor: "#ddd",
      border: "2px solid #ddd",
      borderRadius: "6px",
      padding: "10px 15px",
      marginRight: "10px",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
      fontSize: "12px", // Adjust the font size as needed
    };

    const animalTabHoverStyle = {
      backgroundColor: "#e0e0e0",
    };

    const selectedAnimalTabStyle = {
      ...animalTabStyle,
      backgroundColor: "#388e3c", // Green color for the selected tab
      color: "#388e3c", // White text for better contrast
      borderColor: "#388e3c",
      fontWeight: "bold", // Darker green border
    };

    const tableCellStyle = {
      border: "1px solid #ccc",
      padding: "10px",
      textAlign: "left",
      color: "#555",
      borderRadius: "8px", // Add border-radius here
    };

    const tableHeaderStyle = {
      border: "1px solid #ccc",
      padding: "10px",
      backgroundColor: "#f4f4f4",
      textAlign: "left",
      fontWeight: "bold",
    };

    const isCustomer = this.props.user.role === "customer";

    return (
      <>
        <div className="">
          <div className="list-customer">
            <div className="list-customer-item">
              <div className="event-detail-wrap">
                <div className="">
                  <div className="row">
                    {/*  <div className="form-group col-md-6">
                      <label className="form-label">Business Name</label>
                      <input
                        className="form-control"
                        type="text"
                        name="event_name"
                        onChange={this.valueChange("event_name")}
                      ></input>
                    </div> */}
                    {this.props.user.nmfs_number
                      .split(",")
                      .includes("beef") && (
                      <div className="form-group col-md-6">
                        <label className="form-label">Beef Quantity</label>
                        <input
                          className="form-control"
                          type="number"
                          name="beef_qty"
                          value={this.state.beef_qty}
                          onChange={this.valueChangeNumber("beef_qty")}
                        ></input>
                      </div>
                    )}
                    {this.props.user.nmfs_number
                      .split(",")
                      .includes("bison") && (
                      <div className="form-group col-md-6">
                        <label className="form-label">Bison Quantity</label>
                        <input
                          className="form-control"
                          type="number"
                          name="bison_qty"
                          value={this.state.bison_qty}
                          onChange={this.valueChangeNumber("bison_qty")}
                        ></input>
                      </div>
                    )}
                    {this.props.user.nmfs_number.split(",").includes("hog") && (
                      <div className="form-group col-md-6">
                        <label className="form-label">Hog Quantity</label>
                        <input
                          className="form-control"
                          type="number"
                          name="hog_qty"
                          value={this.state.hog_qty}
                          onChange={this.valueChangeNumber("hog_qty")}
                        ></input>
                      </div>
                    )}
                    {this.props.user.nmfs_number
                      .split(",")
                      .includes("lamb") && (
                      <div className="form-group col-md-6">
                        <label className="form-label">Lamb Quantity</label>
                        <input
                          className="form-control"
                          type="number"
                          name="lamb_qty"
                          value={this.state.lamb_qty}
                          onChange={this.valueChangeNumber("lamb_qty")}
                        ></input>
                      </div>
                    )}
                    {this.props.user.nmfs_number
                      .split(",")
                      .includes("goat") && (
                      <div className="form-group col-md-6">
                        <label className="form-label">Goat Quantity</label>
                        <input
                          className="form-control"
                          type="number"
                          name="goat_qty"
                          value={this.state.goat_qty}
                          onChange={this.valueChangeNumber("goat_qty")}
                        ></input>
                      </div>
                    )}
                    {this.props.user.nmfs_number.split(",").includes("elk") && (
                      <div className="form-group col-md-6">
                        <label className="form-label">Elk Quantity</label>
                        <input
                          className="form-control"
                          type="number"
                          name="elk_qty"
                          value={this.state.elk_qty}
                          onChange={this.valueChangeNumber("elk_qty")}
                        ></input>
                      </div>
                    )}
                    {/* {this.props.user.nmfs_number.split(',').includes('yak') &&
                      <div className="form-group col-md-6">
                        <label className="form-label">Yak Quantity</label>
                        <input className="form-control" type="number" name="yak_qty"
                          value={this.state.yak_qty}
                          onChange={this.valueChangeNumber("yak_qty")}>
                        </input>
                      </div>} */}
                    {/*this.props.user.nmfs_number.split(',').includes('pork') &&
                      <div className="form-group col-md-6">
                        <label className="form-label">Pork Quantity</label>
                        <input className="form-control" type="number" name="pork_qty"
                          value={this.state.pork_qty}
                          onChange={this.valueChangeNumber("pork_qty")}>
                        </input>
                    </div>*/}
                    {this.props.user.nmfs_number
                      .split(",")
                      .includes("poultry") && (
                      <div className="form-group col-md-6">
                        <label className="form-label">Poultry Quantity</label>
                        <input
                          className="form-control"
                          type="number"
                          name="poultry_qty"
                          value={this.state.poultry_qty}
                          onChange={this.valueChangeNumber("poultry_qty")}
                        ></input>
                      </div>
                    )}
                    {this.props.user.nmfs_number
                      .split(",")
                      .includes("deer") && (
                      <div className="form-group col-md-6">
                        <label className="form-label">Deer Quantity</label>
                        <input
                          className="form-control"
                          type="number"
                          name="deer_qty"
                          value={this.state.deer_qty}
                          onChange={this.valueChangeNumber("deer_qty")}
                        ></input>
                      </div>
                    )}
                    {this.props.user.nmfs_number
                      .split(",")
                      .includes("wild_game") && (
                      <div className="form-group col-md-6">
                        <label className="form-label">Wild Game Quantity</label>
                        <input
                          className="form-control"
                          type="number"
                          name="wild_game_qty"
                          value={this.state.wild_game_qty}
                          onChange={this.valueChangeNumber("wild_game_qty")}
                        ></input>
                      </div>
                    )}
                    <div className="form-group col-md-6">
                      <label className="form-label">Organic</label>
                      <select
                        type="text"
                        className="form-control"
                        onChange={(e) => this.handleChangeOrganic(e)}
                      >
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              {/* Tabs Section */}

              {Array.isArray(animalTabs) && animalTabs.length > 0 && (
                <div style={animalTabsStyle}>
                  {animalTabs.map((animal, index) => (
                    <a
                      key={index}
                      style={
                        selectedTab === animal.name
                          ? selectedAnimalTabStyle
                          : animalTabStyle
                      }
                      onClick={() => this.handleTabClick(animal.name)}
                      onMouseOver={(e) =>
                        (e.currentTarget.style.backgroundColor =
                          animalTabHoverStyle.backgroundColor)
                      }
                      onMouseOut={(e) =>
                        (e.currentTarget.style.backgroundColor =
                          selectedTab === animal.name
                            ? animalTabHoverStyle.backgroundColor
                            : animalTabStyle.backgroundColor)
                      }
                    >
                      {`${animal.name.toUpperCase()} (${animal.qty})`}
                    </a>
                  ))}
                </div>
              )}

              {/* Expansion Panels */}
              {selectedTab &&
                animalTabs.length > 0 &&
                [...Array(qtyTotal)].map((_, index) => (
                  <Accordion key={index}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        width="100%"
                      >
                        <Typography>
                          {`${selectedTab.toUpperCase()} : Order No: ${
                            index + 1
                          }`}
                        </Typography>
                        <Button
                          variant="text"
                          color="primary"
                          style={{ marginLeft: "auto" }}
                          onClick={() => this.addAditionalCustomer(index + 1)}
                        >
                          Add Customer
                        </Button>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>
                        {selectedTab ? (
                          this.state.savedFields.some(
                            (field) =>
                              field.animal === selectedTab &&
                              field.orderNumber === index + 1
                          ) ? (
                            <table
                              style={{
                                width: "100%",
                                borderCollapse: "collapse",
                              }}
                            >
                              <thead>
                                <tr>
                                  <th style={tableHeaderStyle}>Order Number</th>
                                  <th style={tableHeaderStyle}>
                                    Customer Name
                                  </th>
                                  <th style={tableHeaderStyle}>Cutsheet</th>
                                  <th style={tableHeaderStyle}>Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {this.state.savedFields
                                  .filter(
                                    (field) =>
                                      field.animal === selectedTab &&
                                      field.orderNumber === index + 1
                                  )
                                  .map((field, idx) => (
                                    <tr key={idx}>
                                      <td style={tableCellStyle}>
                                        {field.orderNumber}
                                      </td>
                                      <td style={tableCellStyle}>
                                        {field.name
                                          ? field.name.first_name
                                          : ""}
                                      </td>
                                      <td style={tableCellStyle}>
                                        {field.cutsheet?.name ||
                                          "No Cut Sheet selected"}
                                        , {field.cutsheet?.type || ""}
                                      </td>
                                      <td style={tableCellStyle}>
                                        {field.amount}
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          ) : (
                            <p>
                              No added customer available for this order, please
                              add customer.
                            </p>
                          )
                        ) : (
                          <p></p>
                        )}
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}

              {/* Dialog Component */}
              <DialogPopup
                title={
                  selectedTab
                    ? `Split Order for: ${selectedTab.toUpperCase()}`
                    : "Split Order"
                }
                subtitle="Customer fields"
                open={this.state.dialogOpen}
                onClose={(e) => () => this.setState({ dialogOpen: false })}
                // TransitionComponent={Transition}
                className="mt-5"
                maxWidth="md"
              >
                <div>
                  {this.state.fields.map((field, index) => (
                    <div
                      key={index}
                      style={{
                        position: "relative",
                        marginBottom: "10px",
                      }}
                    >
                      {index !== 0 && (
                        <IconButton
                          aria-label="delete"
                          style={{
                            position: "absolute",
                            top: "-10px",
                            right: "-10px",
                            color: "red",
                            zIndex: 10,
                          }}
                          onClick={() => this.handleDeleteField(index)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      )}

                      <div
                        style={{
                          border: "1px solid #ccc",
                          padding: "10px",
                          borderRadius: "12px",
                          display: "flex",
                          flexDirection: "row",
                          gap: "20px", // Adjust the spacing between items as needed
                          alignItems: "flex-start", // Align items at the top
                          justifyContent: "center", // Center the items horizontally
                        }}
                      >
                        <div
                          style={{
                            flex: "1",
                            minWidth: "250px",
                          }} // Increased minWidth for wider fields
                        >
                          <label className="new-order-form-label form-label">
                            Cut Sheet
                          </label>
                          <select
                            name="form_id"
                            className="form-control new-order-form-input"
                            value={field.cutsheet?.name || ""}
                            onChange={(e) => {
                              const selectedObject =
                                this.state.cutSheetTemp.find(
                                  (cutSheet) => cutSheet.name === e.target.value
                                );
                              this.handleSelectChange(index, selectedObject);
                            }}
                            style={{ width: "100%" }} // Set to full width of its container
                          >
                            <option value="">Select Cut Sheet</option>
                            {this.state.cutSheetTemp.map((data, idx) => (
                              <option key={idx} value={data.name}>
                                Cut Sheet - {data.name} ({data.type})
                              </option>
                            ))}
                          </select>
                        </div>

                        <div
                          style={{ flex: "1", minWidth: "250px" }} // Increased minWidth for wider fields
                        >
                          <label className="new-order-form-label form-label">
                            Amount
                          </label>
                          <select
                            name="amount"
                            className="form-control new-order-form-input"
                            value={field.amount}
                            onChange={(e) =>
                              this.handleFieldChangeAmount(
                                index,
                                "amount",
                                e.target.value
                              )
                            }
                            style={{ width: "100%" }} // Set to full width of its container
                          >
                            <option value="">Select Amount</option>
                            <option value="Whole">Whole</option>
                            <option value="Half">Half</option>
                            <option value="Quarter">Quarter</option>
                          </select>
                        </div>

                        <div
                          style={{ flex: "1", minWidth: "250px" }} // Increased minWidth for wider fields
                        >
                          <label className="new-order-form-label form-label">
                            Customer
                          </label>

                          <Autocomplete
                            onChange={(e, value) =>
                              this.handleFieldChange(index, "name", value)
                            }
                            onInput={() => this.handleChangeNameInput()}
                            className="mb-3 form-control-autocomplete"
                            options={this.state.dropdownCustomer}
                            disableListWrap
                            getOptionLabel={(option) => {
                              if (option.id === false) {
                                return "Add new customer";
                              } else {
                                return `${option.first_name} ${option.last_name}`;
                              }
                            }}
                            value={field.name || null}
                            filterOptions={(options, params) => {
                              const filter = createFilterOptions();
                              const filtered = filter(options, params);

                              if (params.inputValue !== "") {
                                filtered.push({
                                  id: false,
                                  first_name: `Add`,
                                  last_name: `"${params.inputValue}"`,
                                });
                              }

                              return filtered;
                            }}
                            style={{ width: "100%" }}
                            renderInput={(params) => (
                              <TextField
                                id="ggez"
                                {...params}
                                variant="outlined"
                              />
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  ))}

                  <div className="text-center mt-3">
                    {this.state.errorMessage && (
                      <div style={{ color: "red", marginBottom: "10px" }}>
                        {this.state.errorMessage}
                      </div>
                    )}

                    <Button
                      variant="contained"
                      color="primary"
                      onClick={this.handleAddMore}
                      disabled={
                        this.isAmountDisabled("Half") &&
                        this.isAmountDisabled("Quarter")
                      }
                      style={{
                        borderRadius: "6px",
                        backgroundColor: "blue",
                        fontWeight: "normal",
                      }} // Adjust the value to your preference
                    >
                      Add More
                    </Button>

                    <Button
                      variant="contained"
                      color="primary"
                      style={{
                        marginLeft: "10px",
                        borderRadius: "6px",
                        backgroundColor: "green",
                        fontWeight: "normal",
                      }}
                      onClick={this.setSplitOrder}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </DialogPopup>
            </div>
            <div className="col-md-3">
              <div className="new-order-list-btn">
                {/*  <a
                  onClick={(e) => {
                    if (selectedTab === null) {
                      this.setState({
                        errorMessage:
                          "Please select a animal tab before adding additional customers.",
                      });
                      return;
                    }

                    if (this.state.addCustomerDisable === false) {
                      this.addAditionalCustomer();
                    }
                  }}
                  className={`new-order-list-btn-item${
                    this.state.addCustomerDisable && selectedTab != null
                      ? "-disabled"
                      : ""
                  }`}
                >
                  <span>Add/Edit Customers</span>
                </a> */}

                {this.state.errorMessage && (
                  <p style={{ color: "red", marginTop: "10px" }}>
                    {this.state.errorMessage}
                  </p>
                )}

                <a
                  onClick={(e) => this.setState({ scheduleModal: true })}
                  className="new-order-list-btn-item"
                >
                  <span>Schedule a drop off Date</span>
                </a>
                {/* <a onClick={(e) => this.setState({ depositModal: true })} className="new-order-list-btn-item">
                  <span>Collect Deposit</span>
                </a> */}
                <div className="new-order-list-btn-item">
                  <a
                    className="new-order-list-btn-item-cut-sheet"
                    name="sendcs"
                    onClick={this.valueChange("sendcs")}
                  >
                    <label className="cursor-pointer mb-0">
                      <input type="checkbox" className="mr-2"></input>
                      <span>Send Cut sheets</span>{" "}
                    </label>
                  </a>
                </div>
                <a
                  onClick={(e) => this.setState({ organModal: true })}
                  className="new-order-list-btn-item"
                >
                  <span>Select Organs</span>
                </a>
                <a
                  onClick={(e) => this.setState({ labelModal: true })}
                  className="new-order-list-btn-item"
                >
                  <span>Select Label Type</span>
                </a>
                <a
                  onClick={(e) => this.setState({ sexModal: true })}
                  className="new-order-list-btn-item"
                >
                  <span>Select Sex</span>
                </a>
              </div>
              <div className="new-order-list-info">
                <div className="new-order-list-info-item">
                  <h1>Available</h1>
                  <div className="new-order-list-info-item-value">
                    <span>
                      {this.state.availableSlot - this.state.totalanimals >= 0
                        ? this.state.availableSlot - this.state.totalanimals
                        : 0}
                    </span>
                  </div>
                </div>
                <div className="new-order-list-info-item">
                  <h1>Used</h1>
                  <div className="new-order-list-info-item-value">
                    <span>{this.state.usedSlot + this.state.totalanimals}</span>
                  </div>
                </div>
              </div>
              <div className="new-order-list-btn-submit">
                <a
                  onClick={(e) => {
                    this.props.onClose();
                  }}
                  className="new-order-list-btn-submit-item"
                >
                  Cancel
                </a>
                <LoadingButton
                  isLoading={this.state.submitLoading}
                  onClick={(e) => this.postNewOrder()}
                  className="new-order-list-btn-submit-item"
                  style={{ background: "#3C923F" }}
                >
                  Submit
                </LoadingButton>
              </div>
            </div>
          </div>
        </div>

        <DialogPopup
          title="Schedule a Dropoff Date"
          subtitle="Setup dropoff"
          open={this.state.scheduleModal}
          onClose={(e) => () =>
            this.setState({
              scheduleModal: false,
              temp_recurring: this.state.recurring,
              temp_dropoff_date: this.state.dropoff_date,
              temp_kill_date: this.state.kill_date,
            })}
          maxWidth="md"
        >
          <MyAgendaMini
            setKillDate={this.setKillDate}
            setDropOff={this.setDropOff}
            /* ref={this.ChildElement}*/ currentServerTime={
              this.state.currentServerTime
            }
            searching={this.state.searching}
          />
          <div className="schedule-modal-item">
            <div className="row">
              <div className="form-group col-md-4">
                <p className="popup-form-label form-label">
                  Confirm Drop off Date
                </p>
                <input
                  type="text"
                  className="form-control D-input popup-form-control"
                  placeholder="Click on calendar date"
                  readOnly={true}
                  value={this.state.temp_dropoff_date}
                  // onChange={this.handleChange('new_password')}
                />

                {/* <DayPickerInput

                  // classNames="form-control"
                  // style={{ width: '100%', height: '37px', background: '#fff' }}
                  style={{ width: '100%', height: '37px', border: '1px solid #E3E8EE', boxSadow: '0px 1px 2px 0px #37415114' }}
                  // dayPickerProps={{ disabledDays: { before: new Date()} }}
                  // inputProps={{ class: "form-control", style: { height: '100%', background: '#fff' } }}
                  inputProps={{ type: 'text', class: "form-control", style: { width: '100%', height: '100%', padding: '0 10px' } }}
                  // ref={this.toRef}
                  placeholder="select a date"
                  value={this.state.dropoff_date}
                  format="YYYY-MM-DD"
                  onDayChange={(event) => this.handleDatePicker(event, 'dropoff_date')}
                /> */}
              </div>
              <div className="form-group col-md-4">
                <p className="popup-form-label form-label">Confirm Kill Date</p>
                {/* <input type="text" className="form-control D-input popup-form-control" placeholder="Enter new password"
                        value={localStorage.getItem("schedule_kill")}
                        // onChange={this.handleChange('new_password')}
                        /> */}

                <DayPickerInput
                  // classNames="form-control"
                  // style={{ width: '100%', height: '37px', background: '#fff' }}
                  style={{
                    width: "100%",
                    height: "37px",
                    border: "1px solid #E3E8EE",
                    boxSadow: "0px 1px 2px 0px #37415114",
                  }}
                  // dayPickerProps={{ disabledDays: { before: new Date()} }}
                  // inputProps={{ class: "form-control", style: { height: '100%', background: '#fff' } }}
                  inputProps={{
                    type: "text",
                    class: "form-control",
                    style: { width: "100%", height: "100%", padding: "0 10px" },
                  }}
                  // ref={this.toRef}
                  placeholder="select a date"
                  value={this.state.temp_kill_date}
                  format="YYYY-MM-DD"
                  onDayChange={(event) =>
                    this.handleDatePicker(event, "temp_kill_date")
                  }
                />
              </div>
              <div className="form-group col-md-4">
                <p className="popup-form-label form-label">
                  Confirm if this data will be recurring
                </p>
                <div className="">
                  <div
                    className=""
                    onClick={this.valueChange("temp_recurring")}
                  >
                    <label className="mr-3" style={{ color: "black" }}>
                      <input
                        className="mr-1"
                        type="radio"
                        name="recurring"
                        value={1}
                        checked={this.state.temp_recurring == 1}
                      />
                      Yes
                    </label>

                    <label style={{ color: "black" }}>
                      <input
                        className="mr-1"
                        type="radio"
                        name="recurring"
                        value={0}
                        checked={this.state.temp_recurring == 0}
                      />
                      No
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="popup-form-btn mt-3">
              <a
                onClick={(e) => this.getAvailableSlot()}
                className="btn btn-primary"
              >
                Submit
              </a>
            </div>
          </div>
        </DialogPopup>
        <DialogPopup
          title="Recurring Order"
          subtitle="schedulling"
          open={this.state.rruleModal}
          onClose={(e) => () =>
            this.setState({ rruleModal: false, temp_rrule: this.state.rrule })}
          maxWidth="md"
        >
          <div className="rrulegenerator">
            <RRuleGenerator
              onChange={(rrule) => this.setState({ temp_rrule: rrule })}
              value={this.state.temp_rrule}
              config={{
                repeat: ["Weekly", "Daily"],
                // yearly: 'on the',
                // monthly: 'on',
                end: ["On date"],
                // weekStartsOnSunday: true,
                // hideError: true,
              }}
              // customCalendar={<DateTime
              //   timeFormat={"dddd, MMMM Do YYYY, h:mm:ss a"}
              //   viewMode="days"
              //   closeOnSelect
              //   closeOnTab
              //   required

              // />}
            />
          </div>
          <div className="popup-form-btn mt-3">
            <a
              onClick={() =>
                this.setState({
                  rruleModal: false,
                  rrule: this.state.temp_rrule,
                })
              }
              className="btn btn-primary"
            >
              Save
            </a>
          </div>
        </DialogPopup>
        <DialogPopup
          title="Collect Deposit"
          subtitle="Fields"
          open={this.state.depositModal}
          onClose={(e) => () =>
            this.setState({
              depositModal: false,
              tempDeposit: this.state.deposit,
              tempEmailOrPhoneDeposit: this.state.emailOrPhoneDeposit,
            })}
          maxWidth="xs"
        >
          <div className="row">
            <div className="form-group position-relative coll col-md-6 pr-0">
              <p className="popup-form-label form-label">Deposit</p>
              <input
                style={{ width: "260px" }}
                type="number"
                className="form-control D-input popup-form-control"
                placeholder="Enter Deposit"
                value={this.state.tempDeposit}
                onChange={this.valueChange("tempDeposit")}
              />
            </div>
          </div>
          <div className="row">
            <div className="form-group position-relative col pr-0">
              <p className="popup-form-label form-label">Email or Phone</p>
              <input
                style={{ width: "260px" }}
                type="text"
                className="form-control D-input popup-form-control"
                placeholder="Enter Email or Phone"
                value={this.state.tempEmailOrPhoneDeposit}
                onChange={this.valueChange("tempEmailOrPhoneDeposit")}
              />
            </div>
          </div>
          <div className="popup-form-btn mt-3">
            <a
              onClick={() =>
                this.setState({
                  depositModal: false,
                  emailOrPhoneDeposit: this.state.tempEmailOrPhoneDeposit,
                  deposit: this.state.tempDeposit,
                })
              }
              className="btn btn-primary"
            >
              Save
            </a>
          </div>
        </DialogPopup>
        <DialogPopup
          title="Select Organs"
          subtitle="fields"
          open={this.state.organModal}
          onClose={(e) => () =>
            this.setState({
              organModal: false,
              temp_organs: this.state.organs,
            })}
          maxWidth="sm"
        >
          <div className="">
            <label className="mr-3">
              <input
                type="checkbox"
                className="mr-1"
                style={{ accentColor: "#3C923F" }}
                checked={
                  this.state.temp_organs.split(",").indexOf("Pass") !== -1
                }
                onChange={(e) => {
                  this.handleCheckboxSelect(e, "Pass");
                }}
              />
              Pass
            </label>
            <label className="mr-3">
              <input
                type="checkbox"
                className="mr-1"
                style={{ accentColor: "#3C923F" }}
                checked={
                  this.state.temp_organs.split(",").indexOf("Heart") !== -1
                }
                onChange={(e) => {
                  this.handleCheckboxSelect(e, "Heart");
                }}
              />
              Heart
            </label>
            <label className="mr-3">
              <input
                type="checkbox"
                className="mr-1"
                style={{ accentColor: "#3C923F" }}
                checked={
                  this.state.temp_organs.split(",").indexOf("Liver") !== -1
                }
                onChange={(e) => {
                  this.handleCheckboxSelect(e, "Liver");
                }}
              />
              Liver
            </label>
            <label className="mr-3">
              <input
                type="checkbox"
                className="mr-1"
                style={{ accentColor: "#3C923F" }}
                checked={
                  this.state.temp_organs.split(",").indexOf("Tongue") !== -1
                }
                onChange={(e) => {
                  this.handleCheckboxSelect(e, "Tongue");
                }}
              />
              Tongue
            </label>
            <label className="mr-3">
              <input
                type="checkbox"
                className="mr-1"
                style={{ accentColor: "#3C923F" }}
                checked={
                  this.state.temp_organs.split(",").indexOf("Tail") !== -1
                }
                onChange={(e) => {
                  this.handleCheckboxSelect(e, "Tail");
                }}
              />
              Tail
            </label>
            <label className="mr-3">
              <input
                type="checkbox"
                className="mr-1"
                style={{ accentColor: "#3C923F" }}
                checked={
                  this.state.temp_organs.split(",").indexOf("Testies") !== -1
                }
                onChange={(e) => {
                  this.handleCheckboxSelect(e, "Testies");
                }}
              />
              Testies
            </label>
          </div>

          <div className="popup-form-btn mt-3">
            <a
              onClick={() => {
                this.setState({
                  organModal: false,
                  organs: this.state.temp_organs,
                });
              }}
              className="btn btn-primary"
            >
              Save
            </a>
          </div>
        </DialogPopup>
        <DialogPopup
          title="Select Label"
          subtitle="fields"
          open={this.state.labelModal}
          onClose={(e) => () =>
            this.setState({ labelModal: false, temp_label: this.state.label })}
          maxWidth="md"
        >
          <div className="row">
            <div className="new-order-list-btn-item-organs-label">
              <div
                className="new-order-list-btn-item-organs-item-2"
                onChange={(e) => {
                  this.setState({
                    temp_label: e.target.value,
                  });
                }}
              >
                <label className="mr-3">
                  <input
                    className="mr-1"
                    type="radio"
                    name="label"
                    value="custom"
                    checked={this.state.temp_label === "custom"}
                    style={{ accentColor: "#3C923F" }}
                  />
                  Custom
                </label>

                <label className="mr-3">
                  <input
                    className="mr-1"
                    type="radio"
                    name="label"
                    value="usda"
                    checked={this.state.temp_label === "usda"}
                    style={{ accentColor: "#3C923F" }}
                  />
                  USDA
                </label>

                <label className="mr-3">
                  <input
                    className="mr-1"
                    type="radio"
                    name="label"
                    value="organic"
                    checked={this.state.temp_label === "organic"}
                    style={{ accentColor: "#3C923F" }}
                  />
                  Organic
                </label>

                <label className="mr-3">
                  <input
                    className="mr-1"
                    type="radio"
                    name="label"
                    value="private"
                    checked={this.state.temp_label === "private"}
                    style={{ accentColor: "#3C923F" }}
                  />
                  Private
                </label>

                <label className="mr-3">
                  <input
                    className="mr-1"
                    type="radio"
                    name="label"
                    value="generic"
                    checked={this.state.temp_label === "generic"}
                    style={{ accentColor: "#3C923F" }}
                  />
                  Generic
                </label>

                <label className="mr-3">
                  <input
                    className="mr-1"
                    type="radio"
                    name="label"
                    value="religious"
                    checked={this.state.temp_label === "religious"}
                    style={{ accentColor: "#3C923F" }}
                  />
                  Religious
                </label>
              </div>
            </div>
          </div>
          <div className="popup-form-btn mt-3">
            <a
              onClick={() => {
                this.setState({
                  labelModal: false,
                  label: this.state.temp_label,
                });
              }}
              className="btn btn-primary"
            >
              Save
            </a>
          </div>
        </DialogPopup>
        <DialogPopup
          title="Select Sex"
          subtitle="fields"
          open={this.state.sexModal}
          onClose={(e) => () =>
            this.setState({ sexModal: false, temp_sex: this.state.sex })}
          maxWidth="sm"
        >
          <div className="row">
            <div className="new-order-list-btn-item-organs-label">
              <div
                className="new-order-list-btn-item-organs-item-2"
                onChange={this.valueChange("temp_sex")}
              >
                <label className="mr-3">
                  <input
                    type="radio"
                    name="sex"
                    value="male"
                    checked={this.state.temp_sex === "male"}
                    style={{ accentColor: "#3C923F", marginRight: "0.2rem" }}
                  />
                  Male
                </label>

                <label className="mr-3">
                  <input
                    type="radio"
                    name="sex"
                    value="female"
                    checked={this.state.temp_sex === "female"}
                    style={{ accentColor: "#3C923F", marginRight: "0.2rem" }}
                  />
                  Female
                </label>

                <label className="mr-3">
                  <input
                    type="radio"
                    name="sex"
                    value="heifer"
                    checked={this.state.temp_sex === "heifer"}
                    style={{ accentColor: "#3C923F", marginRight: "0.2rem" }}
                  />
                  Heifer
                </label>

                <label className="mr-3">
                  <input
                    type="radio"
                    name="sex"
                    value="steer"
                    checked={this.state.temp_sex === "steer"}
                    style={{ accentColor: "#3C923F", marginRight: "0.2rem" }}
                  />
                  Steer
                </label>

                <label className="mr-3">
                  <input
                    type="radio"
                    name="sex"
                    value="bull"
                    checked={this.state.temp_sex === "bull"}
                    style={{ accentColor: "#3C923F", marginRight: "0.2rem" }}
                  />
                  Bull
                </label>
              </div>
            </div>
          </div>
          <div className="popup-form-btn mt-3">
            <a
              onClick={() =>
                this.setState({ sexModal: false, sex: this.state.temp_sex })
              }
              className="btn btn-primary"
            >
              Save
            </a>
          </div>
        </DialogPopup>
        <DialogPopup
          title="Add Customer"
          subtitle="Customer fields"
          open={this.state.openAddCustomerModal}
          onClose={(e) => () => this.setState({ openAddCustomerModal: false })}
        >
          <AddorUpdateCustomer
            openAddCustomerModal={this.openAddCustomerModal}
            closeAddCustomerModal={this.closeAddCustomerModal}
            getCustomersData={this.getCustomersData}
            user={this.props.user}
            fetch_all_customer={this.fetch_all_customer}
            isUpdate={""}
          />
        </DialogPopup>
        <DialogPopup
          title="Verify Animal & Finish"
          subtitle="Fields"
          open={this.state.organicModal}
          onClose={(e) => () => this.setState({ organicModal: false })}
          maxWidth="md"
        >
          <div className="">
            <div className="table-responsive">
              <table className="table cstmGrzrTable">
                <thead>
                  <tr>
                    <th>Species</th>
                    <th>Tag Id</th>
                    <th>Hang</th>
                    <th>Date</th>
                    <th>Organic</th>
                    <th>Organs</th>
                    <th>Sex</th>
                    <th>Label</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.listOrganic.length === 0 && (
                    <tr>
                      <td colSpan="8">No data</td>
                    </tr>
                  )}
                  {this.state.listOrganic.map((data, index) => (
                    <>
                      <tr>
                        <td>{this.speciesLabel(data.species)}</td>
                        <td>
                          <input
                            className="form-control"
                            type="number"
                            name="ear_tag_id"
                            value={data.ear_tag_id}
                            onChange={(e) =>
                              this.handleChangeOrganicData(e, index)
                            }
                          ></input>
                        </td>
                        <td>
                          <input
                            className="form-control"
                            type="number"
                            name="hang"
                            value={data.hang}
                            onChange={(e) =>
                              this.handleChangeOrganicData(e, index)
                            }
                          ></input>
                        </td>
                        <td>{data.date}</td>
                        <td>
                          <select
                            name="organic"
                            type="text"
                            className="form-control"
                            value={data.organic}
                            onChange={(e) =>
                              this.handleChangeOrganicData(e, index)
                            }
                          >
                            <option value="">Select</option>
                            <option value="yes">Y</option>
                            <option value="no">N</option>
                          </select>
                        </td>
                        <td>
                          <select
                            name="organs"
                            type="text"
                            className="form-control"
                            value={data.organs}
                            onChange={(e) =>
                              this.handleChangeOrganicData(e, index)
                            }
                          >
                            <option value="">Select</option>
                            <option value="yes">Y</option>
                            <option value="no">N</option>
                          </select>
                        </td>
                        <td>
                          <select
                            name="sex"
                            type="text"
                            className="form-control"
                            value={data.sex}
                            onChange={(e) =>
                              this.handleChangeOrganicData(e, index)
                            }
                          >
                            <option value="">Select</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="heifer">Heifer</option>
                            <option value="steer">Steer</option>
                            <option value="bull">Bull</option>
                          </select>
                        </td>
                        <td>
                          <select
                            name="label"
                            type="text"
                            className="form-control"
                            value={data.label}
                            onChange={(e) =>
                              this.handleChangeOrganicData(e, index)
                            }
                          >
                            <option value="">Select</option>
                            <option value="custom">Custom</option>
                            <option value="usda">USDA</option>
                            <option value="organic">Organic</option>
                            <option value="private">Private</option>
                            <option value="generic">Generic</option>
                            <option value="religious">Religious</option>
                          </select>
                        </td>
                        <td></td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="file-upload">
            <h1 className="file-upload-title">
              Upload Documents to Certify Organic register{" "}
            </h1>
            <div className="file-upload-btn">
              <label>
                <input type="file" onChange={this.selectFile1} />
              </label>
              <a onClick={this.uploadFile1} className="file-upload-btn-item">
                Upload
              </a>
            </div>
            <div className="file-upload-table">
              {this.state.listFile1.map((data) => (
                <div className="file-upload-table-item">
                  <span>{data.name}</span>
                  <span>Completed</span>
                </div>
              ))}
              {this.state.listFile1.length == 0 ? (
                <span>No File Uploaded</span>
              ) : null}
            </div>

            <h1 className="file-upload-title mt-5">
              Upload Documents for 30+ Age Verification{" "}
            </h1>
            <div className="file-upload-btn">
              <label>
                <input type="file" onChange={this.selectFile2} />
              </label>
              <a onClick={this.uploadFile2} className="file-upload-btn-item">
                Upload
              </a>
            </div>
            <div className="file-upload-table">
              {this.state.listFile2.map((data) => (
                <div className="file-upload-table-item">
                  <span>{data.name}</span>
                  <span>Completed</span>
                </div>
              ))}
              {this.state.listFile2.length == 0 ? (
                <span>No File Uploaded</span>
              ) : null}
            </div>
          </div>
          <div
            className="organic-form-btn"
            style={{ paddingBottom: "2rem", paddingTop: "3rem" }}
          >
            <a
              onClick={() => this.setState({ organicModal: false })}
              className="btn btn-secondary mr-2"
            >
              Cancel
            </a>
            <a
              onClick={() => this.setState({ organicModal: false })}
              className="btn btn-primary"
            >
              Save
            </a>
          </div>
        </DialogPopup>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user, loggedIn } = state.login;

  return { user, loggedIn };
}

export default connect(mapStateToProps)(NewOrder);
