export const goatParts = [
  {
    id: "organs",
    title: "Organs",
    fontSize: "0px",
    coordinates: { top: "15%", left: "10%" },
    rotation: 0,
    subcuts: [],
  },
  {
    id: "head",
    title: "Head",
    fontSize: "18px",
    coordinates: { top: "10%", left: "8%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "ear",
    title: "Ear",
    fontSize: "16px",
    coordinates: { top: "6%", left: "19%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "neck",
    title: "Neck",
    fontSize: "20px",
    coordinates: { top: "25%", left: "18%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "shoulder",
    title: "Shoulder",
    fontSize: "20px",
    coordinates: { top: "30%", left: "28%" },
    rotation: -50,
    subParts: [],
  },
  {
    id: "breast",
    title: "Breast",
    fontSize: "18px",
    coordinates: { top: "50%", left: "35%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "rib",
    title: "Rib",
    fontSize: "20px",
    coordinates: { top: "35%", left: "45%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "fore_shank",
    title: "Fore Shank",
    fontSize: "14px",
    coordinates: { top: "61%", left: "33%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "knee",
    title: "Knee",
    fontSize: "16px",
    coordinates: { top: "69%", left: "35%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "loin",
    title: "Loin",
    fontSize: "20px",
    coordinates: { top: "35%", left: "60%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "sirloin",
    title: "Sirloin",
    fontSize: "20px",
    coordinates: { top: "40%", left: "66%" },
    rotation: -75,
    subParts: [],
  },
  {
    id: "flank",
    title: "Flank",
    fontSize: "18px",
    coordinates: { top: "54%", left: "57%" },
    rotation: -10,
    subParts: [],
  },
  {
    id: "belly",
    title: "Belly",
    fontSize: "16px",
    coordinates: { top: "60%", left: "62%" },
    rotation: -10,
    subParts: [],
  },
  {
    id: "rump",
    title: "Rump",
    fontSize: "20px",
    coordinates: { top: "28%", left: "79%" },
    rotation: 10,
    subParts: [],
  },
  {
    id: "leg",
    title: "Leg",
    fontSize: "22px",
    coordinates: { top: "45%", left: "75%" },
    rotation: -5,
    subParts: [],
  },
  {
    id: "tail",
    title: "Tail",
    fontSize: "16px",
    coordinates: { top: "25%", left: "90%" },
    rotation: -40,
    subParts: [],
  },
  {
    id: "hind_shank",
    title: "Hind Shank",
    fontSize: "15px",
    coordinates: { top: "59%", left: "80%" },
    rotation: -20,
    subParts: [],
  },
  {
    id: "hock",
    title: "Hock",
    fontSize: "16px",
    coordinates: { top: "68%", left: "82%" },
    rotation: -20,
    subParts: [],
  },
];
