import React, { Component } from "react";
import "./CalenderPopup.css";
import MaterialUIPickers from "./Calender";
import moment from "moment";
import Service from "./../../Service/Service";
import { connect } from "react-redux";
import ProcessorCalender from "../CalenderComponent/Calender.component";
export class CalenderPopup extends Component {
  state = {
    selected_date: new Date(),
  };
  handler = (e) => {
    this.setState({
      selected_date: e,
    });
  };
  componentDidMount() {
    console.log("CalenderPopup", this.props);
  }
  render() {
    let {
      closeModal,
      showModal,
      currentPopupData,
      working_days,
      block_days,
      schedule_date,
      set_schedule_date,
      cut_sheet_data_id,
      cut_sheet_data,
      cut_sheet_data_submit,
    } = this.props;
    let { selected_date } = this.state;
    return (
      <div className="G-zr" id="calenderComponent">
        <div
          className={showModal ? "modal fade show d-block" : "modal fade"}
          id="select_proc"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div
            className="modal-dialog"
            role="document"
            style={{ maxWidth: "max-content" }}
          >
            <div className="modal-content G-modal rounded-0  model-height">
              <div className="modal-header modal-hdrecng">
                <div className="modal-title text-uppercase fs-18 fw-sm">
                  calender
                </div>
                <span
                  className=""
                  data-dismiss="modal"
                  style={{ cursor: "pointer" }}
                  aria-label="Close"
                  onClick={closeModal(false)}
                >
                  <b>X</b>
                </span>
              </div>
              <ProcessorCalender
                currentPopupData={currentPopupData}
                processorId={currentPopupData.processor_id}
                schedule_date={schedule_date}
                set_schedule_date={set_schedule_date}
                cut_sheet_data_id={cut_sheet_data_id}
                cut_sheet_data={cut_sheet_data}
                cut_sheet_data_submit={cut_sheet_data_submit}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, loggedIn } = state.login;

  return { user, loggedIn };
}
export default connect(mapStateToProps)(CalenderPopup);
