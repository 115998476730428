import { Route, Switch } from "react-router-dom";

import Login from "../views/Login/Login";
import React from "react";
import Register from "../views/Register/Register";

let routes = [
  {
    path: "/register",
    component: Register,
    exact: true,
  },
  {
    path: "/login",
    component: Login,
    exact: true,
  },
];

const LoginRoutes = (props) => {
  return (
    <Switch>
      {routes.map((ele, ind) => {
        return <Route key={ind} history={props.history} {...ele} />;
      })}
    </Switch>
  );
};

export default LoginRoutes;
