import React from 'react'
import "../../views/Dashboard/Dashboard.css"
import {connect} from 'react-redux';
import {ReactComponent as IconYes} from '../../Assets/check_green.svg';
import {ReactComponent as IconNo} from '../../Assets/cross_red.svg';
import "../AddEventsPopup/Add-Event.styles.css";


 class YesNoPopup extends React.Component{
     constructor(props){
         super(props)
         this.state={
            formError:{},
         }
     }
    
    handleSubmit=(e)=>{
        e.preventDefault();
        //self = this;

        console.log("handle subnmit called",this.props);
        this.props.close();

        this.props.callback();
    }

    componentDidMount(){
        //let self = this;
        console.log("componetn deis mount");
        console.log("token @ submit button in componen ---",this.props);
        //console.log("props befor set state -----",this.props.needarg.acount)
    }

    render(){
        let {
            show,
            title,
            subtitle,
            callback
        } = this.props;
        
        let {formError}=this.state
        // console.log("render prop s on reason popou --",this.props)
        return(
            <>
                {show && <div className="G-zr">
                    <div className="Addmodal zindex" id="myModal">
                        <div className="modal-dialog" style={{width:"100%","max-width":"360px"}}>
                            <div className="modal-content trck-lfpop">

                            <div>
                                <div className="popup-warning-container">
                                    <div className=''>
                                        <img src={process.env.REACT_APP_URL + `images/warning.svg`} width="33" height="33"/>
                                    </div>
                                    <div className='pl-3' style={{width:'100%'}}>
                                        <h1 className="popup-title-warning">{title}</h1>
                                        <label className="popup-subtitle-warning">{subtitle}</label>
                                        <br></br>
                                        <div className='popup-btn-container pt-3'>
                                            <IconYes style={{cursor:'pointer'}} className="mr-2" onClick={e=>this.handleSubmit(e)} />
                                            <IconNo style={{cursor:'pointer'}} onClick={() => this.props.close()} />
                                        </div>
                                        
                                    </div>
                                </div>
                        </div>          
                            </div>
                        </div>
                    </div>
                </div>}
            </>
        )
    }
 }
 const mapStateToProps = (state)=>{
    const {login:{loggedIn},login:{user}} = state;
    return {loggedIn,user};
  }
 export default  connect(mapStateToProps)(YesNoPopup);