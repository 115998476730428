import Dialog from "@material-ui/core/Dialog";
import DialogPopup from "../Popup/DialogPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import LoadingButton from "../LoadingButton/LoadingButton";
import { NOTIFICATION } from "../../actions";
import OrganSelector from "../OrganSelector/OrganSelector";
import React from "react";
import Service from "../../Service/Service";
import axios from "axios";
import { connect } from "react-redux";
import { faX } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

class ItemDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryType: "",
      externamProps: {},
      reject_reason: {
        data: "",
        required: true,
        errMsg: "Required",
      },
      formError: {},
      active_listing_data: [],
      processor_status: 0,
      project_id: 0,
      reason_popup: false,
      toggle_question: false,
      reason_text: "",
      update_proccess: {
        ear_tag_id: this.props.current_popup_data.ear_tag_id,
        hang_time: this.props.current_popup_data.hang_time,
        pen: this.props.current_popup_data.pen,
        sex: this.props.current_popup_data.sex,
        organic: this.props.current_popup_data.organic,
        _30plus: this.props.current_popup_data._30plus,
        live_weight: this.props.current_popup_data.live_weight,
        organs: this.props.current_popup_data.organs
          ? this.props.current_popup_data.organs
          : "",
        weight: this.props.current_popup_data.weight,
        cut_sheet_from_id: this.props.current_popup_data.cut_sheet_from_id,
        is_cut_sheet: this.props.current_popup_data.is_cut_sheet,
      },
      accordianOne: false,
      current_popup_data: {},
      update_customer: {
        first_name: this.props.current_popup_data.first_name,
        last_name: this.props.current_popup_data.last_name,
        email: this.props.current_popup_data.email,
        phone: this.props.current_popup_data.phone,
        qty: this.props.current_popup_data.qty,
      },
      payloader: false,
      from_error: "",
      itemPopup: this.props.showModal,
      isCheckBox: false,
      listDocumentOrganic: [],
      listDocument30: [],
      _30plusModal: false,
      organicModal: false,
      selectedOrganicFile: null,
      selected30PlusFile: null,
    };
  }

  downloadImage = (imageUrl, imageName) => {
    fetch(imageUrl).then((response) => {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = url;
        a.download = imageName;
        a.click();
      });
    });
  };

  removeDocument = (baseName, document) => {
    if (document == "thirty_plus_files") {
      this.setState((prevState) => ({
        listDocument30: prevState.listDocument30.filter(
          (data) => data.base_name !== baseName
        ),
      }));
    } else {
      this.setState((prevState) => ({
        listDocumentOrganic: prevState.listDocumentOrganic.filter(
          (data) => data.base_name !== baseName
        ),
      }));
    }
  };

  selectOrganicFile = (e) => {
    this.setState({
      selectedOrganicFile: e.target.files[0],
    });
  };
  uploadOrganicFile = () => {
    const { selectedOrganicFile } = this.state;

    if (!selectedOrganicFile) {
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedOrganicFile);

    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.user.user_token;
    let url = process.env.REACT_APP_BASE_URL;

    axios
      .post(url + "misc/addAttachment", formData)
      .then((response) => {
        if (response && response.status == 200) {
          console.log(response.data.uploaded);
          if (response.data.uploaded != 1) {
            this.props.dispatch(
              NOTIFICATION({ msg: "Upload File Failed", msgColor: "#FAD5BD" })
            );
            setTimeout(() => {
              this.props.dispatch(NOTIFICATION({ msg: "" }));
            }, 2000);
          } else {
            var files = this.state.listDocumentOrganic;
            files = !files ? [] : files;
            files.push({
              id: response.data.attachment_id,
              file_name: response.data.fileName,
              attach_type: "image/jpeg",
              base_name: response.data.fileName,
              link: response.data.url,
            });
            this.setState({
              selectedOrganicFile: null,
              listDocumentOrganic: files,
            });

            this.props.dispatch(
              NOTIFICATION({ msg: "Upload Image Successfully" })
            );
            setTimeout(() => {
              this.props.dispatch(NOTIFICATION({ msg: "" }));
            }, 2000);

            return false;
          }
        } else {
        }
      })
      .catch((error) => {
        this.props.dispatch(
          NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
        );
        setTimeout(() => {
          this.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 2000);
      });
  };

  select30PlusFile = (e) => {
    this.setState({
      selected30PlusFile: e.target.files[0],
    });
  };

  upload30PlusFile = () => {
    const { selected30PlusFile } = this.state;

    if (!selected30PlusFile) {
      return;
    }

    const formData = new FormData();
    formData.append("file", selected30PlusFile);

    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.user.user_token;
    let url = process.env.REACT_APP_BASE_URL;

    axios
      .post(url + "misc/addAttachment", formData)
      .then((response) => {
        if (response && response.status == 200) {
          console.log(response.data.uploaded);
          if (response.data.uploaded != 1) {
            // this.props.dispatch(NOTIFICATION({ msg: "Upload File Failed", msgColor: "#FAD5BD" }))
            //   setTimeout(() => {
            //     this.props.dispatch(NOTIFICATION({ msg: "" }))
            //   }, 2000);
          } else {
            var files = this.state.listDocument30;
            files = !files ? [] : files;
            // console.log('listfile', files)
            files.push({
              id: response.data.attachment_id,
              file_name: response.data.fileName,
              attach_type: "image/jpeg",
              base_name: response.data.fileName,
              link: response.data.url,
            });
            this.setState({
              selected30PlusFile: null,
              listDocument30: files,
            });

            // this.props.dispatch(
            //   NOTIFICATION({ msg: 'Upload '+response.data.fileName+' Successfully'})
            // );
            return false;
          }
        } else {
        }
      })
      .catch((error) => {
        this.props.dispatch(
          NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
        );

        setTimeout(() => {
          this.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 2000);
      });
  };

  reject_reason = () => (e) => {
    console.log("reason ");
    this.setState({
      reason_text: true,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      payloader: true,
    });
    if (
      this.state.update_proccess.cut_sheet_from_id &&
      this.state.update_proccess.cut_sheet_from_id != 0 &&
      this.state.update_proccess.cut_sheet_from_id != ""
    ) {
      this.setState({
        from_error: "",
      });
      this.props.updateStatus(
        this.props.current_popup_data,
        {
          ...this.state.update_proccess,
          organic_certified_file: this.state.listDocumentOrganic
            .map((file) => file.id)
            .join(","),
          thirty_plus_file: this.state.listDocument30
            .map((file) => file.id)
            .join(","),
        },
        ""
      );
    } else {
      this.setState({
        from_error: "Required",
      });
    }
  };
  handleUpdateProccess = (e) => {
    e.preventDefault();
    if (
      this.state.update_proccess.cut_sheet_from_id &&
      this.state.update_proccess.cut_sheet_from_id != 0 &&
      this.state.update_proccess.cut_sheet_from_id != ""
    ) {
      this.setState({
        from_error: "",
      });
      this.props.updateStatus(
        this.props.current_popup_data,
        this.state.update_proccess,
        "update"
      );
    } else {
      this.setState({
        from_error: "Required",
      });
    }
  };

  handleSubmitCustomer = (e) => {
    e.preventDefault();
    this.props.updateStatus(
      this.props.current_popup_data,
      this.state.update_customer,
      "customer"
    );
  };

  handleChange = (name) => (event) => {
    let value = event.target.value;
    this.setState((prevState) => ({
      update_proccess: { ...prevState.update_proccess, [name]: value },
    }));

    if (name === "organic" && value === "yes") {
      this.setState({
        organicModal: true,
      });
    } else if (name === "_30plus" && value === "yes") {
      this.setState({
        _30plusModal: true,
      });
    }
  };
  handleChange2 = (name) => (event) => {
    let value = event.target.value;
    this.setState((prevState) => ({
      update_customer: { ...prevState.update_customer, [name]: value },
    }));
  };

  selectToggle = () => {
    this.setState({ isCheckBox: !this.state.isCheckBox });
  };

  handleCheckboxSelect = (e, data) => {
    if (e.target.checked) {
      console.log("multiple", this.state.update_proccess.organs);
      let arr = this.state.update_proccess.organs
        .split(",")
        .filter((organ) => organ !== "");
      arr.push(data);
      var txt = arr.join(",");
      this.setState((prevState) => ({
        update_proccess: { ...prevState.update_proccess, organs: txt },
      }));
    } else {
      var txt = this.state.update_proccess.organs
        .split(",")
        .filter((organ) => {
          if (organ.trim() !== data) return organ;
        })
        .join(",");
      this.setState((prevState) => ({
        update_proccess: { ...prevState.update_proccess, organs: txt },
      }));
    }
  };

  // componentWillReceiveProps = async (props) => {
  //   console.log('props 2', props)
  // }

  render() {
    let {
      title,
      close,
      isDateClicked,
      role,
      availablecategory,
      current_popup_data,
    } = this.props;

    let { update_customer } = this.state;

    console.log("update_proccess", this.state.update_proccess);
    console.log("popup open", this.state.itemPopup);
    return (
      <div className="">
        <div className="" id="myModal">
          <DialogPopup
            title={title}
            subtitle="Dropoff fields"
            open={this.state.itemPopup}
            onClose={() => this.props.closeModal(false)}
            maxWidth={"md"}
          >
            <>
              {this.props.title === "Customer Details" ? (
                <div className="acrdnWrapper">
                  {/* <div
                    className='acrdnTitle d-flex align-items-center justify-content-between'
                    onClick={
                      this.state.accordianOne
                        ? () => this.setState({ accordianOne: false })
                        : () => this.setState({ accordianOne: true })
                    }
                  >
                    <h5>Customer 1</h5>
                    {this.state.accordianOne ? (
                      <span class='material-icons'>remove_circle_outline</span>
                    ) : (
                      <span class='material-icons'>add_circle_outline</span>
                    )}
                  </div> */}
                  {/* {this.state.accordianOne && (
                    <div className='acrdnInr'>
                      <div className='row'>
                        <div className='col-md-6'>Name:</div>
                        <div className='col-md-6 text-right'>
                          Robert Stewart
                        </div>
                        <div className='col-md-6'>Phone Number:</div>
                        <div className='col-md-6 text-right'>+12 1545 4540</div>
                        <div className='col-md-6'>Email address:</div>
                        <div className='col-md-6 text-right'>
                          robert@yahoo.com
                        </div>
                      </div>
                    </div>
                  )} */}

                  <div className="acrdnInr">
                    <div className="row">
                      <div className="col-md-6">First Name:</div>
                      <div className="col-md-6 text-right">
                        <input
                          type="text"
                          name="first_name"
                          value={update_customer.first_name}
                          className="form-control"
                          placeholder="Enter First Name"
                          onChange={this.handleChange2("first_name")}
                        ></input>
                      </div>
                      <div className="col-md-6">Last Name:</div>
                      <div className="col-md-6 text-right">
                        <input
                          type="text"
                          name="last_name"
                          value={update_customer.last_name}
                          className="form-control"
                          placeholder="Enter Last Name"
                          onChange={this.handleChange2("last_name")}
                        ></input>
                      </div>
                      <div className="col-md-6">Phone Number:</div>
                      <div className="col-md-6 text-right">
                        <input
                          type="text"
                          name="phone"
                          value={update_customer.phone}
                          className="form-control"
                          placeholder="Enter Last Name"
                          onChange={this.handleChange2("phone")}
                        ></input>
                      </div>
                      <div className="col-md-6">Email address:</div>
                      <div className="col-md-6 text-right">
                        <input
                          type="text"
                          name="email"
                          value={update_customer.email}
                          className="form-control"
                          placeholder="Enter Email"
                          onChange={this.handleChange2("email")}
                        ></input>
                      </div>
                      {update_customer.qty != 4 && (
                        <>
                          <div className="col-md-6">Quarter:</div>
                          <div className="col-md-6 text-right">
                            <select
                              type="text"
                              name="qty"
                              value={update_customer.qty}
                              className="form-control"
                              id="qty"
                              placeholder="Select Quarter"
                              onChange={this.handleChange2("qty")}
                            >
                              <option>Select Quarter</option>
                              <option value="1">1 Quarter</option>
                              <option value="2">2 Quarters</option>
                              <option value="3">3 Quarters</option>
                            </select>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="text-center mt-5">
                    <button
                      className="btn btn-green"
                      onClick={this.handleSubmitCustomer}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              ) : this.props.title === "Confirm Drop Off" ||
                this.props.title === "Item Details" ? (
                <>
                  {!this.state._30plusModal && !this.state.organicModal ? (
                    <>
                      <div className="row">
                        <div className="form-group col-md-4">
                          <label
                            for="validationServer02"
                            className="form-label"
                          >
                            Ear Tag Id
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            onChange={this.handleChange("ear_tag_id")}
                            placeholder="Ear Tag Id"
                            value={this.state.update_proccess.ear_tag_id}
                            required
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label
                            for="validationServer02"
                            className="form-label"
                          >
                            Hang Time (Days)
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Hang Time"
                            onChange={this.handleChange("hang_time")}
                            value={this.state.update_proccess.hang_time}
                            required
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label
                            for="validationServer02"
                            className="form-label"
                          >
                            Pen #
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Type"
                            onChange={this.handleChange("pen")}
                            value={this.state.update_proccess.pen}
                            required
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="form-group col-md-4">
                          <label
                            for="validationServer02"
                            className="form-label"
                          >
                            Sex
                          </label>
                          <select
                            type="text"
                            className="form-control"
                            value={this.state.update_proccess.sex}
                            onChange={this.handleChange("sex")}
                            required
                          >
                            <option value="">Select</option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                            <option value="heifer">Heifer</option>
                            <option value="steer">Steer</option>
                            <option value="bull">Bull</option>
                          </select>
                        </div>
                        <div className="form-group col-md-4">
                          <label
                            for="validationServer02"
                            className="form-label"
                          >
                            Organic
                          </label>
                          <select
                            type="text"
                            className="form-control"
                            value={this.state.update_proccess.organic}
                            onChange={this.handleChange("organic")}
                            required
                          >
                            <option value="">Select</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>
                        <div className="form-group col-md-4">
                          <label
                            for="validationServer02"
                            className="form-label"
                          >
                            30+
                          </label>
                          <select
                            type="text"
                            className="form-control"
                            value={this.state.update_proccess._30plus}
                            onChange={this.handleChange("_30plus")}
                            required
                          >
                            <option value="">Select</option>
                            <option value="yes">Yes</option>
                            <option value="no">No</option>
                          </select>
                        </div>
                      </div>
                      <div className="form-group col-md-4">
                        <label for="validationServer02" className="form-label">
                          Enter Live Weight
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          onChange={this.handleChange("live_weight")}
                          placeholder="Enter Live Weight"
                          value={this.state.update_proccess.live_weight}
                          required
                        />
                      </div>
                      <div className="row">
                        <div className="form-group col-md-4">
                          <label
                            for="validationServer02"
                            className="form-label"
                          >
                            Organs
                          </label>
                          <OrganSelector
                            value={this.state.update_proccess.organs}
                            onChange={(value) => {
                              let update_proccess = this.state.update_proccess;
                              update_proccess.organs = value;
                              this.setState({
                                update_proccess,
                              });
                            }}
                          />
                        </div>
                      </div>
                      {/* <div className="row">
                        <div className="">
                          <label for="validationServer02" className="form-label">Live Weight</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Live Weight"
                            onChange={this.handleChange("weight")}
                            value={this.state.update_proccess.weight}
                            required
                          />
                        </div>
                        <div className="">
                          <label for="validationServer02" className="form-label">Attach Cut Sheet</label>
                          <select
                            type="text"
                            className="form-control"
                            value={this.state.update_proccess.cut_sheet_from_id}
                            onChange={this.handleChange("cut_sheet_from_id")}
                            disabled={
                              this.state.update_proccess.cut_sheet_from_id && this.state.update_proccess.is_cut_sheet == 1
                                ? true
                                : false
                            }
                            required
                          >
                            <option value="">Select a cut sheet</option>
                            {this.props.cutSheetTemp.map((data, index) => (
                              <option value={data.id}>
                                {data.name} ({data.type})
                              </option>
                            ))}
                          </select>
                          <span style={{ color: "red" }}>
                            {this.state.from_error}
                          </span>
                        </div>
                      </div> */}
                      <div className="flex row justify-content-center">
                        {this.props.title === "Confirm Drop Off" ? (
                          <LoadingButton
                            className="btn btn-primary"
                            onClick={this.handleSubmit}
                            disabled={this.state.payloader}
                            isLoading={this.state.payloader}
                            width="500px"
                          >
                            Accept
                          </LoadingButton>
                        ) : (
                          <button
                            className="item-detail-btn"
                            onClick={this.handleUpdateProccess}
                          >
                            Update
                          </button>
                        )}
                      </div>{" "}
                    </>
                  ) : null}

                  {this.state.organicModal && (
                    <>
                      <div className="file-upload" style={{ border: "none" }}>
                        <div className="file-upload-btn">
                          <label>
                            <input
                              type="file"
                              onChange={this.selectOrganicFile}
                            />
                          </label>
                          <a
                            onClick={this.uploadOrganicFile}
                            className="file-upload-btn-item"
                          >
                            Upload
                          </a>
                        </div>

                        <div className="file-upload-table">
                          {!this.state.listDocumentOrganic ||
                          this.state.listDocumentOrganic.length == 0 ? (
                            <span>No File Uploaded</span>
                          ) : (
                            this.state.listDocumentOrganic.map((data) => (
                              <div className="file-upload-table-item">
                                <span>{data.base_name}</span>
                                <div>
                                  <span
                                    className="download-button"
                                    onClick={() =>
                                      this.downloadImage(
                                        data.link,
                                        data.base_name
                                      )
                                    }
                                  >
                                    Download
                                  </span>
                                  <span className="button-separator">|</span>
                                  <span
                                    className="download-button"
                                    onClick={() =>
                                      this.removeDocument(
                                        data.base_name,
                                        "organic_certified_files"
                                      )
                                    }
                                  >
                                    Remove
                                  </span>
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                      <div className="organic-form-btn">
                        <a
                          onClick={() => this.setState({ organicModal: false })}
                          className="btn btn-secondary mr-2"
                        >
                          Cancel
                        </a>
                        <a
                          onClick={() => this.setState({ organicModal: false })}
                          className="btn btn-primary"
                        >
                          Save
                        </a>
                      </div>
                    </>
                  )}

                  {this.state._30plusModal && (
                    <>
                      <div className="file-upload" style={{ border: "none" }}>
                        <div className="file-upload-btn">
                          <label>
                            <input
                              type="file"
                              onChange={this.select30PlusFile}
                            />
                          </label>
                          <a
                            onClick={this.upload30PlusFile}
                            className="file-upload-btn-item"
                          >
                            Upload
                          </a>
                        </div>
                        <div className="file-upload-table">
                          {this.state.listDocument30 &&
                            this.state.listDocument30.map((data) => (
                              <div className="file-upload-table-item">
                                <span>{data.base_name}</span>
                                <div>
                                  <span
                                    className="download-button"
                                    onClick={() =>
                                      this.downloadImage(
                                        data.link,
                                        data.base_name
                                      )
                                    }
                                  >
                                    Download
                                  </span>
                                  <span className="button-separator">|</span>
                                  <span
                                    className="download-button"
                                    onClick={() =>
                                      this.removeDocument(
                                        data.base_name,
                                        "thirty_plus_files"
                                      )
                                    }
                                  >
                                    Remove
                                  </span>
                                </div>
                              </div>
                            ))}
                          {!this.state.listDocument30.length ? (
                            <span>No File Uploaded</span>
                          ) : null}
                        </div>
                      </div>
                      <div className="organic-form-btn">
                        <a
                          onClick={() => this.setState({ _30plusModal: false })}
                          className="btn btn-secondary mr-2"
                        >
                          Cancel
                        </a>
                        <a
                          onClick={() => this.setState({ _30plusModal: false })}
                          className="btn btn-primary"
                        >
                          Save
                        </a>
                      </div>
                    </>
                  )}
                </>
              ) : this.props.title === "Reject Reason" ? (
                <div className="form-row">
                  <div className="col-md-8 mb-3">
                    <label htmlFor="EventName">Reject reason:</label>
                    <textarea
                      cols={90}
                      rows={5}
                      name="reject_reason"
                      value={current_popup_data.reject_reason}
                      disabled={true}
                    />
                  </div>
                </div>
              ) : null}
            </>
          </DialogPopup>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const {
    login: { loggedIn },
    login: { user },
  } = state;
  return { loggedIn, user };
};
export default connect(mapStateToProps)(ItemDetails);
