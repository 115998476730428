import React, { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import { connect } from "react-redux";

const LoadingBtn = ({ onclick, text, className = "btn btn-primary" }) => {
  const [isloading, setloading] = useState(false);

  return (
    <a
      onClick={async (e) => {
        try {
          setloading(true);
          await onclick();
          setloading(false);
        } catch (error) {
          console.log(error);
          setloading(false);
        }
      }}
      className={`${className}`}
    >
      {isloading ? ( // Show loader if loading is true
        <div className="loader" style={{ width: "10px", height: "10px" }}></div>
      ) : (
        text // Show "Save" text if loading is false
      )}
    </a>
  );
};

export default LoadingBtn;
