//Component Imports
import "./App.css";

import React, { Component } from "react";

import AllRoutes from "./Router/AllRoutes";
// Routes
import LoginRoutes from "./Router/LoginRouter";
import { Route } from "react-router-dom";
import { connect } from "react-redux";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      routes: [
        {
          path: "/login",
          component: LoginRoutes,
          exact: true,
        },
        {
          path: "/register",
          component: LoginRoutes,
          exact: true,
        },
        {
          path: "/",
          component: AllRoutes,
          exact: false,
        },
      ],
    };
  }
  render() {
    let { routes } = this.state;
    return (
      <div className="App">
        {/* <AllRoutes /> */}
        {routes.map((route, routeindex) => {
          return (
            <>
              <Route key={routeindex} {...route} />
            </>
          );
        })}
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { user, loggedIn } = state.login;
  return { user, loggedIn };
}
export default connect(mapStateToProps)(App);
