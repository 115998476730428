import React from "react";
import "./Add-Event.styles.css";

import ShowEvents from "./Show-Events.component";
import Service from "../../Service/Service";
import moment from "moment";
import axios from "axios";
import { NOTIFICATION } from "../../actions";
import { connect } from "react-redux";
import ReasonPopup from "../../components/Popup/ReasonPopup";
import ConfirmPopup from "../../components/Popup/ConfirmPopup";
import SellerPopup from "../../components/SellerPopup/SellerPopup";
import Select from "react-select";

class AddEvents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryType: "",
      externamProps: {},
      eventName: {
        data: "",
        required: true,
        errMsg: "Required",
      },
      //  description: {
      //    data: '',
      //    required: true,
      //    errMsg: 'Required'
      //  },
      formError: {},
      active_listing_data: [],
      active_listing_data_added: [],
      event_count: this.props.needarg.acount,
      processor_status: 0,
      project_id: 0,
      reason_popup: false,
      delete_popup: false,
      edit_popup: false,
      event_cid: 0,
      event_id: 0,
      event_title: "",
      project_id_hamburger: 0,
      hamburger_toggle: false,
      event_loader: true,
      buyerName: {
        data: "",
        required: true,
        errMsg: "Required",
      },
      last_name: {
        data: "",
        required: true,
        errMsg: "Required",
      },
      userEmail: {
        data: "",
        required: true,
        errMsg: "Required",
      },
      mobileNumber: {
        data: "",
        required: true,
        errMsg: "Required",
      },
      streetAddress: {
        data: "",
        required: true,
        errMsg: "Required",
      },
      eventName: {
        data: "",
        required: true,
        errMsg: "Required",
      },
      selected_seller: [],
      work_loc: {
        data: [],
        required: false,
        errorMsg: "Please select a seller.",
      },
      cut_sheet_data_id: {
        data:
          this.props.cut_sheet_data_id && this.props.cut_sheet_data_id > 0
            ? this.props.cut_sheet_data_id
            : "",
        required: false,
        errorMsg: "",
      },
      cut_sheet_data:
        this.props.cut_sheet_data_id && this.props.cut_sheet_data_id > 0
          ? this.props.cut_sheet_data
          : {
              name: "",
              value: "",
            },
      cut_sheet_temp_id: {
        data: "",
        required: false,
        errorMsg: "",
      },
      cut_sheet_temp: {
        name: "",
        value: "",
      },
      customer_id: 0,
      new_buyer: false,
      customerCutSheetData:
        this.props.cut_sheet_data_id && this.props.cut_sheet_data_id > 0
          ? [this.props.cut_sheet_data]
          : [],
      customerCutSheetDisable:
        this.props.cut_sheet_data_id && this.props.cut_sheet_data_id > 0
          ? true
          : false,
      customerCutSheetTemp: [],
      seller_names: "Select Seller",
      sellerpopup: false,
      btnloading: false,
      non_schedule: {
        data: "",
        checked: true,
      },
      schedule: {
        data: "",
        checked: false,
      },
      non_schedule_multi: {
        data: "",
        checked: false,
      },
      multi_buyers: [
        {
          buyerName: {
            data: "",
            required: false,
            errMsg: "Required",
          },
          last_name: {
            data: "",
            required: false,
            errMsg: "Required",
          },
          userEmail: {
            data: "",
            required: false,
            errMsg: "Required",
          },
          mobileNumber: {
            data: "",
            required: false,
            errMsg: "Required",
          },
          streetAddress: {
            data: "",
            required: false,
            errMsg: "Required",
          },
          quarter: {
            data: "",
            required: false,
            errMsg: "Required",
          },
          cut_sheet_data_id: {
            data:
              this.props.cut_sheet_data_id && this.props.cut_sheet_data_id > 0
                ? this.props.cut_sheet_data_id
                : "",
            required: false,
            errorMsg: "",
          },
          cut_sheet_data:
            this.props.cut_sheet_data_id && this.props.cut_sheet_data_id > 0
              ? this.props.cut_sheet_data
              : {
                  name: "",
                  value: "",
                },
          cut_sheet_temp_id: {
            data: "",
            required: false,
            errorMsg: "",
          },
          cut_sheet_temp: {
            name: "",
            value: "",
          },
          customer_id: 0,
          new_buyer: false,
          customerCutSheetData:
            this.props.cut_sheet_data_id && this.props.cut_sheet_data_id > 0
              ? [this.props.cut_sheet_data]
              : [],
        },
      ],
    };
  }
  openModalp = (event) => (e) => {
    e.preventDefault();
    this.setState({ reason_popup: event, sellerpopup: event });
  };
  closeModal = (event) => (e) => {
    e.preventDefault();
    this.setState({ delete_popup: event, edit_popup: event });
  };
  closeM = (project_id, re) => {
    this.setState({
      reason_popup: false,
    });
    let status = 2;
    let payload = { project_id: project_id, status: 2, reject_reason: re };
    return new Service(this.props.user.user_token)
      .post("/calendar/updateProcessorAcceptanceStatus", payload)
      .then((data) => {
        this.setState({
          processor_status: status,
          project_id: project_id,
        });
      });
  };

  calenderEvent = (event_id, action) => {
    if (action == "delete") {
      this.setState({
        delete_popup: false,
      });
      let payload = { event_id: event_id, action: action };
      return new Service(this.props.user.user_token)
        .post("/calendar/calenderEventAction", payload)
        .then((data) => {
          this.reloadView();
          this.clearData();
          return false;
        });
    } else if (action == "edit") {
      this.setState({
        edit_popup: false,
      });
      this.reloadView();
      this.clearData();
      return false;
    }
  };

  handleChange1 = (e) => {
    console.log("handlechange1 ====", e.target.value);
    let { name, value } = e.target;
    let { formError, buyerName, last_name } = this.state;
    if (formError[name]) delete formError[name];
    if (formError["error"]) delete formError["error"];
    let prestate = this.state[name];
    prestate.data = value;
    this.setState({
      [name]: prestate,
    });

    if (name == "buyerName") {
      this.getCustomerCutSheetData(prestate, last_name);
    } else if (name == "last_name") {
      this.getCustomerCutSheetData(buyerName, prestate);
    }
  };

  handleChange2 = (e, index) => {
    let { multi_buyers } = this.state;
    console.log("handlechange2 ====", e.target.value);
    let { name, value } = e.target;
    multi_buyers[index][name].data = value;
    this.setState({
      multi_buyers: multi_buyers,
    });

    this.getCustomerCutSheetData(
      multi_buyers[index]["buyerName"],
      multi_buyers[index]["last_name"],
      index
    );
  };

  handleSelectChange1 = (selectedOption) => {
    console.log("handleSelectChange1 ====", selectedOption);
    let prestate = this.state.cut_sheet_data_id;
    prestate.data = selectedOption.value || "";
    let cut_sheet_temp_id = this.state.cut_sheet_temp_id;
    cut_sheet_temp_id.data = "";
    this.setState({
      cut_sheet_data_id: prestate,
      cut_sheet_data: selectedOption,
      cut_sheet_temp_id: cut_sheet_temp_id,
      cut_sheet_temp: {
        name: "",
        value: "",
      },
      new_buyer: false,
    });
  };

  handleSelectChange2 = (selectedOption, index) => {
    let { multi_buyers } = this.state;
    multi_buyers[index]["cut_sheet_data_id"].data = selectedOption.value || "";
    multi_buyers[index]["cut_sheet_data"] = selectedOption;
    multi_buyers[index]["cut_sheet_temp_id"].data = "";
    multi_buyers[index]["cut_sheet_temp"] = {
      name: "",
      value: "",
    };
    multi_buyers[index]["new_buyer"] = false;
    this.setState({
      multi_buyers: multi_buyers,
    });
  };

  handleSelectChange3 = (selectedOption) => {
    console.log("handleSelectChange3 ====", selectedOption);
    let prestate = this.state.cut_sheet_temp_id;
    prestate.data = selectedOption.value || "";
    let cut_sheet_data_id = this.state.cut_sheet_data_id;
    cut_sheet_data_id.data = "";
    this.setState({
      cut_sheet_temp_id: prestate,
      cut_sheet_temp: selectedOption,
      cut_sheet_data_id: cut_sheet_data_id,
      cut_sheet_data: {
        name: "",
        value: "",
      },
      new_buyer: true,
    });
  };

  handleSelectChange4 = (selectedOption, index) => {
    let { multi_buyers } = this.state;
    multi_buyers[index]["cut_sheet_temp_id"].data = selectedOption.value || "";
    multi_buyers[index]["cut_sheet_temp"] = selectedOption;
    multi_buyers[index]["cut_sheet_data_id"].data = "";
    multi_buyers[index]["cut_sheet_data"] = {
      name: "",
      value: "",
    };
    multi_buyers[index]["new_buyer"] = true;
    this.setState({
      multi_buyers: multi_buyers,
    });
  };

  handleValidation = () => {
    let { formError } = this.state;
    if (this.state.non_schedule_multi.checked) {
      if (this.state["eventName"].data === "")
        formError["eventName"] =
          this.state["eventName"].errMsg &&
          this.state["eventName"].errMsg !== ""
            ? this.state["eventName"].errMsg
            : "Required";
    } else {
      Object.keys(this.state).map((key) => {
        if (
          this.state[key] &&
          this.state[key].required &&
          this.state[key].data === ""
        )
          formError[key] =
            this.state[key].errMsg && this.state[key].errMsg !== ""
              ? this.state[key].errMsg
              : "Required";
      });
    }

    this.setState(
      {
        formError: formError,
      },
      () => {
        console.log("error set");
      }
    );

    //  return formError
  };

  reject_reason = (event_cid) => (e) => {
    this.setState({
      reason_popup: true,
      event_cid: event_cid,
    });
  };

  event_delete = (event_id, title) => (e) => {
    this.setState({
      delete_popup: true,
      event_id: event_id,
      event_title: title,
    });
  };

  event_edit = (event_id, title) => (e) => {
    this.setState({
      edit_popup: true,
      event_id: event_id,
      event_title: title,
    });
  };

  updateStatus = (status, project_id) => (e) => {
    if (this.props.user && this.props.user.card_profileid != "") {
      console.log("grazr pay is required  ----");
    } else {
      window.location.href = "/paymentgateway";
      return false;
    }

    let self = this;

    // buyer transaction 1 - auto payout to seller after processer accept the order
    let payment_data = {
      mode: process.env.REACT_APP_PAYMENTMODE,
      account_id: process.env.REACT_APP_AUCTIONPAY_ID,
      payment_url: process.env.REACT_APP_AUCTIONPAY_URL,
      type: "seller",
      grazr_fee_rate: 0.075,
      server_fee_rate: Number(process.env.REACT_APP_AUCTIONPAY_RATE),
    };

    let payload = {
      project_id: project_id,
      status: status,
      payment_data: payment_data,
    };
    return new Service(this.props.user.user_token)
      .post("/calendar/updateProcessorAcceptanceStatus", payload)
      .then((data) => {
        self.setState({
          processor_status: status,
          project_id: project_id,
        });
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({
      btnloading: true,
    });

    if (this.props.user && this.props.user.card_profileid != "") {
      console.log("grazr pay is required  ----");
    } else {
      window.location.href = "/paymentgateway";
      return false;
    }

    let {
      token,
      user_token,
      currenteventdate,
      needarg,
      user_id,
      close,
      data,
      role,
      currentPopupData,
    } = this.props;
    //  let { description: { data: cdata }, eventName: { data: ename } } = this.state old
    let {
      eventName: { data: ename },
    } = this.state;
    let currenteventdate1 = new Date(currenteventdate);
    console.log("this.props.role ----", this.props.role);
    //  let payload = {
    //    event_date: moment(this.props.currenteventdate).format('YYYY-MM-DD HH:mm:ss'),
    //    order_id: this.props.role == 'seller' ? this.props.currentPopupData.project_id : 0,
    //    processor_id: this.props.role == 'seller' ? this.props.currentPopupData.processor_id : this.props.user_id,
    //    seller_id: "seller",
    //    category_type: this.props.needarg.category,
    //    eventName: this.props.role == 'seller' ? this.props.currentPopupData.title : 'Non Grazr Animal',
    //    eventDesc: "",
    //    day: currenteventdate1.getDay(),
    //    cart_id : this.props.role == 'seller' ? this.props.currentPopupData.cart_id : 0,
    //    extra_field_1 : 1
    //  };

    let payload = {
      event_date: moment(this.props.currenteventdate).format(
        "YYYY-MM-DD HH:mm:ss"
      ),
      order_id:
        this.props.role == "seller"
          ? this.props.currentPopupData.project_id
          : 0,
      processor_id:
        this.props.role == "seller"
          ? this.props.currentPopupData.processor_id
          : this.props.user_id,
      seller_id: "seller",
      category_type: this.props.needarg.category,
      process_option: this.props.needarg.process_option,
      eventName:
        this.props.role == "seller"
          ? this.props.currentPopupData.title
          : this.state.eventName.data,
      eventDesc: "",
      day: currenteventdate1.getDay(),
      cart_id:
        this.props.role == "seller" ? this.props.currentPopupData.cart_id : 0,
      extra_field_1: 1,
    };

    if (this.props.role != "seller") {
      payload.buyerName = this.state.buyerName.data;
      payload.last_name = this.state.last_name.data;
      payload.userEmail = this.state.userEmail.data;
      payload.streetAddress = this.state.streetAddress.data;
      payload.mobileNumber = this.state.mobileNumber.data;
      payload.status = 0;
      payload.multi_buyers = this.state.multi_buyers;
      payload.non_schedule_multi = this.state.non_schedule_multi.checked;
      payload.cut_sheet_data_id = this.state.cut_sheet_data_id.data;
      payload.cut_sheet_temp_id = this.state.cut_sheet_temp_id.data;
      payload.customer_id = this.state.customer_id;
      payload.new_buyer = this.state.new_buyer;
      payload.nonGrazrOrder = 1;
      payload.cut_sheet_link_base = process.env.REACT_APP_FRONTENT_URL;
      if (this.state.schedule.checked) {
        payload.nonGrazrOrder = 0;
        payload.order_type = "schedule";
      }
    } else {
      payload.nonGrazrOrder = 0;
    }
    console.log("payload =======", payload);
    if (this.props.role != "seller") {
      this.handleValidation();
    }
    if (
      Object.keys(this.state.formError).length > 0 ||
      Object.keys(this.state.formError) === "undefined"
    ) {
      console.log("this.state.formError ==", this.state.formError);
      this.setState({
        loader: false,
        btnloading: false,
      });
      return false;
    }

    new Service(token)
      .post("/calendar/createDropoff", payload)
      .then(({ status, message, data }) => {
        let { formError } = this.state;
        if (status === "yes") {
          let temp_active_listing_data = this.state.active_listing_data;

          if (this.state.customerCutSheetDisable) {
            this.props.cut_sheet_data_submit();
            return false;
          }

          if (this.props.user.role != "seller") {
            // var buyer_info =
            //   this.state.buyerName.data +
            //   " " +
            //   this.state.last_name.data +
            //   "--" +
            //   this.state.mobileNumber.data;

            this.reloadView();
            this.clearData();
            return false;
          } else {
            this.setState({
              event_count: this.state.event_count - 1,
              project_id: this.props.role == "seller" ? payload.order_id : -1,
              btnloading: false,
            });
            this.reloadView("seller_reload");
            this.clearData();
            return false;
            var buyer_info =
              this.props.currentPopupData.first_name +
              " " +
              this.props.currentPopupData.last_name +
              "--" +
              this.props.currentPopupData.phone;
          }
          temp_active_listing_data.push({
            cid: payload.order_id,
            buyer_info: buyer_info,
            seller_info:
              this.props.user.role == "seller"
                ? this.props.user.first_name
                : "--",
            title: payload.eventName,
            ng_buyer_firstname: payload.buyerName,
            ng_buyer_lastname: payload.last_name,
            ng_phone: payload.mobileNumber,
            ng_email: payload.userEmail,
            data_hide: 0,
          });
          this.setState({
            active_listing_data_added: temp_active_listing_data,
            event_count: this.state.event_count - 1,
            project_id: this.props.role == "seller" ? payload.order_id : -1,
            btnloading: false,
          });
          formError["error"] = "";
        }
        if (status === "no") {
          formError["error"] = message;
        }
        this.setState({ formError, btnloading: false });
      });
  };

  hamBurger = (project_id) => (e) => {
    this.setState({
      project_id_hamburger: project_id,
      hamburger_toggle: !this.state.hamburger_toggle,
    });
  };

  handleChange = (e) => {
    let { name, value } = e.target;
    let { extendedProps, handleEventClick } = this.props;
    let eprop = {
      event: {
        extendedProps: { ...extendedProps.event.extendedProps },
      },
    };
    eprop.event.extendedProps.category = value;
    this.setState(
      {
        [name]: value,
      },
      () => handleEventClick(eprop)
    );
  };

  clr_state = () => {
    let refresh_status = false;
    if (this.state.project_id != 0) {
      refresh_status = true;
    }
    this.setState({
      project_id: 0,
    });
    this.props.assign_refetchEvents(refresh_status);
    this.props.close(false);
  };

  popupConctrol = () => {
    this.setState({
      sellerpopup: !this.state.sellerpopup,
    });
  };
  changeChecked = (checkname, first_name, last_name) => (e) => {
    let prcs_name = first_name + " " + last_name;
    let checked = e.target.checked;
    let value = e.target.value;
    let {
      work_loc,
      work_loc: { data: worklocArr },
      formError,
      selected_seller,
    } = this.state;
    if (formError["work_loc"]) delete formError["work_loc"];

    if (checked) {
      worklocArr = [];
      selected_seller = [];
      worklocArr.push(value);
      selected_seller.push(prcs_name);
    } else {
      worklocArr = [];
      selected_seller = [];
    }

    worklocArr = worklocArr.filter(function (item) {
      return item !== 0;
    });

    work_loc["data"] = worklocArr;

    this.setState({
      work_loc: work_loc,
      selected_seller: selected_seller,
      seller_names: selected_seller.join(", "),
    });
  };

  handleValidation2 = () => {
    let { formError } = this.state;
    let key = "work_loc";
    if (
      this.state[key] &&
      this.state[key].required &&
      this.state[key].data === ""
    ) {
      formError[key] =
        this.state[key].errMsg && this.state[key].errMsg !== ""
          ? this.state[key].errMsg
          : "Required";
    }
    this.setState(
      {
        formError: formError,
      },
      () => {
        console.log("error set");
      }
    );
  };

  addCustomer = (type) => {
    let { multi_buyers } = this.state;
    if (type && type == "clear") {
      multi_buyers = [];
    }
    multi_buyers.push({
      buyerName: {
        data: "",
        required: false,
        errMsg: "Required",
      },
      last_name: {
        data: "",
        required: false,
        errMsg: "Required",
      },
      userEmail: {
        data: "",
        required: false,
        errMsg: "Required",
      },
      mobileNumber: {
        data: "",
        required: false,
        errMsg: "Required",
      },
      streetAddress: {
        data: "",
        required: false,
        errMsg: "Required",
      },
      quarter: {
        data: "",
        required: false,
        errMsg: "Required",
      },
      cut_sheet_data_id: {
        data:
          this.props.cut_sheet_data_id && this.props.cut_sheet_data_id > 0
            ? this.props.cut_sheet_data_id
            : "",
        required: false,
        errorMsg: "",
      },
      cut_sheet_data:
        this.props.cut_sheet_data_id && this.props.cut_sheet_data_id > 0
          ? this.props.cut_sheet_data
          : {
              name: "",
              value: "",
            },
      cut_sheet_temp_id: {
        data: "",
        required: false,
        errorMsg: "",
      },
      cut_sheet_temp: {
        name: "",
        value: "",
      },
      customer_id: 0,
      new_buyer: false,
      customerCutSheetData:
        this.props.cut_sheet_data_id && this.props.cut_sheet_data_id > 0
          ? [this.props.cut_sheet_data]
          : [],
    });
    this.setState({
      multi_buyers: multi_buyers,
    });
  };

  handleClick = (name) => (e) => {
    let { formError } = this.state;
    if (formError["order_type"]) delete formError["order_type"];
    let preState = {};
    let { schedule, non_schedule, non_schedule_multi } = this.state;

    if (name === "schedule") {
      preState = schedule;
      preState.checked = true;
      non_schedule.checked = false;
      non_schedule_multi.checked = false;

      this.setState({
        [name]: preState,
        non_schedule: non_schedule,
        non_schedule_multi: non_schedule_multi,
        formError: formError,
      });
    }
    if (name === "non_schedule") {
      preState = non_schedule;
      preState.checked = true;
      schedule.checked = false;
      non_schedule_multi.checked = false;

      this.setState({
        [name]: preState,
        schedule: schedule,
        non_schedule_multi: non_schedule_multi,
        formError: formError,
      });
    }
    if (name === "non_schedule_multi") {
      preState = non_schedule_multi;
      preState.checked = true;
      schedule.checked = false;
      non_schedule.checked = false;

      this.setState({
        [name]: preState,
        schedule: schedule,
        non_schedule: non_schedule,
        formError: formError,
      });
    }
  };

  clearData() {
    let {
      eventName,
      buyerName,
      last_name,
      userEmail,
      mobileNumber,
      streetAddress,
      formError,
      cut_sheet_data_id,
      cut_sheet_data,
      cut_sheet_temp_id,
      cut_sheet_temp,
      customer_id,
      new_buyer,
    } = this.state;

    eventName.data = "";
    buyerName.data = "";
    last_name.data = "";
    userEmail.data = "";
    mobileNumber.data = "";
    streetAddress.data = "";

    cut_sheet_data_id.data = "";
    cut_sheet_data = {
      name: "",
      value: "",
    };
    cut_sheet_temp_id.data = "";
    cut_sheet_temp = {
      name: "",
      value: "",
    };
    customer_id = 0;
    new_buyer = false;

    formError = {};

    this.setState({
      eventName: eventName,
      buyerName: buyerName,
      last_name: last_name,
      userEmail: userEmail,
      mobileNumber: mobileNumber,
      streetAddress: streetAddress,
      formError: formError,
      btnloading: false,
      cut_sheet_data_id: cut_sheet_data_id,
      cut_sheet_data: cut_sheet_data,
      cut_sheet_temp_id: cut_sheet_temp_id,
      cut_sheet_temp: cut_sheet_temp,
      customer_id: customer_id,
      new_buyer: new_buyer,
    });

    this.addCustomer("clear");
  }

  reloadView(type) {
    let self = this;

    if (type == "seller_reload") {
      self.setState({
        event_count: 0,
      });
    } else {
      self.setState({
        event_count: this.props.needarg.acount,
      });
    }

    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.token;
    let url = process.env.REACT_APP_BASE_URL;

    let data = {};
    data = {
      category_type: this.props.needarg.category,
      processor_id:
        this.props.role == "seller"
          ? this.props.currentPopupData.processor_id
          : this.props.user_id,
      event_date: moment(
        moment.utc(this.props.currenteventdate).toDate()
      ).format("YYYY-MM-DD"),
    };
    axios.post(url + "calendar/show_event", data).then(function (response) {
      if (
        response &&
        response.data &&
        response.data &&
        response.data.status === "false"
      ) {
        self.setState({
          event_loader: false,
        });
      } else {
        let active_listing_data = response.data.data;
        console.log("active_listing_data ===", active_listing_data);
        if (active_listing_data.length > 0) {
          self.setState({
            active_listing_data: active_listing_data,
            processor_status: parseInt(active_listing_data[0].processor_status),
            event_loader: false,
          });
        } else {
          self.setState({
            active_listing_data: [],
            processor_status: 0,
            event_loader: false,
          });
        }
      }
      let list = self.state.active_listing_data;
      let list2 = self.state.active_listing_data;
      var count = {};
      list.forEach(function (i) {
        count[i] = (count[i] || 0) + 1;
      });
      list = list.filter(function ({ id }) {
        return !this[id] && (this[id] = id);
      }, {});
      let list1 = [];

      list.forEach((element) => {
        list1.push(element.id);
      });

      let flag = false;
      list2.forEach(function (e) {
        list1.forEach(function (element, index) {
          if (e.id == element) {
            e.data_hide = 0;
            e.count = list2.filter((item) => item.id == e.id).length;
            console.log("element ture");
            flag = true;
            list1.splice(index, 1);
          }
        });
        if (flag == false) {
          e.data_hide = 1;
          flag = false;
        }
      });
      self.setState({
        active_listing_data_added: list2,
      });
    });
  }

  getCutSheetTemp = async () => {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.user.user_token;
    let url = process.env.REACT_APP_BASE_URL;

    let self = this;
    const data = {
      user_id:
        this.props.user.role == "customer" || this.props.user.role == "producer"
          ? this.props.user.admin
          : this.props.user.id,
      form_status: "1",
    };

    axios
      .post(url + "cutsheet/get_custom_form", data)
      .then(function (response) {
        if (response.data.status) {
          let list = [];
          response.data.result.data.forEach(function (element, i) {
            let data = {
              label: "" + element.name + " (" + element.type + ") ",
              value: element.id,
            };
            list.push(data);
          });
          self.setState({
            customerCutSheetTemp: list,
          });
        } else {
          self.props.dispatch(
            NOTIFICATION({
              msg: response.data.message || "something went wrong",
              msgColor: "#FAD5BD",
            })
          );
          setTimeout(() => {
            self.props.dispatch(NOTIFICATION({ msg: "" }));
          }, 3000);
        }
      });
  };

  getCustomerCutSheetData = async (first_name, last_name, index) => {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.user.user_token;
    let url = process.env.REACT_APP_BASE_URL;

    let self = this;

    const data = {
      status: "1",
      user_id: this.props.user.id,
      first_name: first_name.data,
      last_name: last_name.data,
      form_status: "completed",
    };
    axios
      .post(url + "cutsheet/get_custom_form_data", data)
      .then(function (response) {
        if (response.data.status) {
          let list = [];
          response.data.result.data.forEach(function (element, i) {
            let data = {
              label:
                "Order " +
                element.order_id +
                " (" +
                element.type +
                ") " +
                element.name +
                " " +
                moment(element.date_added).format("MM/DD/YYYY") +
                " (" +
                element.form_status +
                ")",
              value: element.id,
            };
            list.push(data);
          });
          console.log("getCustomerCutSheetData", list, index);

          if (index >= 0) {
            let { multi_buyers } = self.state;
            multi_buyers[index]["customer_id"] =
              response.data.result.data.length > 0
                ? response.data.result.data[0].customer_id
                : 0;
            multi_buyers[index]["new_buyer"] = false;
            if (!self.state.customerCutSheetDisable) {
              multi_buyers[index]["customerCutSheetData"] = list;
              multi_buyers[index]["cut_sheet_data_id"].data = "";
              multi_buyers[index]["cut_sheet_data"] = {
                name: "",
                value: "",
              };
              multi_buyers[index]["cut_sheet_temp_id"].data = "";
              multi_buyers[index]["cut_sheet_temp"] = {
                name: "",
                value: "",
              };
            }
            self.setState({
              multi_buyers: multi_buyers,
            });
          } else {
            if (self.state.customerCutSheetDisable) {
              self.setState({
                customer_id:
                  response.data.result.data.length > 0
                    ? response.data.result.data[0].customer_id
                    : 0,
                new_buyer: false,
              });
            } else {
              self.setState({
                customer_id:
                  response.data.result.data.length > 0
                    ? response.data.result.data[0].customer_id
                    : 0,
                new_buyer: false,
                cut_sheet_data_id: {
                  data: "",
                  required: false,
                  errorMsg: "",
                },
                cut_sheet_data: {
                  name: "",
                  value: "",
                },
                cut_sheet_temp_id: {
                  data: "",
                  required: false,
                  errorMsg: "",
                },
                cut_sheet_temp: {
                  name: "",
                  value: "",
                },
                customerCutSheetData: list,
              });
            }
          }
        } else {
          self.props.dispatch(
            NOTIFICATION({
              msg: response.data.message || "something went wrong",
              msgColor: "#FAD5BD",
            })
          );
          setTimeout(() => {
            self.props.dispatch(NOTIFICATION({ msg: "" }));
          }, 3000);
        }
      });
  };

  componentDidMount() {
    console.log("this.props");
    console.log(this.props);
    let self = this;

    this.reloadView();
    this.getCutSheetTemp();

    let { eventClickedCAtegory, needarg } = this.props;
    self.setState({
      categoryType: eventClickedCAtegory,
      event_count: needarg.acount,
    });
  }
  render() {
    let { title } = this.props;
    // let {eventName:{data:edata},description:{data:cdata1},formError} = this.state; old
    let {
      eventName: { data: edata },
      formError,
      work_loc: { data: work_locc },
    } = this.state;
    let {
      active_listing_data,
      active_listing_data_added,
      non_schedule,
      schedule,
      non_schedule_multi,
      multi_buyers,
    } = this.state;
    return (
      <div className="G-zr">
        <div className="Addmodal zindex calenpop" id="myModal">
          <div
            className="modal-dialog"
            style={{ width: "70%", maxWidth: "70%" }}
          >
            <div className="modal-content trck-lfpop">
              {/* <!-- Modal Header --> */}
              <div className="modal-header align-items-center">
                <h4 className="modal-title">
                  <span>
                    {moment(this.props.currenteventdate).format("MM-DD-YYYY")}
                  </span>
                </h4>
                <span
                  className="fs-14 fw-sm fa fa-close fa-close-cls close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={(e) => this.clr_state()}
                ></span>
              </div>

              {/* <!-- Modal body --> */}
              <div
                className="modal-body "
                style={{ overflow: "auto", maxHeight: "80vh" }}
              >
                {Number(this.state.event_count) > 0 ? (
                  <>
                    {this.props.role != "seller" ? (
                      <>
                        <div className="row">
                          <span className="G-radio d-flex justify-content-start align-items-center flex-wrap">
                            <label htmlFor="rad-3" className="conta">
                              Individual Customer
                              <input
                                type="radio"
                                value={non_schedule.data}
                                name="radio3"
                                id="rad-3"
                                onClick={this.handleClick("non_schedule")}
                                checked={non_schedule.checked}
                              />
                              <span className="checkmark" />
                            </label>
                            <label htmlFor="rad-5" className="conta">
                              Multiple Customers
                              <input
                                type="radio"
                                value={non_schedule_multi.data}
                                name="radio5"
                                id="rad-5"
                                onClick={this.handleClick("non_schedule_multi")}
                                checked={non_schedule_multi.checked}
                              />
                              <span className="checkmark" />
                            </label>
                            <label htmlFor="rad-4" className="conta">
                              Schedule Order
                              <input
                                type="radio"
                                name="radio4"
                                id="rad-4"
                                value={schedule.data}
                                onClick={this.handleClick("schedule")}
                                checked={schedule.checked}
                              />
                              <span className="checkmark" />
                            </label>
                          </span>
                          <span style={{ color: "red" }}>
                            {formError["order_type"]}
                          </span>
                        </div>
                      </>
                    ) : null}
                    {this.props.role != "seller" ? (
                      this.state.schedule.checked ? (
                        <label className="fs-18 fw-sm">Schedule Order</label>
                      ) : (
                        <label className="fs-18 fw-sm">Non Grazr Order</label>
                      )
                    ) : null}
                    <form onSubmit={this.handleSubmit}>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-12">
                            <label htmlFor="EventName">Event Name</label>
                            <input
                              type="text"
                              name="eventName"
                              value={
                                this.props.role == "seller"
                                  ? this.props.currentPopupData.title
                                  : this.state.eventName.data
                              }
                              onChange={(e) => this.handleChange1(e)}
                              className="form-control"
                              id="EventName"
                              placeholder={
                                this.state.schedule.checked
                                  ? "Schedule Order"
                                  : "Non Grazr Order"
                              }
                              disable={true}
                            ></input>
                            <span style={{ color: "red" }}>
                              {formError["eventName"]}
                            </span>
                            <br></br>
                          </div>
                          {non_schedule_multi.checked ? (
                            <>
                              {multi_buyers.map((val, index) => (
                                <>
                                  <div className="col-md-6">
                                    <br></br>
                                    <label htmlFor="buyerName">
                                      First Name
                                    </label>
                                    <input
                                      type="text"
                                      name="buyerName"
                                      value={multi_buyers[index].buyerName.data}
                                      className="form-control"
                                      id="buyerName"
                                      onChange={(e) =>
                                        this.handleChange2(e, index)
                                      }
                                      placeholder="Enter Name"
                                    ></input>

                                    <br></br>
                                    <label htmlFor="last_name">Last Name</label>
                                    <input
                                      type="text"
                                      name="last_name"
                                      value={multi_buyers[index].last_name.data}
                                      className="form-control"
                                      id="last_name"
                                      onChange={(e) =>
                                        this.handleChange2(e, index)
                                      }
                                      placeholder="Enter last Name"
                                    ></input>

                                    <br></br>
                                    <label htmlFor="streetAddress">
                                      Street address
                                    </label>
                                    <input
                                      type="text"
                                      name="streetAddress"
                                      value={
                                        multi_buyers[index].streetAddress.data
                                      }
                                      className="form-control"
                                      id="streetAddress"
                                      placeholder="Enter Address"
                                      onChange={(e) =>
                                        this.handleChange2(e, index)
                                      }
                                    ></input>
                                    <br></br>
                                    {!this.state.schedule.checked &&
                                      !this.state.customerCutSheetDisable && (
                                        <>
                                          <label htmlFor="cut_sheet_temp_id">
                                            Attach New Cut Sheet
                                          </label>
                                          <Select
                                            disable={
                                              this.state.customerCutSheetDisable
                                            }
                                            value={
                                              multi_buyers[index].cut_sheet_temp
                                            }
                                            options={
                                              this.state.customerCutSheetTemp
                                            }
                                            name="cut_sheet_temp_id"
                                            id="cut_sheet_temp_id"
                                            placeholder="Select Cut Sheet"
                                            onChange={(e) =>
                                              this.handleSelectChange4(e, index)
                                            }
                                          />
                                          <br></br>
                                        </>
                                      )}
                                  </div>
                                  <div className="col-md-6">
                                    <br></br>
                                    <>
                                      <label htmlFor="mobileNumber">
                                        Mobile number
                                      </label>
                                      <input
                                        type="text"
                                        name="mobileNumber"
                                        value={
                                          multi_buyers[index].mobileNumber.data
                                        }
                                        className="form-control"
                                        id="mobileNumber"
                                        placeholder="Enter Mobile Number"
                                        onChange={(e) =>
                                          this.handleChange2(e, index)
                                        }
                                      ></input>

                                      <br></br>
                                      <label htmlFor="userEmail">Email</label>
                                      <input
                                        type="text"
                                        name="userEmail"
                                        value={
                                          multi_buyers[index].userEmail.data
                                        }
                                        className="form-control"
                                        id="userEmail"
                                        placeholder="Enter Email"
                                        onChange={(e) =>
                                          this.handleChange2(e, index)
                                        }
                                      ></input>
                                      <br></br>
                                      <label htmlFor="quarter">Quarter</label>
                                      <select
                                        type="text"
                                        name="quarter"
                                        value={multi_buyers[index].quarter.data}
                                        className="form-control"
                                        id="quarter"
                                        placeholder="Select Quarter"
                                        onChange={(e) =>
                                          this.handleChange2(e, index)
                                        }
                                      >
                                        <option>Select Quarter</option>
                                        <option value="1">1 Quarter</option>
                                        <option value="2">2 Quarters</option>
                                        <option value="3">3 Quarters</option>
                                      </select>
                                      <br></br>
                                      {!this.state.schedule.checked && (
                                        <>
                                          {(multi_buyers[index]
                                            .customerCutSheetData.length > 0 ||
                                            this.state
                                              .customerCutSheetDisable) && (
                                            <>
                                              <label htmlFor="cut_sheet_data_id">
                                                Attach Previous Cut Sheet Orders
                                              </label>
                                              <Select
                                                disable={
                                                  this.state
                                                    .customerCutSheetDisable
                                                }
                                                value={
                                                  multi_buyers[index]
                                                    .cut_sheet_data
                                                }
                                                options={
                                                  multi_buyers[index]
                                                    .customerCutSheetData
                                                }
                                                name="cut_sheet_data_id"
                                                id="cut_sheet_data_id"
                                                placeholder="Select Cut Sheet"
                                                onChange={(e) =>
                                                  this.handleSelectChange2(
                                                    e,
                                                    index
                                                  )
                                                }
                                              />
                                            </>
                                          )}
                                          <br></br>
                                        </>
                                      )}
                                    </>

                                    {this.state.non_schedule_multi.checked &&
                                    index == multi_buyers.length - 1 ? (
                                      <div className="text-right">
                                        <button
                                          type="button"
                                          className="btn plnButton"
                                          onClick={() => this.addCustomer()}
                                        >
                                          {" "}
                                          <span class="material-icons">
                                            add_circle_outline
                                          </span>{" "}
                                          Add more
                                        </button>
                                      </div>
                                    ) : null}
                                  </div>
                                </>
                              ))}
                            </>
                          ) : (
                            <>
                              <div className="col-md-6">
                                <br></br>
                                {this.props.role != "seller" ? (
                                  <>
                                    <label htmlFor="buyerName">
                                      First Name
                                    </label>
                                    <input
                                      type="text"
                                      name="buyerName"
                                      value={this.state.buyerName.data}
                                      className="form-control"
                                      id="buyerName"
                                      onChange={(e) => this.handleChange1(e)}
                                      placeholder="Enter Name"
                                    ></input>
                                    <span style={{ color: "red" }}>
                                      {formError["buyerName"]}
                                    </span>
                                    <br></br>
                                    <label htmlFor="last_name">Last Name</label>
                                    <input
                                      type="text"
                                      name="last_name"
                                      value={this.state.last_name.data}
                                      className="form-control"
                                      id="last_name"
                                      onChange={(e) => this.handleChange1(e)}
                                      placeholder="Enter last Name"
                                    ></input>
                                    <span style={{ color: "red" }}>
                                      {formError["last_name"]}
                                    </span>
                                    <br></br>
                                    <label htmlFor="streetAddress">
                                      Street address
                                    </label>
                                    <input
                                      type="text"
                                      name="streetAddress"
                                      value={this.state.streetAddress.data}
                                      className="form-control"
                                      id="streetAddress"
                                      placeholder="Enter Address"
                                      onChange={(e) => this.handleChange1(e)}
                                    ></input>
                                    <span style={{ color: "red" }}>
                                      {formError["streetAddress"]}
                                    </span>
                                    <br></br>
                                  </>
                                ) : null}
                              </div>
                              <div className="col-md-6">
                                <br></br>
                                {this.props.role != "seller" ? (
                                  <>
                                    <label htmlFor="mobileNumber">
                                      Mobile number
                                    </label>
                                    <input
                                      type="text"
                                      name="mobileNumber"
                                      value={this.state.mobileNumber.data}
                                      className="form-control"
                                      id="mobileNumber"
                                      placeholder="Enter Mobile Number"
                                      onChange={(e) => this.handleChange1(e)}
                                    ></input>
                                    <span style={{ color: "red" }}>
                                      {formError["mobileNumber"]}
                                    </span>
                                    <br></br>
                                    <label htmlFor="userEmail">Email</label>
                                    <input
                                      type="text"
                                      name="userEmail"
                                      value={this.state.userEmail.data}
                                      className="form-control"
                                      id="userEmail"
                                      placeholder="Enter Email"
                                      onChange={(e) => this.handleChange1(e)}
                                    ></input>
                                    <span style={{ color: "red" }}>
                                      {formError["userEmail"]}
                                    </span>
                                    <br></br>
                                    {!this.state.schedule.checked &&
                                      !this.state.customerCutSheetDisable && (
                                        <>
                                          <label htmlFor="cut_sheet_temp_id">
                                            Attach New Cut Sheet
                                          </label>
                                          <Select
                                            disable={
                                              this.state.customerCutSheetDisable
                                            }
                                            value={this.state.cut_sheet_temp}
                                            options={
                                              this.state.customerCutSheetTemp
                                            }
                                            name="cut_sheet_temp_id"
                                            id="cut_sheet_temp_id"
                                            placeholder="Select Cut Sheet"
                                            onChange={this.handleSelectChange3}
                                          />
                                          <br></br>
                                        </>
                                      )}
                                    {!this.state.schedule.checked && (
                                      <>
                                        {(this.state.customerCutSheetData
                                          .length > 0 ||
                                          this.state
                                            .customerCutSheetDisable) && (
                                          <>
                                            <label htmlFor="cut_sheet_data_id">
                                              Attach Previous Cut Sheet Orders
                                            </label>
                                            <Select
                                              disable={
                                                this.state
                                                  .customerCutSheetDisable
                                              }
                                              value={this.state.cut_sheet_data}
                                              options={
                                                this.state.customerCutSheetData
                                              }
                                              name="cut_sheet_data_id"
                                              id="cut_sheet_data_id"
                                              placeholder="Select Cut Sheet"
                                              onChange={
                                                this.handleSelectChange1
                                              }
                                            />
                                          </>
                                        )}
                                        <br></br>
                                      </>
                                    )}
                                  </>
                                ) : null}
                                {this.state.non_schedule_multi.checked ? (
                                  <div className="text-right">
                                    <button
                                      type="button"
                                      className="btn plnButton"
                                      onClick={() => this.addCustomer()}
                                    >
                                      {" "}
                                      <span class="material-icons">
                                        add_circle_outline
                                      </span>{" "}
                                      Add more
                                    </button>
                                  </div>
                                ) : null}
                              </div>
                            </>
                          )}
                          <div className="col-md-12">
                            <div className="text-center">
                              <button
                                type="submit"
                                className="btn btn-primary"
                                disabled={
                                  this.state.btnloading
                                    ? true
                                    : Number(this.state.event_count) <= 0
                                    ? true
                                    : false
                                }
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </>
                ) : (
                  ""
                )}
                <div className="table-scrool table-responsive cstmPopTable">
                  <table className="table" id="no-more-tables">
                    <thead>
                      <tr>
                        <th className="text-left">Transction Id</th>
                        <th>Ticket Id</th>
                        <th>Title</th>
                        <th>Buyer Info</th>
                        <th>Seller Info</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    {this.state.event_loader ? (
                      <div className="loader"></div>
                    ) : null}

                    {active_listing_data_added ? (
                      active_listing_data_added.map(
                        (event, index) =>
                          event.process_option ===
                            this.props.needarg.process_option && (
                            <tbody key={index}>
                              <tr />
                              <tr />
                              <tr
                                hidden={
                                  event.data_hide == 0 ||
                                  (this.state.project_id_hamburger ==
                                    event.id &&
                                    this.state.hamburger_toggle == true)
                                    ? false
                                    : true
                                }
                              >
                                <td
                                  data-title="Transction Id"
                                  className="table-pro"
                                >
                                  {event.cid == 0 ? "--" : "Gr-00" + event.cid}
                                </td>
                                <td data-title="Ticket Id">
                                  {event.id} &nbsp;&nbsp;
                                  {event.count > 1 ? (
                                    <button
                                      class="btn btn-green"
                                      onClick={this.hamBurger(event.id)}
                                    >
                                      <i
                                        class="fa fa-navicon"
                                        style={{ "font-size": "1rem" }}
                                      ></i>
                                    </button>
                                  ) : null}
                                </td>
                                <td data-title="Title">{event.title}</td>
                                {event.cid == 0 && event.extra_field_2 == "" ? (
                                  <td data-title="Buyer Info">
                                    <div className="row text-left">
                                      <div className="col-6">Name</div>
                                      <div className="col-6">
                                        {event.ng_buyer_firstname}{" "}
                                        {event.ng_buyer_lastname}
                                      </div>
                                    </div>
                                    <div className="row text-left">
                                      <div className="col-6">Mobile</div>
                                      <div className="col-6">
                                        {event.ng_phone}
                                      </div>
                                    </div>
                                    <div className="row text-left">
                                      <div className="col-6">Email</div>
                                      <div className="col-6">
                                        {event.ng_email}
                                      </div>
                                    </div>
                                  </td>
                                ) : (
                                  <td
                                    data-title="Buyer Info"
                                    className="montserrat"
                                  >
                                    {event.buyer_info}
                                  </td>
                                )}
                                {event.cid == 0 && event.report == 0 ? (
                                  event.extra_field_2 != "" ? (
                                    <td data-title="Seller Info">
                                      <div className="row text-left">
                                        <div className="col-6">Name</div>
                                        <div className="col-6">
                                          {event.seller_firstname}{" "}
                                          {event.seller_lastname}
                                        </div>
                                      </div>
                                      <div className="row text-left">
                                        <div className="col-6">Mobile</div>
                                        <div className="col-6">
                                          {event.seller_phone}
                                        </div>
                                      </div>
                                      <div className="row text-left">
                                        <div className="col-6">Email</div>
                                        <div className="col-6">
                                          {event.seller_email}
                                        </div>
                                      </div>
                                    </td>
                                  ) : (
                                    <td data-title="Seller Info">--</td>
                                  )
                                ) : (
                                  <td
                                    data-title="Seller Info"
                                    className="montserrat"
                                  >
                                    {event.seller_info}
                                  </td>
                                )}
                                {event.cid == 0 &&
                                event.processor_status != 2 ? (
                                  this.props.role != "seller" &&
                                  event.extra_field_2 == "" ? ( // && moment(this.props.currenteventdate).isAfter()
                                    <td data-title="Action">
                                      <button
                                        className="btn btn-green py-1 px-3 fw-sm fs-14"
                                        disabled={false}
                                        onClick={this.event_edit(
                                          event.id,
                                          event.title
                                        )}
                                        hidden={
                                          event.data_hide == 0 ? false : true
                                        }
                                      >
                                        Edit
                                      </button>{" "}
                                      {event.processor_status == 1 ? (
                                        "Accepted"
                                      ) : (
                                        <button
                                          className="btn btn-danger py-1 px-3 fw-sm fs-14"
                                          disabled={false}
                                          onClick={this.event_delete(
                                            event.id,
                                            event.title
                                          )}
                                          hidden={
                                            event.data_hide == 0 ? false : true
                                          }
                                        >
                                          Delete
                                        </button>
                                      )}
                                    </td>
                                  ) : (
                                    <td data-title="Action">--</td>
                                  )
                                ) : this.props.role == "seller" &&
                                  (event.cid == this.state.project_id ||
                                    event.processor_status == 0) ? (
                                  <td data-title="Action">
                                    Processor yet to accept
                                  </td>
                                ) : event.processor_status == 1 ||
                                  (this.state.project_id == event.cid &&
                                    this.state.processor_status == 1) ? (
                                  <td data-title="Action">
                                    Order Has been Accepted
                                  </td>
                                ) : event.processor_status == 2 ||
                                  (this.state.project_id == event.cid &&
                                    this.state.processor_status == 2) ? (
                                  <td data-title="Action">
                                    Order has been rejected
                                  </td>
                                ) : moment(this.props.currenteventdate).format(
                                    "YYYY-MM-DD"
                                  ) ==
                                  moment(this.props.currentServerTime).format(
                                    "YYYY-MM-DD"
                                  ) ? (
                                  <td data-title="Action">
                                    Order yet to be accepted
                                  </td>
                                ) : (
                                  <td data-title="Action">
                                    Order yet to be accepted
                                  </td>
                                )}
                              </tr>
                            </tbody>
                          )
                      )
                    ) : active_listing_data ? (
                      <tbody>
                        <tr>
                          <td data-title="Date" className="montserrat">
                            Buyer{" "}
                          </td>
                        </tr>
                      </tbody>
                    ) : (
                      <tbody>
                        <tr>
                          <td data-title="Date" className="montserrat">
                            Buyer{" "}
                          </td>
                        </tr>
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
            {this.state.reason_popup ? (
              <ReasonPopup
                title={title}
                closeModal={this.openModalp}
                close={this.closeEventAddpop}
                updateStatus={(e) => this.updateStatus(e)}
                event_cid={this.state.event_cid}
                closeM={(e, re) => this.closeM(e, re)}
              />
            ) : null}
            {this.state.sellerpopup ? (
              <SellerPopup
                showModal={this.state.sellerpopup}
                closeModal={this.openModalp}
                json_token={this.props.user.user_token}
                product={0}
                fallback={this.changeChecked}
                work_locc={work_locc}
                saveButtonToggle={true}
                checkToggle={true}
                livestock_type={this.state.livestock_type}
                zip={this.props.user.zip}
              />
            ) : (
              ""
            )}
          </div>
        </div>
        {this.state.delete_popup ? (
          <ConfirmPopup
            title={this.state.event_title}
            action={"delete"}
            closeModal={this.closeModal}
            event_id={this.state.event_id}
            confirm={(event_id, action) => this.calenderEvent(event_id, action)}
          />
        ) : null}
        {this.state.edit_popup ? (
          <ConfirmPopup
            title={this.state.event_title}
            action={"edit"}
            closeModal={this.closeModal}
            event_id={this.state.event_id}
            confirm={(event_id, action) => this.calenderEvent(event_id, action)}
            active_listing_data={this.state.active_listing_data}
            currenteventdate={this.props.currenteventdate}
            user={this.props.user}
            needarg={this.props.needarg}
          />
        ) : null}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const {
    login: { loggedIn },
    login: { user },
  } = state;
  return { loggedIn, user };
};
export default connect(mapStateToProps)(AddEvents);
