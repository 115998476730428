import React from 'react'
import Service from '../../../Service/Service'
import './CalenderProcessor.styles.css'
 class CalenderSetuppopup extends React.Component{
     constructor(props){
         super(props)         
     }
    componentDidMount(){
        console.log(this.props);
        const {token} = this.props;
        new Service(token).post('/calendar/getSlots')
    }
    render(){
        let {title,selectedDate,token,user_id,close,data,className} = this.props;
        return(
            <div className={`G-zr ${className} calenderPop`}>
                <p>Popover</p>
            </div>
        )
    }
 }

 export default  CalenderSetuppopup; 