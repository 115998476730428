import React, { Component } from 'react';
//import './'
//import './../CalenderPopup/CalenderPopup.css';
// import './CalenderPopup.css';
//import MaterialUIPickers from './Calender';
import moment from 'moment';
import Service from  '../../Service/Service';
import axios from 'axios';
import { NOTIFICATION } from '../../actions'
//'../../../../actions';
import { connect } from 'react-redux';

export class SlotPopup extends Component {
    state = {
        paid : false,
        msged_user:'',
        slots: this.props.slots,
    }

    hanglePaid=()=>{
        console.log("i am hanglepaid");
        this.setState({
            paid : true
        })
    }
    pay = ()=>{
        
        // dispatch(NOTIFICATION({msg:''}))
    let user = this.props.user;
    console.log("user ----------------" + JSON.stringify(user));
      if (user) {
        let self = this
        let data = {
          preferences: this.props.type,
          employee_name: this.props.actionArr,
          slots: this.state.slots,
        }
        
        return new Service(user.user_token).post('/calendar/deleteprocessorPreference', data) .then(({ status, message }) => {
          if(status=="yes" && self.state.slots.length > 0){

         return new Service(user.user_token).post('/calendar/processorPreference', data) .then(data=>{   
            console.log('qqqqqq',data)
            if(data.status=="yes"){
               console.log("close popup");
               self.setState({
                   slots: [{
                    processing_option : '',
                    avail_slot:0
                }],
                   paid:true
               },()=>{
                setTimeout(()=>{
                  self.setState({
                        paid:false
                    },()=>{
                        self.props.fallback(self.props.rthis)
                    })  
                },1000)
               })
            }
            else
              {
                //this.props.openModalp1();
                //alert("something went wrong");
              }
          })
        } else {
          self.setState({
            slots: [{
             processing_option : '',
             avail_slot:0
          }],
              paid:true
          },()=>{
            setTimeout(()=>{
              self.setState({
                    paid:false
                },()=>{
                    self.props.fallback(self.props.rthis)
                })  
            },1000)
          })
        }
        })
      }
        
    }


      handleAddShareholder = () => {
        this.setState({
          slots: [...this.state.slots,{ processing_option:'',avail_slot:0}]
        });
      };
      handleRemoveSlot = idx => () => {
          console.log(idx)
        this.setState({
          slots: this.state.slots.filter((s, sidx) => idx !== sidx)
        });
      };
      updateOfferingValue = idx => evt => {
        const slots = this.state.slots.map((slot, sidx) => {
          if (idx !== sidx) return slot;
          
          return { ...slot, processing_option: evt.target.value };
        });
    
        this.setState({ slots: slots });
      };

      updateInputValue = idx => evt => {
        const slots = this.state.slots.map((slot, sidx) => {
          if (idx !== sidx) return slot;
          return { ...slot, avail_slot: evt.target.value };
        });
    
        this.setState({ slots: slots });
      };


        
      
    render() {
        let { showModal1, closeModal,actionArr,typem } = this.props;
        const style=this.state.paid ? {display:'none'} : {};
        const style1=this.state.paid ? {} : {display:'none'};
        return (    
            
            <div className="G-zr">
                <div className={showModal1 ? "modal fade show d-block" : "modal fade"} id="select_proc" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document" style={{ "maxWidth": "600px" }}> 

                        <div className="modal-content G-modal rounded-0">
                            <div className="modal-header modal-hdrecng">
                                <div className="modal-title text-uppercase fs-18 fw-sm"> Number of slots available</div> 
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={closeModal(false)}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            {
                                  this.state.slots.length < 2 && 
                                  <div className="">
                                  <button
                                  type="button"
                                  onClick={this.handleAddShareholder}
                                  className="btn btn-green btn-size"
                                  >
                                  +
                                  </button>
                                  </div>

                              }
                            { !this.state.paid
                            ?
                             <div className="modal-body px-5 mt-5">
                              { this.state.slots.map((slot, idx) => (
                                <>
                                <div className="progress-form ">
                                <div class="form-group position-relative mb-5">
                                       
                              <select type="text" className="form-control D-input " value={slot.processing_option} onChange={this.updateOfferingValue(idx)} >
                              <option>Select type of offerings</option>
                              {idx == 0 ?
                              <>
                              {!this.state.slots[1] || (this.state.slots[1] && this.state.slots[1].processing_option != "USDA processing") ? <option>USDA processing</option> : null}
                              {!this.state.slots[1] || (this.state.slots[1] && this.state.slots[1].processing_option != "Custom processing") ? <option>Custom processing</option> : null}
                              </> : 
                              <>
                              {!this.state.slots[0] || (this.state.slots[0] && this.state.slots[0].processing_option != "USDA processing") ? <option>USDA processing</option> : null}
                              {!this.state.slots[0] || (this.state.slots[0] && this.state.slots[0].processing_option != "Custom processing") ? <option>Custom processing</option> : null}
                              </>
                              }
                              
                              </select> 
                                       
                                       <p class="position-absolute t-t m-0">offerings</p>
                                   </div>
                                    <div class="form-group position-relative mb-5">
                                       
                                        <input type="number" name="avail_slot" class="form-control D-input" placeholder="Number of slots available" value={slot.avail_slot} onChange={this.updateInputValue(idx)} />
                                        
                                        <p class="position-absolute t-t m-0">Slots</p>
                                    </div>

                                   
                               

                                </div>

                                {
                                 this.state.slots.length > 0 && 
                                 <div className="text-right">
                                 <button
                                 type="button"
                                 onClick={this.handleRemoveSlot(idx)}
                                 className="btn btn-red btn-size"
                               >
                                 -
                               </button>
                               </div>
                                }
                                                                    
                               
                               </>
                              
                              )) }

                       
                                                 

                              <div className="text-center">
                                    <button className="btn btn-green btn-size" onClick={() => this.pay(this.state.typem)}>
                                        <span>Save</span>
                                    </button>
                                </div>
                           
                       
                            
                            </div> 
                                : <div className="modal-body px-5 mt-5">
                            
                                <p className="btm-text"> Preference saved successfully </p>
                                </div>}
                            
                            
                    
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {

    const { user, loggedIn } = state.login;

    return { user, loggedIn };
}
export default connect(mapStateToProps)(SlotPopup);