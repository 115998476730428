import React from "react";
import { connect } from "react-redux";
import "../HarvestPopup/HarvestPopup.css";

class OrganSelector extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            organs: this.props.value ? this.props.value : '',
            isCheckBox: false,
        };
    }

    selectToggle = () => {
        this.setState({ isCheckBox: !this.state.isCheckBox })
    };

    handleCheckboxSelect = (e, data) => {
        if (e.target.checked) {
            console.log('multiple', this.state.organs)
            let arr = this.state.organs.split(',').filter((organ) => organ !== '');
            arr.push(data);
            var txt = arr.join(',')
            this.setState({ organs: txt });
        } else {
            var txt = this.state.organs.split(',').filter((organ) => {
                if (organ.trim() !== data)
                    return organ;
            }).join(',')
            this.setState({ organs: txt });
        }
        console.log('onChange', txt);
        this.props.onChange(txt)
    }

    // componentWillReceiveProps = async (props) => {
    //   console.log('props 2', props)
    // }

    render() {

        if (!this.props.dropdown) {

            return (
                <div className="form-group">
                    <label className="checkbox-label mr-2">
                        <input
                            className="form-control"
                            type="checkbox"
                            style={{ accentColor: '#3C923F' }}
                            checked={this.state.organs.split(',').indexOf('Pass') !== -1}
                            onChange={(e) => { this.handleCheckboxSelect(e, 'Pass') }}
                        />
                        Pass
                    </label>
                    <label className="checkbox-label mr-2">
                        <input
                            className="form-control"
                            type="checkbox"
                            style={{ accentColor: '#3C923F' }}
                            checked={this.state.organs.split(',').indexOf('Heart') !== -1}
                            onChange={(e) => { this.handleCheckboxSelect(e, 'Heart') }}
                        />
                        Heart
                    </label>
                    <label className="checkbox-label mr-2">
                        <input
                            className="form-control"
                            type="checkbox"
                            style={{ accentColor: '#3C923F' }}
                            checked={this.state.organs.split(',').indexOf('Liver') !== -1}
                            onChange={(e) => { this.handleCheckboxSelect(e, 'Liver') }}
                        />
                        Liver
                    </label>
                    <label className="checkbox-label mr-2">
                        <input
                            className="form-control"
                            type="checkbox"
                            style={{ accentColor: '#3C923F' }}
                            checked={this.state.organs.split(',').indexOf('Tongue') !== -1}
                            onChange={(e) => { this.handleCheckboxSelect(e, 'Tongue') }}
                        />
                        Tongue
                    </label>
                    <label className="checkbox-label mr-2">
                        <input
                            className="form-control"
                            type="checkbox"
                            style={{ accentColor: '#3C923F' }}
                            checked={this.state.organs.split(',').indexOf('Tail') !== -1}
                            onChange={(e) => { this.handleCheckboxSelect(e, 'Tail') }}
                        />
                        Tail
                    </label>
                    <label className="checkbox-label mr-2">
                        <input
                            className="form-control"
                            type="checkbox"
                            style={{ accentColor: '#3C923F' }}
                            checked={this.state.organs.split(',').indexOf('Testies') !== -1}
                            onChange={(e) => { this.handleCheckboxSelect(e, 'Testies') }}
                        />
                        Testies
                    </label>
                </div>
            )

        } else {

            return (
                <div className="popup-page-container-input">
                    <input className={this.props.class + " form-control cursor-pointer"} type="text" name="organs" value={this.state.organs} readOnly={true} onClick={this.selectToggle}></input>
                    {
                        this.state.isCheckBox ?
                            <div className='popup-multiple-checkbox'>
                                <label>
                                    <input
                                        type="checkbox"
                                        style={{ accentColor: '#3C923F' }}
                                        checked={this.state.organs.split(',').indexOf('Pass') !== -1}
                                        onChange={(e) => { this.handleCheckboxSelect(e, 'Pass') }}
                                    />
                                    Pass
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        style={{ accentColor: '#3C923F' }}
                                        checked={this.state.organs.split(',').indexOf('Heart') !== -1}
                                        onChange={(e) => { this.handleCheckboxSelect(e, 'Heart') }}
                                    />
                                    Heart
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        style={{ accentColor: '#3C923F' }}
                                        checked={this.state.organs.split(',').indexOf('Liver') !== -1}
                                        onChange={(e) => { this.handleCheckboxSelect(e, 'Liver') }}
                                    />
                                    Liver
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        style={{ accentColor: '#3C923F' }}
                                        checked={this.state.organs.split(',').indexOf('Tongue') !== -1}
                                        onChange={(e) => { this.handleCheckboxSelect(e, 'Tongue') }}
                                    />
                                    Tongue
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        style={{ accentColor: '#3C923F' }}
                                        checked={this.state.organs.split(',').indexOf('Tail') !== -1}
                                        onChange={(e) => { this.handleCheckboxSelect(e, 'Tail') }}
                                    />
                                    Tail
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        style={{ accentColor: '#3C923F' }}
                                        checked={this.state.organs.split(',').indexOf('Testies') !== -1}
                                        onChange={(e) => { this.handleCheckboxSelect(e, 'Testies') }}
                                    />
                                    Testies
                                </label>
                                {/* <label>
                                    <input
                                        type="checkbox"
                                        style={{ accentColor: '#3C923F' }}
                                        checked={this.state.organs.split(',').indexOf('Failed') !== -1}
                                        onChange={(e) => { this.handleCheckboxSelect(e, 'Failed') }}
                                    />
                                    Failed
                                </label>
                                <label>
                                    <input
                                        type="checkbox"
                                        style={{ accentColor: '#3C923F' }}
                                        checked={this.state.organs.split(',').indexOf('No Required') !== -1}
                                        onChange={(e) => { this.handleCheckboxSelect(e, 'No Required') }}
                                    />
                                    No Required
                                </label> */}
                            </div>
                            : null
                    }
                </div>
            )
        }
    }
}

const mapStateToProps = (state) => {
    const {
        login: { loggedIn },
        login: { user },
    } = state;
    return { loggedIn, user };
};
export default connect(mapStateToProps)(OrganSelector);
