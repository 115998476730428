import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { IconButton } from "@material-ui/core";
import React, { Component } from "react";

import { AddCircle } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import LoadingBtn from "../../../components/LoadingBtn/LoadingBtn.jsx";
import { NOTIFICATION } from "../../../actions";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { connect } from "react-redux";
import refreshToken from "./RefreshToken"; // Import the refreshToken function
import DialogPopup from "../../../components/Popup/DialogPopup";
class AddorUpdateCustomer extends Component {
  state = {
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    customer_portal: "yes",
    customer_type: "customer",
    isUpdate: "",
    customers: [],
    selectedProducer: null,
    selectedCustomer: null,
    selectedCustomersList: [],
    deletedCustomersList: [],
    openAddCustomerModal: false,
  };
  constructor(props) {
    super(props);
  }
  openAddCustomerModal = () => {
    this.setState({ openAddCustomerModal: true });
  };
  closeAddCustomerModal = () => {
    this.setState({ openAddCustomerModal: false });
  };
  componentDidUpdate(prevProps, prevState) {}

  componentDidMount() {
    this.getCustomersData();
    if (this.props.isUpdate != "") {
      this.setState({
        first_name: this.props.first_name,
        last_name: this.props.last_name,
        email: this.props.email,
        phone_number: this.props.phone_number,
        address: this.props.address,
        city: this.props.city,
        state: this.props.state,
        zip: this.props.zip,
        customer_id: this.props.customer_id,
        customer_type: this.props.customer_type,
        selectedProducer: this.props.selectedProducer,
        selectedCustomersList: this.props.selectedCustomersList,
        customer_portal: this.props.customer_portal,
      });
    }
  }
  getCustomersData = () => {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.user.user_token;
    let url = process.env.REACT_APP_BASE_URL;

    let self = this;
    axios.get(url + "profile/getAllCustomers").then(function (response) {
      if (response.data.status == "yes") {
        console.log(response.data.data);
        self.setState({ customers: response.data.data });
      } else {
        self.props.dispatch(
          NOTIFICATION({
            msg: response.data.message || "something went wrong",
            msgColor: "#FAD5BD",
          })
        );
      }
    });
  };

  valueChange = (name) => (e) => {
    this.setState({ [name]: e.target.value });
  };
  handleChangeProducer = (value) => {
    if (value && value.first_name === "Add") {
      this.openAddCustomerModal();
    } else {
      this.setState({
        selectedProducer: value,
      });
    }
  };

  handleChangeCustomer = (value) => {
    if (value && value.first_name === "Add") {
      this.openAddCustomerModal();
    } else {
      this.setState({
        selectedCustomer: value,
      });
    }
  };
  handleChangeNameInput = async () => {
    var element = document.querySelector("#no-option");
    if (element != null) {
      this.setState({ isNoOption: true });
    } else {
      this.setState({ isNoOption: false });
    }
  };

  addCustomerToList = (type) => {
    const { selectedCustomer, selectedCustomersList } = this.state;

    if (selectedCustomer) {
      const listToUpdate = selectedCustomersList;
      const customerAlreadyInList = listToUpdate.find(
        (customer) => customer.id === selectedCustomer.id
      );

      // If the customer is not already in the list, add them
      if (!customerAlreadyInList) {
        this.setState((prevState) => ({
          [type === "Customers"
            ? "selectedCustomersList"
            : "selectedCustomersList"]: [
            ...listToUpdate,
            {
              ...selectedCustomer,
              preType: "add", // Set preType field here
            },
          ],
          selectedCustomer: null, // Clear the selected name after adding
        }));
      }
    }
  };

  removeCustomerFromList = (customerId) => {
    let listToUpdate = this.state.selectedCustomersList;
    const indexToRemove = listToUpdate.findIndex(
      (item) => item.id == customerId
    );
    let deletedCustomers = this.state.deletedCustomersList;
    deletedCustomers.push(customerId);
    listToUpdate.splice(indexToRemove, 1);
    this.setState({
      selectedCustomersList: listToUpdate,
      deletedCustomers: deletedCustomers,
    });
  };

  postCustomer = async () => {
    try {
      // Attempt to refresh token
      const newAccessToken = await refreshToken();

      // Token refresh successful, set the new access token in axios headers
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.props.user.user_token;
      axios.defaults.headers.common["qb-auth-token"] = newAccessToken; // Add your qb-auth-token here

      // Determine the value of the customers field
      let customerIds = null;
      if (this.state.customer_type !== "customer") {
        if (this.state.selectedCustomersList) {
          customerIds = this.state.selectedCustomersList.map(
            (customer) => customer.id
          );
        }
      }

      // Your axios post request
      let url = process.env.REACT_APP_BASE_URL;

      const data = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        phone: this.state.phone_number,
        address: this.state.address,
        city: this.state.city,
        state: this.state.state,
        zip: this.state.zip,
        custom_portal: this.state.customer_portal == "yes" ? 1 : 0,
        type: this.state.customer_type,
        enablecp: this.state.customer_portal,
        producer: this.state.selectedProducer
          ? this.state.selectedProducer.user_ref
          : null,
        customers: customerIds ?? [],
      };

      console.log(data);
      await new Promise((resolve) => {
        axios
          .post(url + "profile/addCustomer", data)
          .then((response) => {
            console.log(response.data.message);
            if (response && response.status === 200) {
              console.log(response.data.message);
              if (response.data.status === "no") {
                this.props.dispatch(
                  NOTIFICATION({
                    msg: response.data.message,
                    msgColor: "#FAD5BD",
                  })
                );
                setTimeout(() => {
                  this.props.dispatch(NOTIFICATION({ msg: "" }));
                }, 2000);
                resolve(false);
              } else {
                this.setState({
                  addModal: false,
                  first_name: "",
                  last_name: "",
                  email: "",
                  phone_number: "",
                  address: "",
                  city: "",
                  state: "",
                  zip: "",
                  selectedProducer: null,
                  selectedCustomersList: [],
                  selectedCustomersList1: [],
                });

                this.props.fetch_all_customer();

                this.props.getCustomersData();
                this.props.closeAddCustomerModal();

                this.props.dispatch(
                  NOTIFICATION({ msg: "Add Customer Successfully" })
                );
                setTimeout(() => {
                  this.props.dispatch(NOTIFICATION({ msg: "" }));
                }, 3000);
              }
            }
            resolve(true);
          })
          .catch((error) => {
            this.props.dispatch(
              NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
            );
            setTimeout(() => {
              this.props.dispatch(NOTIFICATION({ msg: "" }));
            }, 2000);
            resolve(false);
          });
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  updateCustomer = async () => {
    try {
      // Attempt to refresh token
      const newAccessToken = await refreshToken();

      // Determine the value of the customers field
      let customerIds = [];
      if (this.state.customer_type !== "customer") {
        if (this.state.selectedCustomersList) {
          customerIds = this.state.selectedCustomersList.map(
            (customer) => customer.id
          );
        }
      }

      // Token refresh successful, set the new access token in axios headers
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.props.user.user_token;
      axios.defaults.headers.common["qb-auth-token"] = newAccessToken; // Add your qb-auth-token here

      let url = process.env.REACT_APP_BASE_URL;
      var data = {
        id: this.state.customer_id,
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        phone: this.state.phone_number,
        address: this.state.address,
        city: this.state.city,
        state: this.state.state,
        zip: this.state.zip,
        custom_portal: this.state.customer_portal == "yes" ? 1 : 0,
        type: this.state.customer_type,
        enablecp: this.state.customer_portal,
        producer:
          this.props.user.role == "producer"
            ? this.props.user.id
            : this.state.selectedProducer
            ? this.state.selectedProducer.user_ref
            : null,
        customers: customerIds, // Pass the list of customer IDs or null
        deletedcustomers: this.state.deletedCustomersList,
      };

      await new Promise((resolve) => {
        axios
          .post(url + "profile/updateCustomer", data)
          .then((response) => {
            if (response && response.status == 200) {
              console.log(response.data.message);
              if (response.data.status == "no") {
                this.props.dispatch(
                  NOTIFICATION({
                    msg: response.data.message,
                    msgColor: "#FAD5BD",
                  })
                );
                setTimeout(() => {
                  this.props.dispatch(NOTIFICATION({ msg: "" }));
                }, 2000);
                resolve(false);
              } else {
                // this.setState({
                //   addModal: false,
                //   first_name: "",
                //   last_name: "",
                //   email: "",
                //   phone_number: "",
                //   address: "",
                //   city: "",
                //   state: "",
                //   zip: "",
                // });

                this.props.fetch_all_customer();

                this.props.getCustomersData();
                this.props.closeAddCustomerModal();

                this.props.dispatch(
                  NOTIFICATION({ msg: "Update Customer Successfully" })
                );
                setTimeout(() => {
                  this.props.dispatch(NOTIFICATION({ msg: "" }));
                }, 3000);
                resolve(true);
                return false;
              }
            } else {
            }
          })
          .catch((error) => {
            this.props.dispatch(
              NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
            );
            setTimeout(() => {
              this.props.dispatch(NOTIFICATION({ msg: "" }));
            }, 2000);
            resolve(false);
          });
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  render() {
    const tableCellStyle = {
      border: "1px solid #ccc",
      padding: "10px",
      textAlign: "left",
      color: "#555",
      borderRadius: "8px", // Add border-radius here
    };

    const tableHeaderStyle = {
      border: "1px solid #ccc",
      padding: "10px",
      backgroundColor: "#f4f4f4",
      textAlign: "left",
      fontWeight: "bold",
    };

    // Define styles as JavaScript objects
    const radioGroupStyle = {
      display: "flex",
      flexDirection: "column",
    };

    const radioOptionStyle = {
      marginBottom: "10px",
    };

    return (
      <>
        <>
          <div className="row">
            <div className="form-group col-md-6">
              <label className=" popup-form-label form-label">First Name</label>
              <input
                className="form-control"
                type="text"
                name="first_name"
                value={this.state.first_name}
                onChange={this.valueChange("first_name")}
              ></input>
            </div>
            <div className="form-group col-md-6">
              <label className="G-label popup-form-label form-label">
                Last Name
              </label>
              <input
                className="form-control"
                type="text"
                name="last_name"
                value={this.state.last_name}
                onChange={this.valueChange("last_name")}
              ></input>
            </div>
            <div className="form-group col-md-6">
              <label className="G-label popup-form-label form-label">
                Email Address
              </label>
              <input
                className="form-control"
                type="text"
                name="email_addres"
                value={this.state.email}
                disabled={this.props.isUpdate != "" ? true : false}
                readOnly={this.props.isUpdate != "" ? true : false}
                onChange={this.valueChange("email")}
              ></input>
            </div>
            <div className="form-group col-md-6">
              <label className="G-label popup-form-label form-label">
                Phone Number
              </label>
              <input
                className="form-control"
                type="text"
                name="phone_number"
                value={this.state.phone_number}
                onChange={this.valueChange("phone_number")}
              ></input>
            </div>
            <div className="form-group col-md-6">
              <label className="G-label popup-form-label form-label">
                Address
              </label>
              <input
                className="form-control"
                type="text"
                name="address"
                value={this.state.address}
                onChange={this.valueChange("address")}
              ></input>
            </div>
            <div className="form-group col-md-6">
              <label className="G-label popup-form-label form-label">
                City
              </label>
              <input
                className="form-control"
                type="text"
                name="city"
                value={this.state.city}
                onChange={this.valueChange("city")}
              ></input>
            </div>
            <div className="form-group col-md-6">
              <label className="G-label popup-form-label form-label">
                State
              </label>
              <input
                className="form-control"
                type="text"
                name="state"
                value={this.state.state}
                onChange={this.valueChange("state")}
              ></input>
            </div>
            <div className="form-group col-md-6">
              <label className="G-label popup-form-label form-label">ZIP</label>
              <input
                className="form-control"
                type="number"
                name="zip"
                value={this.state.zip}
                onChange={this.valueChange("zip")}
              ></input>
            </div>
            {/* New dropdown field below ZIP */}
            {["processor", "admin", "manager"].indexOf(this.props.user.role) !==
              -1 && (
              <div className="form-group col-md-6">
                <label className="G-label popup-form-label form-label">
                  Enable Customer Portal
                </label>
                <select
                  style={{ fontSize: "12px" }}
                  className="form-control"
                  name="customer_portal"
                  value={this.state.customer_portal}
                  onChange={this.valueChange("customer_portal")}
                >
                  <option value="">Select</option> {/* Default option */}
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </div>
            )}
            {this.state.customer_type === "customer" &&
              !["customer", "producer"].includes(this.props.user.role) && (
                <div className="form-group col-md-6">
                  <label className="popup-form-label form-label">
                    Select Producer
                  </label>
                  <Autocomplete
                    value={this.state.selectedProducer}
                    onChange={(e, v, r) => this.handleChangeProducer(v)}
                    onInput={() => this.handleChangeNameInput()}
                    className="form-control-autocomplete"
                    options={this.state.customers.filter(
                      (value) => value.type == "producer"
                    )}
                    disableListWrap
                    getOptionLabel={(option) => {
                      return option.first_name + " " + option.last_name;
                    }}
                    filterOptions={(options, params) => {
                      const filter = createFilterOptions();
                      return filter(options, params);
                    }}
                    style={{ width: "100%" }}
                    renderInput={(params) => (
                      <TextField
                        id="ggez"
                        className=""
                        {...params}
                        variant="outlined"
                      />
                    )}
                  />
                </div>
              )}
            {["processor", "admin", "manager"].indexOf(this.props.user.role) !==
              -1 &&
              this.state.customer_type === "producer" && (
                <div className="form-group col-md-6">
                  <label className="popup-form-label form-label">
                    Select Customer
                  </label>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Autocomplete
                      onChange={(e, v, r) => this.handleChangeCustomer(v)}
                      onInput={() => this.handleChangeNameInput()}
                      className="form-control-autocomplete"
                      options={this.state.customers.filter(
                        (option) => option.type === "customer"
                      )}
                      disableListWrap
                      getOptionLabel={(option) => {
                        if (option.id === false) {
                          return "Add new customer";
                        } else {
                          return `${option.first_name} ${option.last_name}`;
                        }
                      }}
                      value={this.state.selectedCustomer}
                      filterOptions={(options, params) => {
                        const filter = createFilterOptions();
                        const filtered = filter(options, params);

                        if (params.inputValue !== "") {
                          filtered.push({
                            id: false,
                            first_name: `Add`,
                            last_name: `"${params.inputValue}"`,
                          });
                        }

                        return filtered;
                      }}
                      style={{ width: "100%" }}
                      renderInput={(params) => (
                        <TextField id="ggez" {...params} variant="outlined" />
                      )}
                    />

                    <IconButton
                      aria-label="add"
                      style={{ color: "green" }}
                      onClick={() => this.addCustomerToList("Customers")}
                    >
                      <AddCircle />
                    </IconButton>
                  </div>
                </div>
              )}
          </div>
          {["processor", "admin", "manager"].indexOf(this.props.user.role) !==
            -1 && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                marginLeft: "-15px",
                marginRight: "-15px",
              }}
            >
              <div
                className="form-group col-md-6"
                style={{ marginRight: "2rem" }}
              >
                <label className="popup-form-label form-label">
                  Customer Type
                </label>
                <div className="row">
                  <div style={{ marginRight: "20px", marginLeft: "15px" }}>
                    <input
                      type="radio"
                      id="producer"
                      name="customer_type"
                      value="producer"
                      checked={this.state.customer_type === "producer"}
                      onChange={this.valueChange("customer_type")}
                    />
                    <label htmlFor="producer" style={{ marginLeft: "8px" }}>
                      Producer
                    </label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="customer"
                      name="customer_type"
                      value="customer"
                      checked={this.state.customer_type === "customer"}
                      onChange={this.valueChange("customer_type")}
                    />
                    <label htmlFor="customer" style={{ marginLeft: "8px" }}>
                      Customer
                    </label>
                  </div>
                </div>
              </div>
            </div>
          )}

          {this.state.selectedCustomersList.length > 0 &&
            this.state.customer_type === "producer" && (
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    {/* <th style={tableHeaderStyle}>ID</th> */}
                    <th style={tableHeaderStyle}>Customer Name</th>
                    <th style={tableHeaderStyle}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.selectedCustomersList.map((customer) => (
                    <tr key={customer.user_ref}>
                      {/* <td style={tableCellStyle}>{`${customer.id}`}</td> */}
                      <td style={tableCellStyle}>
                        {`${customer.first_name} ${customer.last_name}`}
                      </td>

                      <td style={tableCellStyle}>
                        <IconButton
                          aria-label="delete"
                          style={{ color: "red" }}
                          onClick={() =>
                            this.removeCustomerFromList(customer.id)
                          }
                        >
                          <DeleteIcon />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          <div className="popup-form-btn mt-4" style={{ marginBottom: "2rem" }}>
            {this.props.isUpdate == "" ? (
              <LoadingBtn onclick={this.postCustomer} text={"Save"} />
            ) : (
              <LoadingBtn onclick={this.updateCustomer} text={"Update"} />
            )}
          </div>
        </>
        <DialogPopup
          title="Add Customer"
          subtitle="Customer fields"
          open={this.state.openAddCustomerModal}
          onClose={(e) => () => this.setState({ openAddCustomerModal: false })}
        >
          <AddorUpdateCustomer
            openAddCustomerModal={this.openAddCustomerModal}
            dispatch={this.props.dispatch}
            user={this.props.user}
            fetch_all_customer={this.props.fetch_all_customer}
            getCustomersData={this.getCustomersData}
            closeAddCustomerModal={this.closeAddCustomerModal}
            isUpdate={""}
            first_name={""}
            last_name={""}
            email={""}
            phone_number={""}
            address={""}
            city={""}
            state={""}
            zip={""}
            customer_id={this.state.id}
            customer_type={"customer"}
            selectedProducer={null}
            selectedCustomersList={[]}
            customer_portal={"yes"}
          />
        </DialogPopup>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user, loggedIn } = state.login;

  return { user, loggedIn };
}

export default connect(mapStateToProps)(AddorUpdateCustomer);
