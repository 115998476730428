import "react-day-picker/lib/style.css";

// import Autocomplete from '@material-ui/lab/Autocomplete';
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import React, { Component } from "react";
import { faCross, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

import { AddCircle } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import DayPickerInput from "react-day-picker/DayPickerInput";
import DeleteIcon from "@material-ui/icons/Delete";
import DialogPopup from "../../../components/Popup/DialogPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import LoadingBtn from "../../../components/LoadingBtn/LoadingBtn.jsx";
import LoadingButton from "../../../components/LoadingButton/LoadingButton";
import MyAgendaMini from "../../ProcessorDashboard/CalenderSetup/MyAgendaMini.component";
import { NOTIFICATION } from "../../../actions";
import RRuleGenerator from "@protodo-io/react-rrule-generator-lite";
import TextField from "@material-ui/core/TextField";
import axios from "axios";
import { connect } from "react-redux";
import { currentServerTime } from "../../../util/socket";
import { faX } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import refreshToken from "./RefreshToken"; // Import the refreshToken function
import AddorUpdateCustomer from "../../Dashboard/Processor/AddorUpdateCustomer";

class EditEvent extends Component {
  state = {
    isNoOption: false,
    schedule_dropoff: "",
    address: "",
    prev_cut_sheet: "",
    type: "",
    phone: "",
    role: "",
    availableSlot: 0,
    usedSlot: 0,
    showPrevCutSheet: false,
    data: [],
    scheduleModal: false,
    cutSheetTemp: [],
    dropdownCustomer: [],
    addCustomerDisable: true,
    beef_qty: 0,
    bison_qty: 0,
    goat_qty: 0,
    hog_qty: 0,
    elk_qty: 0,
    lamb_qty: 0,
    yak_qty: 0,
    pork_qty: 0,
    poultry_qty: 0,
    deer_qty: 0,
    wild_game_qty: 0,
    listOrganic: [],
    organicModal: false,
    rruleModal: false,
    listFile1: [],
    listFile2: [],
    cutSheetTemp: [],
    selectedFile1: null,
    selectedFile2: null,
    openAddCustomerModal: false,
    dropdownProducer: [],
    customers_all: [
      {
        cart_id: "",
        customer_id: "",
        customer_type: "",
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
        address: "",
        quarter: 0,
        form_id: "",
        cut_sheet_data_id: "",
        customer_name: null,
      },
    ],
    submitLoading: false,
    addCustomerModal: false,
    customer_type: "producer", // Default to Producer
    // Order details
    event_name: this.props.order.title,
    organic: this.props.order.organic,
    dropoff_date: moment(this.props.order.dropoff_date).format("YYYY-MM-DD"),
    temp_dropoff_date: moment(this.props.order.dropoff_date).format(
      "YYYY-MM-DD"
    ),
    kill_date: this.props.order.kill_date,
    temp_kill_date: this.props.order.kill_date,
    recurring: this.props.order.recurring,
    temp_recurring: this.props.order.recurring,
    organs: this.props.order.organs ? this.props.order.organs : "",
    temp_organs: this.props.order.organs ? this.props.order.organs : "",
    label: this.props.order.process_option,
    temp_label: this.props.order.process_option,
    sex: this.props.order.sex,
    temp_sex: this.props.order.sex,
    customers: this.props.order.customers_all,
    rrule: this.props.order.rrule,
    animal: this.props.order.species,
    fields: [
      { name: "", amount: "", cutsheet: "" }, // Example initial fields
    ],
    deletedfields: [],
    savedFields: [],
    errorMessage: "", // For displaying error messages
    cutSheet: "",
    selectedCustomersList: [], // New state for the list of selected customers
  };
  constructor(props) {
    super(props);
    console.log("current order", props.order);
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('prevProps',prevProps)
    if (prevProps.order.order_id !== this.props.order.order_id) {
      this.setState({
        event_name: prevProps.order.title,
        organic: prevProps.order.organic,
        dropoff_date: moment(prevProps.order.dropoff_date).format("YYYY-MM-DD"),
        temp_dropoff_date: moment(prevProps.order.dropoff_date).format(
          "YYYY-MM-DD"
        ),
        kill_date: prevProps.order.kill_date,
        temp_kill_date: prevProps.order.kill_date,
        recurring: prevProps.order.recurring,
        temp_recurring: prevProps.order.recurring,
        organs: prevProps.order.organs ? prevProps.order.organs : "",
        temp_organs: prevProps.order.organs ? prevProps.order.organs : "",
        label: prevProps.order.process_option,
        temp_label: prevProps.order.process_option,
        sex: prevProps.order.sex,
        temp_sex: prevProps.order.sex,
        customers: prevProps.order.customers_all,
        rrule: prevProps.order.rrule,
      });
    }
  }

  componentDidMount() {
    currentServerTime((data) => {
      this.setState({
        currentServerTime: data,
      });
    });
    // this.getCutSheetData();
    this.getCustomersData();
    this.getProducerData();
    // console.log('user',this.props.user)
    this.fetchOrder();
    this.getAvailableSlot();
    this.getCutSheetData();
  }
  openAddCustomerModal = () => {
    this.setState({ addCustomerModal: true });
  };
  closeAddCustomerModal = () => {
    this.setState({ addCustomerModal: false });
  };
  getProducerData = () => {
    // Ensure Authorization header is set
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${this.props.user.user_token}`;
    const url = `${process.env.REACT_APP_BASE_URL}profile/getProducers`;

    // Use arrow function to keep the context of 'this'
    axios
      .get(url)
      .then((response) => {
        console.log("response data", response.data.data); // Log the response data directly

        if (response.data.status === "yes") {
          // Handle the case where this.props.cart might be undefined
          const cart = this.props.cart || {}; // Default to an empty object

          const customer = {
            id: cart.form_customer_id || "", // Default to empty string if undefined
            first_name: cart.first_name || "",
            last_name: cart.last_name || "",
            email: cart.email || "",
            phone: cart.phone || "",
          };

          // Update state with the fetched producer data
          this.setState({
            dropdownProducer: response.data.data,
            splitCustomerWhole1: customer.id,
            splitCustomerWhole1Name: customer,
            splitCustomerHalf1: customer.id,
            splitCustomerHalf1Name: customer,
            splitCustomerQuarter1: customer.id,
            splitCustomerQuarter1Name: customer,
          });
        } else {
          // Handle the case where the response status is not 'yes'
          this.props.dispatch(
            NOTIFICATION({ msg: response.data.message, msgColor: "#FAD5BD" })
          );
          setTimeout(() => {
            this.props.dispatch(NOTIFICATION({ msg: "" }));
          }, 2000);
        }
      })
      .catch((error) => {
        // Handle any errors from the API request
        console.error("Error fetching data:", error.message); // Log the error message
        this.props.dispatch(
          NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
        );
        setTimeout(() => {
          this.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 2000);
      });
  };

  handleSelectChange = (index, value) => {
    const fields = [...this.state.fields];
    fields[index].cutsheet = value;
    this.setState({ fields });
    console.log(this.state.fields);
  };
  handleFieldChange = (index, field, value) => {
    const fields = [...this.state.fields];
    console.log(fields, "fields");

    if (field === "name") {
      if (value && value.first_name === "Add") {
        this.setState({ addCustomerModal: true });
        // Handle the case where `first_name` is "Add"
        // You might want to open a dialog or show some input for adding a new customer
        console.log("Adding new customer with value:", value);
      } else {
        // Check if value is not null or undefined before performing the duplicate check
        if (value && value.id) {
          const isDuplicate = fields.some(
            (fieldItem, idx) =>
              fieldItem.name && fieldItem.name.id === value.id && idx !== index
          );

          if (isDuplicate) {
            this.setState({
              errorMessage:
                "Customer already selected. Please choose a different customer.",
            });
            return;
          }
        }

        // Ensure value is not null before updating cart_id
        if (value) {
          let val = { ...value }; // Spread value to avoid mutating the original object
          val.cart_id = this.state.savedFields[index]
            ? this.state.savedFields[index].name.cart_id
            : undefined;
          fields[index][field] = val;
        } else {
          fields[index][field] = value; // If value is null, simply set it
        }

        this.setState({ fields });
      }
    }
  };

  handleFieldChangeAmount = (index, field, value) => {
    const fields = [...this.state.fields];
    fields[index][field] = value;
    this.setState({ fields });
  };

  handleAddMore = () => {
    // Check if the number of customers that can be added has been reached
    if (!this.canAddMore()) {
      this.setState({
        errorMessage: "Cannot add more customers based on the selected amount.",
      });
      return;
    }

    // Check if at least one amount field is selected
    const hasSelectedAmount = this.state.fields.some(
      (field) => field.amount !== ""
    );

    // Check if all fields have a selected cutsheet
    const allFieldsHaveCutsheet = this.state.fields.every(
      (field) => field.cutsheet !== ""
    );

    if (hasSelectedAmount && allFieldsHaveCutsheet) {
      this.setState((prevState) => ({
        fields: [
          ...prevState.fields,
          {
            cutsheet:
              this.state.cutSheetTemp.length > 0
                ? this.state.cutSheetTemp[0].id
                : prevState.cutSheet,
            name: "",
            amount: "",
          },
        ],
        errorMessage: "",
      }));
    } else {
      // Display an error message if not all fields have a cutsheet or amount is not selected
      this.setState({
        errorMessage: hasSelectedAmount
          ? "Please select a cutsheet for each field before adding more."
          : "Please select an amount before adding more fields.",
      });
    }
  };

  canAddMore = () => {
    const totalAmount = this.calculateTotalAmount(this.state.fields);
    return totalAmount < 100;
  };

  calculateTotalAmount = (fields) => {
    return fields.reduce((sum, field) => {
      switch (field.amount) {
        case "Whole":
          return sum + 100;
        case "Half":
          return sum + 50;
        case "Quarter":
          return sum + 25;
        default:
          return sum;
      }
    }, 0);
  };

  getCutSheetData = () => {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.user.user_token;
    let url = process.env.REACT_APP_BASE_URL;

    let self = this;
    const dataNya = new FormData();
    dataNya.append("form_status", "1");
    dataNya.append(
      "user_id",
      this.props.user.role == "customer" || this.props.user.role == "producer"
        ? this.props.user.admin
        : this.props.user.id
    );
    axios
      .post(url + "cutsheet/get_custom_form", dataNya)
      .then(function (response) {
        if (response.data.status) {
          self.setState({
            cutSheetTemp: response.data.result.data,
          });
        } else {
          self.props.dispatch(
            NOTIFICATION({
              msg: response.data.message || "something went wrong",
              msgColor: "#FAD5BD",
            })
          );
          setTimeout(() => {
            self.props.dispatch(NOTIFICATION({ msg: "" }));
          }, 3000);
        }
      })
      .catch((error) => {
        self.props.dispatch(
          NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
        );
        setTimeout(() => {
          self.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 2000);
      });
  };

  getCustomersData = () => {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.user.user_token;
    let url = process.env.REACT_APP_BASE_URL;

    let self = this;
    axios.get(url + "profile/getAllCustomers").then(function (response) {
      if (response.data.status == "yes") {
        console.log(response.data.data);
        self.setState({ dropdownCustomer: response.data.data });
      } else {
        self.props.dispatch(
          NOTIFICATION({
            msg: response.data.message || "something went wrong",
            msgColor: "#FAD5BD",
          })
        );
      }
    });
  };
  handleDeleteField = (index) => {
    const fields = [...this.state.fields];
    const deletedfield = this.state.deletedfields;
    if (index === 0) {
      // Reset the fields for the first item
      fields[index] = {
        name: null,
        cutsheet: "",
        amount: "",
        // Reset other fields as needed
      };
    } else {
      // Remove the item at the given index
      deletedfield.push(fields[index].name.cart_id);
      fields.splice(index, 1);
    }

    this.setState({ fields });
  };

  valueChange = (name) => (e) => {
    console.log("change value: ", { [name]: e.target.value });
    this.setState({ [name]: e.target.value });
    if (name == "temp_recurring" && e.target.value == 1) {
      this.setState({ rruleModal: true });
      // setTimeout(this.removeSecondElement, 500);
    }
  };

  valueChangeNumber = (name) => async (e) => {
    if (e.target.value >= 0) {
      await this.setState({ [name]: parseInt(e.target.value) });
    }
    var count =
      (parseInt(this.state.beef_qty) || 0) +
      (parseInt(this.state.bison_qty) || 0) +
      (parseInt(this.state.hog_qty) || 0) +
      (parseInt(this.state.lamb_qty) || 0) +
      (parseInt(this.state.goat_qty) || 0) +
      (parseInt(this.state.elk_qty) || 0) +
      (parseInt(this.state.yak_qty) || 0) +
      (parseInt(this.state.pork_qty) || 0) +
      (parseInt(this.state.poultry_qty) || 0) +
      (parseInt(this.state.deer_qty) || 0) +
      (parseInt(this.state.wild_game_qty) || 0);
    // console.log('count',count)
    if (count === 1) {
      this.setState({ addCustomerDisable: false });
    } else {
      this.setState({ addCustomerDisable: true });
    }
  };

  handleOpenAddCustomerModal = () => {
    this.setState({ openAddCustomerModal: true });
  };
  fetchOrder = async () => {
    const { match } = this.props; // The "match" prop contains the URL parameters
    const orderId = this.props.order.order_id;
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.user.user_token;
    let url = process.env.REACT_APP_BASE_URL;
    let self = this;

    try {
      // Make the API request
      const response = await axios.get(url + "order/viewOrder/" + orderId);

      // Log the entire response object
      console.log("Response:", response);

      // Check if the response status is "yes"
      if (response.data.status === "yes") {
        console.log("Response data:", response.data.data);

        // Assume customers_all is already populated as you described
        let customers_all = response.data.data.split.map((cart) => {
          return {
            cart_id: cart.cart_id,
            customer_id: cart.form_customer_id,
            customer_type: "whole", // whole or share
            first_name: cart.first_name,
            last_name: cart.last_name,
            phone: cart.phone,
            email: cart.email,
            address: cart.address,
            quarter: cart.qty,
            form_id: cart.form_id,
            cut_sheet_data_id: cart.form_id,
            customer_name: cart,
          };
        });

        // Now `fields` contains the updated list with customer data
        self.setState({
          customers_all,
          fields: customers_all.map((customer) => {
            let amount = "";

            // Determine the amount based on the quarter
            if (customer.quarter === 4) {
              amount = "Whole";
            } else if (customer.quarter === 2) {
              amount = "Half";
            } else if (customer.quarter === 1) {
              amount = "Quarter";
            }

            return {
              name: {
                street_address: customer.address,
                cart_id: customer.cart_id,
                email: customer.email,
                phone_number: customer.phone,
                first_name: customer.first_name,
                last_name: customer.last_name,
                form_customer_id: customer.customer_name.form_customer_id,
                form_data_id: customer.form_data_id,
                form_id: customer.form_id,
                order_id: customer.order_id,
                id: customer.customer_name.form_customer_id,
              },
              amount: amount, // Set the calculated amount
              cutsheet: customer.cut_sheet_data_id, // Assuming cutsheet is this field
            };
          }),
          savedFields: customers_all.map((customer) => {
            let amount = "";

            // Determine the amount based on the quarter
            if (customer.quarter === 4) {
              amount = "Whole";
            } else if (customer.quarter === 2) {
              amount = "Half";
            } else if (customer.quarter === 1) {
              amount = "Quarter";
            }

            return {
              name: {
                street_address: customer.address,
                cart_id: customer.cart_id,
                email: customer.email,
                phone_number: customer.phone,
                first_name: customer.first_name,
                last_name: customer.last_name,
                form_customer_id: customer.customer_name.form_customer_id,
                form_data_id: customer.form_data_id,
                form_id: customer.form_id,
                order_id: customer.order_id,
                id: customer.customer_name.form_customer_id,
              },
              amount: amount, // Set the calculated amount
              cutsheet: customer.cut_sheet_data_id, // Assuming cutsheet is this field
            };
          }),
          // Save the updated fields to state
        });
        console.log("fields data:", this.state.fields);
      }
    } catch (error) {
      // Log any errors that occur
      console.error("Error fetching order:", error);
    }
  };

  saveOrder = () => {
    this.setState({
      submitLoading: true,
    });

    var data = {
      order_id: this.props.order.order_id,
      event_name: this.state.event_name,
      organic: this.state.organic,
      dropoff_date: this.state.dropoff_date,
      old_dropoff_date: this.props.order.dropoff_date,
      status: 0,
      extra_field_1: "",
      organ: "yes",
      kill_date: this.state.kill_date,
      recurring: this.state.recurring,
      organs: this.state.organs,
      process_option: this.state.label,
      sex: this.state.sex,
      customers: this.state.customers_all,
      animal: this.state.animal,
      // rrule: this.state.rrule,
      split: this.state.fields,
      deletedsplit: this.state.deletedfields,
    };

    var emailOrPhone = this.state.emailOrPhoneDeposit
      ? this.state.emailOrPhoneDeposit.includes("@")
      : false;

    if (emailOrPhone) {
      data["deposite_email"] = this.state.emailOrPhoneDeposit;
    } else {
      data["deposite_phone"] = this.state.emailOrPhoneDeposit;
    }

    var listFile1 = "";
    var listFile2 = "";

    this.state.listFile1.map((e, index) => {
      if (index == 0) {
        listFile1 = listFile1 + e.id;
      } else {
        listFile1 = listFile1 + "," + e.id;
      }
    });

    this.state.listFile2.map((e, index) => {
      if (index == 0) {
        listFile2 = listFile2 + e.id;
      } else {
        listFile2 = listFile2 + "," + e.id;
      }
    });

    data["organic_certified_file"] = listFile1;
    data["thirty_plus_file"] = listFile2;

    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.user.user_token;
    let url = process.env.REACT_APP_BASE_URL;

    axios
      .post(url + "order/saveOrder", data)
      .then((response) => {
        if (response && response.status == 200) {
          console.log(response.data.status);
          if (response.data.status == "no") {
            this.props.dispatch(
              NOTIFICATION({ msg: response.data.message, msgColor: "#FAD5BD" })
            );
            setTimeout(() => {
              this.props.dispatch(NOTIFICATION({ msg: "" }));
            }, 2000);
          } else {
            this.props.dispatch(NOTIFICATION({ msg: response.data.message }));
            setTimeout(() => {
              this.props.dispatch(NOTIFICATION({ msg: "" }));
            }, 3000);
            this.props.onClose();
            this.props.refresh();
            return false;
          }
        }

        this.setState({
          submitLoading: false,
        });
      })
      .catch((error) => {
        this.props.dispatch(
          NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
        );
        setTimeout(() => {
          this.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 2000);

        this.setState({
          submitLoading: false,
        });
      });
  };

  setDropOff = (date) => {
    console.log("setdropoffdate:", date);
    this.setState({
      temp_dropoff_date: date,
    });
  };

  setKillDate = (date) => {
    this.setState({
      temp_kill_date: date,
    });
  };

  /*   handleFieldChange = (e, index) => {
    let { customers_all } = this.state;
    console.log("handlechange2 ====", e.target.value);
    let { name, value } = e.target;
    customers_all[index][name] = value;
    this.setState({
      customers_all: customers_all,
    });
  }; */

  handleChangeOrganicData = (e, index) => {
    let { listOrganic } = this.state;
    console.log("handlechange2 ====", e.target.value);
    let { name, value } = e.target;
    listOrganic[index][name] = value;
    this.setState({
      listOrganic: listOrganic,
    });
  };

  handleChangeOrganic = (e) => {
    this.setState({ organic: e.target.value });

    /*
        if (e.target.value == 'yes') {
            this.setState({ organicModal: true })
            var listOrganic = [];

            var beef_qty = this.state.beef_qty;
            var bison_qty = this.state.bison_qty;
            var hog_qty = this.state.hog_qty;
            var lamb_qty = this.state.lamb_qty;
            var goat_qty = this.state.goat_qty;
            var elk_qty = this.state.elk_qty;
            var yak_qty = this.state.yak_qty;
            var pork_qty = this.state.pork_qty;
            var poultry_qty = this.state.poultry_qty;
            var deer_qty = this.state.deer_qty;
            var wild_game_qty = this.state.wild_game_qty;
            // var date = localStorage.getItem("schedule_day_off")
            var date = this.state.dropoff_date;


            for (var i = 0; beef_qty > i; i++) {
                listOrganic.push({
                    "species": "beef",
                    "tagid": "",
                    "hang": 0,
                    "date": date,
                    "organic": "",
                    "organs": "",
                    "sex": "",
                    "label": "",
                })
            }

            for (var i = 0; bison_qty > i; i++) {
                listOrganic.push({
                    "species": "bison",
                    "tagid": "",
                    "hang": 0,
                    "date": date,
                    "organic": "",
                    "organs": "",
                    "sex": "",
                    "label": "",
                })
            }

            for (var i = 0; hog_qty > i; i++) {
                listOrganic.push({
                    "species": "hog",
                    "tagid": "",
                    "hang": 0,
                    "date": date,
                    "organic": "",
                    "organs": "",
                    "sex": "",
                    "label": "",
                })
            }

            for (var i = 0; lamb_qty > i; i++) {
                listOrganic.push({
                    "species": "lamb",
                    "tagid": "",
                    "hang": 0,
                    "date": date,
                    "organic": "",
                    "organs": "",
                    "sex": "",
                    "label": "",
                })
            }

            for (var i = 0; goat_qty > i; i++) {
                listOrganic.push({
                    "species": "goat",
                    "tagid": "",
                    "hang": 0,
                    "date": date,
                    "organic": "",
                    "organs": "",
                    "sex": "",
                    "label": "",
                })
            }

            for (var i = 0; elk_qty > i; i++) {
                listOrganic.push({
                    "species": "elk",
                    "tagid": "",
                    "hang": 0,
                    "date": date,
                    "organic": "",
                    "organs": "",
                    "sex": "",
                    "label": "",
                })
            }

            for (var i = 0; yak_qty > i; i++) {
                listOrganic.push({
                    "species": "yak",
                    "tagid": "",
                    "hang": 0,
                    "date": date,
                    "organic": "",
                    "organs": "",
                    "sex": "",
                    "label": "",
                })
            }

            for (var i = 0; pork_qty > i; i++) {
                listOrganic.push({
                    "species": "pork",
                    "tagid": "",
                    "hang": 0,
                    "date": date,
                    "organic": "",
                    "organs": "",
                    "sex": "",
                    "label": "",
                })
            }

            for (var i = 0; poultry_qty > i; i++) {
                listOrganic.push({
                    "species": "poultry",
                    "tagid": "",
                    "hang": 0,
                    "date": date,
                    "organic": "",
                    "organs": "",
                    "sex": "",
                    "label": "",
                })
            }

            for (var i = 0; deer_qty > i; i++) {
                listOrganic.push({
                    "species": "deer",
                    "tagid": "",
                    "hang": 0,
                    "date": date,
                    "organic": "",
                    "organs": "",
                    "sex": "",
                    "label": "",
                })
            }

            for (var i = 0; wild_game_qty > i; i++) {
                listOrganic.push({
                    "species": "wild_game",
                    "tagid": "",
                    "hang": 0,
                    "date": date,
                    "organic": "",
                    "organs": "",
                    "sex": "",
                    "label": "",
                })
            }

            this.setState({ listOrganic: listOrganic })





        } */
    // let { customers_all } = this.state;
    // console.log("handlechange2 ====", e.target.value);
    // let { name, value } = e.target;
    // customers_all[index][name] = value;
    // this.setState({
    //   customers_all: customers_all,
    // });
  };

  handleChangeName = (o, v, r, index) => {
    console.log("handlechange2 ====", v);
    if (v === null) return;

    let { customers_all } = this.state;

    customers_all[index]["customer_id"] = v.id;
    customers_all[index]["first_name"] = v.first_name;
    customers_all[index]["last_name"] = v.last_name;
    customers_all[index]["phone"] = v.phone_number;
    customers_all[index]["email"] = v.email;
    customers_all[index]["address"] = v.street_address;
    customers_all[index]["customer_name"] = v;
    if (v !== null && v.id === false) {
      customers_all[index]["customer_name"] = null;
      this.setState({
        addCustomerModal: true,
      });
    }
    this.setState({
      customers_all,
      showPrevCutSheet: true,
    });
  };

  handleChangeNameInput = async () => {
    var element = await document.querySelector("#no-option");
    if (element != null) {
      this.setState({ isNoOption: true });
    } else {
      this.setState({ isNoOption: false });
    }
  };

  handleCheckboxSelect = (e, data) => {
    if (e.target.checked) {
      let arr = this.state.temp_organs
        .split(",")
        .filter((organ) => organ !== "");
      arr.push(data);
      var txt = arr.join(",");
      this.setState({ temp_organs: txt });
    } else {
      var txt = this.state.temp_organs
        .split(",")
        .filter((organ) => {
          if (organ.trim() !== data) return organ;
        })
        .join(",");
      this.setState({ temp_organs: txt });
    }
  };

  handleDatePicker = (e, name) => {
    const originalDate = new Date(e);
    const formattedDate = `${originalDate.getFullYear()}-${(
      originalDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${originalDate.getDate().toString().padStart(2, "0")}`;
    this.setState({
      [name]: formattedDate,
    });

    console.log("name", name);
    console.log("formattedDate", formattedDate);
  };

  /*
    selectFile1 = (e) => {
        this.setState({
            selectedFile1: e.target.files[0],
        });
    };

    selectFile2 = (e) => {
        this.setState({
            selectedFile2: e.target.files[0],
        });
    }; */
  fetch_all_customer = () => {
    let url = process.env.REACT_APP_BASE_URL;
    let self = this;
    self.setState({
      customer_loading: true,
    });

    let data = new FormData();
    self.setState({
      scheduled_order: [],
      scheduled_order_download: [{ "No data found": "" }],
      scheduled_order_loading: true,
    });

    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.user.user_token;
    axios.get(`${url}profile/getAllCustomers`).then(function (response) {
      if (
        response &&
        response.data &&
        response.data &&
        response.data.status === "no"
      ) {
        self.props.dispatch(
          NOTIFICATION({ msg: response.data.message, msgColor: "#FAD5BD" })
        );
        setTimeout(() => {
          self.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 2000);
      } else {
        let listing_data = response.data.data;

        if (listing_data && listing_data.length > 0) {
          self.setState({ customer_data: listing_data });
        } else {
          self.setState({
            scheduled_order: [],
            scheduled_order_download: [{ "No data found": "" }],
            scheduled_order_loading: false,
          });
        }
      }

      self.setState({
        customer_loading: false,
      });
    });
  };
  postCustomer = async () => {
    try {
      // Attempt to refresh token
      const newAccessToken = await refreshToken();

      // Token refresh successful, set the new access token in axios headers
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.props.user.user_token;
      axios.defaults.headers.common["qb-auth-token"] = newAccessToken; // Add your qb-auth-token here

      // Determine the value of the customers field
      let customerIds = null;
      if (
        this.state.customer_type !== "customer" &&
        this.state.selectedCustomersList
      ) {
        customerIds = this.state.selectedCustomersList.map(
          (customer) => customer.user_ref
        );
      }

      // Your axios post request
      let url = process.env.REACT_APP_BASE_URL;

      const data = {
        first_name: this.state.first_name,
        last_name: this.state.last_name,
        email: this.state.email,
        phone: this.state.phone_number,
        address: this.state.address,
        city: this.state.city,
        state: this.state.state,
        zip: this.state.zip,
        custom_portal: "1",
        type: this.state.customer_type,
        producer: this.state.splitCustomerWhole1Name
          ? this.state.splitCustomerWhole1Name.id === ""
            ? null
            : this.state.splitCustomerWhole1Name.id
          : null,
        customers: customerIds ?? [], // Pass the list of customer IDs or null
      };

      console.log(data);
      axios
        .post(url + "profile/addCustomer", data)
        .then((response) => {
          console.log(response.data.message);
          if (response && response.status === 200) {
            console.log(response.data.message);
            if (response.data.status === "no") {
              this.props.dispatch(
                NOTIFICATION({
                  msg: response.data.message,
                  msgColor: "#FAD5BD",
                })
              );
              setTimeout(() => {
                this.props.dispatch(NOTIFICATION({ msg: "" }));
              }, 2000);
            } else {
              this.setState({
                addModal: false,
                first_name: "",
                last_name: "",
                email: "",
                phone_number: "",
                address: "",
                city: "",
                state: "",
                zip: "",
                splitCustomerWhole1Name: null,
                selectedCustomersList: [],
              });

              this.fetch_all_customer();
              this.getCustomersData();
              this.props.dispatch(
                NOTIFICATION({ msg: "Add Customer Successfully" })
              );
              setTimeout(() => {
                this.props.dispatch(NOTIFICATION({ msg: "" }));
              }, 3000);
            }
          }
        })
        .catch((error) => {
          this.props.dispatch(
            NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
          );
          setTimeout(() => {
            this.props.dispatch(NOTIFICATION({ msg: "" }));
          }, 2000);
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };
  handleOpenAddCustomerModal = () => {
    this.setState({ addCustomerModal: true });
  };

  handleChangeName1 = (event, value) => {
    if (value && value.first_name === "Add") {
      this.setState({ openAddCustomerModal: true });
      // Handle the case where `first_name` is "Add"
      // You might want to open a dialog or show some input for adding a new customer
      console.log("Adding new customer with value:", value);
    } else {
      this.setState({ splitCustomerWhole1Name: value });
    }
  };
  /* uploadFile1 = () => {
        const { selectedFile1 } = this.state;

        if (!selectedFile1) {
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile1);

        axios.defaults.headers.common['Authorization'] = "Bearer " + this.props.user.user_token;
        let url = process.env.REACT_APP_BASE_URL;

        axios.post(url + 'misc/addAttachment', formData).then(response => {
            if (response && response.status == 200) {
                console.log(response.data.uploaded)
                if (response.data.uploaded != 1) {
                    this.props.dispatch(NOTIFICATION({ msg: "Upload File Failed", msgColor: "#FAD5BD" }))
                    setTimeout(() => {
                        this.props.dispatch(NOTIFICATION({ msg: "" }))
                    }, 2000);

                } else {
                    var files = this.state.listFile1;
                    files.push({
                        "name": response.data.fileName,
                        "id": response.data.attachment_id
                    })
                    this.setState({
                        selectedFile1: null,
                        listFile1: files
                    })

                    this.props.dispatch(NOTIFICATION({ msg: 'Upload Image Successfully' }))
                    setTimeout(() => {
                        this.props.dispatch(NOTIFICATION({ msg: "" }))
                    }, 2000);

                    return false;
                }
            } else {
            }
        }).catch((error) => {
            this.props.dispatch(
                NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
            );
            setTimeout(() => {
                this.props.dispatch(NOTIFICATION({ msg: "" }));
            }, 2000);
        });
    }

    uploadFile2 = () => {
        const { selectedFile2 } = this.state;

        if (!selectedFile2) {
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile2);

        axios.defaults.headers.common['Authorization'] = "Bearer " + this.props.user.user_token;
        let url = process.env.REACT_APP_BASE_URL;

        axios.post(url + 'misc/addAttachment', formData).then(response => {
            if (response && response.status == 200) {
                console.log(response.data.uploaded)
                if (response.data.uploaded != 1) {
                    this.props.dispatch(NOTIFICATION({ msg: "Upload File Failed", msgColor: "#FAD5BD" }))
                    setTimeout(() => {
                        this.props.dispatch(NOTIFICATION({ msg: "" }))
                    }, 2000);

                } else {
                    var files = this.state.listFile2;
                    files.push({
                        "name": response.data.fileName,
                        "id": response.data.attachment_id
                    })
                    this.setState({
                        selectedFile2: null,
                        listFile2: files
                    })

                    this.props.dispatch(
                        NOTIFICATION({ msg: 'Upload ' + response.data.fileName + ' Successfully' })
                    );
                    return false;
                }
            } else {
            }
        }).catch((error) => {
            this.props.dispatch(
                NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
            );
            setTimeout(() => {
                this.props.dispatch(NOTIFICATION({ msg: "" }));
            }, 2000);
        });
    } */

  getAvailableSlot = () => {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.user.user_token;
    let url = process.env.REACT_APP_BASE_URL;
    console.log("dropoff date", this.state.dropoff_date);
    var data = {
      // date: localStorage.getItem('schedule_day_off'),
      date: this.state.temp_dropoff_date,
    };

    axios
      .post(url + "calendar/get_date_available_slot", data)
      .then((response) => {
        if (response && response.status == 200) {
          if (response.data.status == "no") {
            this.props.dispatch(
              NOTIFICATION({ msg: response.data.message, msgColor: "#FAD5BD" })
            );
            setTimeout(() => {
              this.props.dispatch(NOTIFICATION({ msg: "" }));
            }, 2000);
          } else {
            this.setState({
              availableSlot: response.data.data.available,
              usedSlot: response.data.data.used,
              dropoff_date: this.state.temp_dropoff_date,
              kill_date: this.state.temp_dropoff_date,
              recurring: this.state.temp_recurring,
              scheduleModal: false,
            });

            return false;
          }
        }
      })
      .catch((error) => {
        this.props.dispatch(
          NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
        );
        setTimeout(() => {
          this.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 2000);
      });
  };

  speciesLabel = (species) => {
    if (species == "wild_game") {
      return "Wild Game";
    } else {
      return species.charAt(0).toUpperCase() + species.slice(1);
    }
  };

  removeCustomerFromList = (customerId, preType) => {
    this.setState(
      (prevState) => {
        const { selectedCustomersList, deletedCustomersList } = prevState;

        // Create a new Set to avoid duplicates in deletedCustomersList
        let updatedDeletedCustomersSet = new Set(deletedCustomersList);

        // Add customerId to the Set if preType is 'update'
        if (preType === "update") {
          updatedDeletedCustomersSet.add(customerId);
        }

        // Convert the Set back to an array
        let updatedDeletedCustomersList = Array.from(
          updatedDeletedCustomersSet
        );

        // Determine which list to filter based on 'type'
        return {
          deletedCustomersList: updatedDeletedCustomersList,
          selectedCustomersList: selectedCustomersList.filter(
            (customer) => customer.id !== customerId
          ),
        };
      },
      () => {
        // Log after state update to confirm changes
        console.log("After State Update:");
        console.log("deletedCustomersList:", this.state.deletedCustomersList);
      }
    );
  };

  addCustomerToList = () => {
    const { splitCustomerWhole1Name, selectedCustomersList } = this.state;

    if (splitCustomerWhole1Name) {
      const customerAlreadyInList = selectedCustomersList.some(
        (customer) => customer.id === splitCustomerWhole1Name.id
      );

      // If the customer is not already in the list, add them
      if (!customerAlreadyInList) {
        this.setState((prevState) => ({
          selectedCustomersList: [
            ...prevState.selectedCustomersList,
            {
              ...splitCustomerWhole1Name,
              preType: "add", // Set preType field here
            },
          ],
          splitCustomerWhole1Name: null, // Clear the selected name after adding
        }));
      }
    }
  };
  setSplitOrder = (formCustomerId) => {
    const totalAmount = this.calculateTotalAmount(this.state.fields);

    if (totalAmount !== 100) {
      this.setState({ errorMessage: "Total amount must equal 100%." });
    } else {
      this.setState({ errorMessage: "" });
      // Proceed with the submit logic
      console.log("Submitted");
      let url = process.env.REACT_APP_BASE_URL;
      let self = this;
      var data = {
        cart_id: this.state.cart.cart_id,
        split_data: this.state.fields,
        form_id: parseInt(this.state.fields[0].cutsheet),
      };

      console.log(data);

      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.props.user.user_token;
      axios
        .post(url + "order/split", data)
        .then(function (response) {
          if (response.data.status === "yes") {
            self.props.dispatch(
              NOTIFICATION({ msg: "Add Split Order Successfully" })
            );
            setTimeout(function () {
              self.props.dispatch(NOTIFICATION({ msg: "" }));
            }, 2000);
            self.props.refresh();
            self.props.close();
            self.setState({
              cut_sheet_id: null,
              splitCustomerHalf2: null,
              splitCustomerQuarter2: null,
              splitCustomerQuarter3: null,
              splitCustomerQuarter4: null,
              splitModal: false,
            });
          } else {
            self.props.dispatch(
              NOTIFICATION({ msg: response.data.message, msgColor: "#FAD5BD" })
            );
            setTimeout(() => {
              self.props.dispatch(NOTIFICATION({ msg: "" }));
            }, 2000);
          }
        })
        .catch((error) => {
          self.props.dispatch(
            NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
          );
          setTimeout(() => {
            self.props.dispatch(NOTIFICATION({ msg: "" }));
          }, 2000);
        });
      // Reset the form or perform any other actions needed after submission
    }
  };

  render() {
    // console.log('state', this.state)
    const tableCellStyle = {
      border: "1px solid #ccc",
      padding: "10px",
      textAlign: "left",
      color: "#555",
      borderRadius: "8px", // Add border-radius here
    };

    const tableHeaderStyle = {
      border: "1px solid #ccc",
      padding: "10px",
      backgroundColor: "#f4f4f4",
      textAlign: "left",
      fontWeight: "bold",
    };
    return (
      <>
        <div className="">
          <div className="list-customer">
            <div className="list-customer-item">
              <div className="event-detail-wrap">
                <div className="">
                  <div className="row">
                    {/* <div className="form-group col-md-6">
                                            <label className="form-label">Business Name</label>
                                            <input className="form-control" type="text" name="event_name"
                                                value={this.state.event_name}
                                                onChange={this.valueChange("event_name")}
                                            ></input>
                                        </div> */}
                    {/*this.props.user.nmfs_number.split(',').includes('beef') &&
                      <div className="form-group col-md-6">
                        <label className="form-label">Beef Quantity</label>
                        <input className="form-control" type="number" name="beef_qty"
                          value={this.state.beef_qty}
                          onChange={this.valueChangeNumber("beef_qty")}>
                        </input>
                      </div>}
                    {this.props.user.nmfs_number.split(',').includes('bison') &&
                      <div className="form-group col-md-6">
                        <label className="form-label">Bison Quantity</label>
                        <input className="form-control" type="number" name="bison_qty"
                          value={this.state.bison_qty}
                          onChange={this.valueChangeNumber("bison_qty")}>
                        </input>
                      </div>}
                    {this.props.user.nmfs_number.split(',').includes('hog') &&
                      <div className="form-group col-md-6">
                        <label className="form-label">Hog Quantity</label>
                        <input className="form-control" type="number" name="hog_qty"
                          value={this.state.hog_qty}
                          onChange={this.valueChangeNumber("hog_qty")}>
                        </input>
                      </div>}
                    {this.props.user.nmfs_number.split(',').includes('lamb') &&
                      <div className="form-group col-md-6">
                        <label className="form-label">Lamb Quantity</label>
                        <input className="form-control" type="number" name="lamb_qty"
                          value={this.state.lamb_qty}
                          onChange={this.valueChangeNumber("lamb_qty")}></input>
                      </div>}
                    {this.props.user.nmfs_number.split(',').includes('goat') &&
                      <div className="form-group col-md-6">
                        <label className="form-label">Goat Quantity</label>
                        <input className="form-control" type="number" name="goat_qty"
                          value={this.state.goat_qty}
                          onChange={this.valueChangeNumber("goat_qty")}>
                        </input>
                      </div>}
                    {this.props.user.nmfs_number.split(',').includes('elk') &&
                      <div className="form-group col-md-6">
                        <label className="form-label">Elk Quantity</label>
                        <input className="form-control" type="number" name="elk_qty"
                          value={this.state.elk_qty}
                          onChange={this.valueChangeNumber("elk_qty")}>
                        </input>
                      </div>}
                    {this.props.user.nmfs_number.split(',').includes('yak') &&
                      <div className="form-group col-md-6">
                        <label className="form-label">Yak Quantity</label>
                        <input className="form-control" type="number" name="yak_qty"
                          value={this.state.yak_qty}
                          onChange={this.valueChangeNumber("yak_qty")}>
                        </input>
                      </div>}
                    {this.props.user.nmfs_number.split(',').includes('pork') &&
                      <div className="form-group col-md-6">
                        <label className="form-label">Pork Quantity</label>
                        <input className="form-control" type="number" name="pork_qty"
                          value={this.state.pork_qty}
                          onChange={this.valueChangeNumber("pork_qty")}>
                        </input>
                      </div>}
                    {this.props.user.nmfs_number.split(',').includes('poultry') &&
                      <div className="form-group col-md-6">
                        <label className="form-label">Poultry Quantity</label>
                        <input className="form-control" type="number" name="poultry_qty"
                          value={this.state.poultry_qty}
                          onChange={this.valueChangeNumber("poultry_qty")}>
                        </input>
                      </div>}
                    {this.props.user.nmfs_number.split(',').includes('deer') &&
                      <div className="form-group col-md-6">
                        <label className="form-label">Deer Quantity</label>
                        <input className="form-control" type="number" name="deer_qty"
                          value={this.state.deer_qty}
                          onChange={this.valueChangeNumber("deer_qty")}>
                        </input>
                      </div>}
                    {this.props.user.nmfs_number.split(',').includes('wild_game') &&
                      <div className="form-group col-md-6">
                        <label className="form-label">Wild Game Quantity</label>
                        <input className="form-control" type="number" name="wild_game_qty"
                          value={this.state.wild_game_qty}
                          onChange={this.valueChangeNumber("wild_game_qty")}>
                        </input>
    </div> */}
                    <div className="form-group col-md-6">
                      <label className="form-label">Organic</label>
                      <select
                        type="text"
                        className="form-control"
                        onChange={(e) => this.handleChangeOrganic(e)}
                        value={this.state.organic}
                      >
                        <option value="">Select</option>
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                {this.state.fields.map((field, index) => (
                  <div
                    key={index}
                    style={{
                      position: "relative", // Ensure the container is relatively positioned
                      marginBottom: "10px",
                    }}
                  >
                    {/* Delete Icon */}
                    {index !== 0 && ( // Show delete icon only if the index is not 0
                      <IconButton
                        aria-label="delete"
                        style={{
                          position: "absolute",
                          top: "-10px", // Adjust top as needed
                          right: "-10px", // Adjust right as needed
                          color: "red",
                          zIndex: 10, // Ensure the icon stays on top
                        }}
                        onClick={() => this.handleDeleteField(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}

                    {/* The box containing the form fields */}
                    <div
                      style={{
                        border: "1px solid #ccc",
                        padding: "10px",
                        borderRadius: "12px",
                        display: "flex",
                        flexDirection: "row",
                        gap: "20px", // Adjust the spacing between items as needed
                        alignItems: "flex-start", // Align items at the top
                        justifyContent: "center", // Center the items horizontally
                      }}
                    >
                      <div
                        style={{
                          flex: "1",
                          minWidth: "250px",
                        }} // Increased minWidth for wider fields
                      >
                        <label className="new-order-form-label form-label">
                          Cut Sheet
                        </label>
                        <select
                          name="form_id"
                          className="form-control new-order-form-input"
                          value={String(field.cutsheet)} // Compare and set the correct cutsheet value
                          onChange={(e) =>
                            this.handleSelectChange(
                              index,
                              parseInt(e.target.value)
                            )
                          }
                          style={{ width: "100%" }}
                        >
                          {/*  <option value="">Select Cut Sheet</option> */}
                          {this.state.cutSheetTemp.map((data, idx) => (
                            <option key={idx} value={data.id}>
                              Cut Sheet - {data.name} ({data.type})
                            </option>
                          ))}
                        </select>
                      </div>
                      <div
                        style={{ width: "6rem" }}
                        // Increased minWidth for wider fields
                      >
                        <label className="new-order-form-label form-label">
                          Amount (%)
                        </label>
                        <select
                          className="form-control new-order-form-input"
                          value={field.amount}
                          onChange={(e) =>
                            this.handleFieldChangeAmount(
                              index,
                              "amount",
                              e.target.value
                            )
                          }
                          style={{ width: "100%" }} // Set to full width of its container
                        >
                          <option value="">Select Amount</option>
                          <option value="Whole">Whole</option>
                          <option value="Half">Half</option>
                          <option value="Quarter">Quarter</option>
                        </select>
                      </div>
                      <div>
                        <label className="new-order-form-label form-label">
                          Customer
                        </label>

                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <Autocomplete
                            onChange={(e, value) =>
                              this.handleFieldChange(index, "name", value)
                            }
                            onInput={() => this.handleChangeNameInput()}
                            className="mb-3 form-control-autocomplete"
                            options={this.state.dropdownCustomer}
                            disableListWrap
                            getOptionLabel={(option) => {
                              if (option.id === false) {
                                return "Add new customer";
                              } else {
                                return `${option.first_name} ${option.last_name}`;
                              }
                            }}
                            value={field.name || null}
                            filterOptions={(options, params) => {
                              const filter = createFilterOptions();
                              const filtered = filter(options, params);

                              if (params.inputValue !== "") {
                                filtered.push({
                                  id: false,
                                  first_name: `Add`,
                                  last_name: `"${params.inputValue}"`,
                                });
                              }

                              return filtered;
                            }}
                            style={{ width: "370px" }}
                            renderInput={(params) => (
                              <TextField
                                id="ggez"
                                {...params}
                                variant="outlined"
                              />
                            )}
                          />
                          {/*  <Autocomplete
                            options={this.state.dropdownCustomer}
                            getOptionLabel={(option) =>
                              `${option.first_name} ${option.last_name}`
                            }
                            value={field.name || null}
                            onChange={(e, value) =>
                              this.handleFieldChange(index, "name", value)
                            }
                            onInputChange={this.handleChangeNameInput}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label=""
                                variant="outlined"
                                style={{ width: "370px" }} // Set the desired width here
                              />
                            )}
                          /> */}
                          {/* <IconButton
                            edge="end"
                            onClick={this.handleOpenAddCustomerModal}
                            style={{ marginLeft: "8px" }} // Adjust spacing as needed
                          >
                            <FontAwesomeIcon icon={faPlus} />
                          </IconButton> */}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {this.state.errorMessage && (
                  <div style={{ color: "red", marginBottom: "10px" }}>
                    {this.state.errorMessage}
                  </div>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleAddMore}
                  disabled={!this.canAddMore()}
                  style={{ borderRadius: "6px" }} // Adjust the value to your preference
                >
                  Add More
                </Button>
              </div>
            </div>
            <div className="col-md-3">
              <div className="new-order-list-btn">
                <a
                  onClick={(e) => this.setState({ scheduleModal: true })}
                  className="new-order-list-btn-item"
                >
                  <span>Schedule a drop off Date</span>
                </a>
                <a
                  onClick={(e) => this.setState({ organModal: true })}
                  className="new-order-list-btn-item"
                >
                  <span>Select Organs</span>
                </a>
                <a
                  onClick={(e) => this.setState({ labelModal: true })}
                  className="new-order-list-btn-item"
                >
                  <span>Select Label Type</span>
                </a>
                <a
                  onClick={(e) => this.setState({ sexModal: true })}
                  className="new-order-list-btn-item"
                >
                  <span>Select Sex</span>
                </a>
              </div>
              <div className="new-order-list-info">
                <div className="new-order-list-info-item">
                  <h1>Available</h1>
                  <div className="new-order-list-info-item-value">
                    <span>{this.state.availableSlot}</span>
                  </div>
                </div>
                <div className="new-order-list-info-item">
                  <h1>Used</h1>
                  <div className="new-order-list-info-item-value">
                    <span>{this.state.usedSlot}</span>
                  </div>
                </div>
              </div>
              <div className="new-order-list-btn-submit">
                <a
                  onClick={(e) => {
                    this.props.onClose();
                  }}
                  className="new-order-list-btn-submit-item"
                >
                  Cancel
                </a>
                <LoadingButton
                  isLoading={this.state.submitLoading}
                  onClick={
                    (e) => this.saveOrder() // this.setSplitOrder(this.state.cart.form_customer_id)
                  }
                  // onClick={(e) => this.saveOrder()}
                  className="new-order-list-btn-submit-item"
                  style={{ background: "#3C923F" }}
                >
                  Save
                </LoadingButton>
              </div>
            </div>
          </div>
        </div>

        <DialogPopup
          title="Schedule a Dropoff Date"
          subtitle="Setup dropoff"
          open={this.state.scheduleModal}
          onClose={(e) => () =>
            this.setState({
              scheduleModal: false,
              temp_recurring: this.state.recurring,
              temp_dropoff_date: this.state.dropoff_date,
              temp_kill_date: this.state.kill_date,
            })}
          maxWidth="md"
        >
          <MyAgendaMini
            setKillDate={this.setKillDate}
            setDropOff={this.setDropOff}
            /* ref={this.ChildElement}*/ currentServerTime={
              this.state.currentServerTime
            }
            searching={this.state.searching}
          />
          <div className="schedule-modal-item">
            <div className="row">
              <div className="form-group col-md-4">
                <p className="popup-form-label form-label">
                  Confirm Drop off Date
                </p>
                <input
                  type="text"
                  className="form-control D-input popup-form-control"
                  placeholder="Click on calendar date"
                  readOnly={true}
                  value={this.state.temp_dropoff_date}
                  // onChange={this.handleChange('new_password')}
                />

                {/* <DayPickerInput

                  // classNames="form-control"
                  // style={{ width: '100%', height: '37px', background: '#fff' }}
                  style={{ width: '100%', height: '37px', border: '1px solid #E3E8EE', boxSadow: '0px 1px 2px 0px #37415114' }}
                  // dayPickerProps={{ disabledDays: { before: new Date()} }}
                  // inputProps={{ class: "form-control", style: { height: '100%', background: '#fff' } }}
                  inputProps={{ type: 'text', class: "form-control", style: { width: '100%', height: '100%', padding: '0 10px' } }}
                  // ref={this.toRef}
                  placeholder="select a date"
                  value={this.state.dropoff_date}
                  format="YYYY-MM-DD"
                  onDayChange={(event) => this.handleDatePicker(event, 'dropoff_date')}
                /> */}
              </div>
              <div className="form-group col-md-4">
                <p className="popup-form-label form-label">Confirm Kill Date</p>
                {/* <input type="text" className="form-control D-input popup-form-control" placeholder="Enter new password"
                        value={localStorage.getItem("schedule_kill")}
                        // onChange={this.handleChange('new_password')}
                        /> */}

                <DayPickerInput
                  // classNames="form-control"
                  // style={{ width: '100%', height: '37px', background: '#fff' }}
                  style={{
                    width: "100%",
                    height: "37px",
                    border: "1px solid #E3E8EE",
                    boxSadow: "0px 1px 2px 0px #37415114",
                  }}
                  // dayPickerProps={{ disabledDays: { before: new Date()} }}
                  // inputProps={{ class: "form-control", style: { height: '100%', background: '#fff' } }}
                  inputProps={{
                    type: "text",
                    class: "form-control",
                    style: { width: "100%", height: "100%", padding: "0 10px" },
                  }}
                  // ref={this.toRef}
                  placeholder="select a date"
                  value={this.state.temp_kill_date}
                  format="YYYY-MM-DD"
                  onDayChange={(event) =>
                    this.handleDatePicker(event, "temp_kill_date")
                  }
                />
              </div>
              {/* <div className="form-group col-md-4">
                                <p className="popup-form-label form-label">Confirm if this data will be recurring</p>
                                <div className="">
                                    <div className="" onClick={this.valueChange("temp_recurring")}>
                                        <label className="mr-3" style={{ color: 'black' }}>
                                            <input
                                                className="mr-1"
                                                type="radio"
                                                name="recurring"
                                                value={1}
                                                checked={this.state.temp_recurring == 1}
                                            />
                                            Yes</label>

                                        <label style={{ color: 'black' }}>
                                            <input
                                                className="mr-1"
                                                type="radio"
                                                name="recurring"
                                                value={0}
                                                checked={this.state.temp_recurring == 0}
                                            />
                                            No</label>
                                    </div>
                                </div>
                            </div> */}
            </div>
          </div>
          <div>
            <div className="popup-form-btn mt-3">
              <a
                onClick={(e) => this.getAvailableSlot()}
                className="btn btn-primary"
              >
                Submit
              </a>
            </div>
          </div>
        </DialogPopup>
        <DialogPopup
          title="Recurring Order"
          subtitle="schedulling"
          open={this.state.rruleModal}
          onClose={(e) => () =>
            this.setState({ rruleModal: false, temp_rrule: this.state.rrule })}
          maxWidth="md"
        >
          <div className="rrulegenerator">
            <RRuleGenerator
              onChange={(rrule) => this.setState({ temp_rrule: rrule })}
              value={this.state.temp_rrule}
              config={{
                repeat: ["Monthly", "Weekly", "Daily"],
                // yearly: 'on the',
                // monthly: 'on',
                // end: ['Never', 'On date'],
                // weekStartsOnSunday: true,
                // hideError: true,
              }}
              // customCalendar={MyCustomCalendar}
            />
          </div>
          <div className="popup-form-btn mt-3">
            <a
              onClick={() =>
                this.setState({
                  rruleModal: false,
                  rrule: this.state.temp_rrule,
                })
              }
              className="btn btn-primary"
            >
              Save
            </a>
          </div>
        </DialogPopup>
        <DialogPopup
          title="Select Organs"
          subtitle="fields"
          open={this.state.organModal}
          onClose={(e) => () =>
            this.setState({
              organModal: false,
              temp_organs: this.state.organs,
            })}
          maxWidth="sm"
        >
          <div className="">
            <label className="mr-3">
              <input
                type="checkbox"
                className="mr-1"
                style={{ accentColor: "#3C923F" }}
                checked={
                  this.state.temp_organs.split(",").indexOf("Pass") !== -1
                }
                onChange={(e) => {
                  this.handleCheckboxSelect(e, "Pass");
                }}
              />
              Pass
            </label>
            <label className="mr-3">
              <input
                type="checkbox"
                className="mr-1"
                style={{ accentColor: "#3C923F" }}
                checked={
                  this.state.temp_organs.split(",").indexOf("Heart") !== -1
                }
                onChange={(e) => {
                  this.handleCheckboxSelect(e, "Heart");
                }}
              />
              Heart
            </label>
            <label className="mr-3">
              <input
                type="checkbox"
                className="mr-1"
                style={{ accentColor: "#3C923F" }}
                checked={
                  this.state.temp_organs.split(",").indexOf("Liver") !== -1
                }
                onChange={(e) => {
                  this.handleCheckboxSelect(e, "Liver");
                }}
              />
              Liver
            </label>
            <label className="mr-3">
              <input
                type="checkbox"
                className="mr-1"
                style={{ accentColor: "#3C923F" }}
                checked={
                  this.state.temp_organs.split(",").indexOf("Tongue") !== -1
                }
                onChange={(e) => {
                  this.handleCheckboxSelect(e, "Tongue");
                }}
              />
              Tongue
            </label>
            <label className="mr-3">
              <input
                type="checkbox"
                className="mr-1"
                style={{ accentColor: "#3C923F" }}
                checked={
                  this.state.temp_organs.split(",").indexOf("Tail") !== -1
                }
                onChange={(e) => {
                  this.handleCheckboxSelect(e, "Tail");
                }}
              />
              Tail
            </label>
            <label className="mr-3">
              <input
                type="checkbox"
                className="mr-1"
                style={{ accentColor: "#3C923F" }}
                checked={
                  this.state.temp_organs.split(",").indexOf("Testies") !== -1
                }
                onChange={(e) => {
                  this.handleCheckboxSelect(e, "Testies");
                }}
              />
              Testies
            </label>
          </div>

          <div className="popup-form-btn mt-3">
            <a
              onClick={() => {
                this.setState({
                  organModal: false,
                  organs: this.state.temp_organs,
                });
              }}
              className="btn btn-primary"
            >
              Save
            </a>
          </div>
        </DialogPopup>
        <DialogPopup
          title="Select Label"
          subtitle="fields"
          open={this.state.labelModal}
          onClose={(e) => () =>
            this.setState({ labelModal: false, temp_label: this.state.label })}
          maxWidth="md"
        >
          <div className="row">
            <div className="new-order-list-btn-item-organs-label">
              <div
                className="new-order-list-btn-item-organs-item-2"
                onChange={(e) => {
                  this.setState({
                    temp_label: e.target.value,
                  });
                }}
              >
                <label className="mr-3">
                  <input
                    className="mr-1"
                    type="radio"
                    name="label"
                    value="custom"
                    checked={this.state.temp_label === "custom"}
                    style={{ accentColor: "#3C923F" }}
                  />
                  Custom
                </label>

                <label className="mr-3">
                  <input
                    className="mr-1"
                    type="radio"
                    name="label"
                    value="usda"
                    checked={this.state.temp_label === "usda"}
                    style={{ accentColor: "#3C923F" }}
                  />
                  USDA
                </label>

                <label className="mr-3">
                  <input
                    className="mr-1"
                    type="radio"
                    name="label"
                    value="organic"
                    checked={this.state.temp_label === "organic"}
                    style={{ accentColor: "#3C923F" }}
                  />
                  Organic
                </label>

                <label className="mr-3">
                  <input
                    className="mr-1"
                    type="radio"
                    name="label"
                    value="private"
                    checked={this.state.temp_label === "private"}
                    style={{ accentColor: "#3C923F" }}
                  />
                  Private
                </label>

                <label className="mr-3">
                  <input
                    className="mr-1"
                    type="radio"
                    name="label"
                    value="religious"
                    checked={this.state.temp_label === "religious"}
                    style={{ accentColor: "#3C923F" }}
                  />
                  Religious
                </label>

                <label className="mr-3">
                  <input
                    className="mr-1"
                    type="radio"
                    name="label"
                    value="generic"
                    checked={this.state.temp_label === "generic"}
                    style={{ accentColor: "#3C923F" }}
                  />
                  Generic
                </label>
              </div>
            </div>
          </div>
          <div className="popup-form-btn mt-3">
            <a
              onClick={() => {
                this.setState({
                  labelModal: false,
                  label: this.state.temp_label,
                });
              }}
              className="btn btn-primary"
            >
              Save
            </a>
          </div>
        </DialogPopup>
        <DialogPopup
          title="Select Sex"
          subtitle="fields"
          open={this.state.sexModal}
          onClose={(e) => () =>
            this.setState({ sexModal: false, temp_sex: this.state.sex })}
          maxWidth="sm"
        >
          <div className="row">
            <div className="new-order-list-btn-item-organs-label">
              <div
                className="new-order-list-btn-item-organs-item-2"
                onChange={this.valueChange("temp_sex")}
              >
                <label className="mr-3">
                  <input
                    type="radio"
                    name="sex"
                    value="male"
                    checked={this.state.temp_sex === "male"}
                    style={{ accentColor: "#3C923F", marginRight: "0.2rem" }}
                  />
                  Male
                </label>

                <label className="mr-3">
                  <input
                    type="radio"
                    name="sex"
                    value="female"
                    checked={this.state.temp_sex === "female"}
                    style={{ accentColor: "#3C923F", marginRight: "0.2rem" }}
                  />
                  Female
                </label>

                <label className="mr-3">
                  <input
                    type="radio"
                    name="sex"
                    value="heifer"
                    checked={this.state.temp_sex === "heifer"}
                    style={{ accentColor: "#3C923F", marginRight: "0.2rem" }}
                  />
                  Heifer
                </label>

                <label className="mr-3">
                  <input
                    type="radio"
                    name="sex"
                    value="steer"
                    checked={this.state.temp_sex === "steer"}
                    style={{ accentColor: "#3C923F", marginRight: "0.2rem" }}
                  />
                  Steer
                </label>

                <label className="mr-3">
                  <input
                    type="radio"
                    name="sex"
                    value="bull"
                    checked={this.state.temp_sex === "bull"}
                    style={{ accentColor: "#3C923F", marginRight: "0.2rem" }}
                  />
                  Bull
                </label>
              </div>
            </div>
          </div>
          <div className="popup-form-btn mt-3">
            <a
              onClick={() =>
                this.setState({ sexModal: false, sex: this.state.temp_sex })
              }
              className="btn btn-primary"
            >
              Save
            </a>
          </div>
        </DialogPopup>

        <DialogPopup
          title="Add Customer"
          subtitle="Customer fields"
          open={this.state.addCustomerModal}
          onClose={(e) => () => this.setState({ addCustomerModal: false })}
        >
          <AddorUpdateCustomer
            openAddCustomerModal={this.openAddCustomerModal}
            closeAddCustomerModal={this.closeAddCustomerModal}
            getCustomersData={this.getCustomersData}
            user={this.props.user}
            fetch_all_customer={this.fetch_all_customer}
            isUpdate={""}
          />
        </DialogPopup>
        {/*<DialogPopup
                    title="Verify Animal & Finish"
                    subtitle="Fields"
                    open={this.state.organicModal}
                    onClose={(e) => () => this.setState({ organicModal: false })}
                    maxWidth='md'
                >
                    <div className="">
                        <div className="table-responsive">
                            <table className="table cstmGrzrTable">
                                <thead>
                                    <tr>
                                        <th>Species</th>
                                        <th>Tag Id</th>
                                        <th>Hang</th>
                                        <th>Date</th>
                                        <th>Organic</th>
                                        <th>Organs</th>
                                        <th>Sex</th>
                                        <th>Label</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.listOrganic.length === 0 && <tr><td colSpan="8">No data</td></tr>}
                                    {this.state.listOrganic.map((data, index) => (
                                        <>
                                            <tr>
                                                <td>{this.speciesLabel(data.species)}</td>
                                                <td>
                                                    <input
                                                        className="form-control"
                                                        type="text"
                                                        name="tagid"
                                                        value={data.tagid}
                                                        onChange={(e) =>
                                                            this.handleChangeOrganicData(e, index)
                                                        }>
                                                    </input>
                                                </td>
                                                <td>
                                                    <input
                                                        className="form-control"
                                                        type="number"
                                                        name="hang"
                                                        value={data.hang}
                                                        onChange={(e) =>
                                                            this.handleChangeOrganicData(e, index)
                                                        }>
                                                    </input>
                                                </td>
                                                <td>{data.date}</td>
                                                <td>
                                                    <select
                                                        name="organic"
                                                        type="text"
                                                        className="form-control"
                                                        value={data.organic}
                                                        onChange={(e) =>
                                                            this.handleChangeOrganicData(e, index)
                                                        }
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="yes">Y</option>
                                                        <option value="no">N</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <select
                                                        name="organs"
                                                        type="text"
                                                        className="form-control"
                                                        value={data.organs}
                                                        onChange={(e) =>
                                                            this.handleChangeOrganicData(e, index)
                                                        }
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="yes">Y</option>
                                                        <option value="no">N</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <select
                                                        name="sex"
                                                        type="text"
                                                        className="form-control"
                                                        value={data.sex}
                                                        onChange={(e) =>
                                                            this.handleChangeOrganicData(e, index)
                                                        }
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="male">Male</option>
                                                        <option value="female">Female</option>
                                                        <option value="heifer">Heifer</option>
                                                        <option value="steer">Steer</option>
                                                        <option value="bull">Bull</option>
                                                    </select>
                                                </td>
                                                <td>
                                                    <select
                                                        name="label"
                                                        type="text"
                                                        className="form-control"
                                                        value={data.label}
                                                        onChange={(e) =>
                                                            this.handleChangeOrganicData(e, index)
                                                        }
                                                    >
                                                        <option value="">Select</option>
                                                        <option value="custom">Custom</option>
                                                        <option value="usda">USDA</option>
                                                        <option value="organic">Organic</option>
                                                        <option value="private">Private</option>
                                                        <option value="generic">Generic</option>
                                                    </select>
                                                </td>
                                                <td></td>
                                            </tr>
                                        </>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="file-upload">
                        <h1 className="file-upload-title">Upload Documents to Certify Organic register </h1>
                        <div className="file-upload-btn">
                            <label>
                                <input type="file" onChange={this.selectFile1} />
                            </label>
                            <a onClick={this.uploadFile1} className="file-upload-btn-item">Upload</a>
                        </div>
                        <div className="file-upload-table">
                            {
                                this.state.listFile1.map((data) => (
                                    <div className="file-upload-table-item">
                                        <span>{data.name}</span>
                                        <span>Completed</span>
                                    </div>
                                ))
                            }
                            {
                                this.state.listFile1.length == 0 ? <span>No File Uploaded</span> : null
                            }
                        </div>

                        <h1 className="file-upload-title mt-5">Upload Documents for 30+ Age Verification </h1>
                        <div className="file-upload-btn">
                            <label>
                                <input type="file" onChange={this.selectFile2} />
                            </label>
                            <a onClick={this.uploadFile2} className="file-upload-btn-item">Upload</a>
                        </div>
                        <div className="file-upload-table">
                            {
                                this.state.listFile2.map((data) => (
                                    <div className="file-upload-table-item">
                                        <span>{data.name}</span>
                                        <span>Completed</span>
                                    </div>
                                ))
                            }
                            {
                                this.state.listFile2.length == 0 ? <span>No File Uploaded</span> : null
                            }
                        </div>
                    </div>
                    <div className='organic-form-btn' style={{ paddingBottom: '2rem', paddingTop: '3rem' }}>
                        <a onClick={() => this.setState({ organicModal: false })} className="btn btn-secondary mr-2" >Cancel</a>
                        <a onClick={() => this.setState({ organicModal: false })} className="btn btn-primary" >Save</a>
                    </div>
                </DialogPopup> */}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user, loggedIn } = state.login;

  return { user, loggedIn };
}

export default connect(mapStateToProps)(EditEvent);
