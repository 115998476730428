import React,{Component,useState,useEffect} from 'react';
import {connect} from 'react-redux';

// Featcher Icon Import (tick icon)
import Check from '../../../components/Check'

// Service Import
import Service from  '../../../Service/Service';

//slotpopup import
import { SlotPopup } from  '../../../components/SlotPopup/SlotPopup';

// Notification Imports
import {NOTIFICATION} from '../../../actions/index'

// Calender popup
import CalenderPopup from './CalenderSetuppopup.component';
// Imports for css
import './CalenderProcessor.styles.css';
import {currentServerTime} from '../../../util/socket';
import axios from 'axios';



function Days({num,clickHandle,name,arr,slotNo,cslotNo,handleSlotClick,token}){
  let html = [];
  let counter=0;
  let [popover,setPopover] = useState(false);
  for(let i=0;i<num;i++){
    html.push(
    <td key={i} className = {(arr&&arr.indexOf(i) > -1) ? 'active position-relative' : ''} >
      <span onClick={()=>clickHandle(i,name)}>
        <Check className="feather feather-check m-auto"/>
      </span>
      {(arr&&arr.indexOf(i) > -1)?
      <>
      {

      }
      {slotNo[arr.indexOf(i)] != 0 || (arr.indexOf(i, (arr.indexOf(i)+1)) !== -1 && slotNo[arr.indexOf(i, (arr.indexOf(i)+1))] != 0) ?
      <div className="slotNo blue text-white" 
      onClick={()=>clickHandle(i,name)} 
      >
        <span style={{fontSize:'.8rem'}}>
          <label >Slots:</label>
          <span>
             {slotNo[arr.indexOf(i)] != 0 ? slotNo[arr.indexOf(i)]: slotNo[arr.indexOf(i, (arr.indexOf(i)+1))]}
          </span> 
        </span>
        
      </div> : null
      }
      {(cslotNo[arr.indexOf(i)] != 0) || (arr.indexOf(i, (arr.indexOf(i)+1)) !== -1 && cslotNo[arr.indexOf(i, (arr.indexOf(i)+1))] != 0) ?
      <div className="slotNo green text-white" 
      onClick={()=>clickHandle(i,name)} 
      >
        <span style={{fontSize:'.8rem'}}>
          <label >Slots:</label>
          <span>
              {cslotNo[arr.indexOf(i)] != 0 ? cslotNo[arr.indexOf(i)]: cslotNo[arr.indexOf(i, (arr.indexOf(i)+1))]}
          </span> 
        </span>
        
      </div> : null
      }
      </>
      :null}
      </td>
      )
    counter+=1
    
  }
  return html;
}


class CalenderSetup extends Component{
  constructor(props) {
    super(props);
    this.state = {
      headers: ['', 'SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
      beef: [],
      goat: [],
      lamb: [],
      pork: [],
      bslotNo:[],
      gslotNo:[],
      lslotNo:[],
      pslotNo:[],
      bcslotNo:[],
      gcslotNo:[],
      lcslotNo:[],
      pcslotNo:[],
      prepopulatedids:{},
      action_center_data:[],
      actionitem1: false,
      current_popup_data: '',
      actionArr: '',
      idsArr: [],
      typem:'',
      currentServerTime:props.currentServerTime,
      nmfs_number:'',
      slots: [{
        processing_option : '',
        avail_slot:0
      }],
    }
  }
  componentDidMount() {

    axios.defaults.headers.common['Authorization'] = "Bearer " + this.props.user.user_token;
    let url = process.env.REACT_APP_BASE_URL;
    let self = this;
    const data = new FormData();
    data.append('user_id',this.props.user.id);
      axios.post(url+'dashboard/getUserDetails',data).then(function(response){
      if (response.data.status === "no") {
        // self.setState({
        //     error: response.data.message
        // })
        // self.props.dispatch(NOTIFICATION({
        //     error: "response.data.message"
        // }))
        return false;
    }
    let user_profile_data = response && response.data && response.data.data ? JSON.parse(atob(response.data.data.split('.')[1])) : '';
    response.data.data = user_profile_data;

        if(response.data.status === 'yes'){

            Object.keys(response.data.data).map((ele,ind)=>{
              console.log("object keys ", ele,"val",response.data.data[ele])
                                
                if(ele === 'nmfs_number'){
                  self.state.nmfs_number = (response.data.data['nmfs_number']) ? response.data.data['nmfs_number']: '';
                }

            })
        }
      });

    
    currentServerTime((data)=>{
      //console.log("current server time at mssag ",data);
      this.setState({
        currentServerTime:data
      })
    })
    console.log("this.props @ calender setup components --",this.props);
    this.getprocspref();
  }
  getprocspref(){
    let { loggedIn } = this.props;
    let {
      prepopulatedids,
    } = this.state;

    let beef = [];
    let goat = [];
    let pork = [];
    let lamb = [];
    let bslotNo = [];
    let gslotNo = [];
    let lslotNo = [];
    let pslotNo = [];
    let bcslotNo = [];
    let gcslotNo = [];
    let lcslotNo = [];
    let pcslotNo = [];
    if (loggedIn) {
      let { user: { user_token }, user: { id } ,user:{role:urole} } = this.props;
      let data = {
        processor_id: urole==='seller' ? this.props.processorId : id
      }
      new Service(user_token).post('/calendar/getProcessorPreference', data).then(({ status, message, data }) => {
        if (data && data.length > 0) {
          data.map(ele => {
            console.log("ele --------->",ele);
            switch (ele.animals) {
              case 1:
                beef = ele.days
                prepopulatedids[1] = ele.ids
                if (ele.slots.length > 0){
                  bslotNo = ele.slots 

                }
               
            
                if (ele.custom_slots.length > 0){
                  bcslotNo = ele.custom_slots 

                }
                break;
              case 2:
                goat = ele.days
                prepopulatedids[2] = ele.ids
                if (ele.slots.length > 0){
                  gslotNo = ele.slots 

                }
               
            
                if (ele.custom_slots.length > 0){
                  gcslotNo = ele.custom_slots 

                }
                break;
              case 3:
                lamb = ele.days
                prepopulatedids[3] = ele.ids
                if (ele.slots.length > 0){
                  lslotNo = ele.slots 

                }
               
            
                if (ele.custom_slots.length > 0){
                  lcslotNo = ele.custom_slots 

                }
                break;
              case 4:
                pork = ele.days
                prepopulatedids[4] = ele.ids
                if (ele.slots.length > 0){
                  pslotNo = ele.slots 

                }
               
            
                if (ele.custom_slots.length > 0){
                  pcslotNo = ele.custom_slots 

                }
                
               
                break;

            }
           
          });
        }

        this.setState({
          beef: beef,
          lamb: lamb,
          goat: goat,
          pork: pork,
          prepopulatedids:prepopulatedids,
          bslotNo: bslotNo,
          gslotNo: gslotNo,
          lslotNo: lslotNo,
          pslotNo: pslotNo,
          bcslotNo: bcslotNo,
          gcslotNo: gcslotNo,
          lcslotNo: lcslotNo,
          pcslotNo: pcslotNo,
        },()=>{console.log(this.state)})
      })
    }
  }
  handleSlotClick = (i,type) => {
    console.log('handleSlotClick',i,type)
  }
  hancleClick = (i, type) => {
    console.log('hancleClick',i,type)
    let {
      prepopulatedids,
      beef,
      goat,
      lamb,
      pork,
    } = this.state;
   
    let {dispatch,user:{role}} = this.props;
    if(role === 'seller')
      return;
    let actionArr = [];
    let slotNo = []
    let cslotNo = []
    let typem = ''
    console.log("type --> " + type);

    switch (type) {
      case 'beef':
        actionArr = beef;
        slotNo = this.state.bslotNo;
        cslotNo = this.state.bcslotNo;
        typem = 1;
        break;
      case 'goat':
        actionArr = goat;
        slotNo = this.state.gslotNo;
        cslotNo = this.state.gcslotNo;
        typem = 2;
        break;
      case 'lamb':
        actionArr = lamb;
        slotNo = this.state.lslotNo;
        cslotNo = this.state.lcslotNo;
        typem = 3;
        break;
      case 'pork':
        actionArr = pork;
        slotNo = this.state.pslotNo;
        cslotNo = this.state.pcslotNo;
        typem = 4;
        break;
    }
    // let index = actionArr.findIndex(ele => ele === i);

    // let deleteToggle = 0
    // if (index > -1) {
    //   actionArr.splice(index, 1)
    //   deleteToggle = 1
    // }
    // else {
    //   actionArr.push(i)
    //   deleteToggle = 0
    // }
    // if(deleteToggle==1) {
    //   let { user,dispatch } = this.props;
    //   let url = '/calendar/deleteprocessorPreference';
    //       console.log("url--> " + url);
    //       let { user_token } = user;
    //       let data = {
    //         preferences: typem,
    //         employee_name: i,
    //         server_date : this.state.currentServerTime.dTime
    //       }
    //      // window.location.reload();
    //       new Service(user_token).post(url, data).then(({ status, message }) => {
    //           if (status === 'yes') {
    //             this.setState({
    //               [type]: actionArr,
    //               ind: index
    //             })
    //           }
    //         });
         
    // } else {
    //   this.popupConctrol1(i,typem,deleteToggle);
    // }

    let slots = []
    function pushSlots(ind){
      if (slotNo[ind] != 0){
        slots.push({
          processing_option: 'USDA processing',
          avail_slot: slotNo[ind]
        })
      }
      if (cslotNo[ind] != 0){
        slots.push({
          processing_option: 'Custom processing',
          avail_slot: cslotNo[ind]
        })
      }
    }

    let index1 = actionArr.indexOf(i);
    let index2 = actionArr.lastIndexOf(i);
    if (index1 != -1){
      pushSlots(index1);
    } 
    if (index2 !== -1 && index2 != index1){
      pushSlots(index2);
    }

    console.log('processing', slots)
    this.setState({
      actionitem1: ! this.state.actionitem1,
      actionArr: i,
      type: typem,
      deleteToggle: 0,
      user: this.props.user,
      slots: slots
    });
  }

    openModalp = (event) => e => {
      e.preventDefault()
      this.setState({ actionitem1: event,
      });
    }

    openModalp1 = () => {
      
      this.setState({ actionitem1: false
      });
    }
    

  // insertCalender = (i, type, deleteToggle) => {
  //   let { user,dispatch } = this.props;
  //   // dispatch(NOTIFICATION({msg:''}))
  //   console.log("insert calender method");

  //   if (user) {
  //     let url = deleteToggle ? '/calendar/deleteprocessorPreference':'/calendar/processorPreference';
  //     let { user_token } = user;
  //     let data = {
  //       preferences: type,
  //       employee_name: i,
  //       server_date : this.state.currentServerTime.dTime,
  //       chk : "chk val"
  //     }
  //     console.log("data value -- ",data)
  //     return new Service(user_token).post(url, data)
  //   }
  // }
  // deleteCalender = (id) => {
  //   let { user } = this.props;
  //   if (user) {
  //     let url = '/calendar/deleteprocessorPreference';
  //     let { user_token } = user;
  //     let data = {
  //       id:id
  //     }
  //     return new Service(user_token).post(url, data)
  //   }
  // }

  fallback(rthis){
    rthis.setState({
      actionitem1 : false
    },()=>{
      rthis.getprocspref();
    })
  }
    render(){
      let {
            headers,
            beef,
            lamb,
            goat,
            pork,
            bslotNo,
            gslotNo,
            lslotNo,
            pslotNo,
            bcslotNo,
            gcslotNo,
            lcslotNo,
            pcslotNo,nmfs_number
          } = this.state;
      let {user:{user_token:token}} = this.props;
      
        return(
        <>
        <div className="tab-content" id="myTabContent">
                        <div className="tab-pane fade progress-form active show" id="nav-setup" role="tabpanel" aria-labelledby="nav-setup-tab">
                            <div className="agenta-calender">
                            <h4>Setup your calendar to let sellers know what animal you process on which day.</h4>
                            <div className="calender-res">
                              <div className="table-responsive my-4 " style={{overflow:'unset'}}>
                                <table className="table"> 
                                  <thead>
                                    <tr>
                                      {headers.map((ele,index)=>(
                                        <th key={index}>{ele}</th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr height="8"></tr>
                                    {nmfs_number.indexOf('beef')>-1?<tr>
                                      <td scope="col">
                                          <object data="./images/icon/beef.svg" type="image/svg+xml">
                                        </object>
                                      </td >
                                      <Days 
                                        name='beef' 
                                        arr={beef} 
                                        clickHandle={this.hancleClick} 
                                        num ={7} 
                                        slotNo={bslotNo}
                                        cslotNo={bcslotNo}
                                        handleSlotClick={this.handleSlotClick}
                                        token={token}
                                        />
                                    </tr>:null}
                                    {nmfs_number.indexOf('goat')>-1?<tr>
                                      <td scope="col">
                                        <object data="./images/icon/goat.svg" type="image/svg+xml"></object>
                                      </td>
                                      <Days 
                                        name='goat' 
                                        arr={goat} 
                                        clickHandle={this.hancleClick} 
                                        num ={7} 
                                        slotNo={gslotNo}
                                        cslotNo={gcslotNo}
                                        handleSlotClick={this.handleSlotClick}
                                        token={token}
                                        />
                                    </tr>:null}
                                    {nmfs_number.indexOf('lamb')>-1?<tr>
                                      <td scope="col">
                                          <object data="./images/icon/lamb.svg" type="image/svg+xml"></object>
                                      </td>
                                      <Days 
                                        name='lamb' 
                                        arr={lamb} 
                                        clickHandle={this.hancleClick} 
                                        num ={7} 
                                        slotNo={lslotNo}
                                        cslotNo={lcslotNo}
                                        handleSlotClick={this.handleSlotClick}
                                        token={token}
                                        />
                                    </tr>:null}
                                    {nmfs_number.indexOf('pork')>-1?<tr>
                                      <td scope="col">
                                          <object data="./images/icon/pork.svg" type="image/svg+xml"></object>
                                      </td>
                                      <Days 
                                        name='pork' 
                                        arr={pork} 
                                        clickHandle={this.hancleClick} 
                                        num ={7} 
                                        slotNo={pslotNo}
                                        cslotNo={pcslotNo}

                                        handleSlotClick={this.handleSlotClick}
                                        token={token}
                                        />
                                    </tr>:null}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                          </div>
                          </div>
                          {this.state.actionitem1 ?
                          <SlotPopup showModal1={this.state.actionitem1}
                                     closeModal={this.openModalp}
                                     actionArr = {this.state.actionArr}
                                    type = {this.state.type}
                                    deleteToggle = {this.state.deleteToggle}
                                    user = {this.state.user}
                                    openModalp1 = {this.state.openModalp1}
                                    fallback = {this.fallback}
                                    rthis={this}
                                    slots={this.state.slots}
                          /> : null}
                          
        </>
        )
    }
}

function mapStateToProps(state) {
  const { user, loggedIn } = state.login;
  return { user, loggedIn };
}

export default connect(mapStateToProps)(CalenderSetup);