export const deerParts = [
  {
    id: "organs",
    title: "Organs",
    fontSize: "0px",
    coordinates: { top: "15%", left: "10%" },
    rotation: 0,
    subcuts: [],
  },
  {
    id: "neck",
    title: "Neck",
    fontSize: "22px",
    coordinates: { top: "30%", left: "10%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "shoulder",
    title: "Shoulder Chuck",
    fontSize: "20px",
    coordinates: { top: "47%", left: "28%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "ribs",
    title: "Ribs",
    fontSize: "18px",
    coordinates: { top: "45%", left: "48%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "loin",
    title: "Loin/Backstrap",
    fontSize: "16px",
    coordinates: { top: "32%", left: "45%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "rump",
    title: "Rump",
    fontSize: "18px",
    coordinates: { top: "34%", left: "69%" },
    rotation: -20,
    subParts: [],
  },
  {
    id: "flank",
    title: "Flank",
    fontSize: "16px",
    coordinates: { top: "46%", left: "58.5%" },
    rotation: -92,
    subParts: [],
  },
  {
    id: "shank",
    title: "Shank",
    fontSize: "12px",
    coordinates: { top: "62%", left: "29%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "shank_leg",
    title: "Shank Leg",
    fontSize: "12px",
    coordinates: { top: "64%", left: "80%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "leg",
    title: "Leg/Round",
    fontSize: "18px",
    coordinates: { top: "50%", left: "75%" },
    rotation: -10,
    subParts: [],
  },
];
