export const elkParts = [
  {
    id: "organs",
    title: "Organs",
    fontSize: "0px",
    coordinates: { top: "15%", left: "10%" },
    rotation: 0,
    subcuts: [],
  },
  {
    id: "head",
    title: "Head",
    fontSize: "18px",
    coordinates: { top: "20%", left: "5%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "ear",
    title: "Ear",
    fontSize: "12px",
    coordinates: { top: "15%", left: "20%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "neck",
    title: "Neck",
    fontSize: "18px",
    coordinates: { top: "30%", left: "20%" },
    rotation: -60,
    subParts: [],
  },
  {
    id: "shoulder",
    title: "Shoulder",
    fontSize: "18px",
    coordinates: { top: "28%", left: "35%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "fore_leg",
    title: "Fore Leg",
    fontSize: "18px",
    coordinates: { top: "45%", left: "35%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "breast",
    title: "Breast",
    fontSize: "14px",
    coordinates: { top: "50%", left: "27%" },
    rotation: -120,
    subParts: [],
  },
  {
    id: "ribs",
    title: "Ribs",
    fontSize: "22px",
    coordinates: { top: "43%", left: "50%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "tenderloin",
    title: "TENDERLOIN",
    fontSize: "14px",
    coordinates: { top: "32%", left: "54%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "plate",
    title: "Plate",
    fontSize: "16px",
    coordinates: { top: "55%", left: "50%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "loin",
    title: "Loin",
    fontSize: "18px",
    coordinates: { top: "23%", left: "55%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "flank",
    title: "Flank",
    fontSize: "22px",
    coordinates: { top: "45%", left: "63%" },
    rotation: -95,
    subParts: [],
  },
  {
    id: "rump",
    title: "Rump",
    fontSize: "18px",
    coordinates: { top: "25%", left: "75%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "sirloin",
    title: "Sirloin",
    fontSize: "18px",
    coordinates: { top: "40%", left: "70%" },
    rotation: 70,
    subParts: [],
  },
  {
    id: "top_round",
    title: "Top Round",
    fontSize: "16px",
    coordinates: { top: "35%", left: "85%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "bottom_round",
    title: "Bottom Round",
    fontSize: "14px",
    coordinates: { top: "50%", left: "85%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "tail",
    title: "Tail",
    fontSize: "10px",
    coordinates: { top: "32%", left: "90%" },
    rotation: 60,
    subParts: [],
  },
  {
    id: "shank",
    title: "Shank",
    fontSize: "18px",
    coordinates: { top: "64%", left: "26.5%" },
    rotation: -45,
    subParts: [],
  },
  /* {
    id: "shank2",
    title: "Shank",
    fontSize: "15px",
    coordinates: { top: "65%", left: "38.5%" },
    rotation: 85,
    subParts: [],
  },
  {
    id: "shank3",
    title: "Shank",
    fontSize: "18px",
    coordinates: { top: "62%", left: "76%" },
    rotation: 55,
    subParts: [],
  },
  {
    id: "shank4",
    title: "Shank",
    fontSize: "18px",
    coordinates: { top: "62%", left: "88%" },
    rotation: 45,
    subParts: [],
  }, */
];
