import React, { Component } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';

class MaterialUIPickers extends Component {
  state = {
    date: new Date(),
  }
  onChange = date => {
    this.setState({ date })
  }

  tileDisabled = (e) => {
    let days = this.props.workingDays && this.props.workingDays.days || '';
    let block_days = this.props.block_days && this.props.block_days || '';
    if(block_days.length && block_days.indexOf(moment(e.date).format('D/M/YYYY')) >=0){
      return true;
    }  
    if (days.length) {
      return !(days.indexOf(e.date.getDay()) >= 0)
    }
    // else{
    //   return true;
    // }   
       
    
  }

  render() {
    console.log('calender render ',this.props)
    return (
      <div>
        <Calendar className="calender-margin"
          onChange={this.props.handler}
          value={this.state.date}
          minDate={new Date()}
          tileClassName="all-dates"
          tileDisabled={this.tileDisabled}
        />
      </div>
    );
  }
}


export default MaterialUIPickers;