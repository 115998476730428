import "react-form-builder2/dist/app.css";
import "../Buyer/BuyerDashboard.css";
import "./CutSheetForm.css";

import { LOGIN, NOTIFICATION } from "../../../actions";
import { MyInput, TestComponent } from "./CurSheetCustomComponents";
import React, { PureComponent } from "react";
import { ReactFormBuilder, ReactFormGenerator } from "react-form-builder2";

import { Button } from "@material-ui/core";
import { CalenderPopup } from "../../../components/CalenderPop/CalenderPopup";
import CutSheetAnimalComponent from "./CutSheetAnimalComponent";
import DialogPopup from "../../../components/Popup/DialogPopup";
import { Link } from "react-router-dom";
import NewOrder from "./NewOrder";
import Notification from "../../../components/Notification/Notification";
import Select from "react-select";
import Service from "../../../Service/Service";
import axios from "axios";
import { connect } from "react-redux";
import html2canvas from "html2canvas";
import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import moment from "moment";
import NewCutSheetView from "./order/NewCutSheetView";

class NewCutSheetForm extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      ispreview: false,
      form_submit_url:
        process.env.REACT_APP_BASE_URL + "cutsheet/submit_custom_form/",
      form_id: null,
      data_id: null,
      order_id: null,
      form_name: "",
      form_type: "",
      form_date: "",
      form_data_json: null,
      form_json: null,
      form_json_temp: null,
      preview: false,
      form_status: "",
      is_clone: false,
      is_edit: false,
      openOrderOptions: [],
      openOrderData: [],
      open_order_id: 0,
      isPortrait: window.innerHeight > window.innerWidth,
      open_order_data: {
        name: "",
        value: "",
      },
      is_ready: true,
      current_popup_data: "",
      working_days: "",
      viewcal: false,
      block_days: "",
      category_id: "",
      schedule_order: false,
      calendar_id: "",
      cutSheetData: [],
      btnloading: false,
    };
    this.handleOrientationChange = this.handleOrientationChange.bind(this);
  }
  handleOrientationChange() {
    this.setState({
      isPortrait: window.innerHeight > window.innerWidth,
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.handleOrientationChange);
  }
  componentDidMount() {
    let { location, match } = this.props;

    window.addEventListener("resize", this.handleOrientationChange);
    if (match.params.action && match.params.action == "preview") {
      this.setState({
        ispreview: true,
      });
    }
  }

  copyCutSheetData = async (form_id, data_id) => {
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.user.user_token;
    let url = process.env.REACT_APP_BASE_URL;
    let self = this;
    const data = new FormData();
    data.append("form_id", form_id);
    data.append("data_id", data_id);
    axios
      .post(url + "cutsheet/copy_custom_form_data", data)
      .then(function (response) {
        if (response.data.status) {
          if (response.data.result) {
            let list = [];
            response.data.result.data.forEach(function (element, i) {
              let type = "";
              switch (element.category_id) {
                case 1:
                  type = "Beef";
                  break;
                case 2:
                  type = "Goat";
                  break;
                case 3:
                  type = "Lamb";
                  break;
                default:
                  type = "Pork";
              }

              let data = {
                label:
                  "Order " +
                  element.order_id +
                  " (" +
                  type +
                  ") " +
                  element.title +
                  " - " +
                  element.first_name +
                  " " +
                  element.last_name +
                  " " +
                  moment(element.event_date).format("MM/DD/YYYY"),
                value: element.cart_id,
              };
              list.push(data);
            });
            let cutSheetData = {
              label:
                "Order " +
                self.state.order_id +
                " (" +
                self.state.form_type +
                ") " +
                self.state.form_name +
                " " +
                moment(self.state.form_date).format("MM/DD/YYYY") +
                " (" +
                self.state.form_status +
                ")",
              value: response.data.result.data_id,
            };

            self.setState({
              data_id: response.data.result.data_id,
              openOrderOptions: list,
              openOrderData: response.data.result.data,
              is_ready: true,
              cutSheetData: cutSheetData,
            });
          } else {
            self.props.dispatch(
              NOTIFICATION({
                msg: response.data.message || "something went wrong",
                msgColor: "#FAD5BD",
              })
            );
          }
        } else {
          self.props.dispatch(
            NOTIFICATION({
              msg: response.data.message || "something went wrong",
              msgColor: "#FAD5BD",
            })
          );
        }
        setTimeout(() => {
          self.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 3000);
      })
      .catch((error) => {
        self.props.dispatch(
          NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
        );
        setTimeout(() => {
          self.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 2000);
      });
  };

  handleSelectChange = (selectedOption) => {
    this.setState({
      open_order_data: selectedOption,
      open_order_id: selectedOption.value,
    });
  };

  newOrder = () => {
    this.props.dispatch(
      NOTIFICATION({
        msg: "Cut sheet attatched successfully",
      })
    );
    window.location.reload();
  };

  attachOrder = () => {
    if (this.state.open_order_id == 0) {
      this.props.dispatch(
        NOTIFICATION({
          msg: "Please select one order",
          msgColor: "#FAD5BD",
        })
      );
      setTimeout(() => {
        this.props.dispatch(NOTIFICATION({ msg: "" }));
      }, 3000);
      return false;
    }
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.user.user_token;
    let url = process.env.REACT_APP_BASE_URL;
    let self = this;

    let order = this.state.openOrderData.filter(function (item) {
      return item.cart_id == self.state.open_order_id;
    });

    let data = {
      cart_id: this.state.open_order_id,
      order_id: order.length > 0 ? order[0].order_id : 0,
      data_id: this.state.data_id,
      customer_id: order.length > 0 ? order[0].customer_id : 0,
      ng_order: order.length > 0 ? order[0].ng_order : 1,
    };
    axios
      .post(url + "cutsheet/update_custom_form_data", data)
      .then(function (response) {
        if (response.data.status) {
          self.props.dispatch(
            NOTIFICATION({
              msg: "Cut sheet attatched successfully",
            })
          );
          window.location.reload();
        } else {
          self.props.dispatch(
            NOTIFICATION({
              msg: response.data.message || "something went wrong",
              msgColor: "#FAD5BD",
            })
          );
        }
        setTimeout(() => {
          self.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 3000);
      })
      .catch((error) => {
        self.props.dispatch(
          NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
        );
        setTimeout(() => {
          self.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 2000);
      });
  };

  CalenderPopupConctrol = () => {
    if (this.props.user && this.props.user.card_profileid != "") {
      console.log("grazr pay is required  ----");
    } else {
      window.location.href = "/paymentgateway";
      return false;
    }

    let processor_id = this.props.user.id;
    // let category_id = this.state.livestock_type.data;

    document.body.classList.add("modal-open");
    let payload = {
      processor_id: processor_id,
    };
    let payload1 = {
      processor_id: processor_id,
      month_no: 1,
    };

    new Service(this.props.user.user_token)
      .post("/calendar/show_available_slot", payload1)
      .then(({ status, message, data }) => {
        let { availableslot } = data;
        let block_days = [];
        let dateToBeBlocked =
          availableslot &&
          availableslot.map(function (val) {
            if (val.withdraw === val.event_count) {
              let new_str = val.date_added.replace(/\T.+/g, "");
              block_days.push(moment(new_str).format("D/M/YYYY"));
            }
          });
        return block_days;
      })
      .then((block_days) => {
        new Service(this.props.user.user_token)
          .post("/calendar/getProcessorPreference", payload)
          .then((data) => {
            return {
              block_days: block_days,
              working_days: data.data,
            };
          });
      })
      .then((data) => {
        let popup_data = {
          cart_id: 0,
          processor_id: processor_id,
          seller_id: this.props.user.id,
        };
        this.setState({
          viewcal: true,
          current_popup_data: popup_data,
          ...data,
        });
      })
      .catch((error) => {
        this.props.dispatch(
          NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
        );
        setTimeout(() => {
          this.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 2000);
      });
  };

  closeModal = (event) => (e) => {
    e.preventDefault();
    if (event) {
      document.body.classList.add("modal-open");
    } else document.body.classList.remove("modal-open");
    this.setState({ viewcal: event });
  };

  closeEventAddpop = (toggle) => {
    this.setState(
      {
        addEventPopup: false,
      },
      () => {
        document.querySelector("body").style.overflow = "auto";
      }
    );
  };

  getCutSheetData = async (form_id, data_id) => {
    let url = process.env.REACT_APP_BASE_URL;

    let self = this;
    const data = new FormData();
    data.append("form_id", form_id);
    data.append("data_id", data_id);
    data.append("status", "1");
    axios
      .post(url + "cutsheet/get_custom_form_data", data)
      .then(function (response) {
        if (response.data.status) {
          if (response.data.result.data.length > 0) {
            self.setState({
              form_name: response.data.result.data[0].name,
              form_type: response.data.result.data[0].type,
              form_date: response.data.result.data[0].date_added,
              form_id: form_id,
              data_id: data_id,
              order_id: response.data.result.data[0].order_id,
              form_json:
                response.data.result.data[0].form_json != ""
                  ? JSON.parse(response.data.result.data[0].form_json)
                  : null,
              preview: false,
              form_status: response.data.result.data[0].form_status,
              form_data_json:
                response.data.result.data[0].form_data_json != ""
                  ? JSON.parse(response.data.result.data[0].form_data_json)
                  : null,
            });
            let { location, match } = self.props;

            if (
              (match.params.action && match.params.action == "edit") ||
              (match.params.action && match.params.action == "clone")
            ) {
              self.copyCutSheetData(form_id, data_id);
            }
          } else {
            self.props.dispatch(
              NOTIFICATION({
                msg: response.data.message || "something went wrong",
                msgColor: "#FAD5BD",
              })
            );
          }
        } else {
          self.props.dispatch(
            NOTIFICATION({
              msg: response.data.message || "something went wrong",
              msgColor: "#FAD5BD",
            })
          );
        }
        setTimeout(() => {
          self.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 3000);
        return response.data.status;
      })
      .catch((error) => {
        self.props.dispatch(
          NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
        );
        setTimeout(() => {
          self.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 2000);
      });
  };

  getCutSheetTemp = async (form_id) => {
    let url = process.env.REACT_APP_BASE_URL;

    let self = this;
    const data = new FormData();

    data.append("form_id", form_id);
    axios
      .post(url + "cutsheet/get_custom_form", data)
      .then(function (response) {
        if (response.data.status) {
          if (response.data.result.data.length > 0) {
            self.setState({
              form_name: response.data.result.data[0].name,
              form_type: response.data.result.data[0].type,
              form_id: form_id,
              form_json:
                response.data.result.data[0].form_json != ""
                  ? JSON.parse(response.data.result.data[0].form_json)
                  : null,
              form_json_temp:
                response.data.result.data[0].form_json_temp != ""
                  ? JSON.parse(response.data.result.data[0].form_json_temp)
                  : null,
              preview: true,
            });
          } else {
            self.props.dispatch(
              NOTIFICATION({
                msg: response.data.message || "something went wrong",
                msgColor: "#FAD5BD",
              })
            );
          }
        } else {
          self.props.dispatch(
            NOTIFICATION({
              msg: response.data.message || "something went wrong",
              msgColor: "#FAD5BD",
            })
          );
        }
        setTimeout(() => {
          self.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 3000);
        return response.data.status;
      })
      .catch((error) => {
        self.props.dispatch(
          NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
        );
        setTimeout(() => {
          self.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 2000);
      });
  };

  CutSheetFormSubmit = (form) => {
    console.log("CutSheetFormSubmit", form);
    let url = process.env.REACT_APP_BASE_URL;

    let self = this;
    const data = new FormData();
    data.append("data_id", this.state.data_id);
    data.append("form_id", this.state.form_id);
    var form_data = {};
    form.map((body) => {
      form_data[body.name] = body.value;
    });
    data.append("form_data", JSON.stringify(form_data));
    axios
      .post(url + "cutsheet/submit_custom_form", data)
      .then(function (response) {
        console.log(response);
        if (response.data.status) {
          console.log("ok");
          self.props.dispatch(
            NOTIFICATION({
              msg: "Cut Sheet form submit successfully",
            })
          );
        } else {
          self.props.dispatch(
            NOTIFICATION({
              msg: response.data.message || "something went wrong",
              msgColor: "#FAD5BD",
            })
          );
        }
        setTimeout(() => {
          self.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 3000);
      })
      .catch((error) => {
        self.props.dispatch(
          NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
        );
        setTimeout(() => {
          self.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 2000);
      });
  };
  printPage = () => {
    this.setState({
      btnloading: true,
    });
    let self = this;
    html2canvas(document.getElementById("capture"), { useCORS: true }).then(
      (canvas) => {
        var imgData = canvas.toDataURL("image/png");
        var printWindow = window.open("");

        // Write the HTML for the print window
        printWindow.document.write("<html><head><title>Cutsheet</title>");
        printWindow.document.write("</head><body>");

        // Embed the image in the print window
        printWindow.document.write(
          '<div style="width: 100%; height: 100%; display: flex;flex-direction: row;align-items: start;align-content: start; scale: 1">'
        );
        printWindow.document.write(
          '<img src="' + imgData + '" style="width:100%;height:auto;" />'
        );
        printWindow.document.write("</div>");
        printWindow.document.write("</body></html>");

        // Close the document to finish writing
        printWindow.document.close();

        // Trigger print once the content has loaded
        printWindow.onload = function () {
          printWindow.focus();
          printWindow.print();

          // Close the window after printing
          setTimeout(function () {
            printWindow.close();
            self.setState({
              btnloading: false,
            });
          }, 500); // Adjust the delay if needed
        };
      }
    );
  };
  exportPdf = () => {
    this.setState({
      btnloading: true,
    });
    let self = this;
    const element = document.getElementById("capture");
    html2pdf()
      .set({
        filename: "cutsheet.pdf",
        margin: 0.2,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 1 },
        jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
      })
      .from(element)
      .save();
    this.setState({
      btnloading: false,
    });
  };

  render() {
    const { isPortrait } = this.state;
    let json_token =
      this && this.props && this.props.user && this.props.user.user_token
        ? this.props.user.user_token
        : "";

    return (
      <>
        <div className="G-zr" style={{ height: "430px" }}>
          {isPortrait && (
            <div className="landscape-warning active">
              Please rotate your device to landscape mode for the best
              experience.
            </div>
          )}
          {/* <div className="container">
            <button
              className="btn btn-primary print_button d-inline"
              style={{ backgroundColor: "#4FD1C5", border: 0 }}
              // onClick={() => window.print()}
              onClick={() => this.printPage()}
            >
              Print
            </button>
            <button
              className="btn btn-primary pull-right print_button"
              style={{ backgroundColor: "#4FD1C5", border: 0 }}
              onClick={(e) => this.exportPdf()}
              disabled={this.state.btnloading ? true : false}
            >
              Download
            </button>
          </div>
 */}
          <div style={{ display: isPortrait ? "none" : "block" }}>
            <div className="container" id="capture">
              <div className="tabVwWrapper">
                {this.state.order_id ? (
                  <h3 className="hdrCntr">
                    Order {this.state.order_id} ({this.state.form_type}){" "}
                    {this.state.form_name}{" "}
                  </h3>
                ) : (
                  <h3 className="hdrCntr">
                    Cut Sheet - ({this.state.form_type}) {this.state.form_name}{" "}
                  </h3>
                )}
              </div>

              <CutSheetAnimalComponent
                dataId={this.props.match.params.data_id}
                formId={this.props.match.params.form_id}
                ispreview={this.state.ispreview}
              />
            </div>

            {(this.state.is_edit || this.state.is_clone) && (
              <div className="container" style={{ marginTop: "30px" }}>
                <div className="tabVwWrapper">
                  <div className="row">
                    <div className="col-md-12">
                      <label>Select Open Order</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-9">
                      <Select
                        value={this.state.open_order_data}
                        options={this.state.openOrderOptions}
                        name="open_order"
                        id="open_order"
                        placeholder="Select Open Order"
                        onChange={this.handleSelectChange}
                      />
                    </div>
                    <div className="col-md-3">
                      <button
                        className="btn btn-green"
                        onClick={(e) => this.attachOrder()}
                      >
                        Attach to order
                      </button>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <label>OR</label>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-3">
                      <button
                        className="btn btn-green"
                        // onClick={(e) => this.CalenderPopupConctrol()}
                        onClick={(e) => this.setState({ newOrder: true })}
                      >
                        Create new order
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.state.viewcal ? (
              <CalenderPopup
                showModal={this.state.viewcal}
                closeModal={this.closeModal}
                jsonToken={json_token}
                currentPopupData={this.state.current_popup_data}
                working_days={this.state.working_days}
                userRole={this.props.user.role}
                category_id={this.state.category_id}
                block_days={this.state.block_days}
                closepopup={(e) => {
                  this.setState({ viewcal: false });
                }}
                schedule_date={false}
                cut_sheet_data_id={this.state.data_id}
                cut_sheet_data={this.state.cutSheetData}
                cut_sheet_data_submit={this.newOrder}
                {...this.props}
              />
            ) : (
              ""
            )}

            {this.state.newOrder && (
              <DialogPopup
                title="New Order"
                subtitle={this.state.currentDate}
                maxWidth="lg"
                open={this.state.newOrder}
                onClose={(e) => () => this.setState({ newOrder: false })}
                style={{ marginTop: "5rem" }}
              >
                <NewOrder
                  onClose={() => this.setState({ newOrder: false })}
                  attach_cut_sheet={this.state.data_id}
                ></NewOrder>
              </DialogPopup>
            )}

            <Notification />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    login: { loggedIn },
    login: { user },
  } = state;
  return { loggedIn, user };
};
export default connect(mapStateToProps)(NewCutSheetForm);
