import "./Login.css";

import { LOGIN, LOGIN_FAILURE, LOGOUT } from "../../actions";
import React, { Component } from "react";
import {
  faArrowRight,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";

import Cookies from "universal-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import LoginSlider from "../../components/LoginSlider";
import { Switch } from "@material-ui/core";
import axios from "axios";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";

// Correct import for @material-ui v4

const cookies = new Cookies();
const GreenSwitch = withStyles({
  switchBase: {
    color: "#3C923F",
    "&$checked": {
      color: "#3C923F",
    },
    "&$checked + $track": {
      backgroundColor: "#3C923F",
    },
  },
  checked: {},
  track: {},
})(Switch);

class Login extends Component {
  state = {
    username: {
      data: "",
      required: true,
      errorMsg: "Required",
    },
    password: {
      data: "",
      required: true,
      errorMsg: "Required",
    },
    rememberPassword: false,
    error: "",
    formError: {},
    checked: false,
    loader: false,
    showPassword: false, // Add state for password visibility
  };
  handelChecked() {
    if (this.state.rememberPassword) {
      let accountInfo =
        this.state.username.data + "&" + this.state.password.data;

      let Days = 3;
      let exp = new Date();
      exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
      document.cookie =
        "accountInfo" +
        "=" +
        escape(accountInfo) +
        ";expires=" +
        exp.toGMTString();
    } else {
      let exp = new Date();
      exp.setTime(exp.getTime() - 1);
      let accountInfo = document.cookie;
      var cookie_pos = accountInfo.indexOf("accountInfo");

      if (cookie_pos != -1) {
        document.cookie =
          "accountInfo" + "=" + " " + ";expires=" + exp.toGMTString();
      }

      this.state.name.data = "";
      this.state.password.data = "";
    }
  }

  //判断cookie中是否有账号信息，有就可以进行预填写，没有则直接返回
  loadAccountInfo = () => {
    //读取cookie
    let arr,
      reg = new RegExp("(^| )" + "accountInfo" + "=([^;]*)(;|$)");
    let accountInfo = "";
    if ((arr = document.cookie.match(reg))) {
      accountInfo = unescape(arr[2]);
    } else {
      accountInfo = null;
    }

    if (Boolean(accountInfo) == false) {
      return false;
    } else {
      let userName = "";
      let passWord = "";

      let i = new Array();
      i = accountInfo.split("&");
      userName = i[0];
      passWord = i[1];

      this.state.username.data = userName;
      this.state.password.data = passWord;
      this.setState({
        rememberPassword: true,
      });
    }
  };

  componentDidMount() {
    let check = localStorage.getItem("checked") === "true";
    let user = localStorage.getItem("username");
    let pass = localStorage.getItem("password");
    if (user != "" && user != undefined && user != null) {
      this.state.username.data = user;
      this.state.password.data = pass;
      this.state.checked = check;
    }
    this.setState({});
    //   let userDatainCookie = cookies.get('userdata')
    //   if(userDatainCookie){
    //   if(Object.keys(userDatainCookie).length > 0){
    //       this.state.username.data = userDatainCookie.username
    //       this.state.password.data = userDatainCookie.password
    //       this.setState({
    //           username : this.state.username , password : this.state.password , checked:true
    //       });

    //   }
    // }
    //   localStorage.removeItem('user');
    //   this.props.dispatch(LOGOUT({}))
  }

  handleValidation() {
    let self = this;
    Object.keys(this.state).map(function (key) {
      if (self.state[key].required && self.state[key].data === "") {
        self.state.formError[key] = self.state[key].errorMsg
          ? self.state[key].errorMsg
          : "Required!";
      }
      self.setState(
        {
          formError: self.state.formError,
        },
        () => {
          console.log("qwerty========");
        }
      );
    });
  }

  formSubmit(e) {
    e.preventDefault();
    //console.log(cookies.get('userdata'));
    let self = this;
    self.setState({
      loader: true,
    });
    this.handleValidation();
    let payload = {};
    if (
      Object.keys(self.state.formError).length > 0 ||
      Object.keys(self.state.formError) === "undefined"
    ) {
      self.setState({
        loader: false,
      });
      return false;
    }
    Object.keys(self.state).map(function (key) {
      payload[key] = self.state[key].data;
    });
    //console.log("url is"+ process.env.REACT_APP_BASE_URL)
    let url = process.env.REACT_APP_BASE_URL;
    console.log("url is" + url);
    axios
      .post(url + "auth/login", payload)
      .then(function (response) {
        console.log("res " + response.data.status);
        if (response.data.status == "no") {
          self.setState({
            error: response.data.message,
            loader: false,
          });
          self.props.dispatch(
            LOGIN_FAILURE({
              error: response.data.message,
            })
          );
          return false;
        }
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        // window.fcWidget.user.setFirstName(response.data.username);
        // window.fcWidget.user.setEmail(response.data.userdata.email);
        let userdata =
          response && response.data && response.data.data
            ? JSON.parse(atob(response.data.data.split(".")[1]))
            : "";
        userdata.user_token = response.data.data;
        if (userdata.user_token) {
          self.setState({
            loader: false,
          });
        }
        localStorage.setItem("user", JSON.stringify(userdata));
        if (self.state.checked) {
          localStorage.setItem("checked", self.state.checked);
          localStorage.setItem("username", self.state.username.data);
          localStorage.setItem("password", self.state.password.data);
        } else {
          localStorage.setItem("username", "");
          localStorage.setItem("password", "");
        }
        //localStorage.setItem('user_token', response.data.data);
        self.props.dispatch(LOGIN(userdata));
        switch (userdata.role) {
          case "processor":
            self.props.history.push("/processordashboard");
            break;
          case "admin":
            self.props.history.push("/processordashboard");
            break;
          case "butcher":
            self.props.history.push("/processordashboard");
            break;
          case "manager":
            self.props.history.push("/processordashboard");
            break;

          case "seller":
            self.props.history.push("/sellerdashboard");
            break;
          case "customer":
          case "producer":
            if (userdata.enablecp == 1) {
              self.props.history.push("/processordashboard");
            } else {
              self.setState({
                error:
                  "You do not have access to the the customer portal. Contact admin.",
                loader: false,
              });
              break;
            }
            break;
          default:
            self.props.history.push("/buyerdashboard");
            break;
        }
      })
      .catch(function (error) {
        self.setState({
          error: "Try later!",
          loader: false,
        });
        return false;
      });
  }

  handleChange = (name) => (event) => {
    let preState = this.state[name];
    if (this.state.formError[name]) {
      delete this.state.formError[name];
    }
    preState.data = event.target.value;
    this.setState({
      [name]: preState,
      formError: this.state.formError,
      error: "",
    });
  };

  handleClick = (name) => (e) => {
    this.setState({
      checked: !this.state.checked,
    });
  };

  togglePasswordVisibility = () => {
    this.setState((prevState) => ({
      showPassword: !prevState.showPassword,
    }));
  };

  closeToggle = (path) => (event) => {
    const { name } = event.target;
    this.setState(() => {
      this.props.history.push(path);
    });
  };

  render() {
    const {
      username,
      password,
      formError,
      error,
      loader,
      checked,
      showPassword,
    } = this.state;
    return (
      <div className="login-page">
        <div className="row m-auto w-100">
          <div
            className="col-lg-7 col-md-6 col-sm-12"
            style={{ flex: "0 0 55.333333%" }}
          >
            <div className="form-right">
              <h1 className="login-title">Welcome Back</h1>
              <span style={{ color: "#A0AEC0", fontSize: "14px" }}>
                Enter your email and password to sign in
              </span>
              <div className="G-form pb-3 py-4">
                <form className="form" onSubmit={this.formSubmit.bind(this)}>
                  <div className="form-input">
                    <div className="form-group mb-4">
                      <label className="G-label" style={{ fontSize: "14px" }}>
                        Email
                      </label>
                      <div className="input-login">
                        <img
                          src="./images/user-icon.svg"
                          className="input-login-icon img-fluid mr-2"
                          alt="geo"
                          width="18"
                          height="18"
                        />
                        <input
                          style={{ marginRight: "1em", fontSize: "14px" }}
                          className="input-login-form"
                          type="email"
                          placeholder="Your Email Address"
                          name="username"
                          value={this.state.username.data}
                          onChange={this.handleChange("username")}
                          autoFocus
                        />
                      </div>
                      <span style={{ color: "red" }}>
                        {this.state.formError["username"]}
                      </span>
                    </div>
                    <div className="form-group mb-4">
                      <label className="G-label" style={{ fontSize: "14px" }}>
                        Password
                      </label>
                      <div className="input-login">
                        <img
                          src="./images/password-icon.svg"
                          className="input-login-icon img-fluid mr-2"
                          alt="geo"
                          width="18"
                          height="18"
                        />
                        <input
                          style={{ marginRight: "1em", fontSize: "14px" }}
                          className="input-login-form"
                          type={showPassword ? "text" : "password"}
                          placeholder="Your Password"
                          name="password"
                          value={this.state.password.data}
                          onChange={this.handleChange("password")}
                        />
                        <FontAwesomeIcon
                          icon={showPassword ? faEye : faEyeSlash}
                          className="password-eye-icon"
                          style={{ color: "grey" }}
                          onClick={this.togglePasswordVisibility}
                        />
                      </div>
                      <span style={{ color: "red" }}>
                        {this.state.formError["password"]}
                      </span>
                    </div>
                  </div>

                  <div
                    className="form-group"
                    style={{
                      alignItems: "baseline",
                    }}
                  >
                    <label className="switch" style={{ marginRight: "7rem" }}>
                      <GreenSwitch
                        name="remember"
                        onChange={this.handleClick("remember")}
                        checked={this.state.checked}
                      />
                      <span
                        className="slider round"
                        style={{ fontSize: "12px" }}
                      >
                        Remember me
                      </span>
                    </label>
                    <span className="mt-3" style={{ fontSize: "12px" }}>
                      <Link
                        style={{ color: "#4FD1C5" }}
                        className="ml-1"
                        to="/forgotPassword"
                      >
                        Forgot Password?
                      </Link>
                    </span>
                  </div>
                  {this.state.error ? (
                    <div className="alert alert-danger" role="alert">
                      {this.state.error}
                    </div>
                  ) : (
                    ""
                  )}
                  {this.state.loader ? (
                    <div className="cp-spinner cp-bubble"></div>
                  ) : (
                    <button
                      type="submit"
                      className="btn-login1"
                      onMouseOver={(e) =>
                        (e.target.style.backgroundColor = "#3C923F")
                      }
                      onMouseOut={(e) =>
                        (e.target.style.backgroundColor = "#3C923F80")
                      }
                    >
                      Sign In
                    </button>
                  )}
                </form>
              </div>
              <div className="mt-3 dont-have-account">
                <span style={{ fontSize: "14px", marginLeft: "9%" }}>
                  I don’t have an account
                  <Link
                    style={{ color: "#4FD1C5", fontSize: "15px" }}
                    className="ml-1"
                    to="/register"
                  >
                    Sign Up
                  </Link>
                </span>
              </div>
            </div>
          </div>
          <div className="col-lg-5 p-0 col-md-6 col-sm-12 order-lg-0 order-md-0 order-sm-1 order-xs-1">
            <div
              className="form-left1"
              style={{ backgroundImage: "url(./images/login_background.png)" }}
            >
              {/* Your left content */}
            </div>
          </div>
        </div>
        <footer className="footer1">
          <span className="msg-copyright" style={{ fontSize: "14px" }}>
            Copyright © 2023 Grazr
          </span>
        </footer>
      </div>
    );
  }
}
function mapStateToProps(state) {
  const { user, loggedIn } = state.login;
  return { user, loggedIn };
}

export default connect(mapStateToProps)(Login);
