import React from 'react'

import CalenderSetup from '../../views/ProcessorDashboard/CalenderSetup/CalenderSetup.component';
import MyAgenda from '../../views/ProcessorDashboard/CalenderSetup/MyAgenda.component';
import '../../views/ProcessorDashboard/ProcessorDashCalender.css';
class ClenderComponent extends React.Component{
    constructor(props){
        super(props)
        this.state={
            tab:1
        }
    }
    tabChange=(tab)=>e=>{
        this.setState({
            tab : tab
        })
    }
    render(){
        let {tab} = this.state;
        let activeClass = 'nav-item nav-link activepnav'
        let nactiveClass = 'nav-item nav-link'
        return (
            <>
            <div className="G-zr pt-1 p-3" style={{overflow:'auto'}}>
                <div className="col-md-12">
                    <div className="Dash-right">
                        <MyAgenda  {...this.props} isRunProcessor={true}/>
                    </div>
                </div>
            </div>
            </>
        )
    }
}

export default ClenderComponent;