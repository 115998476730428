import "../Buyer/BuyerDashboard.css";
import "../../Dashboard/Dashboard.css";

import React, { Component, useEffect, useState } from "react";
import { faGear, faInfo, faPlus } from "@fortawesome/free-solid-svg-icons";

import DialogPopup from "../../../components/Popup/DialogPopup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import NewOrder from "../../Dashboard/Processor/NewOrder";
import PrivacyPolicyData from "../../privacypolicy/PrivacyPolicyData";
import TermsOfServiceData from "../../termscondition/TermsOfServiceData";
import { connect } from "react-redux";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-solid-svg-icons";

const ProcDashLayout = (props) => {
  const data = props.data;
  // const user = JSON.parse(localStorage.getItem('user'));
  const user = props.user;
  const [isOpen, setIsOpen] = useState(false);
  const [currentDate, setCurrentDate] = useState(null);
  const [isOpenVersion, setIsOpenVersion] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [privacyPopup, setPrivacyPopup] = useState(false);
  const [termsPopup, setTermsPopup] = useState(false);

  const [response, setResponse] = useState("");

  async function fetchData() {
    let url = process.env.REACT_APP_BASE_URL;
    try {
      const headers = {
        Authorization: "Bearer " + props.user.user_token,
        "Content-Type": "application/json",
      };
      const response = await fetch(url + "qblogin", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          redirectUri: process.env.REACT_APP_URL,
        }), // You may need to provide the request body here if required
      });
      if (response.ok) {
        const responseData = await response.json();
        window.location.href = responseData.url;
        const responseData1 = await response.text();
        console.log("Response Data:", responseData1);
      } else {
        console.error("Failed to fetch data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  }

  const fetchDataValidate = async () => {
    localStorage.setItem("auth_token", props.user.user_token);
    try {
      let accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        /*  console.log("No Auth token" + localStorage.getItem("access_token"));
        console.log(
          JSON.stringify({
            refresh_token: refresh_token,
            x_refresh_token_expires_in: x_refresh_token_expires_in,
            access_token: accessToken,
            token_type: token_type,
            expires_in: expires_in,
          })
        ); */
        fetchData();
        // If access token doesn't exist, do nothing
        return;
      }
      const expires_in = localStorage.getItem("expires_in");
      const refresh_token = localStorage.getItem("refresh_token");
      const token_type = localStorage.getItem("token_type");
      const x_refresh_token_expires_in = localStorage.getItem(
        "x_refresh_token_expires_in"
      );

      // Make API call to validate access token
      let url = process.env.REACT_APP_BASE_URL + "qbvalidate";
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + props.user.user_token,
      };
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          refresh_token: refresh_token,
          x_refresh_token_expires_in: x_refresh_token_expires_in,
          access_token: accessToken,
          token_type: token_type,
          expires_in: expires_in,
        }),
      });

      if (response.status == 200) {
        const responseData = await response.json();
        console.log("Response Data:", responseData);
        // Handle successful response if needed
      } else {
        // Handle error response
        console.error("Failed to validate access token:", response.statusText);
        // Reset access token and other related values in local storage
        localStorage.removeItem("access_token");
        localStorage.removeItem("expires_in");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("token_type");
        localStorage.removeItem("x_refresh_token_expires_in");
        fetchData();
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  };

  useEffect(() => {
    updateCurrentDate();
    // fetchDataValidate(); // Call fetchDataValidate only once on component mount

    function handleWindowResize() {
      setIsMobile(window.innerWidth < 768);
    }

    window.addEventListener("resize", handleWindowResize);

    handleWindowResize();

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []); // Empty dependency array ensures that this effect runs only once on component mount

  const updateCurrentDate = () => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    const currentDate = new Date()
      .toLocaleDateString("en-US", options)
      .replaceAll("/", " ");

    setCurrentDate(currentDate);
  };

  return (
    <>
      {!isMobile ? (
        <div className="G-zr top-h dashLayoutWrapper">
          {props.title && (
            <div className="filter-right bg-white text-center">
              <div className="col-md-12">
                <h4 className="bg-white txt-green fw-sm fs-20 pt-5 m-0">
                  {" "}
                  {props.title}
                </h4>
              </div>
            </div>
          )}
          <div
            className={`d-flex align-items-start flex-mb-wrap ${
              window.location.pathname === "/processordashboard"
                ? "mnDashLayoutWrpr"
                : "mnDashLayoutWrpr"
            }`}
          >
            <div className="main-dash seller-D sdNavWrpr">
              {[
                "processor",
                "admin",
                "manager",
                "producer",
                "customer",
              ].indexOf(user.role) !== -1 && (
                <div
                  // to="/neworder"
                  className={`d-flex mb-4`}
                >
                  <a
                    className="left-menu-item-create-new"
                    onClick={() => setIsOpen(true)}
                  >
                    <FontAwesomeIcon
                      className="mr-3"
                      style={{ color: "#367BF5", fontSize: "22px" }}
                      icon={faPlusCircle}
                    />
                    <h1>Create new Order</h1>
                  </a>
                </div>
              )}
              {["producer", "customer"].indexOf(user.role) !== -1 && (
                <Link
                  to="/processordashboard/requestedOrder"
                  className={`d-flex ${
                    window.location.pathname ===
                      "/processordashboard/requestedOrder" && "active"
                  }`}
                >
                  <div className="left-menu-item">
                    <img
                      src="/images/clock.svg"
                      className="left-menu-item-icon"
                      width="22"
                      height="22"
                    />
                    <div className="left-menu-item-text">
                      <h1 className="">Order Requests</h1>
                      <span>View all requested orders in one place</span>
                    </div>
                  </div>
                  {/* {data.tile_count_loader && data.tile_count_loader ? (
                <div className="cp-spinner cp-bubble"></div>
              ) : (
                <h3 className=" mb-0 cntNumber fw-sm montserrat">
                  {data.procOrdersCount}
                </h3>
              )} */}
                </Link>
              )}
              <Link
                to="/processordashboard/pendingOrders"
                className={`d-flex ${
                  window.location.pathname ===
                    "/processordashboard/pendingOrders" && "active"
                }`}
              >
                <div className="left-menu-item">
                  <img
                    src="/images/icon-pending-order.svg"
                    className="left-menu-item-icon"
                    width="22"
                    height="22"
                  />
                  <div className="left-menu-item-text">
                    <h1 className="">Pending Orders</h1>
                    <span>View all scheduled orders in one place</span>
                  </div>
                </div>
                {/* {data.tile_count_loader && data.tile_count_loader ? (
                <div className="cp-spinner cp-bubble"></div>
              ) : (
                <h3 className=" mb-0 cntNumber fw-sm montserrat">
                  {data.procOrdersCount}
                </h3>
              )} */}
              </Link>
              <Link
                to="/processordashboard/openOrders"
                className={`d-flex ${
                  window.location.pathname ===
                    "/processordashboard/openOrders" && "active"
                }`}
              >
                <div className="left-menu-item">
                  <img
                    src="/images/icon-open-order.svg"
                    className="left-menu-item-icon"
                    width="22"
                    height="22"
                  />
                  <div className="left-menu-item-text">
                    <h1 className="">Open Orders</h1>
                    <span>View all open orders in one place</span>
                  </div>
                </div>
              </Link>
              {["customer", "producer"].indexOf(user.role) !== -1 && (
                <Link
                  to="/processordashboard/completedOrders"
                  className={`d-flex ${
                    window.location.pathname ===
                      "/processordashboard/completedOrders" && "active"
                  }`}
                >
                  <div className="left-menu-item">
                    <img
                      src="/images/icon-open-order.svg"
                      className="left-menu-item-icon"
                      width="22"
                      height="22"
                    />
                    <div className="left-menu-item-text">
                      <h1 className="">Completed Orders</h1>
                      <span>View all completed orders in one place</span>
                    </div>
                  </div>
                </Link>
              )}
              {["processor", "admin", "manager", "producer"].indexOf(
                user.role
              ) !== -1 && (
                <Link
                  to="/allCustomer"
                  className={`d-flex ${
                    window.location.pathname === "/allCustomer" && "active"
                  }`}
                >
                  <div className="left-menu-item">
                    <img
                      src="/images/icon-all-customer.svg"
                      className="left-menu-item-icon"
                      width="22"
                      height="22"
                    />
                    <div className="left-menu-item-text">
                      <h1 className="">All Customers</h1>
                      <span>View all customers information</span>
                    </div>
                  </div>
                </Link>
              )}
              {["processor", "admin", "manager"].indexOf(user.role) !== -1 && (
                <Link
                  to="/processorCutSheet"
                  className={`d-flex ${
                    window.location.pathname === "/processorCutSheet" &&
                    "active"
                  }`}
                >
                  <div className="left-menu-item">
                    <img
                      src="/images/icon-cut-sheet.svg"
                      className="left-menu-item-icon"
                      width="22"
                      height="22"
                    />
                    <div className="left-menu-item-text">
                      <h1 className="">Cut Sheets</h1>
                      <span>View all cut sheets at one place</span>
                    </div>
                  </div>
                </Link>
              )}
              {["processor", "admin", "manager"].indexOf(user.role) !== -1 && (
                <Link
                  to="/reports"
                  className={`d-flex ${
                    window.location.pathname === "/reports" && "active"
                  }`}
                >
                  <div className="left-menu-item">
                    <img
                      src="/images/icon-report.svg"
                      className="left-menu-item-icon"
                      width="22"
                      height="22"
                    />
                    <div className="left-menu-item-text">
                      <h1 className="">Reports</h1>
                      <span>Get insights into the big data</span>
                    </div>
                  </div>
                </Link>
              )}
              <div className="left-menu-profile">
                <img
                  src={
                    user.avatar != null && user.avatar
                      ? process.env.REACT_APP_ROOT_URL +
                        "uploads/" +
                        user.avatar
                      : "/images/No_Image_Available.jpg"
                  }
                  className="left-menu-item-icon-img"
                  alt="profile"
                  width="20"
                  height="20"
                />
                <h1>{user.first_name}</h1>
              </div>
              <hr
                style={{ width: "80%", marginTop: "0", marginBottom: "0" }}
              ></hr>
              <Link to="/processorprofile">
                <div
                  className={`d-flex left-menu-items ${
                    window.location.pathname === "/processorprofile" &&
                    "left-menu-items-active"
                  }`}
                >
                  <FontAwesomeIcon
                    className="mr-4"
                    style={{ color: "#78909C" }}
                    icon={faGear}
                    width="20"
                    height="20"
                  />
                  <h1>Settings</h1>
                </div>
              </Link>
              <hr
                style={{ width: "80%", marginTop: "0", marginBottom: "0" }}
              ></hr>
              {["processor", "admin", "manager"].indexOf(user.role) !== -1 && (
                <Link to="/processor/users-and-roles">
                  <div
                    className={`d-flex left-menu-items ${
                      window.location.pathname ===
                        "/processor/users-and-roles" && "left-menu-items-active"
                    }`}
                  >
                    <FontAwesomeIcon
                      className="mr-4"
                      style={{ color: "#78909C" }}
                      icon={faUserCircle}
                      width="20"
                      height="20"
                    />
                    <h1>Users and Roles</h1>
                  </div>
                </Link>
              )}
              <hr
                style={{ width: "80%", marginTop: "0", marginBottom: "0" }}
              ></hr>
              <div
                className="left-menu-items"
                onClick={() => {
                  setIsOpenVersion(true);
                }}
              >
                <FontAwesomeIcon
                  className="mr-4"
                  style={{ color: "#78909C" }}
                  icon={faRefresh}
                  width="20"
                  height="20"
                />
                <h1>Version</h1>
              </div>
              <hr
                style={{ width: "80%", marginTop: "0", marginBottom: "0" }}
              ></hr>
              <div className="left-menu-items" hidden>
                <FontAwesomeIcon
                  className="mr-4"
                  style={{ color: "#78909C" }}
                  icon={faInfo}
                  width="20"
                  height="20"
                />
                <h1>What's New?</h1>
              </div>
              <hr
                style={{ width: "80%", marginTop: "0", marginBottom: "0" }}
              ></hr>
              <a href="mailto:support@grazr.net">
                <div className="left-menu-items">
                  <FontAwesomeIcon
                    className="mr-4"
                    style={{ color: "#78909C" }}
                    icon={faQuestionCircle}
                    width="20"
                    height="20"
                  />
                  <h1>Customers Support</h1>
                </div>
              </a>
              <hr
                style={{ width: "80%", marginTop: "0", marginBottom: "0" }}
              ></hr>
              <div className="left-menu-items-button">
                <a className="btn-mini" href="#" hidden>
                  Help
                </a>
                {/* <Link to='/privacypolicy'> */}
                <a
                  onClick={() => setPrivacyPopup(true)}
                  className="btn-mini cursor-pointer"
                >
                  Privacy
                </a>
                {/* </Link> */}
                {/* <Link to='/termscondition'> */}
                <a
                  onClick={() => setTermsPopup(true)}
                  className="btn-mini cursor-pointer"
                >
                  Terms
                </a>
                {/* </Link> */}
              </div>
            </div>
            <div className="childrenValues main-wrap w-100">
              {props.children}
            </div>
          </div>
          {isOpen && (
            <DialogPopup
              title="New Order"
              subtitle={currentDate}
              maxWidth="lg"
              open={isOpen}
              onClose={(e) => () => setIsOpen(false)} // Close the dialog when requested
              style={{ marginTop: "5rem" }}
            >
              <NewOrder onClose={() => setIsOpen(false)}></NewOrder>
            </DialogPopup>
          )}
          {isOpenVersion && (
            <DialogPopup
              title="Version"
              subtitle={"v. 2.0"}
              maxWidth="xs"
              open={isOpenVersion}
              onClose={(e) => () => setIsOpenVersion(false)} // Close the dialog when requested
              style={{ marginTop: "5rem" }}
            >
              {/* <span>ggwp</span> */}
            </DialogPopup>
          )}
          {privacyPopup && (
            <DialogPopup
              title="Privacy Policy"
              subtitle={"Please read our privacy policy"}
              maxWidth="lg"
              open={privacyPopup}
              onClose={(e) => () => setPrivacyPopup(false)} // Close the dialog when requested
              style={{ marginTop: "5rem" }}
            >
              <PrivacyPolicyData />
            </DialogPopup>
          )}
          {termsPopup && (
            <DialogPopup
              title="Terms &amp; Conditions"
              subtitle={"Please read our terms and conditions"}
              maxWidth="lg"
              open={termsPopup}
              onClose={(e) => () => setTermsPopup(false)} // Close the dialog when requested
              style={{ marginTop: "5rem" }}
            >
              <TermsOfServiceData />
            </DialogPopup>
          )}
        </div>
      ) : (
        <div>
          <div className="childrenValues main-wrap w-100">{props.children}</div>
        </div>
      )}
    </>
  );
};

function mapStateToProps(state) {
  const { user, loggedIn } = state.login;

  return { user, loggedIn };
}
export default connect(mapStateToProps)(ProcDashLayout);
