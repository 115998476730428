// Component Imports
import "./CalenderProcessor.styles.css";
import "./CalendarSetup.css";

import EventsPopup from "../EventsPopup/EventsPopup";
// Third Parties imports
import FullCalendar from "@fullcalendar/react";
// Core Imports
import React from "react";
import Service from "../../../Service/Service";
import axios from "axios";
// Reduc Imports
import { connect } from "react-redux";
import { currentServerTime } from "../../../util/socket";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction"; // needed for dayClick
import listPlugin from "@fullcalendar/list";
import moment from "moment";

class MyAgenda extends React.Component {
  calref = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      search: props.search,
      hiddendays: [],
      currenteventdate: Date,
      currentServerTime: props.currentServerTime,
      cal_loader: true,
      rerender: false,
      eventData: [],
      eventPopup: false,
      selectedDate: null,

      // sidebar
      slotData: [],
      pastSlotData: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.search !== this.props.search) {
      this.refresh();
    }
  }

  refresh = () => {
    this.setState(
      {
        rerender: true,
      },
      () => {
        this.setState({
          rerender: false,
        });
      }
    );
  };

  handleDateClick = (info) => {
    console.log("Clicked on: ", info);
  };

  viewRender = (view, element) => {};

  handleEvent = (arg) => {
    console.log("event click", arg);
    let date = moment(arg.event.start).format("YYYY-MM-DD");
    this.setState({
      selectedDate: date,
      eventPopup: true,
    });
  };

  getMondays = (day, mth, yr) => {
    let days = [];
    days = day
      .toString()
      .split(",")
      .map(function (i) {
        return parseInt(i, 10);
      });

    let mondays = [];
    var d = new Date();

    days.forEach((day) => {
      var month;
      if (mth == 0) {
        d.setMonth(11);
        d.setFullYear(yr - 1);
      } else {
        d.setMonth(mth - 1);
        d.setFullYear(yr);
      }
      month = d.getMonth();

      d.setDate(1);
      // Get the first Monday in the month
      while (d.getDay() !== day) {
        d.setDate(d.getDate() + 1);
      }

      // Get all the other Mondays in the month
      while (d.getMonth() === month) {
        mondays.push(new Date(d));
        d.setDate(d.getDate() + 7);
      }
    });
    return mondays;
  };

  assign_refetchEvents = (refresh_status) => {
    if (refresh_status) {
      window.location.reload();
    }
  };

  assign_events = async (date) => {
    if (!date) {
      var date = new Date();
    }
    var month = date.getMonth() + 1;
    var year = date.getFullYear();
    var event = [];

    const data = {
      month: month,
      year: year,
      search: this.props.search,
    };

    await new Promise((promised) => {
      axios.defaults.headers.common["Authorization"] =
        "Bearer " + this.props.user.user_token;
      let url = process.env.REACT_APP_BASE_URL;

      axios
        .post(url + "calendar/getEvents", data)
        .then(async ({ status, message, data }) => {
          console.log(data);
          if (data.data) {
            event = await Promise.all(
              data.data.map((e, index) => {
                return {
                  title:
                    (e.process_option
                      ? `[${e.process_option.toUpperCase()}] `
                      : "") + `${e.title}`,
                  start: moment(e.date).format("YYYY-MM-DD"),
                  backgroundColor:
                    e.status == 0
                      ? "#ffa500"
                      : e.status == 1
                      ? "#40e0d0"
                      : e.status == 4
                      ? "#ff80ed"
                      : "#bada55",
                  textColor: "#000000 !important",
                };
              })
            );
          }

          this.setState({ cal_loader: false });
          promised();
        });
    });
    this.setState({
      eventData: event,
    });
  };

  sidebar_events = async () => {
    let mth, yr;
    const d = new Date();
    mth = d.getMonth();
    yr = d.getFullYear();

    if (Number(mth) == 0) {
      mth = 12;
      yr = Number(yr) - 1;
    }

    const {
      user: { id },
      user: { user_token },
      user: { role: urole },
      currentPopupData,
    } = this.props;

    let processor_id = 0;
    if (this.props.user.role == "seller") {
      processor_id = this.props.processorId;
    } else if (this.props.user.role == "processor") {
      processor_id = this.props.user.id;
    }

    let payload = {
      processor_id: processor_id,
      month_no: mth,
      year_no: yr,
      search: this.props.search ? this.props.search : "",
    };

    // console.log("payload my agenda component", payload);
    await new Service(user_token)
      .post("/calendar/show_available_slot", payload)
      .then(({ status, message, data }) => {
        try {
          let { availableslot, availablecategory, searchallslot } = data;
          var slotData = [].concat(searchallslot);
          this.setState({
            slotData: slotData,
            pastSlotData: searchallslot.reverse(),
          });
        } catch (e) {
          console.log(e);
        }
      });
  };

  deleteEvents = ({ id, tableName }) => {
    let {
      user: { user_token },
    } = this.props;
    let payload = {
      event_id: id,
    };
    let url = "/calendar/remove_event";
    new Service(user_token).post(url, payload).then(({ status, message }) => {
      console.log(status, message);
    });
  };

  dayrender = (dayRenderInfo) => {
    //  let {availablecategory} = this.state;
    //  console.log(availablecategory,'dar rener',this.state)
    // //  console.log(availablecategory)
    //   if(availablecategory && availablecategory.length === 0)
    //       dayRenderInfo.el.style.background='grey'
  };

  handleDateChanges = (payload) => {
    let days = 10;
    let date = payload.start;
    date.setDate(date.getDate() + days);
    this.assign_events(date);
    this.sidebar_events();
  };

  componentDidMount() {
    currentServerTime((data) => {
      this.setState({
        currentServerTime: data,
      });
    });
    this.assign_events();
    this.sidebar_events();
  }
  render() {
    let { rerender, hiddendays } = this.state;
    console.log("event data " + this.state.eventData);

    return (
      <>
        {this.state.cal_loader ? (
          <div className="loader"></div>
        ) : (
          !rerender && (
            <div className="row">
              <div className="calendar-wrap col-md-9">
                <div className="agendaCnt">
                  <FullCalendar
                    ref={this.props.passingRef}
                    dateClick={this.handleDateClick}
                    hiddenDays={hiddendays}
                    plugins={[dayGridPlugin, listPlugin, interactionPlugin]}
                    eventClick={this.handleEvent}
                    viewRender={this.viewRender}
                    events={this.state.eventData}
                    // events={
                    //   [
                    //     {
                    //       title  : 'event1',
                    //       start  : '2023-10-10'
                    //     },
                    //     {
                    //       title  : 'event2',
                    //       start  : '2023-10-10',
                    //       end    : '2023-10-12'
                    //     },
                    //     {
                    //       title  : 'event3',
                    //       start  : '2023-10-10T12:30:00',
                    //       allDay : false // will make the time show
                    //     }
                    //   ]
                    // }
                    // events={this.assign_events}
                    // events={[
                    //   { title: 'event 1', date: '2023-07-22' },
                    //   { title: 'event 2', date: '2023-07-23' }
                    // ]}
                    rerenderEvents={this.assign_refetchEvents}
                    // ref={this.calref}
                    dayRender={this.dayrender}
                    // initialView={this.state.view}
                    headerToolbar={{
                      // left: "title",
                      left: "today prev,next",
                      // left: "",
                      center: "",
                      // right: "today prev,next,dayGridMonth,dayGridWeek,dayGridDay,listWeek",
                      right: "",
                    }}
                    views={{
                      // GridWeek: {
                      //   type: "listWeek",
                      //   duration: { days: 4 },
                      //   dayMaxEventRows:5,
                      // },
                      dayGridMonth: {
                        // eventLimit: 6, // adjust to 6 only for timeGridWeek/timeGridDay
                        dayMaxEventRows: 3,
                      },
                    }}
                    // ** DO NOT REMOVE **
                    //  - TODO -> Set Default event color and event limit
                    // eventColor= '#378006'
                    // eventLimit={ true} // for all non-TimeGrid views
                    timezone={"UTC"}
                    eventOrder={"start"}
                    datesSet={(event) => this.handleDateChanges(event)}
                  />
                </div>
              </div>

              <div className="calendar-sidebar col-md-3">
                <div className="otherSlots">
                  <h3>Upcoming Events</h3>
                  <ul style={{ maxHeight: "311px", overflow: "auto" }}>
                    {this.state.slotData?.map((data, index) =>
                      moment(data.date_added.replace(/\T.+/g, "")).isAfter(
                        moment(new Date()),
                        "day"
                      ) ? (
                        <>
                          <hr />
                          <li
                            onClick={(e) => {
                              this.setState({
                                selectedDate: moment(data.date_added).format(
                                  "YYYY-MM-DD"
                                ),
                                eventPopup: true,
                              });
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <h4>{data.title}</h4>
                            <p>
                              {moment(
                                data.date_added.replace(/\T.+/g, "")
                              ).format("MMM Do YYYY")}
                            </p>
                          </li>
                        </>
                      ) : (
                        ""
                      )
                    )}
                  </ul>
                </div>
                <br></br>
                <div className="otherSlots">
                  <h3>Past Events</h3>
                  <ul style={{ maxHeight: "311px", overflow: "auto" }}>
                    {this.state.pastSlotData?.map((data, index) =>
                      moment(data.date_added.replace(/\T.+/g, "")).isBefore(
                        moment(new Date()),
                        "day"
                      ) ||
                      moment(data.date_added.replace(/\T.+/g, "")).isSame(
                        moment(new Date()),
                        "day"
                      ) ? (
                        <>
                          <hr />
                          <li
                            onClick={(e) => {
                              this.setState({
                                selectedDate: moment(data.date_added).format(
                                  "YYYY-MM-DD"
                                ),
                                eventPopup: true,
                              });
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <h4>{data.title}</h4>
                            <p>
                              {moment(
                                data.date_added.replace(/\T.+/g, "")
                              ).format("MMM Do YYYY")}
                            </p>
                          </li>
                        </>
                      ) : (
                        ""
                      )
                    )}
                  </ul>
                </div>
              </div>

              <EventsPopup
                date={this.state.selectedDate}
                open={this.state.eventPopup}
                close={() => this.setState({ eventPopup: false })}
                refresh={this.refresh}
              />
            </div>
          )
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  const {
    login: { loggedIn },
    login: { user },
  } = state;
  return { loggedIn, user };
};
export default connect(mapStateToProps)(MyAgenda);
