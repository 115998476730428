const refreshToken = async () => {
  try {
    let authToken = localStorage.getItem("auth_token");
    let accessToken = localStorage.getItem("access_token");
    const expires_in = localStorage.getItem("expires_in");
    const refresh_token = localStorage.getItem("refresh_token");
    const token_type = localStorage.getItem("token_type");
    const x_refresh_token_expires_in = localStorage.getItem(
      "x_refresh_token_expires_in"
    );
    const url = process.env.REACT_APP_BASE_URL + "qbrefresh"; // Adjust the URL as per your API endpoint
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },
      body: JSON.stringify({
        refresh_token: refresh_token,
        x_refresh_token_expires_in: x_refresh_token_expires_in,
        access_token: accessToken,
        token_type: token_type,
        expires_in: expires_in,
        redirectUri: process.env.REACT_APP_URL,
      }),
    });

    if (response.ok) {
      const responseData = await response.json();
      console.log(responseData);
      // Update access token in localStorage or wherever you store it
      localStorage.setItem("access_token", responseData.access_token);
      // Save response data to local storage
      localStorage.setItem("expires_in", responseData.expires_in);
      localStorage.setItem("refresh_token", responseData.refresh_token);
      localStorage.setItem("token_type", responseData.token_type);
      localStorage.setItem(
        "x_refresh_token_expires_in",
        responseData.x_refresh_token_expires_in
      );
      return responseData.access_token;
    } else {
      console.error("Failed to refresh token:", response.statusText);
      // Handle token refresh failure
      return null;
    }
  } catch (error) {
    console.error("Error refreshing token:", error.message);
    return null;
  }
};

export default refreshToken;
