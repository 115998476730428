import { LOGIN, NOTIFICATION } from "../../../actions";
import React, { Component } from "react";

import AppBar from "@material-ui/core/AppBar";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import Dialog from "@material-ui/core/Dialog";
import Header from "../../Header/Header";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";
import NewCutSheetView from "./order/NewCutSheetView";
import ProcDashLayout from "./ProcDashLayout";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { bisonParts } from "./bisonParts";
import { connect } from "react-redux";
import { cowParts } from "./cowParts";
import { deerParts } from "./deerParts";
import { elkParts } from "./elkParts";
import { goatParts } from "./goatParts";
import { hogParts } from "./hogParts";
import html2pdf from "html2pdf.js";
import { lambParts } from "./lambParts";
import moment from "moment";
import { resolve } from "patch-package/dist/path";

class CutSheetAnimalComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form_submit_url:
        process.env.REACT_APP_BASE_URL + "cutsheet/submit_custom_form/",
      form_id: null,
      data_id: null,
      order_id: null,
      form_name: "",
      form_type: "",
      form_date: "",
      form_data_json: null,
      form_json: null,
      form_json_temp: null,
      preview: false,
      form_status: "",
      is_clone: false,
      is_edit: false,
      openOrderOptions: [],
      openOrderData: [],
      open_order_id: 0,
      open_order_data: {
        name: "",
        value: "",
      },
      is_ready: true,
      current_popup_data: "",
      working_days: "",
      viewcal: false,
      block_days: "",
      category_id: "",
      schedule_order: false,
      calendar_id: "",
      cutSheetData: [],
      btnloading: false,
      selectedParts: {},
      activePart: null,
      expanded: null,
      activeSubPart: null,
      controlValues: {},
      selectedOrgans: {},
      highlightedSubPart: null,
      isSaved: false,
      selectedAnimal: [],
    };
    this.optionsRef = React.createRef();
  }

  componentDidMount() {
    const { dataId, formId } = this.props;
    console.log("Loaded data:", dataId);
    if (dataId && formId) {
      this.getCutSheetData(formId, dataId);
    }

    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (e) => {
    if (
      this.optionsRef.current &&
      !this.optionsRef.current.contains(e.target)
    ) {
      this.setState({ activePart: null });
    }
  };

  exportPdf = () => {
    this.setState({
      btnloading: true,
    });
    let self = this;
    const element = document.getElementById("capture");
    html2pdf()
      .set({
        filename: "cutsheet.pdf",
        margin: 0.2,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 1 },
        jsPDF: { unit: "in", format: "a4", orientation: "landscape" },
      })
      .from(element)
      .save();
    this.setState({
      btnloading: false,
    });
  };

  updateCowPartsWithData = (data) => {
    console.log("Received data:", data);
    const nestedData = data || {}; // Use data directly
    console.log("Selected Animal:", this.state.selectedAnimal);

    Object.keys(nestedData).forEach((key) => {
      const partIndex = this.state.selectedAnimal.findIndex(
        (part) => part.id === key
      );
      if (partIndex !== -1) {
        this.state.selectedAnimal[partIndex].subcuts = nestedData[key].subcuts;
      } else {
        console.warn(`No matching part found for id: ${key}`);
      }
    });
  };

  toggleOrgansSelection = (partId, subPart) => {
    this.setState((prevState) => {
      const newSelection = { ...prevState.selectedOrgans };
      if (!newSelection[partId]) newSelection[partId] = [];

      const isSelected = newSelection[partId].some(
        (item) => item.title === subPart.title
      );

      if (isSelected) {
        newSelection[partId] = newSelection[partId].filter(
          (item) => item.title !== subPart.title
        );
      } else {
        newSelection[partId].push(subPart);
      }

      if (newSelection[partId].length === 0) delete newSelection[partId];
      return { selectedOrgans: newSelection };
    });
  };

  togglesubcutselection = (partId, subPart) => {
    this.setState((prevState) => {
      const newSelection = { ...prevState.selectedParts };
      if (!newSelection[partId]) newSelection[partId] = [];

      const isSelected = newSelection[partId].some(
        (item) => item.title === subPart.title
      );

      if (isSelected) {
        newSelection[partId] = newSelection[partId].filter(
          (item) => item.title !== subPart.title
        );
      } else {
        const isControls = subPart.controls.length > 0;
        const hasRequiredControls = subPart.controls.some(
          (control) => control.isRequired
        );
        const isCompleted = !isControls;
        newSelection[partId].push({
          ...subPart,
          isControls,
          hasRequiredControls,
          isCompleted,
        });
      }

      if (newSelection[partId].length === 0) delete newSelection[partId];
      console.log("NEW SELECTION: ", newSelection);
      return { selectedParts: newSelection };
    });
  };

  handleSubPartClick = (partId, subPart) => {
    this.setState({
      activeSubPart: { partId, subPart },
      highlightedSubPart: subPart.title,
    });

    const transformedValues = Object.entries(this.state.controlValues).map(
      ([subcut, controls]) => ({
        animal_title: [
          {
            animal: this.state.form_type,
            cuts:
              this.state.selectedAnimal.find((p) =>
                p.subcuts.some((sp) => sp.title === subcut)
              )?.title || "Unknown",
            subcuts: subcut,
            controls: controls.map(
              ({ description, selectedOptions, enteredText, text }) => ({
                description,
                selectedOption: Array.isArray(selectedOptions)
                  ? selectedOptions
                  : [selectedOptions],
                enteredText,
                text,
              })
            ),
          },
        ],
      })
    );

    const existingValues = transformedValues.find(
      (value) => value.animal_title[0].subcuts === subPart.title
    );

    const newControlValues = subPart.controls.map((control, index) => {
      if (existingValues) {
        const existingControl = existingValues.animal_title[0].controls[index];
        return {
          description: control.description,
          selectedOptions:
            existingControl.selectedOption ||
            (control.type === "Multiselect" ? [] : null),
          enteredText: existingControl.enteredText ?? "",
          text: control.text,
        };
      } else {
        return {
          description: control.description,
          selectedOptions: control.type === "Multiselect" ? [] : null,
          enteredText: "",
          text: control.text,
        };
      }
    });

    this.setState((prevState) => ({
      controlValues: {
        ...prevState.controlValues,
        [subPart.title]: newControlValues,
      },
    }));
  };

  handleControlChange = (subParttitle, controlIndex, field, value) => {
    this.setState((prevState) => ({
      controlValues: {
        ...prevState.controlValues,
        [subParttitle]: prevState.controlValues[subParttitle].map(
          (control, index) =>
            index === controlIndex
              ? {
                  ...control,
                  [field]: value,
                }
              : control
        ),
      },
    }));
  };

  transformControlValues = () => {
    const transformedValues = Object.entries(this.state.controlValues)
      .map(([subcut, controls]) => {
        console.log("ANIM", this.state.selectedAnimal);
        const part = this.state.selectedAnimal.find((p) =>
          p.subcuts?.some((subPart) => subPart.title === subcut)
        );

        const allRequiredFilled = controls.every(
          ({ title, selectedOptions, enteredText }, index) => {
            const control = this.state.activeSubPart.subPart.controls[index];
            if (control != null ? control.isRequired : false) {
              if (
                control.type === "Dropdown" ||
                control.type === "Textbox" ||
                control.type === "Subfields"
              ) {
                return selectedOptions !== "" || enteredText !== "";
              } else if (control.type === "Multiselect") {
                return selectedOptions.length > 0;
              }
            }
            return true;
          }
        );

        if (!allRequiredFilled) {
          alert("Please fill in all required fields before proceeding.");
          return null;
        }

        if (allRequiredFilled) {
          this.setState((prevState) => {
            const newSelection = { ...prevState.selectedParts };
            const partIndex = newSelection[
              this.state.activeSubPart.partId
            ].findIndex((item) => item.title === subcut);
            if (partIndex !== -1) {
              newSelection[this.state.activeSubPart.partId][
                partIndex
              ].isCompleted = true;
            }
            return { selectedParts: newSelection };
          });
        }

        return {
          animal_title: [
            {
              animal: this.state.form_type,
              cuts: part ? part.title : "Unknown",
              subcuts: subcut,
              controls: controls.map(
                ({ description, selectedOptions, enteredText }) => ({
                  description,
                  selectedOption: Array.isArray(selectedOptions)
                    ? selectedOptions
                    : [selectedOptions],
                  enteredText,
                })
              ),
            },
          ],
        };
      })
      .filter(Boolean);
  };

  getCutSheetData = async (form_id, data_id) => {
    const url = process.env.REACT_APP_BASE_URL;
    const data = new FormData();
    data.append("form_id", form_id);
    data.append("data_id", data_id);
    data.append("status", "1");

    try {
      const response = await axios.post(
        url + "cutsheet/get_custom_form_data",
        data
      );

      if (response.data.status) {
        if (response.data.result.data.length > 0) {
          const formData = response.data.result.data[0];

          this.setState({
            form_name: formData.name,
            form_type: formData.type,
            form_date: formData.date_added,
            form_id: form_id,
            data_id: data_id,
            order_id: formData.order_id,
            form_json:
              formData.form_json !== "" ? JSON.parse(formData.form_json) : null,
            preview: false,
            form_status: formData.form_status,
            form_data_json:
              formData.form_data_json !== ""
                ? JSON.parse(formData.form_data_json)
                : {},
          });

          const animalPartsMapping = {
            beef: cowParts,
            bison: bisonParts,
            lamb: lambParts,
            goat: goatParts,
            deer: deerParts,
            elk: elkParts,
            hog: hogParts,
          };

          const selectedAnimal = animalPartsMapping[this.state.form_type] || [];

          this.setState({ selectedAnimal: selectedAnimal });
          this.updateCowPartsWithData(this.state.form_json);
          this.populateOrderAndChoiceList(
            this.state.form_data_json,
            this.state.form_json
          );
        } else {
          this.props.dispatch(
            NOTIFICATION({
              msg: response.data.message || "No data found",
              msgColor: "#FAD5BD",
            })
          );
        }
      } else {
        this.props.dispatch(
          NOTIFICATION({
            msg: response.data.message || "Failed to load data",
            msgColor: "#FAD5BD",
          })
        );
      }
    } catch (error) {
      // this.props.dispatch(
      //   NOTIFICATION({
      //     msg: error.message || "An error occurred",
      //     msgColor: "#FAD5BD",
      //   })
      // );
    } finally {
      setTimeout(() => {
        this.props.dispatch(NOTIFICATION({ msg: "" }));
      }, 3000);
    }
  };
  toCamelCase = (input) => {
    if (!input.includes(" ")) return input; // Return as is if no spaces

    return input
      .split(" ") // Split the string by spaces
      .map(
        (word, index) =>
          index === 0
            ? word.toLowerCase() // Lowercase the first word
            : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Capitalize subsequent words
      )
      .join(""); // Join them back without spaces
  };
  populateOrderAndChoiceList = (formDataJson, formJson) => {
    const selectedParts = {};
    const newControlValues = { ...this.state.controlValues };

    if (formDataJson) {
      formDataJson.order_list.forEach((order) => {
        const part = this.state.selectedAnimal.find(
          (p) => p.title.toLowerCase() === order.cut.toLowerCase()
        );

        if (part) {
          selectedParts[part.id] = order.subcuts.map((subcut) => {
            const subPart = {
              title: subcut.title,
              isControls: false,
              hasRequiredControls: false,
              isCompleted: false,
              controls: [],
            };

            const formSubcut = (
              formJson[this.toCamelCase(order.cut.toLowerCase())] !== undefined
                ? formJson[this.toCamelCase(order.cut.toLowerCase())]
                : formJson[order.cut.toLowerCase().replace(" ", "_")]
            ).subcuts.find(
              (fSubcut) =>
                fSubcut.title.toLowerCase() === subcut.title.toLowerCase()
            );

            if (formSubcut) {
              subPart.controls = formSubcut.controls
                .map((control, index) => {
                  const choice = subcut.choices[index];

                  if (!choice) {
                    console.warn(
                      `No matching choice found for control: ${control.description}`
                    );
                    return null;
                  }

                  let selectedOptions = [];
                  let enteredText = "";

                  if (control.type === "Dropdown") {
                    selectedOptions =
                      Array.isArray(choice.selected_values) &&
                      choice.selected_values.length > 0
                        ? choice.selected_values[0]
                        : "";
                  } else if (
                    control.type === "Textbox" ||
                    control.type === "Subfields"
                  ) {
                    enteredText =
                      Array.isArray(choice.selected_values) &&
                      choice.selected_values.length > 0
                        ? choice.selected_values[0]
                        : "";
                  } else if (control.type === "Multiselect") {
                    selectedOptions = choice.selected_values || [];
                  }

                  return {
                    ...control,
                    selectedOptions,
                    enteredText,
                  };
                })
                .filter(Boolean);

              subPart.isControls = subPart.controls.length > 0;
              subPart.hasRequiredControls = subPart.controls.some(
                (control) => control.required
              );
              subPart.isCompleted = subPart.controls.every((control) => {
                if (control.required) {
                  if (
                    control.type === "Dropdown" ||
                    control.type === "Textbox" ||
                    control.type === "Subfields"
                  ) {
                    return (
                      control.selectedOptions.length > 0 ||
                      control.enteredText !== ""
                    );
                  } else if (control.type === "Multiselect") {
                    return control.selectedOptions.length > 0;
                  }
                }
                return true;
              });

              newControlValues[subPart.title] = subPart.controls.map(
                ({ description, selectedOptions, enteredText, text }) => ({
                  description,
                  selectedOptions: selectedOptions,
                  enteredText,
                  text,
                })
              );
            }

            return subPart;
          });
        }
      });
    }

    this.setState({ selectedParts, controlValues: newControlValues }, () => {
      console.log("Updated selectedParts:", this.state.selectedParts);
      console.log(
        "Updated Control Values with form_data_json:",
        this.state.controlValues
      );
    });

    console.log("selectedParts data:", selectedParts);
  };

  CutSheetFormSubmit = () => {
    console.log("control values:", this.state.controlValues);

    const transformedValues = {
      order_list: Object.entries(this.state.selectedParts).map(
        ([partId, subcuts]) => {
          const part = this.state.selectedAnimal.find((p) => p.id === partId);
          return {
            cut: part.title,
            subcuts: subcuts.map((subcut) => {
              const choices =
                this.state.controlValues[subcut.title]?.map((control) => {
                  const selectedOptions = Array.isArray(control.selectedOptions)
                    ? control.selectedOptions
                    : control.selectedOptions !== null
                    ? [control.selectedOptions]
                    : [];

                  console.log("Control:", control);
                  console.log("Entered Text:", control.enteredText);

                  const enteredText = control.enteredText || "";

                  return {
                    description: control.description,
                    selected_values:
                      enteredText.length === 0
                        ? selectedOptions
                        : [enteredText],
                  };
                }) || [];
              return {
                title: subcut.title,
                choices: choices,
              };
            }),
          };
        }
      ),
    };

    console.log("Transformed Values with Organs:", transformedValues);

    this.setState({ isSaved: true });

    let url = process.env.REACT_APP_BASE_URL;
    const data = new FormData();
    data.append("data_id", this.state.data_id);
    data.append("form_id", this.state.form_id);
    data.append("form_data", JSON.stringify(transformedValues));

    axios
      .post(url + "cutsheet/submit_custom_form", data)
      .then((response) => {
        if (response.data.status) {
          this.props.dispatch(
            NOTIFICATION({
              msg: "Cut Sheet form submitted successfully",
            })
          );
        } else {
          this.props.dispatch(
            NOTIFICATION({
              msg: response.data.message || "Something went wrong",
              msgColor: "#FAD5BD",
            })
          );
        }
        setTimeout(() => {
          this.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 3000);
      })
      .catch((error) => {
        this.props.dispatch(
          NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
        );
        setTimeout(() => {
          this.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 2000);
      });
  };

  isPartSelected = (partId) => this.state.selectedParts[partId]?.length > 0;

  issubcutselected = (partId, subPart) => {
    const iftttarr =
      this.state.selectedParts[partId]?.length > 0
        ? this.state.selectedAnimal
            .find((part) => part.id === partId)
            .subcuts.find(
              (value) =>
                value.title === this.state.selectedParts[partId][0].title
            ).ifttt
        : [];
    return iftttarr.findIndex((ifttt) => ifttt === subPart.title) !== -1;
  };

  handleToggle = (partId) => {
    this.setState((prevState) => ({
      expanded: prevState.expanded === partId ? null : partId,
    }));
  };

  getDotColor = (subcuts) => {
    const hasRequired = subcuts.some((subPart) => subPart.hasRequiredControls);
    const hasControls = subcuts.some((subPart) => subPart.isControls);
    const isCompleted = subcuts.every((subPart) => subPart.isCompleted);

    if (isCompleted) return "green";
    if (hasRequired) return "red";
    if (hasControls) return "gold";
    return "transparent";
  };

  areAllRequiredFieldsFilled = () => {
    return Object.values(this.state.selectedParts).every((subcuts) =>
      subcuts.every((subPart) => subPart.isCompleted)
    );
  };

  render() {
    const {
      selectedParts,
      activePart,
      expanded,
      activeSubPart,
      controlValues,
      highlightedSubPart,
      isSaved,
    } = this.state;
    const isMobile = window.innerWidth <= 1000;

    const animalImages = {
      beef: "/images/cow.png",
      bison: "/images/bison.png",
      lamb: "/images/lamb.png",
      goat: "/images/goat.png",
      deer: "/images/dear.png",
      elk: "/images/elk.png",
      hog: "/images/hog.png",
    };

    const animalImageSrc = animalImages[this.state.form_type] || "";
    console.log("SELECTED ANIMALS:", this.state.selectedAnimal);
    return (
      <>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
            flexDirection: "column",
            position: "relative",
            minHeight: "500px",
            paddingBottom: "38%",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: isMobile ? "column" : "row",
              alignItems: "center",
            }}
          >
            <div
              style={{
                position: "relative",
                width: "600px",
                height: "400px",
              }}
            >
              <img
                src={animalImageSrc}
                alt="Animal Diagram"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              />

              {this.state.selectedAnimal.map((part) => (
                <div
                  key={part.id}
                  onClick={() => this.setState({ activePart: part })}
                  style={{
                    position: "absolute",
                    top: part.coordinates.top,
                    left: part.coordinates.left,
                    width: "70px",
                    height: "70px",
                    color: "white",
                    borderRadius: "50%",
                    transform: `rotate(${part.rotation}deg)`,
                    transformOrigin: "center",
                    backgroundColor: "transparent",
                    border: "1px solid transparent",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                    fontSize: part.fontSize,
                    fontWeight: "bold",
                    whiteSpace: "pre-wrap",
                    lineHeight: "1.2",
                  }}
                >
                  <span
                    style={{
                      textShadow:
                        activePart?.id === part.id
                          ? "0 0 10px rgba(255, 255, 0, 0.8), 0 0 20px rgba(255, 255, 0, 0.6)"
                          : "none",
                      color: this.isPartSelected(part.id) ? "gold" : "white",
                      transition: "text-shadow 0.3s ease, color 0.3s ease",
                    }}
                  >
                    {part.title ? part.title.replace(" ", "\n") : ""}
                  </span>
                </div>
              ))}

              {activePart && (
                <div
                  ref={this.optionsRef}
                  style={{
                    position: "absolute",
                    top: activePart.coordinates.top,
                    left: `calc(${activePart.coordinates.left} + 80px)`,
                    backgroundColor: "white",
                    border: "1px solid #ddd",
                    borderRadius: "5px",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                    padding: "10px",
                    zIndex: 10,
                    width: "150px",
                  }}
                >
                  <h4 style={{ margin: "0 0 5px 0", fontSize: "14px" }}>
                    {activePart.title} Sub-Parts
                  </h4>
                  {activePart.subcuts.map(
                    (subPart) =>
                      subPart.title && (
                        <label
                          key={subPart.title}
                          style={{
                            display: "block",
                            marginBottom: "5px",
                            fontWeight: selectedParts[activePart.id]?.some(
                              (item) => item.title === subPart.title
                            )
                              ? "bold"
                              : "normal",
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={
                              selectedParts[activePart.id]?.some(
                                (item) => item.title === subPart.title
                              )
                                ? true
                                : false
                            }
                            disabled={
                              this.issubcutselected(activePart.id, subPart) ||
                              this.props.ispreview
                            }
                            onChange={() =>
                              this.togglesubcutselection(activePart.id, subPart)
                            }
                          />

                          <span
                            style={{
                              cursor: "pointer",
                              color: this.issubcutselected(
                                activePart.id,
                                subPart
                              )
                                ? "gray"
                                : "black",
                            }}
                            onClick={() =>
                              subPart.isControls
                                ? this.handleSubPartClick(
                                    activePart.id,
                                    subPart
                                  )
                                : null
                            }
                          >
                            {subPart.title}
                          </span>
                        </label>
                      )
                  )}
                </div>
              )}
            </div>

            <div
              style={{
                marginLeft: isMobile ? "0%" : "26%",
                padding: "10px",
                border: "1px solid #ddd",
                borderRadius: "8px",
                width: isMobile ? "100%" : "250px",
                backgroundColor: "#f9f9f9",
                borderStyle: "dotted",
                marginTop: isMobile ? "6%" : "0%",
              }}
            >
              <h3 style={{ textAlign: "center" }}>Select Organs</h3>
              {this.state.selectedAnimal
                .filter((part) => part.id === "organs")
                .map((part) => (
                  <div key={part.id}>
                    {part.subcuts.map((subPart) => (
                      <label
                        key={subPart.title}
                        style={{
                          display: "block",
                          color: this.issubcutselected(part.id, subPart)
                            ? "gray"
                            : "black",
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={
                            selectedParts[part.id]?.some(
                              (item) => item.title === subPart.title
                            )
                              ? true
                              : false
                          }
                          disabled={
                            this.issubcutselected(part.id, subPart) ||
                            this.props.ispreview
                          }
                          onChange={() =>
                            this.togglesubcutselection(part.id, subPart)
                          }
                          style={{
                            marginRight: "2%",
                          }}
                        />
                        {subPart.title}
                      </label>
                    ))}
                  </div>
                ))}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <div
              style={{
                marginTop: isMobile ? "4%" : "-2%",
                padding: "10px",
                border: "1px solid #ddd",
                borderRadius: "8px",
                width: isMobile ? "100%" : "600px",
                backgroundColor: "#f9f9f9",
                alignSelf: "center",
                marginRight: isMobile ? "0%" : "3%",
                height: "28rem",
                overflow: "auto",
                borderStyle: "dotted",
              }}
            >
              <h3 style={{ textAlign: "center", fontSize: "22px" }}>
                Order List
              </h3>
              {Object.keys(selectedParts).length > 0 ? (
                <div>
                  {Object.entries(selectedParts).map(([partId, subcuts]) => {
                    const part = this.state.selectedAnimal.find(
                      (p) => p.id === partId
                    );
                    const isExpanded = expanded === partId;

                    return (
                      <div
                        key={partId}
                        style={{
                          marginBottom: "10px",
                          border: "1px solid #ccc",
                          borderRadius: "4px",
                          overflow: "hidden",
                          backgroundColor: "#fff",
                        }}
                      >
                        <div
                          onClick={() => this.handleToggle(partId)}
                          style={{
                            cursor: "pointer",
                            padding: "10px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            backgroundColor: isExpanded ? "#e0e0e0" : "#f0f0f0",
                            fontWeight: "bold",
                          }}
                        >
                          <div>
                            {part.title}
                            <span
                              style={{
                                display: "inline-block",
                                width: "10px",
                                height: "10px",
                                backgroundColor: this.getDotColor(subcuts),
                                borderRadius: "50%",
                                marginLeft: "5px",
                              }}
                            ></span>
                          </div>

                          <span style={{ fontSize: "14px" }}>
                            {isExpanded ? "↓" : "→"}
                          </span>
                        </div>
                        {isExpanded && (
                          <div
                            style={{
                              padding: "10px",
                              backgroundColor: "#f9f9f9",
                            }}
                          >
                            <ul style={{ paddingLeft: "15px", margin: "0" }}>
                              {subcuts.map((subPart) => (
                                <li
                                  key={subPart.title}
                                  style={{
                                    fontSize: "14px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <span
                                    style={{
                                      cursor: "pointer",
                                      color:
                                        highlightedSubPart === subPart.title
                                          ? "gold"
                                          : "black",
                                      textDecoration: "underline",
                                      transition:
                                        "color 0.3s ease, text-shadow 0.3s ease",
                                    }}
                                    onMouseEnter={(e) => {
                                      e.target.style.color = "blue";
                                      e.target.style.textShadow =
                                        "0 0 5px rgba(0, 0, 255, 0.5)";
                                    }}
                                    onMouseLeave={(e) => {
                                      e.target.style.color =
                                        highlightedSubPart === subPart.title
                                          ? "gold"
                                          : "black";
                                      e.target.style.textShadow = "none";
                                    }}
                                    onClick={() =>
                                      subPart.isControls
                                        ? this.handleSubPartClick(
                                            part.id,
                                            subPart
                                          )
                                        : null
                                    }
                                  >
                                    {subPart.title}
                                    <span
                                      style={{
                                        display: "inline-block",
                                        width: "10px",
                                        height: "10px",
                                        backgroundColor: (() => {
                                          if (
                                            subPart.hasRequiredControls &&
                                            !subPart.isCompleted
                                          ) {
                                            return "red";
                                          }
                                          if (
                                            subPart.isCompleted &&
                                            subPart.isControls
                                          ) {
                                            return "green";
                                          }
                                          if (
                                            subPart.isControls &&
                                            !subPart.hasRequiredControls &&
                                            !subPart.isCompleted
                                          ) {
                                            return "gold";
                                          }
                                          return "transparent";
                                        })(),
                                        borderRadius: "50%",
                                        marginLeft: "5px",
                                      }}
                                    ></span>
                                  </span>

                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    {subPart.isControls && (
                                      <span
                                        style={{
                                          display: "inline-block",
                                          padding: "5px 10px",
                                          fontSize: "11px",
                                          backgroundColor: subPart.isCompleted
                                            ? "green"
                                            : "red",
                                          borderRadius: "15px",
                                          marginLeft: "5px",
                                          border: "1px solid #ccc",
                                          color: "white",
                                          cursor: "pointer",
                                          marginBottom: "12px",
                                        }}
                                      >
                                        {subPart.isCompleted
                                          ? "Filled Choice"
                                          : "Fill Choice"}
                                      </span>
                                    )}
                                  </div>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <p>No parts selected</p>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "end",
              }}
            >
              <div
                style={{
                  marginTop: "7%",
                  padding: "10px",
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  width: isMobile ? "100%" : "600px",
                  backgroundColor: "#f9f9f9",
                  alignSelf: "center",
                  height: "28rem",
                  overflow: "auto",
                  borderStyle: "dotted",
                }}
              >
                <h3 style={{ textAlign: "center", fontSize: "22px" }}>
                  Choice
                  {activeSubPart ? ` - ${activeSubPart.subPart.title}` : ""}
                </h3>

                {activeSubPart ? (
                  <div>
                    {activeSubPart.subPart.controls.map((control, index) => (
                      <div
                        key={control.title}
                        style={{ marginBottom: "10px", display: "grid" }}
                      >
                        <label>
                          {index + 1}: {control.description}
                          {control.isRequired && (
                            <span style={{ color: "red", marginLeft: "5px" }}>
                              *
                            </span>
                          )}
                        </label>
                        {control.type === "Dropdown" && (
                          <select
                            value={
                              controlValues[activeSubPart.subPart.title][index]
                                .selectedOptions || ""
                            }
                            disabled={this.props.ispreview}
                            onChange={(e) =>
                              this.handleControlChange(
                                activeSubPart.subPart.title,
                                index,
                                "selectedOptions",
                                e.target.value
                              )
                            }
                            style={{
                              width: "50%",
                              height: "2.5rem",
                              borderRadius: "7px",
                            }}
                          >
                            <option value="" disabled>
                              Select an option
                            </option>
                            {control.options.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        )}

                        {control.type === "Multiselect" && (
                          <div style={{ width: "50%" }}>
                            {control.options.map((option) => (
                              <label key={option} style={{ display: "block" }}>
                                <input
                                  type="checkbox"
                                  disabled={this.props.ispreview}
                                  checked={
                                    controlValues[
                                      activeSubPart.subPart.title
                                    ]?.[index]?.selectedOptions?.includes(
                                      option
                                    ) || false
                                  }
                                  style={{ marginRight: "3%" }}
                                  onChange={(e) => {
                                    const newValue = e.target.checked
                                      ? [
                                          ...(controlValues[
                                            activeSubPart.subPart.title
                                          ]?.[index]?.selectedOptions || []),
                                          option,
                                        ]
                                      : controlValues[
                                          activeSubPart.subPart.title
                                        ]?.[index]?.selectedOptions.filter(
                                          (val) => val !== option
                                        ) || [];
                                    this.handleControlChange(
                                      activeSubPart.subPart.title,
                                      index,
                                      "selectedOptions",
                                      newValue
                                    );
                                  }}
                                />
                                {option}
                              </label>
                            ))}
                          </div>
                        )}

                        {(control.type === "Textbox" ||
                          control.type === "Subfields") && (
                          <label
                            htmlFor={`input-${index}`}
                            style={{
                              display: "block",
                              marginBottom: "0.5rem",
                            }}
                          >
                            <input
                              id={`input-${index}`}
                              type="text"
                              disabled={this.props.ispreview}
                              value={
                                controlValues[activeSubPart.subPart.title][
                                  index
                                ].enteredText
                              }
                              placeholder={
                                controlValues[activeSubPart.subPart.title][
                                  index
                                ].text || ""
                              }
                              style={{
                                width: "50%",
                                height: "2.5rem",
                                borderRadius: "7px",
                                display: "block",
                                marginTop: "0.5rem",
                              }}
                              onChange={(e) =>
                                this.handleControlChange(
                                  activeSubPart.subPart.title,
                                  index,
                                  "enteredText",
                                  e.target.value
                                )
                              }
                            />
                          </label>
                        )}
                      </div>
                    ))}
                    {this.props.ispreview ? null : (
                      <button
                        classtitle="btn btn-green"
                        style={{
                          backgroundColor: "#4FD1C5",
                          border: 0,
                          marginLeft: "75%",
                          marginTop: "5%",
                          width: "-webkit-fill-available",
                          height: "3rem",
                          borderRadius: "5px",
                          color: "white",
                        }}
                        onClick={this.transformControlValues}
                      >
                        Save Choice
                      </button>
                    )}
                  </div>
                ) : (
                  <p>No choice selected</p>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  height: "3rem",
                  justifyContent: "end",
                  marginTop: "3%",
                  width: "-webkit-fill-available",
                }}
              >
                {isSaved && (
                  <>
                    <button
                      className="btn btn-green"
                      style={{
                        backgroundColor: "#4FD1C5",
                        border: 0,
                        width: "inherit",
                        borderRadius: "5px",
                        color: "white",
                      }}
                    >
                      Print
                    </button>
                    <button
                      className="btn btn-green"
                      onClick={(e) => this.exportPdf()}
                      style={{
                        backgroundColor: "#4FD1C5",
                        border: 0,
                        marginLeft: "3%",
                        marginRight: "3%",
                        width: "inherit",
                        borderRadius: "5px",
                        color: "white",
                      }}
                    >
                      Download
                    </button>
                  </>
                )}
                {this.props.ispreview ? null : (
                  <button
                    type="submit"
                    classtitle="btn btn-green"
                    style={{
                      backgroundColor: this.areAllRequiredFieldsFilled()
                        ? "#4FD1C5"
                        : "grey", // Change color based on disabled state

                      borderColor: "transparent",
                      width: isSaved === false ? "50%" : "111%",
                      borderRadius: "5px",
                      color: "white",
                    }}
                    onClick={this.CutSheetFormSubmit}
                    disabled={!this.areAllRequiredFieldsFilled()}
                  >
                    Submit CutSheet Form
                  </button>
                )}
                <div style={{ height: "20px" }}></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    login: { loggedIn },
    login: { user },
  } = state;
  return { loggedIn, user };
};

export default connect(mapStateToProps)(CutSheetAnimalComponent);
