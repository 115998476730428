import { Button, Menu, MenuItem } from "@material-ui/core";
import React, { Component } from "react";

import DialogPopup from "../../../components/Popup/DialogPopup";
import EditEvent from "./EditEvent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NOTIFICATION } from "../../../actions";
import Service from "../../../Service/Service";
import { Skeleton } from "@material-ui/lab";
import YesNoPopup from "../../../components/Popup/YesNoPopup";
import axios from "axios";
import { connect } from "react-redux";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";

export class EventsPopup extends Component {
  state = {
    list_order: [],
    list_order_download: [{ "No data found found": "" }],
    list_order_loading: true,
    anchorEl: false,
    clicked: false,
    popup: {
      yesNoModal: {
        open: false,
        title: null,
        subtitle: null,
        callback: null,
        type: null,
      },
    },
    editEventModal: false,
  };

  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    this.fetch_orders();
  }

  componentDidUpdate(prevProps, prevState) {
    // if (prevProps.clicked !== this.props.clicked) {
    //   console.log("component update");
    //   this.refreshInvoice()
    // }
    if (prevProps.date !== this.props.date) {
      // console.log("component update");
      this.fetch_orders();
    }
  }
  refresh = () => {
    this.fetch_orders();
    this.props.refresh();
  };

  animalLabel = (code) => {
    if (typeof code === "undefined") return "";

    const arr = code.split("_");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(" ");
  };

  TableRowsLoader = (rowsNum, colsNum) => {
    return [...Array(rowsNum)].map((row, indexRow) => (
      <tr key={indexRow}>
        {[...Array(colsNum)].map((row, indexCol) => (
          <td key={indexCol}>
            <Skeleton animation="wave" variant="text" />
          </td>
        ))}
      </tr>
    ));
  };

  fetch_orders = () => {
    let url = process.env.REACT_APP_BASE_URL;
    let self = this;
    let data = new FormData();
    self.setState({
      list_order: [],
      list_order_download: [{ "No data found found": "" }],
      list_order_loading: true,
    });

    data = {
      // event_date: moment(this.state.date_today).format("YYYY-MM-DD"),
      order_type: "pending,open,complete",
      event_date: this.props.date,
    };
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.user.user_token;
    axios
      .post(`${url}order/pro_order_list`, data)
      .then(function (response) {
        if (
          response &&
          response.data &&
          response.data &&
          response.data.status === "no"
        ) {
          self.props.dispatch(
            NOTIFICATION({ msg: response.data.message, msgColor: "#FAD5BD" })
          );
          setTimeout(() => {
            self.props.dispatch(NOTIFICATION({ msg: "" }));
          }, 2000);

          self.setState({
            list_order_loading: false,
          });
        } else {
          let listing_data = response.data.data;

          if (listing_data && listing_data.length > 0) {
            let list_order = listing_data;
            if (listing_data.length > 0) {
              let order_download = [];
              for (var i = 0; i < list_order.length; i++) {
                let order = {
                  "Order ID": list_order[i].order_id,
                  QTY: list_order[i].qty,
                  "First Name": list_order[i].first_name,
                  "Last Name": list_order[i].last_name,
                  Phone: list_order[i].phone,
                  Email: list_order[i].email,
                  "Event Name": list_order[i].title,
                  Weight: list_order[i].weight,
                  "Ear Tag ID": list_order[i].ear_tag_id,
                  "Hang Time": list_order[i].hang_time,
                  "Cut Sheet": "",
                };
                order_download.push(order);
              }
              self.setState({
                list_order_download: order_download,
              });
            } else {
              self.setState({
                list_order_download: [{ "No data found found": "" }],
              });
            }
            // console.log("list_order", list_order);

            // self.filter_order_list(list_order, "list_order");
            self.setState({
              list_order,
              // list_order_download: [{ "No data found found": "" }],
              list_order_loading: false,
            });
          } else {
            self.setState({
              list_order: [],
              list_order_download: [{ "No data found found": "" }],
              list_order_loading: false,
            });
          }
        }
      })
      .catch((error) => {
        self.props.dispatch(
          NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
        );
        setTimeout(() => {
          self.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 2000);

        self.setState({
          list_order: [],
          list_order_loading: false,
        });
      });
  };

  handleDropdownClose = () => {
    this.setState({
      anchorEl: false,
    });
  };

  handleConfirm = (status) => {
    let title = "Are you sure";
    let subtitle = `Want to ${status} this order `;
    let callback;
    let status_num;

    switch (status) {
      case "complete":
        status_num = 3;
        callback = this.updateOrder;
        break;
      case "cancel":
        status_num = 0;
        callback = this.updateOrder;
        break;
      case "delete":
        status_num = -1;
        callback = this.removeOrder;
        break;
    }

    this.setState({
      popup: {
        ...{
          yesNoModal: {
            ...{
              open: true,
              title,
              subtitle,
              callback: callback,
              status: status_num,
            },
          },
        },
      },
    });

    this.handleDropdownClose();
  };

  removeOrder = () => {
    let self = this;
    let payload = {
      order_id: this.state.current_popup_data.order_id,
    };

    return new Service(this.props.user.user_token)
      .post("/order/removeOrder", payload)
      .then((response) => {
        console.log("respnse", response);
        if (response.status == "yes") {
          self.props.dispatch(NOTIFICATION({ msg: "Order was removed" }));
          self.fetch_orders();
          setTimeout(() => {
            self.props.dispatch(NOTIFICATION({ msg: "" }));
          }, 2000);
        } else if (response.status == "no") {
          self.props.dispatch(
            NOTIFICATION({ msg: response.message, msgColor: "#FAD5BD" })
          );
          setTimeout(() => {
            self.props.dispatch(NOTIFICATION({ msg: "" }));
          }, 2000);
        }
      })
      .catch((error) => {
        self.props.dispatch(
          NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
        );
        setTimeout(() => {
          self.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 2000);
      });
  };

  animalLabel = (code) => {
    const arr = code.split("_");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    return arr.join(" ");
  };

  statusLabel = (code) => {
    switch (code) {
      case 1:
        return "Open";
      case 0:
        return "Pending";
      case 2:
        return "Rejected";
      case 3:
        return "Completed";
    }
  };

  render() {
    let { user } = this.props;

    return (
      <>
        {this.props.open && (
          <DialogPopup
            title={moment(this.props.date).format("DD-MM-YYYY")}
            subtitle="Events"
            open={this.props.open}
            onClose={(e) => () => this.props.close()}
            maxWidth="xl"
          >
            <div className="table-responsive" id="print-group-table">
              <table className="table cstmGrzrTable">
                <thead>
                  <tr>
                    <th>Order#</th>
                    <th>Title</th>
                    <th>Tag#</th>
                    <th>Species</th>
                    <th>Label Type</th>
                    {/* <th>Hang Weight</th> */}
                    <th>Account Name</th>
                    <th>Status</th>
                    <th>Cutsheet Status</th>
                    {/* <th>Storage Location</th> */}
                    {/* <th>Day From Kill</th> */}
                    {/* <th>Cut Date</th> */}
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.list_order_loading && this.TableRowsLoader(5, 8)}
                  {!this.state.list_order_loading &&
                    this.state.list_order.length === 0 && (
                      <tr>
                        <td colSpan="8">No data found</td>
                      </tr>
                    )}
                  {this.state.list_order.map((order, index) => {
                    const menus = [];
                    // console.log('order status', order.status)
                    // if(order.status !== 3)
                    // menus.push(<MenuItem key="1" onClick={() => this.setState({ editEventModal: true })}>Edit</MenuItem>)
                    // // if (order.status == 0 || order.status == "0") {
                    // menus.push(<MenuItem key="2" onClick={(e) => this.handleConfirm('delete')}>Delete</MenuItem>)
                    // }

                    return (
                      <tr key={index}>
                        <td>{order.order_id} </td>
                        <td>{order.title} </td>
                        <td>{order.tagid}</td>
                        <td>{this.animalLabel(order.species)}</td>
                        <td>{order.process_option}</td>
                        {/* <td>{order.hw_animal_total ? order.hw_animal_total : "-"}</td> */}
                        <td>
                          {order.cart_details.map((e) => {
                            return (
                              e.first_name +
                              " " +
                              e.last_name +
                              " (" +
                              e.amount +
                              "), "
                            );
                          })}
                        </td>
                        <td>{this.statusLabel(order.status)}</td>
                        {/* <td>{order.location ? order.location : "-"}</td> */}
                        {/* <td>{order.hang_time ? order.hang_time : "-"}</td> */}
                        {/* <td>{order.cut_date ? moment(order.cut_date).format('MM/DD/YY') : "-"}</td> */}
                        <td>
                          <div className="d-flex align-items-center">
                            <p>{order.form_status}</p>
                          </div>
                        </td>
                        <td>
                          <Button
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={(event) =>
                              this.setState({
                                current_popup_data: order,
                                anchorEl: event.currentTarget,
                                clicked: !this.state.clicked,
                                showmodal: this.state.showmodal,
                              })
                            }
                          >
                            <FontAwesomeIcon
                              className="mr-3"
                              style={{ color: "black" }}
                              icon={faBars}
                            />
                          </Button>
                          <Menu
                            id="basic-menu"
                            anchorEl={this.state.anchorEl}
                            open={this.state.anchorEl ? true : false}
                            onClose={this.handleDropdownClose}
                          >
                            {this.state.current_popup_data?.status !== 3 && (
                              <MenuItem
                                key="1"
                                onClick={() =>
                                  this.setState({ editEventModal: true })
                                }
                              >
                                Edit
                              </MenuItem>
                            )}
                            <MenuItem
                              key="2"
                              onClick={(e) => this.handleConfirm("delete")}
                            >
                              Delete
                            </MenuItem>
                            {/* {menus.map((menu) => {
                                                            return menu
                                                        })} */}
                          </Menu>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <DialogPopup
              title="Edit Order"
              subtitle={moment(this.props.date).format("MM DD YYYY")}
              maxWidth="lg"
              open={this.state.editEventModal}
              onClose={(e) => () => this.setState({ editEventModal: false })} // Close the dialog when requested
              style={{ marginTop: "5rem" }}
            >
              <EditEvent
                refresh={this.refresh}
                onClose={() => this.setState({ editEventModal: false })}
                order={this.state.current_popup_data}
              ></EditEvent>
            </DialogPopup>
            <YesNoPopup
              show={this.state.popup.yesNoModal.open}
              close={() => {
                console.log("close clicked");
                this.setState({
                  popup: {
                    ...{
                      yesNoModal: {
                        ...{ open: false },
                      },
                    },
                  },
                });
              }}
              title={this.state.popup.yesNoModal.title}
              subtitle={this.state.popup.yesNoModal.subtitle}
              callback={this.state.popup.yesNoModal.callback}
            />
          </DialogPopup>
        )}
      </>
    );
  }
}

function mapStateToProps(state) {
  const { user, loggedIn } = state.login;

  return { user, loggedIn };
}
export default connect(mapStateToProps)(EventsPopup);
