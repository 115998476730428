export const bisonParts = [
  {
    id: "organs",
    title: "Organs",
    fontSize: "0px",
    coordinates: { top: "15%", left: "10%" },
    rotation: 0,
    subcuts: [],
  },
  {
    id: "head",
    title: "Head",
    fontSize: "18px",
    coordinates: { top: "25%", left: "80%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "brisket",
    title: "Brisket",
    fontSize: "20px",
    coordinates: { top: "45%", left: "68%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "foreShank",
    title: "Fore Shank",
    fontSize: "18px",
    coordinates: { top: "50%", left: "55%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "shortPlate",
    title: "Short Plate",
    fontSize: "20px",
    coordinates: { top: "45%", left: "40%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "flank",
    title: "Flank",
    fontSize: "18px",
    coordinates: { top: "45%", left: "25%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "shank",
    title: "Shank",
    fontSize: "18px",
    coordinates: { top: "45%", left: "5%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "round",
    title: "Round",
    fontSize: "22px",
    coordinates: { top: "25%", left: "5%" },
    rotation: -10,
    subParts: [],
  },
  {
    id: "sirloin",
    title: "Sirloin",
    fontSize: "20px",
    coordinates: { top: "20%", left: "22%" },
    rotation: -10,
    subParts: [],
  },
  {
    id: "shortLoin",
    title: "Short Loin",
    fontSize: "20px",
    coordinates: { top: "20%", left: "36%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "rib",
    title: "Rib",
    fontSize: "22px",
    coordinates: { top: "20%", left: "45%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "chuck",
    title: "Chuck",
    fontSize: "22px",
    coordinates: { top: "15%", left: "60%" },
    rotation: 0,
    subParts: [],
  },
];
