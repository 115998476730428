export const lambParts = [
  {
    id: "organs",
    title: "Organs",
    fontSize: "0px",
    coordinates: { top: "15%", left: "10%" },
    rotation: 0,
    subcuts: [],
  },
  {
    id: "head",
    title: "Head",
    fontSize: "18px",
    coordinates: { top: "0%", left: "5%" },
    rotation: -40,
    subParts: [],
  },
  {
    id: "tongue",
    title: "Tongue",
    fontSize: "13px",
    coordinates: { top: "16%", left: "01%" },
    rotation: -20,
    subParts: [],
  },
  {
    id: "cheek",
    title: "Cheek",
    fontSize: "14px",
    coordinates: { top: "8%", left: "9.5%" },
    rotation: -50,
    subParts: [],
  },
  {
    id: "neck",
    title: "Neck",
    fontSize: "20px",
    coordinates: { top: "12%", left: "16%" },
    rotation: -45,
    subParts: [],
  },
  {
    id: "shoulder",
    title: "Shoulder",
    fontSize: "22px",
    coordinates: { top: "25%", left: "22%" },
    rotation: -30,
    subParts: [],
  },
  {
    id: "rib",
    title: "Rib",
    fontSize: "25px",
    coordinates: { top: "22%", left: "40%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "breast",
    title: "Breast",
    fontSize: "22px",
    coordinates: { top: "45%", left: "35%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "fore_shank",
    title: "Fore Shank",
    fontSize: "15px",
    coordinates: { top: "58%", left: "34%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "loin",
    title: "Loin",
    fontSize: "22px",
    coordinates: { top: "25%", left: "56%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "flank",
    title: "Flank",
    fontSize: "22px",
    coordinates: { top: "50%", left: "58%" },
    rotation: -10,
    subParts: [],
  },
  {
    id: "sirloin",
    title: "Sirloin",
    fontSize: "22px",
    coordinates: { top: "25%", left: "66%" },
    rotation: -90,
    subParts: [],
  },
  {
    id: "hind_shank",
    title: "Hind Shank",
    fontSize: "15px",
    coordinates: { top: "62%", left: "86%" },
    rotation: 0,
    subParts: [],
  },
  {
    id: "leg",
    title: "Leg",
    fontSize: "30px",
    coordinates: { top: "35%", left: "80%" },
    rotation: -5,
    subParts: [],
  },
];
