
import React from "react";
import { ElementStore, Registry } from "react-form-builder2";
import "react-form-builder2/dist/app.css";

export const TestComponent = () => <h2>Hello</h2>;

export const MyInput = React.forwardRef((props, ref) => {
  const { name, defaultValue, disabled } = props;
  return (
    <input ref={ref} name={name} defaultValue={defaultValue} disabled={disabled} />
  );
});

Registry.register('MyInput', MyInput);
Registry.register('TestComponent', TestComponent);