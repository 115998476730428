export const cowParts = [
  {
    id: "organs",
    title: "Organs",
    fontSize: "0px",
    coordinates: { top: "15%", left: "10%" },
    rotation: 0,
    subcuts: [],
  },
  {
    id: "head",
    title: "Head",
    fontSize: "22px",
    coordinates: { top: "15%", left: "10%" },
    rotation: -15,
    subcuts: [],
  },
  {
    id: "chuck",
    title: "Chuck",
    fontSize: "20px",
    coordinates: { top: "20%", left: "30%" },
    rotation: 0,
    subcuts: [],
  },
  {
    id: "brisket",
    title: "Brisket",
    fontSize: "20px",
    coordinates: { top: "45%", left: "25%" },
    rotation: 50,
    subcuts: [],
  },
  {
    id: "foreShank",
    title: "Fore Shank",
    fontSize: "15px",
    coordinates: { top: "45%", left: "38%" },
    rotation: 5,
    subcuts: [],
  },
  {
    id: "rib",
    title: "Rib",
    fontSize: "20px",
    coordinates: { top: "20%", left: "45%" },
    rotation: 0,
    subcuts: [],
  },
  {
    id: "shortPlate",
    title: "Short Plate",
    fontSize: "16px",
    coordinates: { top: "45%", left: "52%" },
    rotation: 0,
    subcuts: [],
  },
  {
    id: "shortLoin",
    title: "Short Loin",
    fontSize: "15px",
    coordinates: { top: "20%", left: "56%" },
    rotation: -5,
    subcuts: [],
  },
  {
    id: "sirloin",
    title: "Sirloin",
    fontSize: "15px",
    coordinates: { top: "20%", left: "65%" },
    rotation: -90,
    subcuts: [],
  },
  {
    id: "flank",
    title: "Flank",
    fontSize: "18px",
    coordinates: { top: "43%", left: "66%" },
    rotation: -80,
    subcuts: [],
  },
  {
    id: "rumpCap",
    title: "Rump Cap",
    fontSize: "14px",
    coordinates: { top: "8%", left: "85%" },
    rotation: 10,
    subcuts: [],
  },
  {
    id: "round",
    title: "Round",
    fontSize: "19px",
    coordinates: { top: "30%", left: "80%" },
    rotation: 8,
    subcuts: [],
  },
  {
    id: "shank",
    title: "Shank",
    fontSize: "16px",
    coordinates: { top: "52%", left: "82%" },
    rotation: -25,
    subcuts: [],
  },
];
