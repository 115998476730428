import "./Header.css";

import {
  LOGIN,
  LOGIN_FAILURE,
  LOGOUT,
  NOTIFICATION,
  updateCart,
} from "../../actions";
import React, { Component, useEffect, useState } from "react";
import {
  faBars,
  faBell,
  faEllipsisV,
  faGear,
  faHome,
  faInfo,
  faPlusCircle,
  faQuestionCircle,
  faRefresh,
  faSearch,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";

import ApprovalPopup from "../../components/Popup/ApprovalPopup";
import { Button } from "@material-ui/core";
import DialogPopup from "../../components/Popup/DialogPopup";
import EditEvent from "../../views/ProcessorDashboard/EventsPopup/EditEvent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ItemDetails from "../../components/OrderTable/ItemDetails";
import { Link } from "react-router-dom";
import NewOrder from "../Dashboard/Processor/NewOrder";
import NotesIcon from "@material-ui/icons/Notes";
import Notification from "../../components/Notification/Notification";
import ReasonPopup from "../../components/Popup/ReasonPopup";
import Service from "../../Service/Service";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import axios from "axios";
import { connect } from "react-redux";
import moment from "moment";

const pStyle = {
  textAlign: "center",
};

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profiledrop: "profiledrop",
      profiledropToggle: false,
      menulist: "",
      pwaToast: false,
      dateTime: "sadas",
      dropdown: false,
      isMobile: false,
      isMenuOpen: false,
      isOpenVersion: false,
      isOpen: false,
      currentDate: "",
      customer_data: [],
      customer_loading: false,
      itemPopup: false,
      selectedCustomer: null,
      menuVisible: false,
      setMenuVisible: false,
      scheduled_order: [],
      title: "",
      current_popup_data: "",
      cutSheetTemp: [],
      reason_popup: false,
    };
    this.handleWindowResize = this.handleWindowResize.bind(this);
  }
  closeM = (order, re) => {
    this.setState({
      reason_popup: false,
    });
    this.updateStatus(2, order, re);
  };
  closeA = (order, re) => {
    this.setState({
      itemPopup: false,
    });
    this.updateStatus(0, order, re);
  };
  fetch_all_schedule = () => {
    let url = process.env.REACT_APP_BASE_URL;
    let self = this;
    let data = new FormData();
    self.setState({
      list_order: [],
      list_order_download: [{ "No data found found": "" }],
      list_order_loading: true,
    });

    data = {
      event_date: moment(this.state.date_today).format("L"),
    };
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.user.user_token;
    axios
      .post(`${url}dashboard/getActionCenter`, data)
      .then(function (response) {
        if (
          response &&
          response.data &&
          response.data &&
          response.data.status === "no"
        ) {
          self.props.dispatch(
            NOTIFICATION({ msg: response.data.message, msgColor: "#FAD5BD" })
          );
          setTimeout(() => {
            self.props.dispatch(NOTIFICATION({ msg: "" }));
          }, 2000);
        } else {
          let listing_data = response.data.data;

          if (listing_data && listing_data.length > 0) {
            let list_order = listing_data.filter((list) => {
              let new_str = list.event_date.replace(/\T.+/g, "");
              let day = moment(new_str).format("L");
              let today = moment(self.state.date_today).format("L");
              return day === today;
            });
            if (list_order.length > 0) {
              let order_download = [];
              for (var i = 0; i < list_order.length; i++) {
                let order = {
                  "Order ID": list_order[i].order_id,
                  QTY: list_order[i].qty,
                  "First Name": list_order[i].first_name,
                  "Last Name": list_order[i].last_name,
                  Phone: list_order[i].phone,
                  Email: list_order[i].email,
                  "Event Name": list_order[i].title,
                  Weight: list_order[i].weight,
                  "Ear Tag ID": list_order[i].ear_tag_id,
                  "Hang Time": list_order[i].hang_time,
                  "Cut Sheet": "",
                };
                order_download.push(order);
              }
              self.setState({
                list_order_download: order_download,
              });
            } else {
              self.setState({
                list_order_download: [{ "No data found found": "" }],
              });
            }
            console.log("list_order", list_order);

            self.filter_order_list(list_order, "list_order");
          } else {
            self.setState({
              list_order: [],
              list_order_download: [{ "No data found found": "" }],
              list_order_loading: false,
            });
          }
        }
      })
      .catch((error) => {
        self.props.dispatch(
          NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
        );
        setTimeout(() => {
          self.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 2000);
      });
  };
  updateStatus = (status, order, reject_reason) => {
    let self = this;
    let payload = {
      schedule_id: order.order_id,
      ng_order: order.ng_order,
      order_id: order.project_id,
      status: status,
      reject_reason: reject_reason,
      project_id: order.project_id,
    };
    return new Service(this.props.user.user_token)
      .post("/calendar/updateProcessorAcceptanceStatus", payload)
      .then((data) => {
        if (status == 2) {
          self.props.dispatch(
            NOTIFICATION({ msg: "Order was rejected", msgColor: "#FAD5BD" })
          );
          // self.fetch_orders();

          setTimeout(() => {
            window.location.reload();
          }, 1000);
        } else {
          if (status == 0) {
            self.props.dispatch(NOTIFICATION({ msg: "Order was accepted" }));
            // self.fetch_orders();

            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
        }
      })
      .catch((error) => {
        self.props.dispatch(
          NOTIFICATION({ msg: error.message, msgColor: "#FAD5BD" })
        );
        setTimeout(() => {
          self.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 2000);
      });
  };
  state = { showSideNav: false };

  myAccountNav = () => {
    this.setState({
      profiledropToggle: !this.state.profiledropToggle,
    });
  };

  toggleMenu = () => {
    this.setState((prevState) => ({
      menuVisible: !prevState.menuVisible,
    }));
  };

  reject_reason = (status, event) => (e) => {
    this.setState({
      actionitem: false,
      reason_popup: true,
      current_popup_data: event,
    });
  };

  closeToggle = (order, details, type) => {
    if (type != "") {
      document.body.classList.remove("modal-open");
      this.setState({
        itemPopup: false,
      });
    }
    console.log("closeToggle", details, type);
    this.updateStatus(1, order, details, type);
  };
  openModalp = (event) => (e) => {
    e.preventDefault();
    if (event) {
      document.body.classList.add("modal-open");
    } else document.body.classList.remove("modal-open");
    this.setState({
      trackingpopup: event,
      reason_popup: event,
      itemPopup: event,
    });
  };

  clickToRedirect = (event) => {
    console.log(
      "Access Token before logout:",
      localStorage.getItem("access_token")
    );

    this.onresclick();
    let prop = this.props;
    let link = "/login";
    this.setState(
      {
        profiledropToggle: false,
      },
      () => {
        localStorage.setItem("user", "");
        localStorage.setItem("draft", 0);
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        prop.dispatch(LOGOUT({}));
      }
    );
  };

  closeToggle = (path) => (event) => {
    const { name } = event.target;
    this.setState(() => {
      if (
        path !== "/processordashboard/message" &&
        path !== "/buyerdashboard/message" &&
        path !== "/sellerdashboard/message"
      ) {
        path && this.props.history.push(path);
      } else {
        this.props.history.push({
          pathname: path,
          state: { msgUserid: "admin" },
        });
      }
    });
  };

  handleBlur = () => {
    this.setState({
      profiledropToggle: false,
    });
  };

  getCart = async () => {
    try {
      let {
        user: { user_token },
        dispatch,
      } = this.props;
      const res = await new Service(user_token).get("cart/getUserCart", {});
      if (res.data.status) {
        let cart_data = res.data.data.filter((val) => parseInt(val.r_id) === 0);
        this.props.dispatch(
          updateCart({
            count: cart_data.length,
            data: cart_data,
          })
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  getDatetime = () => {
    const date = new Date();
    const options = {
      month: "short",
      day: "numeric",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };
    const formattedDateTime = date.toLocaleString("en-US", options);
    this.setState({ dateTime: formattedDateTime });
  };

  fetch_all_customer = () => {
    let url = process.env.REACT_APP_BASE_URL;
    let self = this;
    self.setState({
      customer_loading: true,
    });

    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.user.user_token;
    axios
      .get(`${url}profile/getProcessors`)
      .then(function (response) {
        if (response && response.data && response.data.status === "no") {
          self.props.dispatch(
            NOTIFICATION({ msg: response.data.message, msgColor: "#FAD5BD" })
          );
          setTimeout(() => {
            self.props.dispatch(NOTIFICATION({ msg: "" }));
          }, 2000);
        } else {
          let listing_data = response.data.processors; // Corrected to response.data.processors
          console.log("listing_data", listing_data);
          if (listing_data && listing_data.length > 0) {
            self.setState({
              customer_data: listing_data,
              selectedCustomer: listing_data[0].id, // Set the first customer as the default selected value
            });
          } else {
            self.setState({
              customer_data: [],
            });
          }
        }

        self.setState({
          customer_loading: false,
        });
      })
      .catch(function (error) {
        console.error("Error fetching customers:", error);
        self.setState({
          customer_loading: false,
        });
      });
  };

  handleCustomerChange = (event) => {
    const selectedCustomer = this.state.customer_data.find(
      (customer) => customer.id === parseInt(event.target.value)
    );
    this.setState({ selectedCustomer });

    // API call to set the current processor
    let url = process.env.REACT_APP_BASE_URL;
    let self = this;
    self.setState({
      customer_loading: true,
    });

    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.user.user_token;
    axios
      .post(`${url}profile/setCurrentProcessor`, {
        processor: selectedCustomer.id,
      })
      .then(function (response) {
        if (response && response.data && response.data.status === "no") {
          self.props.dispatch(
            NOTIFICATION({ msg: response.data.message, msgColor: "#FAD5BD" })
          );
          setTimeout(() => {
            self.props.dispatch(NOTIFICATION({ msg: "" }));
          }, 2000);
        } else {
          console.log("Processor set successfully:", response.data);
          // Fetch the updated customer data
          self.fetch_all_customer();
          // Refresh the current page
          window.location.reload();
        }

        self.setState({
          customer_loading: false,
        });
      })
      .catch(function (error) {
        console.error("Error setting processor:", error);
        self.setState({
          customer_loading: false,
        });
      });
  };

  componentDidMount() {
    window.addEventListener("scroll", () => {
      let activeClass = "normal";
      if (window.scrollY === 0) {
        activeClass = "top";
      }
      this.setState({ activeClass });
    });
    this.getCart();
    this.updateCurrentDate();
    this.fetch_all_customer();
    this.fetch_pending_orders();
    console.log("this.props.user:", this.props.user);
  }

  updateCurrentDate = () => {
    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    const currentDateRaw = new Date()
      .toLocaleDateString("en-US", options)
      .replaceAll("/", " ");

    this.setState({ currentDate: currentDateRaw });
  };

  onresclick = (e) => {};

  componentWillMount() {
    this.getDatetime();
    this.pwaFunction();
    window.addEventListener("resize", this.handleWindowResize);
    this.handleWindowResize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleWindowResize);
  }

  handleWindowResize() {
    this.setState({ isMobile: window.innerWidth < 768 });
  }

  fetch_pending_orders = () => {
    let url = process.env.REACT_APP_BASE_URL;
    let self = this;
    let data = new FormData();

    data = {
      // event_date: moment(this.state.date_today).format("YYYY-MM-DD"),
      order_type: "pending_approval",
    };
    axios.defaults.headers.common["Authorization"] =
      "Bearer " + this.props.user.user_token;
    axios.post(`${url}order/pro_order_list`, data).then(function (response) {
      if (
        response &&
        response.data &&
        response.data &&
        response.data.status === "no"
      ) {
        self.props.dispatch(
          NOTIFICATION({ msg: response.data.message, msgColor: "#FAD5BD" })
        );
        setTimeout(() => {
          self.props.dispatch(NOTIFICATION({ msg: "" }));
        }, 2000);
      } else {
        // let listing_data = response.data.data;
        self.setState({ scheduled_order: response.data.data });
        console.log(response.data.data);
      }
    });
  };

  async fetchDataValidate() {
    localStorage.setItem("auth_token", this.props.user.user_token);
    const accessToken = localStorage.getItem("access_token");
    console.log("Access Token before validation:", accessToken);
    // Existing code...
    try {
      let accessToken = localStorage.getItem("access_token");
      if (!accessToken) {
        this.fetchData();
        return;
      }
      const expires_in = localStorage.getItem("expires_in");
      const refresh_token = localStorage.getItem("refresh_token");
      const token_type = localStorage.getItem("token_type");
      const x_refresh_token_expires_in = localStorage.getItem(
        "x_refresh_token_expires_in"
      );

      let url = process.env.REACT_APP_BASE_URL + "qbvalidate";
      const headers = {
        "Content-Type": "application/json",
        Authorization: "Bearer " + this.props.user.user_token,
      };
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          refresh_token: refresh_token,
          x_refresh_token_expires_in: x_refresh_token_expires_in,
          access_token: accessToken,
          token_type: token_type,
          expires_in: expires_in,
        }),
      });

      if (response.status === 200) {
        const responseData = await response.json();
        console.log("Response Data:", responseData);
      } else {
        console.error("Failed to validate access token:", response.statusText);
        localStorage.removeItem("access_token");
        localStorage.removeItem("expires_in");
        localStorage.removeItem("refresh_token");
        localStorage.removeItem("token_type");
        localStorage.removeItem("x_refresh_token_expires_in");
        this.fetchData();
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  }

  async fetchData() {
    let url = process.env.REACT_APP_BASE_URL;
    try {
      const headers = {
        Authorization: "Bearer " + this.props.user.user_token,
        "Content-Type": "application/json",
      };
      const response = await fetch(url + "qblogin", {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          redirectUri: process.env.REACT_APP_URL,
        }),
      });
      if (response.ok) {
        const responseData = await response.json();
        window.location.href = responseData.url;
        const responseData1 = await response.text();
        console.log("Response Data:", responseData1);
      } else {
        console.error("Failed to fetch data:", response.statusText);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
    }
  }

  pwaFunction = () => {
    console.log("entered pwa function inititated");
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      if (/iphone|ipad|ipod/.test(userAgent)) {
        if (/chrome|crios/.test(userAgent)) {
          return false;
        } else if ("brave" in window.navigator) {
          return false;
        } else {
          return /safari/.test(userAgent);
        }
      }
    };

    const isInStandaloneMode = () =>
      "standalone" in window.navigator && window.navigator.standalone;

    if (isIos() && !isInStandaloneMode()) {
      this.setState({ pwaToast: true });
      this.setState({ showInstallMessage: true });
    }
  };

  render() {
    let { user, loggedIn, msg, msgLink, msgColor, classs, cartData } =
      this.props;
    let img_url = process.env.REACT_APP_IMAGE_URL;
    let url = process.env.REACT_APP_BASE_URL;
    const { customer_data, customer_loading, selectedCustomer } = this.state;
    // Optional: Add some basic styles for the menu
    const menuStyles = {
      position: "absolute",
      top: "61px", // Adjust based on your layout
      right: "10px", // Adjust based on your layout
      backgroundColor: "#fff",
      border: "1px solid #ccc",
      borderRadius: "11px",
      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
      padding: "10px",
      zIndex: 1000,
      height: "500px", // Set the desired height
      // Enable vertical scrolling
    };

    const listViewStyles = {
      position: "static",
      top: "61px", // Adjust based on your layout
      right: "10px", // Adjust based on your layout

      zIndex: 1000,
      height: "400px", // Set the desired height
      overflowY: "auto", // Enable vertical scrolling
    };
    const userId = this.props.user.id;

    // Filter out orders where the user ID matches the associate ID
    const filteredOrders = this.state.scheduled_order.filter(
      (order) => order.associate !== userId || order.isCustCreated == 1
    );

    return (
      <div className="G-zr">
        {/* <StickyCompenet {...this.props} /> */}
        {!this.state.isMobile ? (
          <header className={`fixed-top Af-log `}>
            <nav className="navbar navbar-expand-lg p-0">
              <Link className="navbar-brand" to="/">
                <img
                  src="/images/logo-white.png"
                  className="img-thumnail logo1"
                  alt="LOGO"
                />
              </Link>
              <a href="#" className="header-toggler">
                <FontAwesomeIcon
                  className="mr-3"
                  style={{ color: "black" }}
                  icon={faBars}
                />
              </a>
              <a href="/processordashboard" className="header-toggler">
                <FontAwesomeIcon
                  className="mr-3"
                  style={{ color: "black" }}
                  icon={faHome}
                />
              </a>
              {["customer", "producer"].indexOf(user.role) !== -1 && (
                <div>
                  {customer_loading ? (
                    <p>Loading...</p>
                  ) : (
                    <select
                      className="custom-select"
                      onChange={this.handleCustomerChange}
                      value={selectedCustomer ? selectedCustomer.id : ""}
                    >
                      <option value="" disabled>
                        Select Processor
                      </option>
                      {customer_data.map((customer) => (
                        <option key={customer.id} value={customer.id}>
                          {customer.first_name} {customer.last_name}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              )}

              <div
                className="collapse navbar-collapse mr-0"
                id="navbarSupportedContent"
              >
                {/* <ul className={`navbar-nav ${this.state.lefttog}onres ml-auto`}> */}
                <ul
                  className={
                    `navbar-nav onres` +
                    " " +
                    `${this.state.menulist}` +
                    " " +
                    `ml-auto`
                  }
                >
                  {["processor", "admin", "manager"].indexOf(
                    this.props.user.role
                  ) !== -1 && (
                    <div>
                      {localStorage.getItem("access_token") ? (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <span
                            className="material-icons"
                            style={{
                              color: "green",
                              fontSize: "35px",
                              marginRight: "5px",
                            }}
                          >
                            check_circle
                          </span>
                          <span
                            style={{
                              marginRight: "19px",
                            }}
                          >
                            QuickBook is connected
                          </span>
                          <button
                            style={{
                              backgroundColor: "red",
                              color: "white",
                              padding: "10px 20px",
                              border: "none",
                              borderRadius: "5px",
                              cursor: "pointer",
                              marginRight: "20px",
                            }}
                            onClick={() => {
                              localStorage.removeItem("access_token");
                              localStorage.removeItem("refresh_token");
                              window.location.reload();
                            }}
                          >
                            Disconnect
                          </button>
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                          }}
                        >
                          <span
                            className="material-icons"
                            style={{
                              color: "red",
                              fontSize: "35px",
                              marginRight: "8px",
                            }}
                          >
                            cancel
                          </span>
                          <span
                            style={{
                              marginRight: "19px",
                            }}
                          >
                            QuickBook is not connected
                          </span>
                          <button
                            style={{
                              backgroundColor: "#4FD1C5",
                              color: "white",
                              padding: "8px 20px",
                              border: "none",
                              borderRadius: "8px",
                              cursor: "pointer",
                              marginRight: "20px",
                              fontFamily: "Roboto",
                              fontSize: "14px",
                            }}
                            onClick={() => {
                              // window.location.href = "/";
                              this.fetchDataValidate();
                            }}
                          >
                            Connect
                          </button>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="header-datetime">
                    <span>{this.state.dateTime}</span>
                  </div>
                  <FontAwesomeIcon
                    className="mr-3 ml-3"
                    style={{ color: "#78909C" }}
                    icon={faSearch}
                  />
                  <div className="bell-icon-container">
                    <FontAwesomeIcon
                      className="mr-3 ml-3"
                      style={{ color: "#78909C", cursor: "pointer" }}
                      icon={faBell}
                      onClick={this.toggleMenu}
                    />
                    {filteredOrders && filteredOrders.length > 0 && (
                      <span className="notification-dot"></span>
                    )}
                  </div>

                  {/* Conditional rendering of the menu */}
                  {this.state.menuVisible && (
                    <div className="menu" style={menuStyles}>
                      {/* Close Button */}
                      <button
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                          fontSize: "16px",
                        }}
                        onClick={() => this.setState({ menuVisible: false })}
                      >
                        <span className="material-icons">close</span>
                      </button>
                      <br />
                      <br />
                      <div className="menu" style={listViewStyles}>
                        {filteredOrders && filteredOrders.length > 0 ? (
                          filteredOrders.reverse().map((order, index) => (
                            <div
                              key={index}
                              style={{
                                border: "1px solid #ccc",
                                borderRadius: "8px",
                                padding: "10px",
                                marginBottom: "10px",
                                display: "flex",
                              }}
                            >
                              <p>
                                A new order has been created{" "}
                                {order.isCustCreated == 1 ? "by " : "for "}
                                {order.first_name + " "} {order.last_name}{" "}
                                {order.isCustCreated == 1 ? "" : "by "}
                                {order.isCustCreated == 1
                                  ? ""
                                  : order.associate_first_name + " "}
                                {order.isCustCreated == 1
                                  ? ""
                                  : order.associate_last_name}{" "}
                                with drop off date{" "}
                                {new Date(
                                  order.dropoff_date
                                ).toLocaleDateString("en-US", {
                                  month: "2-digit",
                                  day: "2-digit",
                                  year: "numeric",
                                })}
                                .
                              </p>
                              {["processor", "admin", "manager"].indexOf(
                                this.props.user.role
                              ) !== -1 && (
                                <div
                                  className="d-flex align-items-center"
                                  style={{ marginLeft: "18%" }}
                                >
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <>
                                        <button
                                          style={{ color: "green" }}
                                          className="icnButton chckButton"
                                          onClick={() =>
                                            this.setState({
                                              itemPopup: true,
                                              editEventModal: false,
                                              title: "Confirm Drop Off",
                                              current_popup_data: order,
                                              menuVisible: false,
                                            })
                                          }
                                        >
                                          <span className="material-icons">
                                            check_circle
                                          </span>
                                        </button>
                                        <button
                                          style={{ color: "red" }}
                                          className="icnButton cancelButton"
                                          onClick={this.reject_reason(2, order)}
                                        >
                                          <span className="material-icons">
                                            cancel
                                          </span>
                                        </button>
                                        <button
                                          className="icnButton editButton"
                                          onClick={() => {
                                            this.setState({
                                              itemPopup: false,
                                              editEventModal: true,
                                              current_popup_data: order,
                                              menuVisible: false,
                                            });
                                          }}
                                        >
                                          <span className="material-icons">
                                            edit
                                          </span>
                                        </button>
                                      </>
                                    </div>
                                  </td>
                                </div>
                              )}
                            </div>
                          ))
                        ) : (
                          <div
                            style={{
                              padding: "10px",
                              marginTop: "4%",
                            }}
                          >
                            {" "}
                            <p>No pending orders available.</p>
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  <FontAwesomeIcon
                    onClick={() =>
                      this.setState({ dropdown: !this.state.dropdown })
                    }
                    className="mr-3 ml-3 cursor-pointer"
                    style={{ color: "#78909C" }}
                    icon={faEllipsisV}
                  />
                  {this.state.itemPopup && (
                    <ApprovalPopup
                      title={this.state.current_popup_data.title}
                      closeModal={this.openModalp}
                      close={this.closeEventAddpop}
                      event_cid={this.state.current_popup_data.order_id}
                      current_popup_data={this.state.current_popup_data}
                      closeM={(e, re) => this.closeA(e, re)}
                    />
                  )}
                  {this.state.reason_popup ? (
                    <ReasonPopup
                      title={this.state.current_popup_data.title}
                      closeModal={this.openModalp}
                      close={this.closeEventAddpop}
                      event_cid={this.state.current_popup_data.order_id}
                      current_popup_data={this.state.current_popup_data}
                      closeM={(e, re) => this.closeM(e, re)}
                    />
                  ) : null}
                  <DialogPopup
                    title="Edit Order"
                    subtitle={moment(
                      this.state.current_popup_data.dropoff_date
                    ).format("MM DD YYYY")}
                    maxWidth="lg"
                    open={this.state.editEventModal}
                    onClose={(e) => () =>
                      this.setState({ editEventModal: false })} // Close the dialog when requested
                    style={{ marginTop: "5rem" }}
                  >
                    <EditEvent
                      refresh={this.fetchData}
                      onClose={() => {
                        this.setState({ editEventModal: false });
                        this.fetch_pending_orders();
                      }}
                      order={this.state.current_popup_data}
                    ></EditEvent>
                  </DialogPopup>
                  {/* <li className="nav-item " onClick={this.onresclick}>
                      <Link
                        className="nav-link"
                        to="/aboutus"
                        onClick={this.closeToggle("/aboutus")}
                      >
                        ABOUT US{" "}
                      </Link>
                    </li>
                    <li className="nav-item " onClick={this.onresclick}>
                      <Link
                        className="nav-link"
                        to="/contactus"
                        onClick={this.closeToggle("/contactus")}
                      >
                        CONTACT US{" "}
                      </Link>
                    </li> */}
                </ul>
              </div>

              {this.state.dropdown ? (
                <a
                  href="/login"
                  name="logoutLink"
                  onClick={() => this.clickToRedirect()}
                >
                  <div className="header-dropdown">Logout</div>
                </a>
              ) : (
                ""
              )}
              <Notification />
              {this.state.pwaToast && (
                <div className="installPWA">
                  <div className="inrFlx">
                    <img
                      src="./images/iosadd.svg"
                      alt="add_icon"
                      className="addIconImg"
                    />
                    <p>
                      Install the webapp on your iPhone: tap{" "}
                      <img
                        src="./images/iosupload.svg"
                        alt="menu_icon"
                        className="mnuIconImg"
                      />{" "}
                      and then Add to homescreen
                    </p>
                  </div>
                  <Button onClick={() => this.setState({ pwaToast: false })}>
                    Okay
                  </Button>
                </div>
              )}
            </nav>
          </header>
        ) : (
          <div className="header">
            <div
              className="menu-icon"
              onClick={() => {
                this.setState({ isMenuOpen: !this.state.isMenuOpen });
              }}
            >
              &#9776;
            </div>
            <div className="centered-logo">
              <img
                src="/images/logo-white.png"
                className="logo-mobile"
                alt="LOGO"
              />
            </div>
            {this.state.isMenuOpen && (
              <div className="mobile-menu">
                <div className="main-dash seller-D sdNavWrpr">
                  {[
                    "processor",
                    "admin",
                    "manager",
                    "producer",
                    "customer",
                  ].indexOf(user.role) !== -1 && (
                    <div
                      // to="/neworder"
                      className={`d-flex mb-4`}
                    >
                      <a
                        className="left-menu-item-create-new"
                        onClick={() =>
                          this.setState({ isOpen: true, isMenuOpen: false })
                        }
                      >
                        <FontAwesomeIcon
                          className="mr-3"
                          style={{ color: "#367BF5", fontSize: "22px" }}
                          icon={faPlusCircle}
                        />
                        <h1>Create new Order</h1>
                      </a>
                    </div>
                  )}
                  <Link
                    to="/processordashboard/pendingOrders"
                    className={`d-flex ${
                      window.location.pathname ===
                        "/processordashboard/pendingOrders" && "active"
                    }`}
                  >
                    <div className="left-menu-item">
                      <img
                        src="/images/icon-pending-order.svg"
                        className="left-menu-item-icon"
                        width="22"
                        height="22"
                      />
                      <div className="left-menu-item-text">
                        <h1 className="">Pending Orders</h1>
                        <span>View all scheduled orders in one place</span>
                      </div>
                    </div>
                    {/* {data.tile_count_loader && data.tile_count_loader ? (
                      <div className="cp-spinner cp-bubble"></div>
                    ) : (
                      <h3 className=" mb-0 cntNumber fw-sm montserrat">
                        {data.procOrdersCount}
                      </h3>
                    )} */}
                  </Link>
                  <Link
                    to="/processordashboard/openOrders"
                    className={`d-flex ${
                      window.location.pathname ===
                        "/processordashboard/openOrders" && "active"
                    }`}
                  >
                    <div className="left-menu-item">
                      <img
                        src="/images/icon-open-order.svg"
                        className="left-menu-item-icon"
                        width="22"
                        height="22"
                      />
                      <div className="left-menu-item-text">
                        <h1 className="">Open Orders</h1>
                        <span>View all open orders in one place</span>
                      </div>
                    </div>
                  </Link>
                  {["processor", "admin", "manager", "customer"].indexOf(
                    user.role
                  ) !== -1 && (
                    <Link
                      to="/allCustomer"
                      className={`d-flex ${
                        window.location.pathname === "/allCustomer" && "active"
                      }`}
                    >
                      <div className="left-menu-item">
                        <img
                          src="/images/icon-all-customer.svg"
                          className="left-menu-item-icon"
                          width="22"
                          height="22"
                        />
                        <div className="left-menu-item-text">
                          <h1 className="">All Customers</h1>
                          <span>View all customers information</span>
                        </div>
                      </div>
                    </Link>
                  )}
                  <Link
                    to="/processorCutSheet"
                    className={`d-flex ${
                      window.location.pathname === "/processorCutSheet" &&
                      "active"
                    }`}
                  >
                    <div className="left-menu-item">
                      <img
                        src="/images/icon-cut-sheet.svg"
                        className="left-menu-item-icon"
                        width="22"
                        height="22"
                      />
                      <div className="left-menu-item-text">
                        <h1 className="">Cut Sheets</h1>
                        <span>View all cut sheets at one place</span>
                      </div>
                    </div>
                  </Link>
                  {["processor", "admin", "manager"].indexOf(user.role) !==
                    -1 && (
                    <Link
                      to="/reports"
                      className={`d-flex ${
                        window.location.pathname === "/reports" && "active"
                      }`}
                    >
                      <div className="left-menu-item">
                        <img
                          src="/images/icon-report.svg"
                          className="left-menu-item-icon"
                          width="22"
                          height="22"
                        />
                        <div className="left-menu-item-text">
                          <h1 className="">Reports</h1>
                          <span>Get insights into the big data</span>
                        </div>
                      </div>
                    </Link>
                  )}
                  <div className="left-menu-profile">
                    <img
                      src={
                        user.avatar != null && user.avatar
                          ? process.env.REACT_APP_ROOT_URL +
                            "uploads/" +
                            user.avatar
                          : "/images/No_Image_Available.jpg"
                      }
                      className="left-menu-item-icon-img"
                      alt="profile"
                      width="20"
                      height="20"
                    />
                    <h1>{user.first_name}</h1>
                  </div>
                  <hr
                    style={{ width: "80%", marginTop: "0", marginBottom: "0" }}
                  ></hr>
                  <Link to="/processorprofile">
                    <div
                      className={`d-flex left-menu-items ${
                        window.location.pathname === "/processorprofile" &&
                        "left-menu-items-active"
                      }`}
                    >
                      <FontAwesomeIcon
                        className="mr-4"
                        style={{ color: "#78909C" }}
                        icon={faGear}
                        width="20"
                        height="20"
                      />
                      <h1>Settings</h1>
                    </div>
                  </Link>
                  <hr
                    style={{ width: "80%", marginTop: "0", marginBottom: "0" }}
                  ></hr>
                  {["processor", "admin", "manager"].indexOf(user.role) !==
                    -1 && (
                    <Link to="/processor/users-and-roles">
                      <div
                        className={`d-flex left-menu-items ${
                          window.location.pathname ===
                            "/processor/users-and-roles" &&
                          "left-menu-items-active"
                        }`}
                      >
                        <FontAwesomeIcon
                          className="mr-4"
                          style={{ color: "#78909C" }}
                          icon={faUserCircle}
                          width="20"
                          height="20"
                        />
                        <h1>Users and Roles</h1>
                      </div>
                    </Link>
                  )}
                  <hr
                    style={{ width: "80%", marginTop: "0", marginBottom: "0" }}
                  ></hr>
                  <div
                    className="left-menu-items"
                    onClick={() => {
                      this.setState({ isOpenVersion: true, isMenuOpen: false });
                    }}
                  >
                    <FontAwesomeIcon
                      className="mr-4"
                      style={{ color: "#78909C" }}
                      icon={faRefresh}
                      width="20"
                      height="20"
                    />
                    <h1>Version</h1>
                  </div>
                  <hr
                    style={{ width: "80%", marginTop: "0", marginBottom: "0" }}
                  ></hr>
                  <div className="left-menu-items" hidden>
                    <FontAwesomeIcon
                      className="mr-4"
                      style={{ color: "#78909C" }}
                      icon={faInfo}
                      width="20"
                      height="20"
                    />
                    <h1>What's New?</h1>
                  </div>
                  <hr
                    style={{ width: "80%", marginTop: "0", marginBottom: "0" }}
                  ></hr>
                  <a href="mailto:support@grazr.net">
                    <div className="left-menu-items">
                      <FontAwesomeIcon
                        className="mr-4"
                        style={{ color: "#78909C" }}
                        icon={faQuestionCircle}
                        width="20"
                        height="20"
                      />
                      <h1>Customers Support</h1>
                    </div>
                  </a>
                  <hr
                    style={{ width: "80%", marginTop: "0", marginBottom: "0" }}
                  ></hr>
                  <div className="left-menu-items-button">
                    <a className="btn-mini" href="#" hidden>
                      Help
                    </a>
                    <Link to="/privacypolicy">
                      <a className="btn-mini" href="#">
                        Privacy
                      </a>
                    </Link>
                    <Link to="/termscondition">
                      <a className="btn-mini" href="#">
                        Terms
                      </a>
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        {this.state.isOpen && (
          <DialogPopup
            title="New Order"
            subtitle={this.state.currentDate}
            maxWidth="lg"
            open={this.state.isOpen}
            onClose={(e) => () => this.setState({ isOpen: false })}
            style={{ marginTop: "5rem" }}
          >
            <NewOrder
              onClose={() => this.setState({ isOpen: false })}
            ></NewOrder>
          </DialogPopup>
        )}
        {this.state.isOpenVersion && (
          <DialogPopup
            title="Version"
            subtitle={"v. 2.0"}
            maxWidth="xs"
            open={this.state.isOpenVersion}
            onClose={(e) => () => this.setState({ isOpenVersion: false })} // Close the dialog when requested
            style={{ marginTop: "5rem" }}
          >
            {/* <span>ggwp</span> */}
          </DialogPopup>
        )}
      </div>
    );
  }
}
function MaterialToggle(props) {
  const size = useWindowSize();
  const [material, setMaterial] = useState();
  useEffect(() => {
    if (size.width >= 992) {
      setMaterial();
    }
  });
  return (
    <>
      <button
        className="navbar-toggler"
        type="button"
        onClick={(e) => setMaterial(true)}
      >
        <span className="navbar-toggler-icon" />
        <NotesIcon style={pStyle} />
      </button>
      <SwipeableDrawer open={material}>
        <ul>
          <li>first lement</li>
          <li>second lement</li>
          <li>thrid lement</li>
        </ul>
      </SwipeableDrawer>
    </>
  );
}
function useWindowSize() {
  const isClient = typeof window === "object";

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}
function mapStateToProps(state) {
  const { user, loggedIn, class: classs } = state.login;
  const { msg, msgColor, msgLink } = state.header;
  const { cartData } = state.Cart;

  return { user, loggedIn, msg, msgColor, msgLink, cartData, classs };
}

export default connect(mapStateToProps)(Header);
